import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ConfigurationService } from '../../configuration/services/configuration.service';
import { ConfigurationEnum } from '../../../../common/configuration/enums/configuration.enum';

@Directive({
  selector: '[apConfiguration]'
})
export class ConfigurationDirective implements OnInit {
  @Input('apConfiguration') public allowedConfigurations: ConfigurationEnum[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private configurationService: ConfigurationService,
  ) {
  }

  ngOnInit() {
    let allow = false;

    for (const allowedConfiguration of this.allowedConfigurations) {
      if (this.configurationService.isOn(allowedConfiguration)) {
        allow = true;
      }
    }

    if (allow) {
      this.setView();
    } else {
      this.hideView();
    }
  }

  private setView() {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private hideView() {
    this.viewContainer.clear();
  }
}