<vex-secondary-toolbar>
    <vex-breadcrumbs [crumbs]="crumbs" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>
<div class="page-content">
    <div class="row ap-card shadow">
        <page-header titleTranslate="SPA.SYSTEM_CONFIG.HEADER"></page-header>
        <div class="row checkbox-container">
            <ng-container *ngFor="let configuration of configurations">
                <mat-slide-toggle class="checkbox" [disabled]="isWorkstep(configuration.property) && isWorkstepDisabled()"
                        *ngIf="settingExists(configuration.property)" [(ngModel)]="configuration.value" (ngModelChange)="configChanged(configuration)">
                    {{prepareTransLabel(configuration.property) | translate}}
                </mat-slide-toggle>
            </ng-container>
        </div>
        <div class="row">
            <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
                <button color="warn" mat-button type="button" [routerLink]="['..']"> {{ 'COMMON.CANCEL' | translate }}</button>
                <ap-async-button text="COMMON.SAVE" (onClick)="save()" [inProgress]="inProgress"></ap-async-button>
            </div>
        </div>
    </div>
</div>
