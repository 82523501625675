<div class="columns-selector">
    <button [matMenuTriggerFor]="columnsMenu"
            class="ml-4"
            color="primary"
            fxFlex="none"
            mat-mini-fab
            type="button">
        <i class="material-icons mat-icon-rtl-mirror">{{icon}}</i>
    </button>
    <mat-menu #columnsMenu="matMenu" [overlapTrigger]="false">
        <ng-container *ngFor="let column of columns">
            <button *ngIf="!column.isAlwaysVisible" (click)="toggleColumnVisibility(column, $event)"
                    class="checkbox-item mat-menu-item">
                <mat-checkbox [(ngModel)]="column.isVisible">
                </mat-checkbox>
                {{ translatable ? (column.id | translate) : column.id }}
            </button>
        </ng-container>
    </mat-menu>
</div>

