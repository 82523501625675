import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { SlidesControlsService } from '../../services/slides-controls.service';
import { SlideshowButtonEnum } from '../../../../../common/andon/enums/slideshow-button.enum';
import { SingleIoInterface } from '../../../../../common/io-module/interfaces/single-io.interface';
import { SlideshowStatusEnum } from '../../../../../common/andon/enums/slideshow-status.enum';
import { SocketService } from '../../../_services/socket.service';
import { SocketChannel } from '../../../../../common/socket/utils/socket-channel';
import { SocketMessageEnum } from '../../../../../common/socket/enums/socket-message.enum';
import { SlideshowStatusDataSocket } from '../../../../../common/andon/interfaces/slideshow-status-data.socket';
import { SlideshowInterface } from '../../../../../common/slideshow/interfaces/slideshow.interface';
import { SlideshowButtonInterface } from '../../../../../common/slideshow/interfaces/slideshow-button.interface';
import { ConfigurationEnum } from '../../../../../common/configuration/enums/configuration.enum';

@Component({
  selector: 'app-andon-edit',
  templateUrl: './andon-edit.component.html',
  styleUrls: ['./andon-edit.component.scss']
})
export class AndonEditComponent implements OnInit, OnDestroy {

  @ViewChild('stationFtpForm') stationFtpForm;

  andonId: number;
  SlideshowButtonEnum = SlideshowButtonEnum;
  SlideshowStatusEnum = SlideshowStatusEnum;
  ConfigurationEnum = ConfigurationEnum;
  allIos: SingleIoInterface[] = [];
  slideshow: SlideshowInterface;
  socketSubs: string[] = [];
  constructor(
    private route: ActivatedRoute,
    private _location: Location,
    private socket: SocketService,
    public slidesControlsService: SlidesControlsService
  ) {
  }

  ngOnInit() {
    this.andonId = this.route.snapshot.params.id;
    this.getIos();
    this.slidesControlsService.getSlideshow(this.andonId).subscribe((result) => {
      this.slideshow = result;
    });
    this.initSocket();
  }

  initSocket() {
    this.socket.join(SocketChannel.SLIDESHOW);
    this.socket.join(SocketChannel.IO_MODULE);
    this.socketSubs.push(this.socket.on(SocketMessageEnum.SLIDESHOW_STATUS_CHANGED, (data: SlideshowStatusDataSocket) => {
      if (!data.andonId || data.andonId === this.andonId) {
        this.slideshow.status = data.status;
      }
    }));
    this.socketSubs.push(this.socket.on(SocketMessageEnum.IO_CONNECTION_CHANGED, (data: { ioId: number }) => {
        if (!data.ioId || this.allIos.map(i => i.id).includes(data.ioId)) {
          this.getIos();
        }
    }));
  }

  ngOnDestroy(): void {
    this.socket.leave(SocketChannel.SLIDESHOW);
    this.socket.leave(SocketChannel.IO_MODULE);
    this.socketSubs.forEach(s => this.socket.off(s));
  }

  clickButton(typeEnum: SlideshowButtonEnum) {
    switch (typeEnum) {
      case SlideshowButtonEnum.START_PAUSE:
        this.slidesControlsService.startStop(this.andonId);
        break;
      case SlideshowButtonEnum.FORWARD:
        this.slidesControlsService.forward(this.andonId);
        break;
      case SlideshowButtonEnum.REWIND:
        this.slidesControlsService.rewind(this.andonId);
        break;
    }
  }

  setIo(button: SlideshowButtonInterface, ioId: number) {
    this.slidesControlsService.setIo(button, ioId);
    button.ioId = ioId;
  }

  private getIos() {
    this.slidesControlsService.getIos().subscribe((result) => {
      this.allIos = result;
    });
  }
}
