<div class="disorder-row" (click)="expanded = !expanded" [ngStyle]="{'margin-left': marginLeft + 'px'}"  *ngIf="!isEditing()">
    <div class="code-and-icon" [ngStyle]="{'width': 'calc(20% - ' + marginLeft + 'px)'}">
        <div class="icon">
            <mat-icon class="mat-icon-rtl-mirror" *ngIf="disorder.children?.length">
                {{expanded ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
        </div>
        <div class="code">
            {{disorder.code}}
        </div>
    </div>
    <div class="reason">
        {{disorder.reason}}
    </div>
    <div class="actions">
        <div *ngIf="isAddEditListing()" class="actions-container">
            <div class="action-button">
                <button mat-icon-button *ngIf="!isLastNode" (click)="addChild($event)">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div class="action-button">
                <button mat-icon-button (click)="edit()">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
            <div class="action-button">
                <button mat-icon-button (click)="delete(disorder, $event)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
<app-edit-disorder-row [disorder]="disorder" *ngIf="isEditing()"  (disorderSaved)="onDisorderSaved($event)"></app-edit-disorder-row>
<ng-container *ngIf="expanded">
    <app-disorder-row *ngFor="let child of disorder.children" [disorder]="child" [marginLeft]="marginLeft + 20" [isLastNode]="true" (onDelete)="onChildDelete($event)"></app-disorder-row>
</ng-container>

