<div class="page-header">
	<page-header titleTranslate="SPA.WORKSTEP_MANAGER.TRANSLATION_MODEL.HEADER" [onStation]="true"
		tabindex="0">
	</page-header>
</div>
<div mat-dialog-content>
	<div>
		<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="free-text">
			<mat-form-field fxFlex="auto" appearance="outline" class="  green" color="primary">
				<mat-label> {{ "SPA.WORKSTEP_MANAGER.TRANSLATION_MODEL.LANGUAGE" | translate }}

				</mat-label>

				<mat-select [(ngModel)]='selectedLanguage' (ngModelChange)="getTranslationByLang($event)">
					<mat-option *ngFor="let lang of languages | stringEnumToArray" [value]="languages[lang]" >
						{{lang}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="free-text">
			<app-quill-textarea *ngIf="data.isTextArea" [text]="translationText" (changed)="translationText = $event"
				[backgroundColor]="data?.backgroundColor"></app-quill-textarea>
			<mat-form-field *ngIf="!data.isTextArea" fxFlex="auto" appearance="outline" class="  green" color="primary">
				<mat-label>{{ "SPA.WORKSTEP_MANAGER.TRANSLATION_MODEL.TEXT" | translate }}</mat-label>
				<input matInput [(ngModel)]="translationText">
				<!-- [matKeyboardOwn] -->
			</mat-form-field>

		</div>
	</div>
</div>
<div>
	<div class="translation-table">
		<table>
			<tr>
				<th>{{ "SPA.WORKSTEP_MANAGER.TRANSLATION_MODEL.LANGUAGE" | translate }}</th>
				<th>{{ "SPA.WORKSTEP_MANAGER.TRANSLATION_MODEL.TEXT" | translate }}</th>
			</tr>
			<tr *ngFor="let lang of languages | stringEnumToArray">
						<td> {{ lang }} </td>
						<td *ngIf="!data.isTextArea"> {{ getTranslationByLang(languages[lang], true) }}</td>
						<td *ngIf="data.isTextArea"> <quill-view [content]="getTranslationByLang(languages[lang], true)" format="html" theme="snow"></quill-view></td>
					</tr>
		</table>
	</div>
<div class="footer-btns">
	<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
		<button color="primary" mat-button (click)="cancel()" type="button">
			{{ "SPA.WORKSTEP_MANAGER.TRANSLATION_MODEL.CANCEL_BTN" | translate }}
		</button>
		<button color="primary" mat-raised-button (click)='ok(true)' [disabled]="!(!!translationText && !!selectedLanguage)">
			{{ "SPA.WORKSTEP_MANAGER.TRANSLATION_MODEL.SAVE_AND_ADD_NEW_BTN" | translate }}
		</button>
		<button color="primary" mat-raised-button (click)='ok(false)' [disabled]="!(!!translationText && !!selectedLanguage)">
			{{ "SPA.WORKSTEP_MANAGER.TRANSLATION_MODEL.SAVE_AND_CLOSE_BTN" | translate }}
		</button>
	</div>
</div>