import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { AuthService } from '../../auth/auth.service';
import {Observable} from 'rxjs';
import {SettingsService} from '../../core/services/settings.service';
import { ConfigurationEnum } from '../../../../common/configuration/enums/configuration.enum';
import { ConfigurationDataInterface } from '../../../../common/configuration/interfaces/configuration-data.interface';

@Injectable()
export class ConfigurationService {

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        public settings: SettingsService
    ) {
    }

    get configurations() {
        return this.settings.getConfigurations();
    }

    public updateConfiguration(configuration: ConfigurationDataInterface[]): Observable<ConfigurationDataInterface[]> {
        return this.http.post<ConfigurationDataInterface[]>(`/config`, configuration);
    }

    isOn(property: ConfigurationEnum): boolean {
        if (this.authService.isSuperAdmin()) {
            return true;
        }

        const configuration = this.configurations.find(c => c.property === property);

        if (!configuration) {
            throw new Error('Configuration array does not contain proper data');
        }

        return configuration.value;
    }

    get trackAndTrace(): boolean {
        return this.isOn(ConfigurationEnum.TRACK_AND_TRACE);
    }

    get reports(): boolean {
        return this.isOn(ConfigurationEnum.REPORTS);
    }

    get ftp(): boolean {
        return this.isOn(ConfigurationEnum.FTP);
    }

    get workstepManager(): boolean {
        return this.isOn(ConfigurationEnum.WORKSTEP_MANAGER);
    }

    get remoteStationView(): boolean {
        return this.isOn(ConfigurationEnum.REMOTE_STATION_VIEW);
    }

    get alarmsAndEscalations(): boolean {
        return this.isOn(ConfigurationEnum.ALARMS_AND_ESCALATIONS);
    }

    get charts(): boolean {
        return this.isOn(ConfigurationEnum.CHARTS);
    }

    get deviceConfiguration(): boolean {
        return this.isOn(ConfigurationEnum.DEVICE_CONFIGURATION);
    }
}