<ng-template #sidenavRef>
  <vex-sidenav [collapsed]="sidenavCollapsed$ | async"></vex-sidenav>
</ng-template>

<ng-template #toolbarRef>
  <vex-toolbar [hasShadow]="toolbarShadowEnabled$ | async"
               [mobileQuery]="!(isDesktop$ | async)"
               class="vex-toolbar"
               [ngClass]="{ 'banner-offset': showBanner && authService.isSuperAdmin() }"
               ></vex-toolbar>
</ng-template>

<ng-template #quickpanelRef>
  <vex-quickpanel></vex-quickpanel>
</ng-template>

<ng-template #bannerRef >
  <vex-banner *ngIf="showBanner && authService.isSuperAdmin()"></vex-banner>
</ng-template>

<vex-layout [quickpanelRef]="quickpanelRef"
            [sidenavRef]="sidenavRef"
            [toolbarRef]="toolbarRef"
            [bannerRef]="bannerRef"></vex-layout>
