import { Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {QuillService} from 'ngx-quill';

@Component({
  selector: 'app-quill-textarea',
  templateUrl: './quill-textarea.component.html',
  styleUrls: [
      './quill-textarea.component.scss',
      '../../../../../../../node_modules/quill/dist/quill.snow.css',
      '../../../../../../@vex/styles/partials/plugins/_quill.scss'
  ],
    encapsulation: ViewEncapsulation.None,
    providers: [QuillService]
})
export class QuillTextareaComponent {

    @Input() text: string;
    @Input() backgroundColor: string = '#FFFFFF';
    @Output() changed = new EventEmitter();

}
