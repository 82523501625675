<mat-dialog-content>
    <p style="margin-bottom: 8px"> {{ 'SPA.ALARMS_ESCALATIONS.UPDATE_REASON.LABEL' | translate}}</p>
    <div class="reasons-list-container">
        <div class="reasons-list-bread-crumb" *ngIf='(breadCrumb.length > 1)'>
            <span *ngFor='let b of breadCrumb; let i = index;'>
                <span *ngIf='i >0'> / </span> <span [ngClass]="i != (breadCrumb.length-1) ? 'link' : ''"
                    (click)='goTo(b , i)'>{{b.text}} </span>
            </span>
        </div>

        <div class="clearfix reason-row" *ngFor='let reason of displayedReasons.children'
            (click)='reasonClicked(reason)'>
            <div class="select-contaier">
                <span *ngIf='(!reason.children) || (reason.children && reason.children.length ==0)'
                    class="select-reason"
                    [ngClass]="selectedDisorderReason && selectedDisorderReason.id == reason.id ? 'selected':''">
                </span>

                <span *ngIf='reason.children && reason.children.length >0' class="">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </span>
            </div>
            <div class="reason">
                {{ getTranslatedName('name', reason?.translationJson) }}
                <div class="reason-path" *ngIf='reason.path'>{{reason.path}}</div>
            </div>
        </div>
    </div>
    <div class="alarm-data" *ngIf="data && data.modalType === 'endAlarm' && data.customFieldsData">
        <ng-container *ngFor="let extraField of data.customFieldsData">
          <ng-container *ngIf="extraField.type == 'String' && !!extraField?.allowEndingAlarm">
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="free-text">
              <mat-form-field fxFlex="auto" appearance="outline" class="  green" color="primary">
                <mat-label>{{ getTranslatedName('name', extraField.translationJson) }}</mat-label>
                <textarea matInput [(ngModel)]="extraField.value"></textarea>
              </mat-form-field>
    
      
    
            </div>
          </ng-container>
    
          <ng-container *ngIf="extraField.type == 'Boolean' && !!extraField?.allowEndingAlarm">
    
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="free-text">
              <mat-form-field fxFlex="auto" appearance="outline" class="  green" color="primary">
                <mat-label>{{ getTranslatedName('name', extraField.translationJson) }}</mat-label>
      
                <mat-select  [(ngModel)]="extraField.value">
                  <mat-option *ngFor="let answer of ['YES' , 'NO']" [value]="answer">
                    {{answer}}
                  </mat-option>
                </mat-select>
      
      
              </mat-form-field>
            </div>
          </ng-container>
    
    
    
          <ng-container *ngIf="extraField.type == 'Number' && !!extraField?.allowEndingAlarm">
         
    
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="free-text">
              <mat-form-field fxFlex="auto" appearance="outline" class="  green" color="primary">
                <mat-label>{{ getTranslatedName('name', extraField.translationJson) }}</mat-label>
                <input type="number" matInput [(ngModel)]="extraField.value">
              </mat-form-field> 
    
            </div>
          </ng-container>
    
        <!-- NEED TO MAKE CHANGES FOR THE DROPDOWN -->
        <ng-container *ngIf="extraField.type == 'Dropdown' && !!extraField.dropdownOptions && !!extraField?.allowEndingAlarm">
          <div class="dropdown-field">
            <mat-form-field fxFlex="auto" (click)="openDropdownOptionModal($event, extraField)" appearance="outline" class="dropDown green" color="primary">
              <mat-label>{{ getTranslatedName('name', extraField.translationJson) }}</mat-label>
              <input 
                matInput
                type="text" 
                readonly
                ngDefaultControl
              >
              <!-- [(ngModal)]="dropdownSearch"-->
              <mat-icon matSuffix>arrow_drop_down</mat-icon>
            </mat-form-field>
            <div class="tag-section">
               <span class="tag" *ngFor="let element of extraField.value; let i=index">
                    {{ element.name }} ( {{ element.count}} )
                    <span style="color: black; margin-left: 6px; cursor: pointer;" (click)="deleteDropdownOption(element, extraField)"> X </span>
               </span> 
            </div>
          </div>
        </ng-container>
    
        </ng-container>
    
    
    
    
    
    
    
      </div>
    <div  class="markup-div" *ngIf="showDisorderUnderInvestigation">
        <div class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" style="padding: 10px; justify-content: space-between;">
            <mat-slide-toggle
              class="checkbox"
              [checked]="true"
              [(ngModel)]="isDisorderUnderInvestigation"
            ></mat-slide-toggle>
            <mat-label class="label">{{
              'SPA.ALARMS_ESCALATIONS.UPDATE_REASON.ALARM_UNDER_INVESTIGATION' | translate
            }}</mat-label>
        </div>
        
        <mat-form-field appearance="outline" class="comment-div" *ngIf="isDisorderUnderInvestigation" style="display: block">
          <mat-label>{{ 'SPA.ALARMS_ESCALATIONS.UPDATE_REASON.DESCRIPTION_TEXT' | translate }}</mat-label>
          <input matInput placeholder="Placeholder" [(ngModel)]="comment">
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button matDialogClose>{{ 'SPA.ALARMS_ESCALATIONS.UPDATE_REASON.CANCEL' | translate}}</button>
    <button mat-raised-button color="primary" (click)='confirm()' [disabled]="!selectedDisorderReason && data?.configuredAlarm?.requireAlarmEndingReason">{{
        'SPA.ALARMS_ESCALATIONS.UPDATE_REASON.CONFIRM' | translate}}</button>
</mat-dialog-actions>