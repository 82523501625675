import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { WorkInstructionsService } from '../work-instructions.service';
import { LineService } from '../../line/line.service';
import { FileInput } from 'ngx-material-file-input';
import { StationFullInterface } from '../../../../common/station/interfaces/station-full.interface';
import { DeviceService } from '../../shared/components/devices/services/device.service';
import { WorkInstructionDataInterface } from '../../../../common/work-instructions/interfaces/work-instruction-data.interface';
import { InfoboxService } from '../../../../common/shared/services/infobox.service';

@Component({
  selector: 'app-work-instructions-add',
  templateUrl: './work-instructions-add.component.html',
  styleUrls: ['./work-instructions-add.component.scss']
})
export class WorkInstructionsAddComponent implements OnInit {
  stations: StationFullInterface[] = [];
  workInstruction: WorkInstructionDataInterface = {
    id: null,
    name: '',
    stations: [],
    file: null,
    folder: ''
  };
  fileName: string = '';
  lineId: number;
  loading: boolean = false;
  constructor(
    private workInstructionsService: WorkInstructionsService,
    private _location: Location,
    private deviceService: DeviceService,
    private lineService: LineService,
    private infoboxService: InfoboxService
  ) {}

  ngOnInit() {
    this.lineId = this.lineService.getSelectedLine();
    this.deviceService.init(this.lineId);
    this.deviceService.getDevices().subscribe(response => {
      this.stations = response;
    });
  }

  save() {
    if ((!(/^[A-Za-z0-9/ äöü]*$/.test(this.workInstruction.folder))) || (!(/^[A-Za-z0-9/ äöü]*$/.test(this.workInstruction.name)))) {
      this.infoboxService.pushInfo({danger: true, text: 'SPA.WORK_INSTRUCTIONS.FOLDER_ERROR'});
      this.loading = false;
      return;
    }
    this.loading = true;
    this.workInstructionsService.createWorkInstruction(this.lineId, this.workInstruction).subscribe(
      res => {
        if (res) {
          this.loading = false;
          this.goBack();
        }
      }, err =>{
        this.loading = false;
      });
  }

  goBack() {
    this._location.back();
  }

  disableSave() {
    //@todo this doesn't work...
    return ((!!!this.workInstruction.name || this.workInstruction.name === '') || !!!this.workInstruction.file || this.workInstruction.stations.length === 0);
  }

  fileChangeEvent(event: FileInput) {
    const file = event.files[0];
    this.workInstruction.file = file;
    this.workInstruction.name = this.workInstruction.name.length ? this.workInstruction.name : file.name;
    this.fileName = file.name;
  }
}