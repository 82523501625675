import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EmailConfigurationInterface } from '../../../../common/email/interfaces/email-configuration.interface';
import { CheckEmailConfigurationResponseInterface } from '../../../../common/email/interfaces/check-email-configuration-response.interface';
import { StorageService } from '../../../../common/shared/services/storage.service';


@Injectable()
export class EmailConfigurationService {

    constructor(
        private http: HttpClient,
        private storage: StorageService
    ) {
        this.apiUrl = environment.apiUrl;
    }

    apiUrl: String;

    getConfiguration(): Observable<EmailConfigurationInterface> {
        return this.http
            .get<EmailConfigurationInterface>( this.apiUrl + `/api/email-configuration`,
                {headers: this.getHeader()});
    }

    updateConfiguration(configuration): Observable<HttpResponse<any>> {
        return this.http
            .post<any>(this.apiUrl + `/api/email-configuration`, 
                configuration, {headers: this.getHeader(), observe: 'response'});
    }

    deleteConfiguration() {
        return this.http.delete<boolean>(this.apiUrl + `/api/email-configuration`);
    }

    checkEmailConfiguration(configuration: EmailConfigurationInterface): Observable<CheckEmailConfigurationResponseInterface> {
        return this.http
            .post<CheckEmailConfigurationResponseInterface>(this.apiUrl + `/api/email-configuration/check`,
              configuration,
                {headers: this.getHeader()}
            );
    }

    private getHeader(): HttpHeaders {
        return new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.storage.getItem('token')
            });
    }
}
