import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ICONS} from '../../../core/icons/icons';

@Component({
  selector: 'icon-select',
  templateUrl: './icon-select.component.html',
  styleUrls: ['./icon-select.component.scss']
})
export class IconSelectComponent implements OnInit {

    iconList = ICONS;
    page = 1;
    @Input() icon: string;
    @Input() height: string = '100%';
    @Input() label: string;
    @Input() perPage = 40;
    @Output() selected = new EventEmitter<string>();

    constructor() { }

    ngOnInit(): void {
        if (this.icon) {
            this.iconList = this.iconList.filter((icon) => icon != this.icon);
            this.iconList.unshift(this.icon);
        }
    }

    getIconList() {
        return this.iconList.slice(0, this.page * this.perPage);
    }

    canLoadMore(): boolean {
        return this.page * this.perPage < this.iconList.length;
    }
}
