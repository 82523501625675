import { Routes } from '@angular/router';
import { AuthGuard } from '../../_guards/auth.guard';
import { UserPermissionsEnum } from '../../../../common/auth/enums/user-permissions.enum';
import { GeneralSettingsComponent } from './general-settings.component';

export const GENERAL_SETTINGS_ROUTES: Routes = [
    {
        path: '',
        component: GeneralSettingsComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: [UserPermissionsEnum.GeneralSettingsManagement],
            configuration: null,
            breadcrumb: {
                label: 'SPA.GENERAL_SETTINGS.LABEL',
            }
        },
    }
];
