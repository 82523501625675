import { ChartDataTypeEnum } from '../../../common/charts/enums/chart-data-type.enum';
import { TableDataTypeEnum } from '../../../common/charts/enums/table-data-type.enum';

export interface ChartToTableMapperData {
  tableDataTypeEnum: TableDataTypeEnum,
  headers: string[]
}

export class ChartToTableMapper {
  static map(chartDataType: ChartDataTypeEnum): ChartToTableMapperData {
    switch (chartDataType) {
      case ChartDataTypeEnum.DISORDER_AMOUNT:
        return {
          tableDataTypeEnum: TableDataTypeEnum.DISORDER_AMOUNT,
          headers: [
            'SPA.CHARTS.TABLE.DISORDER_AMOUNT.REASON',
            'SPA.CHARTS.TABLE.DISORDER_AMOUNT.COUNT',
          ],
        };
      case ChartDataTypeEnum.ALARM_AMOUNT:
        return {
          tableDataTypeEnum: TableDataTypeEnum.ALARM_AMOUNT,
          headers: [
            'SPA.CHARTS.ALARM_AMOUNT',
            'SPA.CHARTS.TABLE.DISORDER_AMOUNT.COUNT',
          ],
        };
      case ChartDataTypeEnum.BEAT:
        return {
          tableDataTypeEnum: TableDataTypeEnum.BEAT,
          headers: [
            'SPA.CHARTS.TABLE.BEAT.DATE',
            'SPA.CHARTS.TABLE.BEAT.CREATED',
            'SPA.CHARTS.TABLE.BEAT.BEAT_TIME',
          ],
        };
      case ChartDataTypeEnum.SHOULD_IS:
        return {
          tableDataTypeEnum: TableDataTypeEnum.SHOULD_IS,
          headers: [
            'SPA.CHARTS.TABLE.SHOULD_IS.DATE',
            'SPA.CHARTS.TABLE.SHOULD_IS.SHIFT_BEGIN',
            'SPA.CHARTS.TABLE.SHOULD_IS.SHIFT_END',
            'SPA.CHARTS.TABLE.SHOULD_IS.SHOULD',
            'SPA.CHARTS.TABLE.SHOULD_IS.IS',
            'SPA.CHARTS.TABLE.SHOULD_IS.DATE_OCCURRED',
            'SPA.CHARTS.TABLE.SHOULD_IS.TIME_OCCURRED',
            'SPA.CHARTS.TABLE.SHOULD_IS.DURATION',
            'SPA.CHARTS.TABLE.SHOULD_IS.OVER_BEAT_TIME',
            'SPA.CHARTS.TABLE.SHOULD_IS.REASON',
            'SPA.CHARTS.TABLE.SHOULD_IS.PART_NUMBER',
            'SPA.CHARTS.TABLE.SHOULD_IS.TEXT',
            'SPA.CHARTS.TABLE.SHOULD_IS.STATION_NAME',
          ],
        };
      default:
        return null;
    }
  }
}