<mat-card>

    <!-- <div style="display: flex; align-items: center; margin-bottom: 4px; column-gap: 14px">
        <page-header titleTranslate="SPA.ALARMS_ESCALATIONS.ALARM_REASONS.HEADER"></page-header>
        <span style="flex-grow: 1"></span>
        <button mat-raised-button color="primary"
                (click)="ExportToCSV()">{{'SPA.ALARMS_ESCALATIONS.ALARM_REASONS.EXPORT_TO_CSV' | translate }}</button>
        <button mat-raised-button color="primary" (click)="importFromCSV()">{{'SPA.ALARMS_ESCALATIONS.ALARM_REASONS.IMPORT_FROM_CSV' | translate }}</button>
    </div> -->

    <div cdkDropListGroup>
        <app-alarm-reasons-item *ngFor="let item of root.children" [reason]="item"

        (onDelete) = "mainParentDeleted(item.id)" 
                                [parent]="root" (showLoading) = 'handleLoading()'></app-alarm-reasons-item>

    </div>

</mat-card>

<button mat-fab class="ap-fab" (click)="AddReason()">
    <mat-icon>add</mat-icon>
</button>

