import {Observable, throwError as observableThrowError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {InfoboxService} from '../../../../common/shared/services/infobox.service';
import { TranslateService } from '../../translate/services/translate.service';
import { BackupModeService } from '../../../../common/backups/services/backup-mode.service';

enum ErrorStatus {
    BadRequest = 400,
    ServerError = 500,
    Locked = 423,
    PayloadTooLarge = 413,
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
      private translate: TranslateService,
      private toastService: InfoboxService,
      private backupModeService: BackupModeService
    ) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.params.has('ignoreErrorInterception')) {
            return next.handle(req);
        }

        return this.overrideRequest(req, next);
    }

    private overrideRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
                switch (err.status) {
                    case ErrorStatus.BadRequest:
                        return this.handleGeneralError(err);
                    case ErrorStatus.ServerError:
                        return this.handleServerError(err);
                    case ErrorStatus.Locked:
                        this.backupModeService.backupModeStarted();
                        break;
                    case ErrorStatus.PayloadTooLarge:
                        return this.handlePayloadTooLarge(err);
                    default:
                        return observableThrowError(err);
                }
            }));
    }

    private handleGeneralError(err: HttpErrorResponse): Observable<any> {
        if (err.error['ap-message']) {
            const params = err.error['params'] || null;
            this.translate.get(err.error['ap-message'], params)
              .subscribe((translation) => {
                this.toastService.error(translation);
              }
            );
        }

        return observableThrowError(err);
    }

    private handleServerError(err: HttpErrorResponse): Observable<any> {
        this.translate.get('API.GENERAL.ERROR')
          .subscribe((translation) => {
                this.toastService.error(translation);
            }
          );

        return observableThrowError(err);
    }

    private handlePayloadTooLarge(err: HttpErrorResponse): Observable<any> {
        this.translate.get('API.GENERAL.PAYLOAD_TOO_LARGE')
          .subscribe((translation) => {
              this.toastService.error(translation);
          }
        );

        return observableThrowError(err);
    }
}