import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { HOUR_IN_SECONDS, MILLISECONDS_IN_SECOND, TIME_FORMAT_EXTENDED } from '../constants/time.constants';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform(value: number): string {

    if(!value || isNaN(value)){
      return;
    }
    const sign = value >= 0
      ? ''
      : '-';

    value = Math.abs(value);

    // const format = value >= HOUR_IN_SECONDS
    //   ? 'HH:mm:ss'
    //   : 'mm:ss';
    const format = 'HH:mm:ss'

    let days;
     if (value > 86400) {
      days = Math.floor(value / 86400);
    }
    let daysMsg = "";
    // days = days - 1;
    if (days && days > 1) {
      daysMsg = ((days < 10 ? '' : '') + days + ' d. ') + "";
    }

    return daysMsg + `${sign}${moment.utc(value * MILLISECONDS_IN_SECOND).format(format)}`;
  }
}
