<mat-card>
    <page-header
            [titleTranslate]="alarmId ?  'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.UPDATE_ALARM' :'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ADD_ALARM'"></page-header>
    <div>

        <form #form="ngForm">
            <h3>{{'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.GENERAL_DATA' | translate }}</h3>
            <div class="cols-3">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.NAME' | translate }}</mat-label>
                    <input matInput
                           type="text"
                           name="NAME" [(ngModel)]="model.translationJson[translateService.userLang].name"
                           required
                           placeholder="{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.NAME' | translate }}">
                           <mat-icon matSuffix class="translate-language"  (click)="openAddTranslationModal('name',model.translationJson)">edit</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" color="primary">
                    <mat-label>{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.PRODUCTION_SECTOR' | translate }}</mat-label>
                    <mat-select required name="productionSector" [(ngModel)]="model.productionSector" multiple [compareWith]="compareObjects" >
                        <mat-option #allSelected (click)="toggleAllSelection()" value="All">All</mat-option>
                        <mat-option *ngFor = 'let line of allLines' [value]="line" (click)="productionSectorChanged()">{{line.name}}</mat-option>
                    
                    </mat-select>
                </mat-form-field>
               <mat-form-field appearance="outline" color="primary" *ngIf="model.productionSector && model.productionSector.length >0">
                    <mat-label>{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.STATION' | translate }}</mat-label>
                    <mat-select required name="SELECT_STATION" [(ngModel)]="model.selectStation" multiple  [compareWith]="compareObjects" >
                        <mat-option #allSelected (click)="selectAllStation()" value="All">All</mat-option>
                                <mat-option (click)="selectOneStation()" *ngFor = 'let st of allStations'
                                [value]="st">{{st.name}}</mat-option>
                    </mat-select>

                </mat-form-field>

                <mat-form-field appearance="outline" color="primary">
                    <mat-label>{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.PRIORITY' | translate }}</mat-label>
                    <mat-select required name="priority" [(ngModel)]="model.priority">
                        <mat-option [value]="0">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_LOW_PRIORITY' | translate }}</mat-option>
                        <mat-option [value]="1">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_MIDDLE_PRIORITY' | translate }}</mat-option>
                        <mat-option [value]="2">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_HIGH_PRIORITY' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="cols-3">
                <mat-slide-toggle style="margin-bottom: 14px" name="requireAlarmEndingReason"
                [(ngModel)]="model.requireAlarmEndingReason">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALLOW_OPTIONAL_REASON' | translate }}
                </mat-slide-toggle>
            </div>

            <!-- Delay Before Occurring -->
            <h3 class="mt-4 mb-2">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.DELAY_BEFORE_OCCURRING' | translate }}</h3>
            <div class="delay-before-wrapper">
                <div class="fields">
                    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                        <mat-label>
                            {{ 'SPA.LINES.MINUTES' | translate }}
                        </mat-label>
                        <input matInput type="Number" [(ngModel)]="model.delayBeforeOccurTime.minutes"
                            [ngModelOptions]="{ standalone: true }" (change)="leadingZeros($event)" min='0'>
                    </mat-form-field>
                    <div class="dot-text">:</div>
                    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field seconds-field" color="primary">
                        <mat-label>
                            {{ 'SPA.LINES.SECONDS' | translate }}
                        </mat-label>
                        <input matInput type="Number" [(ngModel)]="model.delayBeforeOccurTime.seconds"
                            [ngModelOptions]="{ standalone: true }" (change)="leadingZeros($event)" min='0' max="59"
                            #secondsInput="ngModel" (keyup)="leadingZeros($event)">
                        <mat-error *ngIf="secondsInput.errors?.max">
                            {{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.MAX_SECONDS_ERROR' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <p class="note my-4"><strong>Note:</strong>
                    {{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.DELAY_BEFORE_OCCURRING_NOTE'
                    | translate }} </p>
            </div>

            <h3 class="mt-4">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.CUSTOM_FIELD' | translate }}</h3>
            <div class="cols-1">    
                <div class="custom-field-container">
                    <div class="items-container" *ngIf="model.customFields?.length > 0">
                        <div *ngFor="let field of model.customFields; let i = index">
                            <div class="filed">
                                <mat-form-field class="name-field" appearance="outline">
                                    <mat-label>{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.CUSTOM_FIELD_NAME' | translate }}</mat-label>
                                    <input matInput [name]="'CUSTOM_FIELD_NAME'+i" [(ngModel)]="field.translationJson[translateService.userLang].name"
                                        type="text"
                                        required
                                        placeholder="{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.CUSTOM_FIELD_NAME' | translate }}">
                                    <mat-icon matSuffix class="translate-language"  (click)="openAddTranslationModal('name',field.translationJson)">edit</mat-icon>
                                </mat-form-field>
                                <mat-form-field appearance="outline" style="width: 100px; margin-left: 4px">
                                    <mat-label>{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.CUSTOM_FIELD_TYPE' | translate }}</mat-label>
                                    <mat-select required [name]="'CUSTOM_FIELD_TYPE_'+i" [(ngModel)]="field.type" (ngModelChange)="validateSelectionOfType(field, i, selectTypeEl)" [(value)]="field.type" #selectTypeEl>
                                        <mat-option [value]="'String'">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.OPTION_STRING' | translate }}</mat-option>
                                        <mat-option [value]="'Number'">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.OPTION_NUMBER' | translate }}</mat-option>
                                        <mat-option [value]="'Boolean'">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.OPTION_BOOLEAN' | translate }}</mat-option>
                                        <mat-option [value]="'Dropdown'">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.OPTION_DROPDOWN' | translate }}</mat-option>
                                        <mat-option [value]="'File'">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.OPTION_FILE' | translate }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="dropdown-options" *ngIf="field.type == 'Dropdown'">
                                
                                    <input id="input-file-id" (change)="changeFileListener($event.target.files, i)" multiple type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                                </div>
                                <div
                                    fxLayout="row"
                                    class="content-row slider"
                                    fxLayout.lt-sm="column"
                                    fxLayoutGap="16px"
                                    fxLayoutGap.lt-sm="0"
                                    style="padding: 10px;"
                                    >
                                    <mat-label>{{'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALLOW_ON_TRIGGERING_ALARM' | translate}}</mat-label>
                                    <mat-slide-toggle
                                    class="checkbox"
                                    [checked]="field.allowTriggeringAlarm === true"
                                    (change)="field.allowTriggeringAlarm = $event.checked"
                                    ></mat-slide-toggle>
                                </div>
                                <div
                                    fxLayout="row"
                                    class="content-row slider"
                                    fxLayout.lt-sm="column"
                                    fxLayoutGap="16px"
                                    fxLayoutGap.lt-sm="0"
                                    style="padding: 10px;"
                                    >
                                    <mat-label>{{'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALLOW_ON_ENDING_ALARM' | translate}}</mat-label>
                                    <mat-slide-toggle
                                    class="checkbox"
                                    [checked]="field.allowEndingAlarm === true"
                                    (change)="field.allowEndingAlarm = $event.checked"
                                    ></mat-slide-toggle>
                                </div>
                                <button mat-icon-button type="button" color="warn" (click)="removeCustomField(i)">
                                    <mat-icon class="mat-18">remove_circle</mat-icon>
                                </button>
                                <button mat-fab class="add-btn" *ngIf="(model.customFields.length-1) === i && model.customFields.length < 10" type="button" (click)="addCustomField()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>
                            <div *ngIf="field.type == 'Dropdown'" style="margin-bottom: 14px;">
                                <span *ngIf="!!field?.uploadedFile">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.UPLOADED_FILE_NAME' | translate }}: {{ field?.uploadedFile }}<br/></span>
                                <span *ngIf="field?.dropdownOptions?.length > 0">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.REPLACE_DROPDOWN_OPTIONS' | translate }}</span>
                                <span *ngIf="!field?.dropdownOptions?.length">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.UPLOAD_CSV_MESSAGE' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="items-container h-full justify-center items-center no-custom-field" *ngIf="model.customFields?.length === 0">
                        <div class="no-fields" *ngIf="model.customFields?.length === 0">
                            {{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.NO_CUSTOM_FIELDS' | translate }}
                        </div>
                    </div>
                    <button mat-fab class="add-btn" *ngIf="model.customFields?.length === 0" type="button" (click)="addCustomField()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
 
            </div>
            <h3>{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_COLORING' | translate }}</h3>
            <div class="mat-dialog-content">
                <div>
                    <color-picker label="SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_COLOR" [width]="300"
                    [color]="model.color"
                    (changed)="model.color = $event"></color-picker>
                </div>
                <div class="alarm-coloring-section">
                    <div class="alarm-coloring alarm-coloring-header"> 
                        <div class="alarm-coloring-name header">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_STATUS' | translate }}</div>
                        <div class="header">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_BASIC_COLOR' | translate }}</div>
                        <div class="header">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_BLINKING_COLOR' | translate }}</div>
                        <div class="header">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_BLINKING_INTERVAL' | translate }}</div>
                    </div>
                    <div class="alarm-coloring"> 
                        <div class="alarm-coloring-name header">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_OCCURED' | translate }}</div>
                        <div>
                            <div class="alarm-coloring-picker">
                                <color-picker [displayLabel]="false" [width]="300" [color]="model?.alarmColoring?.occuredBasicColor"
                                (changed)="model.alarmColoring.occuredBasicColor = $event"></color-picker>
                            </div>
                        </div>
                        <div>
                            <div class="alarm-coloring-picker">
                                <color-picker [displayLabel]="false" [width]="300" [color]="model?.alarmColoring?.occuredBlinkingColor"
                                (changed)="model.alarmColoring.occuredBlinkingColor = $event"></color-picker>
                            </div>
                        </div>
                        <div>
                            <div class="alarm-coloring-picker">
                                <mat-form-field appearance="outline">
                                    <input matInput
                                        type="number"
                                        name="occuredBlinkingInterval"
                                        [(ngModel)]="model.alarmColoring.occuredBlinkingInterval"
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="alarm-coloring"> 
                        <div class="alarm-coloring-name header">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_FORWARDED' | translate }}</div>
                        <div>
                            <div class="alarm-coloring-picker">
                                <color-picker [displayLabel]="false" [width]="300" [color]="model.alarmColoring.forwardedBasicColor"
                                (changed)="model.alarmColoring.forwardedBasicColor = $event"></color-picker>
                            </div>
                        </div>
                        <div>
                            <div class="alarm-coloring-picker">
                                <color-picker [displayLabel]="false" [width]="300" [color]="model.alarmColoring.forwardedBlinkingColor"
                                (changed)="model.alarmColoring.forwardedBlinkingColor = $event"></color-picker>
                            </div>
                        </div>
                        <div>
                            <div class="alarm-coloring-picker">
                                <mat-form-field appearance="outline">
                                    <input matInput
                                        type="number"
                                        name="forwardedBlinkingInterval"
                                        [(ngModel)]="model.alarmColoring.forwardedBlinkingInterval"
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="alarm-coloring"> 
                        <div class="alarm-coloring-name header">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_ESCALATED' | translate }}</div>
                        <div>
                            <div class="alarm-coloring-picker">
                                <color-picker [displayLabel]="false" [width]="300" [color]="model.alarmColoring.escalatedBasicColor"
                                (changed)="model.alarmColoring.escalatedBasicColor = $event"></color-picker>
                            </div>
                        </div>
                        <div>
                            <div class="alarm-coloring-picker">
                                <color-picker [displayLabel]="false" [width]="300" [color]="model.alarmColoring.escalatedBlinkingColor"
                                (changed)="model.alarmColoring.escalatedBlinkingColor = $event"></color-picker>
                            </div>
                        </div>
                        <div>
                            <div class="alarm-coloring-picker">
                                <mat-form-field appearance="outline">
                                    <input matInput
                                        type="number"
                                        name="escalatedBlinkingInterval"
                                        [(ngModel)]="model.alarmColoring.escalatedBlinkingInterval"
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="alarm-coloring"> 
                        <div class="alarm-coloring-name header">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_SUBSCRIBED' | translate }}</div>
                        <div>
                            <div class="alarm-coloring-picker">
                                <color-picker [displayLabel]="false" [width]="300" [color]="model.alarmColoring.subscribedBasicColor"
                                (changed)="model.alarmColoring.subscribedBasicColor = $event"></color-picker>
                            </div>
                        </div>
                        <div>
                            <div class="alarm-coloring-picker">
                                <color-picker [displayLabel]="false" [width]="300" [color]="model.alarmColoring.subscribedBlinkingColor"
                                (changed)="model.alarmColoring.subscribedBlinkingColor = $event"></color-picker>
                            </div>
                        </div>
                        <div>
                            <div class="alarm-coloring-picker">
                                <mat-form-field appearance="outline">
                                    <input matInput
                                        type="number"
                                        name="subscribedBlinkingInterval"
                                        [(ngModel)]="model.alarmColoring.subscribedBlinkingInterval"
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
            <h3 class="mt-6">{{'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_GROUPS' | translate }}</h3>
            <div *ngIf="model.productionSector?.length > 0 " fxLayout="column" fxLayout.lt-sm="column"
                 fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <table>
                    <tr>
                        <td>{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.PRODUCTION_SECTOR' | translate }}</td>
                        <td>{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_GROUPS' | translate }}</td>
                        <td>{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.TIME_TO_ESCALATION' | translate }}</td>
                        <td>{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_ESCALATION_GROUP' | translate }}</td>
                    </tr>
                    <tr *ngFor="let field of model.productionSector; let i = index">
                        <ng-container *ngIf="field !== 'All'">
                        <td>{{field.name}} </td>
                        <td>
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_GROUPS' | translate }}</mat-label>
                                <mat-select required [name]="'ALARM_GROUPS'+ i" [(ngModel)]="field.alarmGroups"  [compareWith]="compareObjects"  >
                                    <mat-option
                                            [value]="">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.SELECT_ALARM_GROUPS' | translate }}</mat-option>
                                    <mat-option *ngFor = 'let agr of allGroups' [value]="agr">{{agr.name}}</mat-option>
                  
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td style="width:230px;">
                            <div>
                                <div class="time-input">
                                    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                                        <mat-label>
                                            {{ 'SPA.LINES.HOURS' | translate }}
                                        </mat-label>
                                        <input matInput type="number" [name]="'HOURS'+ i" [(ngModel)]="field.hours" [required]="validateEscalationTime(field)" (change)="leadingZeros($event)"
                                        (keyup)="leadingZeros($event)"
                                        (click)="leadingZeros($event)" [min]="validateEscalationTime(field) ? '1' : '0'">
                                    </mat-form-field>
                                    <div class="dot-text">:</div>
                                      <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                                        <mat-label>
                                            {{ 'SPA.LINES.MINUTES' | translate }}
                                        </mat-label>
                                        <input matInput type="number" [name]="'MINUTES'+ i" [(ngModel)]="field.minutes" [required]="validateEscalationTime(field)" (change)="leadingZeros($event)"
                                                (keyup)="leadingZeros($event)"
                                                (click)="leadingZeros($event)" [min]="validateEscalationTime(field) ? '1' : '0'">
                                      </mat-form-field>
                                      <div class="dot-text">:</div>
                                      <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                                        <mat-label>
                                          {{ 'SPA.LINES.SECONDS' | translate }}
                                        </mat-label>
                                        <input matInput type="number" [name]="'SECONDS'+ i" [(ngModel)]="field.seconds" [required]="validateEscalationTime(field)" (change)="leadingZeros($event)"
                                                (keyup)="leadingZeros($event)"
                                                (click)="leadingZeros($event)" [min]="(validateEscalationTime(field)) ? '1' : '0'">
                                    </mat-form-field>
                                </div>
                            </div>
                        </td>
                        <td>
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_ESCALATION_GROUP' | translate }}</mat-label>
                                <mat-select [name]="'ALARM_ESCALATION_GROUP'+ i" [(ngModel)]="field.escalationGroup" [required]="validateEscalationGroup(field)" [compareWith]="compareObjects" >
                                    <mat-option
                                            [value]="">{{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.SELECT_ALARM_ESCALATION_GROUP' | translate }}</mat-option> 
                                            <mat-option *ngFor = 'let agr of allGroups' [value]="agr">{{agr.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </ng-container>
                    </tr>
                </table>

            </div>
            <div class="d-flex">
                <span class="flex-grow-1"></span>
                <button mat-button type="button" routerLink="/alarms-and-escalations/alarms">{{'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.CANCEL' | translate }}</button>
                <button mat-raised-button (click) = 'save()'
                        color="primary">{{ (alarmId ? 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.UPDATE_ALARM' : 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ADD_ALARM') | translate }}</button>
            </div>
        </form>

    </div>
</mat-card>