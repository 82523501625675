import {Component, OnDestroy, OnInit, ViewChild, Renderer2, ElementRef} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import {AEGroupInterface} from '../../groups/interfaces/a-e-group.interface';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { AlarmsAndEscalationsService } from '../../alarms-and-escalations.service';
import { SaveFilterService } from '../../../../../common/shared/services/save-filter.service';
import { historicAlarmFilterKey } from '../../../../../common/filters/constants/filter-key.constants';
import { TableColumnVisibilityService } from '../../../../../common/user-table-settings/services/table-column-visibility.service';
import { UserTableTypeEnum } from '../../../../../common/user-table-settings/enums/user-table-type.enum';
import { UserTablePlaceEnum } from '../../../../../common/user-table-settings/enums/user-table-place.enum';
import { CommonService } from '../../../../../common/shared/services/common.service';

@Component({
    selector: 'app-historic-alarms',
    templateUrl: './historic-alarms.component.html',
    styleUrls: ['./historic-alarms.component.scss']
})
export class HistoricAlarmsComponent implements OnInit, OnDestroy {

    currentPage = 0;
    groups: AEGroupInterface[] = [];
    displayedColumns: string[] = [];
    columnsData = [
        {
          name: "alarmId",
          id: "Alarm id",
          isAlwaysVisible: true,
          isVisible: true,
        },
        {
          name: "lineName",
          id: "Production Sector",
          isAlwaysVisible: false,
          isVisible: true,
        },
        {
          name: "stationName",
          id: "Station",
          isAlwaysVisible: false,
          isVisible: true,
        },
        {
          name: "configuredAlarmName",
          id: "Alarm Type",
          isAlwaysVisible: false,
          isVisible: true,
        },
        {
          name: "reason",
          id: "Reason",
          isAlwaysVisible: false,
          isVisible: true,
        },
        {
          name: "creator",
          id: "Creator",
          isAlwaysVisible: false,
          isVisible: true,
        },
        {
          name: "resolvedBy",
          id: "Resolved By",
          isAlwaysVisible: false,
          isVisible: true,
        },
        {
            name: "duration",
            id: "Duration",
            isAlwaysVisible: false,
            isVisible: true,
          },
          {
            name: "actions",
            id: "Actions",
            isAlwaysVisible: true,
            isVisible: true,
          },
      ];
    dataSource = new MatTableDataSource<any>([]);
    productionSectors=[];
    stations=[];
    filterData: any = {};
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    showFilters = false;
    sortBy = '';
    sortDirection = '';
    myInterval: any;
    filterToSend: any = {};
    isStationIdInFilter: boolean = false;
    data = [];
    isMobileScreen: boolean;

    constructor(private alarmsAndEscalationsService : AlarmsAndEscalationsService,
        private saveFilterService: SaveFilterService,private tableColumnVisibilityService: TableColumnVisibilityService, 
        private commonService: CommonService,
        private renderer: Renderer2, 
        private el: ElementRef) {

    }

    ngOnInit(): void {
        // this.loadList();

        this.alarmsAndEscalationsService.fetchLines().subscribe((result=>{
            this.productionSectors = result;
        }));
        this.alarmsAndEscalationsService.fetchStations().subscribe((result=>{
            this.stations = result;
        }));

        // To get the saved filters from database
        this.saveFilterService.getFiltersUsingKey(historicAlarmFilterKey).subscribe(filters => {
            if (!!filters) {
                this.filterData.productionSector = filters.productionSector;
                this.isStationIdInFilter = !!filters.station;
                if (!!filters.productionSector) {
                    let dataToend = {
                        lines: filters.productionSector
                    }
                    
                    // To fetch the stations based on the production sector
                    this.alarmsAndEscalationsService.fetchLinesBySector(dataToend).subscribe(res => {
                        this.stations = res;
                        this.filterData.station = filters.station;
                        if (this.isStationIdInFilter) {
                            this.filterToSend = {
                                ...this.filterData
                            };
                            this.loadList();
                        }
                    })
                }
                this.filterToSend = {
                    ...this.filterData
                };
            }
            if (!this.isStationIdInFilter) {
                this.loadList();
            }
        });
        this.myInterval = setInterval(this.loadList, 5000);
        this.getColumns();

        this.renderer.listen('window', 'resize', (event) => {
            this.getWindowWidth();
          });
      
          this.getWindowWidth();

    }

    getWindowWidth() {
        this.isMobileScreen = this.renderer.parentNode(this.el.nativeElement).ownerDocument.defaultView.innerWidth <= 768
      }
    
    
    loadList = () =>{
        let data = {
            page: this.currentPage,
            size: 10
        }

        this.alarmsAndEscalationsService.adminGetAllResolvedAlarmsWithFilter(data,this.filterToSend).subscribe(res=>{
           if(res.length ==0 && this.currentPage >0){
            this.currentPage = this.currentPage -1;
           }else{
            this.dataSource = new MatTableDataSource<any>(res);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.data = res;
           }
       
        });

        // this.groupsService.fetchGroups().subscribe((result) => {
     
      
        // });
    }

    getColumns() {
        this.tableColumnVisibilityService.loadTableMetaData(UserTableTypeEnum.HISTORIC_ALARMS,UserTablePlaceEnum.SPA).subscribe((result:any)=>{
            if(result?.length > 0){
                this.columnsData = result;
            }
            this.setColumns();
        })
      }

    setColumns(){
        this.displayedColumns = [];
        this.columnsData.forEach((element) => {
            if (element.isVisible) {
              this.displayedColumns.push(element.name);
            }
        });
    }
    
    columnsChanged() {    
        let data = {
            tableTypeEnum: UserTableTypeEnum.HISTORIC_ALARMS,
            placeEnum: UserTablePlaceEnum.SPA,
            columns: this.columnsData
        }
        this.tableColumnVisibilityService.saveTableMetaData(data).subscribe((result: any)=>{
            this.columnsData = result;
            this.setColumns();
        })
    }

    accept(id) {

    }

    forward(id) {

    }


    next() {
        this.currentPage = this.currentPage + 1;
        this.loadList();
    }

    previous(){
        if(this.currentPage >=1)
        {
        this.currentPage = this.currentPage - 1;
        this.loadList();
        }
    }



    changeFilters() {
        this.filterToSend = {};
        for (const [key, value] of Object.entries(this.filterData)) {
            this.filterToSend[key] = value;
        }
        // To save the filters
        this.saveFilterService.createObject(historicAlarmFilterKey, this.filterToSend);
        this.loadList();
    }

    sortHalarms(sort){
this.sortBy = sort.active;
this.sortDirection = sort.direction;

 this.loadList();

    }


    resetFilters() {
        this.filterToSend = {};
        this.filterData = {};
        this.currentPage = 0;
        // To save the filters
        this.saveFilterService.createObject(historicAlarmFilterKey, this.filterToSend);
        this.loadList();   

    }


    ngOnDestroy(){
        clearInterval(this.myInterval);
    }


    productionSectorChanged() {
        this.filterData.station = null;
        this.stations = [];
        if(!this.filterData['productionSector']){
            return;
        }
        let dataToend = {
            lines: this.filterData['productionSector']
        }

        this.alarmsAndEscalationsService.fetchLinesBySector(dataToend).subscribe(res => {
            this.stations = res;
        })

    }

    getTranslatedName(name, translationObj): string {
        return this.commonService.getTranslatedName(name, translationObj);
    }

    getTranslatedAlarm(alarm): string {
        return this.commonService.getTranslatedName('name', alarm?.alarm?.configuredAlarm?.translationJson || null);
    }
}
