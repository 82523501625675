<mat-dialog-content>
    <p style="margin-bottom: 8px"> {{ 'SPA.ALARMS_ESCALATIONS.FORWARD_ALARM.LABEL' | translate}}</p>
    <mat-form-field>
        <mat-label>{{ 'SPA.ALARMS_ESCALATIONS.FORWARD_ALARM.INPUT_LABEL' | translate}}</mat-label>
        <mat-select [(ngModel)]="cAlarm" >
            <mat-option *ngFor = 'let ca of allCAlarm'  [value]="ca">{{getTranslatedAlarm('name',ca.translationJson)}}</mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button matDialogClose>{{ 'SPA.ALARMS_ESCALATIONS.FORWARD_ALARM.CANCEL' | translate}}</button>
    <button mat-raised-button color="primary"   (click) = 'confirm()' [disabled]="!cAlarm">{{ 'SPA.ALARMS_ESCALATIONS.FORWARD_ALARM.CONFIRM' | translate}}</button>
</mat-dialog-actions>
