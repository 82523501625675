import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { AuthGuard } from '../../app/_guards/auth.guard';
import {AuthService} from '../../app/auth/auth.service';
import { UserPermissionsEnum } from '../../../common/auth/enums/user-permissions.enum';
import {LoginResponseInterface} from '../../../common/auth/interfaces/login-response.interface';
import {AlarmAutologinInterface} from '../../../common/alarms-and-escalations/interfaces/alarm-autologin.interface';
import { InfoboxService } from '../../../common/shared/services/infobox.service';
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class AlarmLoginService {

    constructor(
        private http: HttpClient,
        private authService: AuthService, 
        private authGuard: AuthGuard,
        private toastService: InfoboxService,
        private translate: TranslateService
    ) {
    }

    async autoLogin(alarm, isAutoLoginForForwardAlarm: boolean = false): Promise<LoginResponseInterface|boolean> {
        const user = this.authService.getDecodedTokenSnapshot();
        const alarmId = alarm.id;
        if (this.authGuard.hasPermission([UserPermissionsEnum.StationDisorderAutologin])) {
            if (!!isAutoLoginForForwardAlarm) {
                if (!this.authGuard.hasPermission([UserPermissionsEnum.AEForwardAlarmManagement])) {
                    this.toastService.pushInfo({
                        warning: true,
                        text:  this.translate.instant('SPA.LOGIN.INSUFFICIENT_ROLE_FOR_FORWARDING')
                    });
                    return null;
                }
            } else {
                if (!this.authGuard.hasPermission([UserPermissionsEnum.AESupervisor])) {
                    this.toastService.pushInfo({
                        warning: true,
                        text:  this.translate.instant('SPA.LOGIN.INSUFFICIENT_ROLE')
                    });
                    return null;
                }
            }

            try {
                const result = await this.autoLoginCall({
                    alarmId,
                    stationId: alarm.station.id,
                    productItemId: null,
                    isAutoLoginForForwardAlarm: isAutoLoginForForwardAlarm
                }, this.authService.userToken).toPromise();

                return this.loginSubscription(result);
                
            } catch (error) {
                if (!!error.error && !!error.error['ap-message']) {
                    const errorMessage = this.translate.instant(error.error['ap-message']);

                    if (errorMessage ===  this.translate.instant('SPA.ALARMS_ESCALATIONS.ERRORS.NOT_IN_AE_GROUP')) {
                        return false;
                    }
                    
                    this.toastService.pushInfo({
                        danger: true,
                        text: errorMessage,
                        params: error.error['params']
                    });
                }
                return null;
            }
        } else {
            return false;
        }
    }

    private autoLoginCall(data: AlarmAutologinInterface, userToken: string): Observable<LoginResponseInterface> {
        const params = new HttpParams({
            fromObject: {
                userToken: userToken,
                ignoreErrorInterception: 'true',
            },
        });
        return this.http.post<LoginResponseInterface>('/api/alarm/auto-login', data, { params });
    }

    private loginSubscription(result: LoginResponseInterface) {
        if (!!!result || !!!result.token) {
            this.toastService.pushInfo({
                warning: true,
                text:  this.translate.instant('SPA.LOGIN.ERROR_INVALID')
            });
            return null;
        } else {
            if (result.message) {
                this.toastService.pushInfo({
                    warning: true,
                    text: result.message
                });
            }
            return this.handleToken(result);
        }
    }

    private handleToken(result: LoginResponseInterface) {
        const user = this.authService.decodeToken(result.token);
        if (this.authGuard.hasPermissionsForSpecificUser(user, [UserPermissionsEnum.AESupervisor])) {
            return result;
        } else {
            this.toastService.pushInfo({
                warning: true,
                text:  this.translate.instant('SPA.LOGIN.INSUFFICIENT_ROLE')
            });
            return null;
        }
    }
}