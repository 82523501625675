<page-header titleTranslate="COMMON.EDIT"></page-header>
<form [formGroup]="form">
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.FIRST_NAME' | translate }}</mat-label>
            <input matInput required formControlName="firstname">
            <mat-error>{{ 'SPA.USERS.EDIT.FIRST_NAME_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.LAST_NAME' | translate }}</mat-label>
            <input matInput type="text" required formControlName="name">
            <mat-error>{{ 'SPA.USERS.EDIT.LAST_NAME_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.EMAIL' | translate }}</mat-label>
            <input type="email" matInput formControlName="email">
            <mat-error>{{ 'SPA.USERS.EDIT.EMAIL_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.COIN' | translate }}</mat-label>
            <input type="coin" matInput formControlName="coin">
            <mat-error>{{ 'SPA.USERS.EDIT.COIN_ERROR' | translate }}</mat-error>
        </mat-form-field>

    </div>
    <div class="second-row" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.ADD.ROLE' | translate }}</mat-label>
            <mat-select formControlName="role">
                <ng-container *ngFor="let role of roles">
                    <mat-option [value]="role.id">{{role.role | titlecase}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.USERNAME' | translate }}</mat-label>
            <input matInput required formControlName="username">
            <mat-error>{{ 'SPA.USERS.EDIT.USERNAME_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="!showDummyPassword" fxFlex="auto" appearance="outline" class="vex-flex-form-field"
            color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.PASSWORD' | translate }}</mat-label>
            <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password" class="password-field">
            <mat-icon class="visibility-icon" matSuffix (click)="showPassword = !showPassword">
                {{ showPassword ? 'visibility_off' : 'visibility' }}
            </mat-icon>
        </mat-form-field>
        <mat-form-field *ngIf="showDummyPassword" fxFlex="auto" appearance="outline" class="vex-flex-form-field"
            color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.PASSWORD' | translate }}</mat-label>
            <input matInput type="text" formControlName="showpassword">
        </mat-form-field>
        <mat-form-field *ngIf="!showDummyPassword" fxFlex="auto" appearance="outline" class="vex-flex-form-field"
            color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.CONFIRM_PASSWORD' | translate }}</mat-label>
            <input matInput [type]="showConfirmPassword ? 'text' : 'password'" formControlName="confirmPassword"
                class="confirm-password-field">
            <mat-icon class="visibility-icon" matSuffix (click)="showConfirmPassword = !showConfirmPassword">
                {{ showConfirmPassword ? 'visibility_off' : 'visibility' }}
            </mat-icon>
            <mat-error *ngIf="confirmPassword?.errors?.valueMismatch">{{ 'SPA.USERS.ADD.ERROR_PASSWORD_MATCH' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="showDummyPassword" fxFlex="auto" appearance="outline" class="vex-flex-form-field"
            color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.CONFIRM_PASSWORD' | translate }}</mat-label>
            <input matInput type="text" formControlName="showConfirmPassword">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.LANGUAGE' | translate }}</mat-label>
            <mat-select formControlName="language" required>
                <mat-option *ngFor="let lang of languages | stringEnumToArray" [value]="languages[lang]">
                    {{ 'SPA.LANGUAGE_TYPE.'+lang | translate}}
                  </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.WATCH_ID' | translate }}</mat-label>
            <mat-select formControlName="watch">
                <mat-option *ngFor="let watch of smartwatches" [value]="watch">
                    {{ watch.watchId }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">

        <div class="barcode-section">
            <ng-container *ngIf="barcodeString">
                <ngx-barcode #barcode [bc-format]="'CODE128'" [bc-value]="barcodeString" [bc-width]="1" [bc-height]="80"></ngx-barcode>
                <div class="barcode-btns">
                    <button  mat-raised-button color="warn" *ngIf="disabledResetBarcode()" (click)="updateBarcode()">
                        {{ 'SPA.USERS.EDIT.BARCODE.RESET_BTN' | translate }}
                    </button>
                    <button color="primary" mat-raised-button  (click)="downloadBarcode()" [disabled]="disabledDownloadBarcode()">
                        {{ 'SPA.USERS.EDIT.BARCODE.DOWNLOAD_BTN' | translate }}
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</form>
<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="mt-10">
    <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
        <ng-container *ngIf="!barcodeString">
            <button color="primary" mat-raised-button (click)="updateBarcode()">
                {{ 'SPA.USERS.EDIT.BARCODE.SET_BTN' | translate }}
            </button>
        </ng-container>
        <button color="warn" mat-button type="button" (click)="goBack()"> {{ 'SPA.USERS.EDIT.BACK' | translate }}</button>
        <button color="primary" mat-raised-button (click)="save()" [disabled]="form.pristine || !form.valid">
            {{ 'COMMON.SAVE' | translate }}
        </button>
    </div>
</div>
