import { LineInterface } from '../interfaces/line.interface';
import { LineBeatTypeEnum } from '../enums/line-beat-type.enum';
import { LineTypeEnum } from '../enums/line-type.enum';
import { StationOrderTypeEnum } from '../enums/station-order-type.enum';

export class LineTypeUtil {
  static allowedBeatTypes(line: LineInterface): LineBeatTypeEnum[] {
    let allowedBeatTypes = [];
    switch (line.typeEnum) {
      case LineTypeEnum.NO_BEAT:
      case LineTypeEnum.REWORK:
        allowedBeatTypes = [LineBeatTypeEnum.NO_BEAT];
        break;
      case LineTypeEnum.PRODUCTION_REWORK:
        allowedBeatTypes = [LineBeatTypeEnum.STATION_BASED_BEAT, LineBeatTypeEnum.FIXED_BEAT_LENGTH];
        break;
      case LineTypeEnum.PRODUCTION:
      default:
        allowedBeatTypes = [LineBeatTypeEnum.LINE_BASED_BEAT, LineBeatTypeEnum.STATION_BASED_BEAT, LineBeatTypeEnum.FIXED_BEAT_LENGTH];
        break
    }

    return allowedBeatTypes;
  }

  static allowedStationOrders(line: LineInterface): StationOrderTypeEnum[] {
    switch (line.typeEnum) {
      case LineTypeEnum.NO_BEAT:
      case LineTypeEnum.REWORK:
        return [StationOrderTypeEnum.NO_ORDER];
      default:
      case LineTypeEnum.PRODUCTION:
      case LineTypeEnum.PRODUCTION_REWORK:
        if (line.beatTypeEnum === LineBeatTypeEnum.STATION_BASED_BEAT) {
          return [StationOrderTypeEnum.PARALLEL, StationOrderTypeEnum.SEQUENTIAL];
        }

        return [StationOrderTypeEnum.SEQUENTIAL, StationOrderTypeEnum.PARALLEL];
    }
  }
}