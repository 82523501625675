import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UnassignedDeviceInterface } from '../../../../../../common/unassigned-device/interfaces/unassigned-device.interface';

@Injectable()
export class UnassignedDeviceNetwork {
  constructor(
    private http: HttpClient,
  ) {
  }

  getAll(): Observable<UnassignedDeviceInterface[]> {
    return this.http
      .get<UnassignedDeviceInterface[]>('/unassigned-device');
  }

  getAllDevicesForMultipleStations(lineId: number): Observable<UnassignedDeviceInterface[]> {
    return this.http
      .get<UnassignedDeviceInterface[]>(`/unassigned-device/get-devices-for-multiple-stations/${lineId}`);
  }
}
