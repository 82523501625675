import { NgModule } from '@angular/core';
import { AndonAddComponent } from './components/andon-add/andon-add.component';
import { AndonEditComponent } from './components/andon-edit/andon-edit.component';
import { AndonFtpFormComponent } from './components/andon-ftp-form/andon-ftp-form.component';
import { AndonFtpListingComponent } from './components/andon-ftp-listing/andon-ftp-listing.component';
import { SlidesNetwork } from './networks/slides.network';
import { SlidesService } from './services/slides.service';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StationsModule } from '../stations/stations.module';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatExpansionModule } from '@angular/material/expansion';
import { AndonComponent } from './andon.component';
import { AndonListComponent } from './components/andon-list/andon-list.component';
import { SlidesControlsService } from './services/slides-controls.service';

@NgModule ({
  imports: [
    FormsModule,
    RouterModule,
    StationsModule,
    SharedModule,
    MatTabsModule,
    MatDialogModule,
    MatSelectModule,
    MatExpansionModule,
    ReactiveFormsModule
  ],
  declarations: [
    AndonComponent,
    AndonListComponent,
    AndonAddComponent,
    AndonFtpFormComponent,
    AndonEditComponent,
    AndonFtpListingComponent,
  ],
  providers: [
    SlidesNetwork,
    SlidesService,
    SlidesControlsService
  ],
  exports: [
    AndonComponent,
  ],
})
export class AndonModule { }
