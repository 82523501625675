import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AlarmsAndEscalationsService } from '../../alarms-and-escalations.service';
import { debounceTime } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import * as _ from 'lodash';
import { WidgetTypeEnum } from '../../../../../common/widget/enums/widget-type.enum';
import { CommonService } from '../../../../../common/shared/services/common.service';
import { AlarmDropdownOptionsModalComponent } from '../alarm-dropdown-options-modal/alarm-dropdown-options-modal.component';
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";


@Component({
    selector: 'app-update-reason',
    templateUrl: './update-reason.component.html',
    styleUrls: ['./update-reason.component.scss']
})
export class UpdateReasonComponent implements OnInit {
    allReasons = [];
    displayedReasons: any = {};
    breadCrumb = [];
    public userToken = null;
    term$ = new BehaviorSubject<string>('');
    allNodesReasons: any = [];
    isDisorderUnderInvestigation: boolean = false;
    comment: string = '';
    public selectedDisorderReason: any = null;
    widgetSettings: any = null;
    showDisorderUnderInvestigation: boolean = false;

    constructor(private alarmsAndEscalationsService: AlarmsAndEscalationsService,
        public dialogRef: MatDialogRef<UpdateReasonComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data,
        private commonService: CommonService
    ) {
    }

    ngOnInit(): void {
        if (!!this.data) {
            this.userToken = this.data ? this?.data?.token || null : null;
            const stationId = this.data.modalType === 'updateReason' ? this?.data?.stationId : this?.data.station?.id;
            const configuredAlarmId = this.data.modalType === 'updateReason' ? this?.data?.configuredAlarmId : this?.data?.configuredAlarm?.id;
            let alarmTriggeringData;
            if(this.data?.customFieldsData){
                alarmTriggeringData= this.data.customFieldsData;
                this.data.customFieldsData = this.data.configuredAlarm.data;
                this.data.customFieldsData.forEach(data => {
                    let matchData = alarmTriggeringData.filter((ele)=>{return data.uniqueId === ele.uniqueId});
                    if(matchData.length > 0){
                    data.value = matchData[0].value ? matchData[0].value : "";
                    data.valueAsAString = matchData[0].valueAsAString ? matchData[0].valueAsAString : "";
                    }
                });
            } else{
                alarmTriggeringData= this.data.data;
                this.data.customFieldsData = this.data.configuredAlarm.data;
                this.data.customFieldsData.forEach(data => {
                    let matchData = alarmTriggeringData.filter((ele)=>{return data.uniqueId === ele.uniqueId});
                    if(matchData.length > 0){
                    data.value = matchData[0].value ? matchData[0].value : "";
                    data.valueAsAString = matchData[0].valueAsAString ? matchData[0].valueAsAString : "";
                    }
                });
            }
            
            if(!!stationId && !!configuredAlarmId) {
                this.alarmsAndEscalationsService.getAllDisorderReasonswithParentChild(configuredAlarmId, stationId, this.userToken).subscribe(res => {
                    this.allReasons = res;
                    this.handleReasons();
                });
                const ALARM_ESCALATION = WidgetTypeEnum.ALARM_ESCALATION;
                if (this.data.modalType === 'endAlarm') {
                    this.alarmsAndEscalationsService.getDeviceWidgetConfiguration(stationId).subscribe(configurationData => {
                        if (!!configurationData) {
                            let widgetsData: any = configurationData;
                            let alarmWidget;
                            _.forEach(widgetsData.pages, (item) => {
                                alarmWidget = item.widgets.filter(function(widget) {
                                    return widget.typeEnum === ALARM_ESCALATION
                                })
                            });
                            if (alarmWidget.length > 0) {
                                this.showDisorderUnderInvestigation = alarmWidget[0]?.settings?.showDisorderInvestgationDiv || false;
                            }
                        }
                    });
                }
            }
        }
    }

    handleReasons() {
        this.term$.
        pipe(
            debounceTime(300)
        ).subscribe((res: any) => {
            this.displayedReasons = { parent: null, children: this.allReasons };
        })
        this.displayedReasons = { parent: null, children: this.allReasons };
        this.breadCrumb = [{
            text: 'All Reasons',
            children: this.displayedReasons.children

        }]
        this.fillNodes(this.allReasons, "");
    }

    fillNodes(parent, parentPath) {

        for (let index = 0; index < parent.length; index++) {
            if (parent[index]['children'] && parent[index]['children'].length > 0) {
                let tempPath = parentPath + (parentPath ? " / " : "") + parent[index]['reason']
                this.fillNodes(parent[index]['children'], tempPath)
            } else {
                this.allNodesReasons.push(
                    {
                        id: parent[index]['id'],
                        reason: parent[index]['reason'],
                        children: [],
                        path: parentPath
                    }
                );
            }
        }
    }

    reasonClicked(reason) {
        if (reason.children && reason.children.length == 0) {
            this.selectedDisorderReason = reason;
        } else {
            this.selectedDisorderReason = null;
            this.displayedReasons = {
                parent: reason,
                children: reason.children
            };

            this.breadCrumb.push({
                text: this.getTranslatedName('name', reason?.translationJson),
                children: reason.children
            })
        }
    }

    goTo(b, index) {
        this.selectedDisorderReason = null;
        if (index > 0) {
            this.displayedReasons = {
                parent: b,
                children: b.children
            };
            this.breadCrumb = this.breadCrumb.splice(0, index + 1);
        } else {
            this.displayedReasons = { parent: null, children: this.allReasons };
            this.breadCrumb = [{
                text: 'All Reasons',
                children: this.displayedReasons.children

            }]
        }
    }

    confirm() {
        if (this.data.modalType === 'endAlarm') {
            if (!this.data.configuredAlarm.requireAlarmEndingReason && !this.selectedDisorderReason) {
                let extraData;
                if(this.data?.customFieldsData){
                    extraData = this.data.customFieldsData.filter
                    (item => item.allowEndingAlarm || item.allowTriggeringAlarm)
                    .map(({
                        allowEndingAlarm,
                        allowTriggeringAlarm,
                        dropdownOptions,
                        ...rest
                    }) => ({
                        ...rest,
                        valueAsAString: rest.type === 'Dropdown' ? rest.valueAsAString : rest.value
                    }));
                }
                const endAlarmReq = {
                    id: this.data.id,
                    stationId: this.data.station.id,
                    lineId: this.data.line.id,
                    configuredAlarmId: this.data.configuredAlarm.id,
                    extraData: extraData,
                    fromStation: true,
                    disorderSolvedData: {
                        code: 'No reason',
                        partNumber: 'No reason',
                        text: 'No reason',
                        isRework: false,
                        markForRework: false,
                        reason: 'No reason',
                        reasonTranslationJson: {"en":{"name":"No reason"},"de":{"name":"No reason"},"zh":{"name":"No reason"}},
                        comment: !!this.isDisorderUnderInvestigation ? this.comment : '',
                        isDisorderUnderInvestigation: this.isDisorderUnderInvestigation,
                    }
                }
                this.alarmsAndEscalationsService.alarmResolved(endAlarmReq).subscribe(
                    (data) => {this.dialogRef.close(true)}, 
                    (error) => {}
                );
            } else {
                if (!this.selectedDisorderReason) {
                    return;
                }
                let extraData;
                if(this.data?.customFieldsData){
                    extraData = this.data.customFieldsData.filter
                    (item => item.allowEndingAlarm || item.allowTriggeringAlarm)
                    .map(({
                        allowEndingAlarm,
                        allowTriggeringAlarm,
                        dropdownOptions,
                        ...rest
                    }) => ({
                        ...rest,
                        valueAsAString: rest.type==='Dropdown'?rest.valueAsAString:rest.value
                    }));
                }
                const endAlarmReq = {
                    id: this.data.id,
                    stationId: this.data.station.id,
                    lineId: this.data.line.id,
                    configuredAlarmId: this.data.configuredAlarm.id,
                    // productItemId?: number,
                    extraData:extraData,
                    fromStation: true,
                    disorderSolvedData: {
                        code: '',
                        partNumber: null,
                        text: null,
                        isRework: false,
                        markForRework: false,
                        reason: this.selectedDisorderReason.reason,
                        reasonTranslationJson: this.selectedDisorderReason?.translationJson,
                        comment: !!this.isDisorderUnderInvestigation ? this.comment : '',
                        isDisorderUnderInvestigation: this.isDisorderUnderInvestigation,
                    }
                }
                this.alarmsAndEscalationsService.alarmResolved(endAlarmReq).subscribe(
                    (data) => {this.dialogRef.close(true)}, 
                    (error) => {}
                )
            }
        } else {
            if (!this.selectedDisorderReason || !this.data.disorderId) {
                return;
            }
            const reasonData = {
                reason : this.selectedDisorderReason.reason,
                reasonTranslationJson: this.selectedDisorderReason.translationJson
            };
            this.alarmsAndEscalationsService.updateDisorderReason(this.data.disorderId, reasonData).subscribe(
                res => {
                    this.dialogRef.close(true);
                }, err => {
                }
            )
        }
    }

    getTranslatedName(name, translationObj): string {
        return this.commonService.getTranslatedName(name, translationObj);
    }

    openDropdownOptionModal(event, key) {
        let modalMaxWidth;
        let modalMaxHeight;
        if(localStorage.getItem('width') === null || localStorage.getItem('width') === ''){
          modalMaxWidth = '1000px';
          modalMaxHeight = '1000px';
        } else {
          let width:any = localStorage.getItem('width');
          modalMaxWidth =  (width * 0.9) + 'px';
          let height:any = localStorage.getItem('height');
          modalMaxHeight =  (height * 0.9) + 'px';
        }
        const dialogRef = this.dialog.open(AlarmDropdownOptionsModalComponent, {
          width: '60%',
          maxWidth: modalMaxWidth,
          maxHeight: modalMaxHeight,
          panelClass: 'forward-disorder',
          disableClose: true,
          data: {
            key: key,
            options: key.dropdownOptions,
            headers: key?.dropdownHeaders || null,
            selectedDropdownValuesWithCount: key.value === ''? []:key.value,
            translationObject: key.translationJson
          }
        });
        key.value = [];
        dialogRef.afterClosed().subscribe(res => {
          if (!res) {
            return;
          }
          key.value = res.selectedDropdownValuesWithCount;
          if (!!res.selectedDropdownValuesWithCount) {
            let newExtraDataValues = [];
            key.value.forEach(element => {
              newExtraDataValues.push(element.count + ' X ' + element.name );
            });
            key.valueAsAString = newExtraDataValues.toString();
          }
        })
      }
  
      // To remove the item form the selected dropdown list
    deleteDropdownOption(element, key) {
      const checkItemExist = key.value.findIndex(item => item.key === element.key);
      if (checkItemExist != -1) {
            const count = key.value[checkItemExist].count;
            if (count === 1) {
                key.value.splice(checkItemExist, 1);
        } else {
          key.value[checkItemExist].count = count > 1 ? (count - 1) : 0;
        }
      }
      let newExtraDataValues = [];
      key.value.forEach(element => {
        newExtraDataValues.push(element.count + ' X ' + element.name );
      });
      key.valueAsAString = newExtraDataValues.toString();
      }
}
