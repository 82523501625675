import { Routes } from '@angular/router';
import { AuthGuard } from '../../_guards/auth.guard';
import { FtpConfigurationComponent } from './ftp-configuration.component';
import { ConfigurationEnum } from '../../../../common/configuration/enums/configuration.enum';
import { UserPermissionsEnum } from '../../../../common/auth/enums/user-permissions.enum';

export const FTP_CONFIGURATION_ROUTES: Routes = [
    {
        path: '',
        component: FtpConfigurationComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: [UserPermissionsEnum.FtpSettingsManagement],
            configuration: ConfigurationEnum.FTP,
            breadcrumb: {
                label: 'SPA.FTP_CONFIGURATION.LABEL',
            }
        },
    }
];
