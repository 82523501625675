import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DeviceFullInterface } from '../../../../../../../common/device/interfaces/device-full.interface';
import { DeviceRotationEnum } from '../../../../../../../common/device/enums/device-rotation.enum';
import { DeviceTypeEnum } from '../../../../../../../common/device/enums/device-type.enum';
import { DeviceService } from '../../services/device.service';
import { DeviceUpdaterService } from '../../services/device.updater.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DEVICE_NAME_LENGTH } from '../../../../../../../common/device/constants/device.constants';
import { UnassignedDeviceNetwork } from '../../network/unassigned-device.network';
import { UnassignedDeviceInterface } from '../../../../../../../common/unassigned-device/interfaces/unassigned-device.interface';
import { SocketService } from '../../../../../_services/socket.service';
import { SocketMessageEnum } from '../../../../../../../common/socket/enums/socket-message.enum';
import { Socket } from '../../../../../../../common/socket/models/socket.model';
import { SocketChannel } from '../../../../../../../common/socket/utils/socket-channel';

@Component({
  selector: 'app-device-edit',
  templateUrl: './device-edit.component.html',
  styleUrls: ['./device-edit.component.scss']
})
export class DeviceEditComponent implements OnInit, OnDestroy {

  @Input() isAndon: boolean = false;
  @Input() lineId: number | null;
  @Input() deviceId: number;

  form: UntypedFormGroup;
  maxNameLength = DEVICE_NAME_LENGTH;
  deviceTypes = DeviceTypeEnum;
  DeviceRotationEnum = DeviceRotationEnum;
  device: DeviceFullInterface;
  unassignedDevices: UnassignedDeviceInterface[] = [];
  private socket: Socket;
  disableWebAPPSetting: boolean = false;
  isVisibleSectionForWebApp: boolean = false;
  private socketSubs: string[] = [];
  selectedRotation: string = DeviceRotationEnum.NORMAL;
  constructor(
    public deviceService: DeviceService,
    private deviceUpdaterService: DeviceUpdaterService,
    private unassignedDeviceNetwork: UnassignedDeviceNetwork,
    private socketService: SocketService,
  ) { }

  ngOnInit() {
    this.deviceService.init(this.lineId);
    this.initForm();
    this.getDevice();
    this.fillUnassignedDevices();
    this.socket = this.socketService.getSocket();
    this.socket.join(SocketChannel.DEVICE);
    this.socketSubs.push(this.socket.on(SocketMessageEnum.DEVICE_UNASSIGNED_CONNECTED, () => {
      this.fillUnassignedDevices();
    }));
    this.form.get('isMultipleStation').valueChanges.subscribe(( value )=> {
      this.form.get('unassignedDeviceId').patchValue(null);
      if (value) {
        this.getAllMultipleStations();
      } else {
        this.fillUnassignedDevices();
      }
    })
  }

  ngOnDestroy(): void {
    this.socketSubs.forEach(s => this.socket.off(s));
    this.socket.leave(SocketChannel.DEVICE);
    this.deviceUpdaterService.destroy();
  }

  hasCode(): boolean {
    return this.device?.code[0] === '#';
  }

  isRotationSelected(rotation: string) {
    return this.device?.rotation === rotation;
  }

  updateRotation(event) {
    this.changeRotation(event);
  }

  async changeRotation(rotation: DeviceRotationEnum) {
    await this.deviceService.changeRotation(this.device, rotation);
  }
  
  save() {
    if (!this.form.valid) {
      return
    }
    let data;
    if(!this.isVirtualStation){
      data = {
        name: this.form.value.name,
        type: this.form.value.type,
        unassignedDeviceId: this.form.value.unassignedDeviceId,
        isMultipleStation: this.form.value.isMultipleStation,
        isRunningAsWebApp: this.form.value.isRunningAsWebApp,
        isAndonAutomaticReboot: this.form.value.isAndonAutomaticReboot
      }
    }else{
      data = {
        name: this.form.value.name,
        type: this.form.value.type,
        unassignedDeviceId: this.form.value.unassignedDeviceId,
        blockInPause: this.form.value?.blockInPause ? this.form.value?.blockInPause : false ,
        blockInNoShift: this.form.value?.blockInNoShift ? this.form.value?.blockInNoShift : false,
        isMultipleStation: this.form.value?.isMultipleStation ? this.form.value?.isMultipleStation : false,
        // isRunningAsWebApp: this.form.value?.isRunningAsWebApp ? this.form.value?.isRunningAsWebApp : false
      }
    }
    this.deviceService.updateDevice(this.device.id, data, () => {
      this.getDevice();
      this.fillUnassignedDevices();
    });
  }

  unassign() {
    this.deviceService.unassign(this.device.id, () => {
      this.getDevice();
      this.form.patchValue({
        unassignedDeviceId: null,
      })
    });
  }

  private getDevice() {
    this.deviceService.getDevice(this.deviceId)
      .subscribe(device => {
        this.device = device;
        this.selectedRotation = device.rotation;
        if (!this.device.isRunningAsWebApp) {
          this.isVisibleSectionForWebApp = true;
        }
        if(!this.isVirtualStation){
          this.form.patchValue({
            name: this.device.name,
            type: this.device.type,
            isMultipleStation: this.device.isMultipleStation,
            isRunningAsWebApp: this.device.isRunningAsWebApp,
            isAndonAutomaticReboot: this.device.isAndonAutomaticReboot,
          });
        }else{
          this.form.patchValue({
            name: this.device.name,
            type: this.device.type,
            blockInPause: this.device?.blockInPause,
            blockInNoShift: this.device?.blockInNoShift,
            isMultipleStation: this.device.isMultipleStation,
            isRunningAsWebApp: this.device.isRunningAsWebApp
          });
        }
        if (this.device.isMultipleStation) {
          this.getAllMultipleStations();
        }
        this.deviceUpdaterService.init([this.device]);
      });
  }

  private initForm() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(this.maxNameLength)]),
      type: new UntypedFormControl(null, Validators.required),
      unassignedDeviceId: new UntypedFormControl(null),
      isMultipleStation: new UntypedFormControl(false),
      isRunningAsWebApp: new UntypedFormControl({value: false, disabled: true}),
      blockInPause: new UntypedFormControl(false),
      blockInNoShift: new UntypedFormControl(false),
      isAndonAutomaticReboot: new UntypedFormControl(false),
    })
  }

  get isVirtualStation(): boolean{
    return this.device?.type === DeviceTypeEnum.VIRTUAL_STATION;
  }
  

  private fillUnassignedDevices() {
    this.unassignedDeviceNetwork.getAll()
      .subscribe(unassignedDevices => {
        this.unassignedDevices = unassignedDevices;
      });
  }

  private getAllMultipleStations() {
    this.unassignedDeviceNetwork.getAllDevicesForMultipleStations(this.lineId).subscribe((multipleDevices) => {
      this.unassignedDevices = multipleDevices;
    });
  }
}
