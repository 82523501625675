import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DeviceFullInterface } from '../../../../../../common/device/interfaces/device-full.interface';
import { DeviceDataInterface } from '../../../../../../common/device/interfaces/device-data.interface';
import { DeviceRotationEnum } from '../../../../../../common/device/enums/device-rotation.enum';
import { ProcessOrderInterface } from '../../../../../../common/process/interfaces/process-order.interface';

@Injectable()
export class DeviceNetwork {
  private urlPart: string;

  constructor(
    private http: HttpClient,
  ) {
  }

  init(lineId?: number) {
    this.urlPart = lineId ? 'station' : 'andon';
  }

  getDevices(lineId?: number): Observable<DeviceFullInterface[]> {
    let url = `/${this.urlPart}`;

    if (lineId) {
      url += `/line/${lineId}`;
    }

    return this.http
      .get<DeviceFullInterface[]>(url);
  }

  getDevice(id: number): Observable<DeviceFullInterface> {
    return this.http
      .get<DeviceFullInterface>(`/${this.urlPart}/${id}`);
  }

  addDevice(data: DeviceDataInterface): Observable<DeviceFullInterface> {
    const url = `/${this.urlPart}`;

    return this.http
      .post<any>(url, data);
  }

  deleteDevice(id: number, lineId?: number): Observable<boolean> {
    let url = `/${this.urlPart}`;

    if (lineId) {
      url += `/line/${lineId}`;
    }

    return this.http
      .delete<boolean>(`${url}/${id}`);
  }

  updateDevice(id: number, data: DeviceDataInterface): Observable<DeviceFullInterface> {
    return this.http
      .patch<DeviceFullInterface>(`/${this.urlPart}/${id}`, data);
  }

  changeActive(id: number): Observable<boolean> {
    return this.http.patch<boolean>(`/${this.urlPart}/change-active/${id}`, null);
  }

  rebootDevice(id: number): Observable<boolean> {
    return this.http
      .post<boolean>(`/${this.urlPart}/reboot/${id}`, null);
  }

  updateDeviceBeat(deviceId: number, beatTime: number, type: any) {
    const obj: { beat: number, lastUpdatedBeatValue?: null|number } = {
      beat: beatTime
    };
    if (type === true || type === false) {
      obj.lastUpdatedBeatValue = type === false ? beatTime: null
    }
    return this.http
      .post<any>(`/${this.urlPart}/beat/${deviceId}`, obj);
  }

  changeRotation(deviceId: number, rotation: DeviceRotationEnum) {
    return this.http.post(`/${this.urlPart}/rotation/${deviceId}`, {
      rotation
    });
  }

  canRotate(deviceId: number): Observable<boolean> {
    return this.http.get<boolean>(`/${this.urlPart}/can-rotate/${deviceId}`);
  }

  unassign(id: number): Observable<boolean> {
    return this.http
      .delete<boolean>( `/device-connect/${id}`);
  }

  changeOrder(lineId: number, data: ProcessOrderInterface[]): Observable<boolean> {
    return this.http
      .patch<boolean>(`/process/${lineId}`, data);
  }
}
