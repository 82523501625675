<vex-secondary-toolbar>
    <vex-breadcrumbs [crumbs]="crumbs" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>
<div class="page-content">
  <div class="row ap-card shadow">
    <page-header titleTranslate="SPA.NAV.EMAIL_CONFIGURATION"></page-header>
    <div class="row">
      <div class="col s12">
        <form #emailConfigForm="ngForm" (ngSubmit)="save(emailConfigForm)">
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                    <mat-label>{{ 'SPA.EMAIL_CONFIG.HOST' | translate }}</mat-label>
                    <input matInput required [(ngModel)]="configuration.host" [ngModelOptions]="{standalone: true}">
                    <mat-error>{{ 'SPA.EMAIL_CONFIG.HOST_ERROR' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                    <mat-label>{{ 'SPA.EMAIL_CONFIG.ADDRESS' | translate }}</mat-label>
                    <input matInput type="text" required [(ngModel)]="configuration.address" [ngModelOptions]="{standalone: true}">
                    <mat-error>{{ 'SPA.EMAIL_CONFIG.ADDRESS_ERROR' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                    <mat-label>{{ 'SPA.EMAIL_CONFIG.LOGIN' | translate }}</mat-label>
                    <input matInput [(ngModel)]="configuration.login" [ngModelOptions]="{standalone: true}">
                    <mat-error>{{ 'SPA.EMAIL_CONFIG.LOGIN_ERROR' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                    <mat-label>{{ 'SPA.EMAIL_CONFIG.PASSWORD' | translate }}</mat-label>
                    <input matInput type="password" [(ngModel)]="configuration.password" [ngModelOptions]="{standalone: true}">
                    <mat-error>{{ 'SPA.EMAIL_CONFIG.PASSWORD_ERROR' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                    <mat-label>{{ 'SPA.EMAIL_CONFIG.PORT' | translate }}</mat-label>
                    <input matInput required type="number" [(ngModel)]="configuration.port" [ngModelOptions]="{standalone: true}">
                    <mat-error>{{ 'SPA.EMAIL_CONFIG.PORT_ERROR' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                    <mat-label>{{ 'SPA.EMAIL_CONFIG.SERVER_HOST' | translate }}</mat-label>
                    <input matInput type="text" required [(ngModel)]="configuration.serverHost" [ngModelOptions]="{standalone: true}">
                    <mat-error>{{ 'SPA.EMAIL_CONFIG.HOST_ERROR' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <mat-slide-toggle [(ngModel)]="configuration.ssl" [ngModelOptions]="{standalone: true}">
                    {{ 'SPA.EMAIL_CONFIG.SSL' | translate }}
                </mat-slide-toggle>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="mt-10">
                <div fxFlex="auto" fxLayoutGap="16px">
                    <button color="warn" mat-raised-button (click)="removeConfiguration()" [disabled]="!hasConfiguration" type="button">
                        {{ 'SPA.EMAIL_CONFIG.REMOVE_CONFIGURATION' | translate }}
                    </button>
                    <button color="primary" mat-raised-button (click)="checkEmailConfiguration()" [disabled]="checking">
                        {{ 'SPA.EMAIL_CONFIG.CHECK_EMAIL_BTN' | translate }}
                    </button>
                </div>
                <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
                    <button color="warn" mat-button type="button" (click)="goBack()"> {{ 'SPA.EMAIL_CONFIG.BACK_BTN' | translate }}</button>
                    <button color="primary" mat-raised-button type="submit" [disabled]="disabledSave()">
                        {{ 'COMMON.SAVE' | translate }}
                    </button>
                </div>
            </div>
        </form>
      </div>
    </div>
  </div>
</div>
