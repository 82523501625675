<div class="page-header">
	<page-header titleTranslate="{{ getTranslatedDropdownHeader(data.key) }}" [onStation]="true" tabindex="0">
	</page-header>
</div>
<div mat-dialog-content class="dropdown">
	<mat-form-field fxFlex="auto" appearance="outline" class="dropDown green" color="primary">
		<mat-label>{{ "SPA.ALARMS_ESCALATIONS.DROPDOWN_MODAL.COUNT" | translate }}</mat-label>
		<input matInput type="text"
			[formControl]="dropDownSearch"
			(keyup)="filterDropdownOptions($event, data.key)">
			<mat-icon matSuffix *ngIf="!!dropDownSearch.value" (click)="dropDownSearch.patchValue('');filterDropdownOptions($event, data.key)">close</mat-icon>
	</mat-form-field>
	<div>
		<div>
			<table  style="width:97%">
				<tr *ngIf="dropdownHeaders.length > 0">
					<th>{{dropdownHeaders[1]}}</th>
					<th>{{dropdownHeaders[2]}}</th>
					<th>{{ "SPA.ALARMS_ESCALATIONS.DROPDOWN_MODAL.COUNT" | translate }}</th>
				</tr>
			</table>
			<div class="inputs dropdown-table" *ngIf="dropdownOptions.length > 0">
				
				<table style="width:100%">
						<tr *ngFor="let dOption of dropdownOptions"
							(click)="changeDropdownValue(dOption[CONSTANT_KEY_UNIQUE_ID], dOption[CONSTANT_KEY_MATERIAL_NAME], $event);"
							[ngClass]="{'dropdown-highlight' : checkSelected(dOption[CONSTANT_KEY_UNIQUE_ID])}"
							class=""
							>
							<td> {{ dOption[CONSTANT_KEY_MATERIAL_NAME] }}</td>
							<td> {{ dOption[CONSTANT_KEY_MATERIAL_NUMBER] }}</td>
							<td>{{ getCount(dOption[CONSTANT_KEY_UNIQUE_ID]) }}</td>
						</tr>
				</table>
			</div>
		</div>
		<div>
			<span class="tag" *ngFor="let element of selectedDropdownValuesWithCount; let i=index">
				{{ element.name }} ( {{ element.count}} )
				<span style="color: black; margin-left: 6px; cursor: pointer;"
					(click)="deleteDropdownOption(element)"> X
				</span>
			</span>
		</div>
	</div>
</div>
<div class="footer-btns">
	<div fxFlex="auto" fxLayoutAlign="center" fxLayoutGap="16px">
		<button color="warn" mat-raised-button type="button" (click)='cancel()'>
			{{ 'SPA.ALARMS_ESCALATIONS.UPDATE_REASON.CANCEL' | translate }}
		</button>
		<button color="primary" mat-raised-button (click)='ok()'>
			{{ 'SPA.ALARMS_ESCALATIONS.UPDATE_REASON.CONFIRM' | translate }}
		</button>
	</div>
</div>