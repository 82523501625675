import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from '../../../../../common/shared/services/common.service';
import {
	CONSTANT_KEY_UNIQUE_ID,
	CONSTANT_KEY_MATERIAL_NAME,
	CONSTANT_KEY_MATERIAL_NUMBER
} from '../../../../../common/alarms-and-escalations/constants/alarm-dropdown-data-keys.constants';

@Component({
	selector: 'app-alarm-dropdown-options-modal',
	templateUrl: './alarm-dropdown-options-modal.component.html',
	styleUrls: ['./alarm-dropdown-options-modal.component.scss'],
})
export class AlarmDropdownOptionsModalComponent implements OnInit {

	selectedDropdownValuesWithCount: any = [];  // Used for counts
	dropdownOptions: any = [];
	dropdownHeaders: any = [];
	dropdownValuesWithCountForParentModal: any = [];
	readonly CONSTANT_KEY_UNIQUE_ID = CONSTANT_KEY_UNIQUE_ID;
	readonly CONSTANT_KEY_MATERIAL_NAME = CONSTANT_KEY_MATERIAL_NAME;
	readonly CONSTANT_KEY_MATERIAL_NUMBER = CONSTANT_KEY_MATERIAL_NUMBER;
	private dropDownChangeSubject: Subject<object | any> = new Subject();
	public dropDownSearch: UntypedFormControl = new UntypedFormControl('');
	constructor(
		public dialogRef: MatDialogRef<AlarmDropdownOptionsModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private commonService: CommonService
	) { }

	ngOnInit() {
		if (this.data.options) {
			this.dropdownOptions = _.cloneDeep(this.data.options);
			if (!!this?.data?.headers) {
				this.dropdownHeaders = [
				  0,
				  ...this.data.headers
				];
			} else {
				this.dropdownHeaders = Object.keys(this.dropdownOptions[0])
			}
		}

		// To set the cloned object to pass the old data on cancle button click
		if (this.data.selectedDropdownValuesWithCount) {
			this.dropdownValuesWithCountForParentModal = _.cloneDeep(this.data.selectedDropdownValuesWithCount);
		}

		this.selectedDropdownValuesWithCount = this?.data?.selectedDropdownValuesWithCount || [];

		// Filter the dropdown based on user input
		this.dropDownChangeSubject.pipe(
			debounceTime(500)
		).subscribe((data) => {
			this._filter(data.eventData?.target?.value || '', data.key)
		});
	}

	// Event which is triggered on dropdown input value changes
	filterDropdownOptions(updateObj, key) {
		this.dropDownChangeSubject.next({
			eventData: updateObj,
			key
		});
	}

	// Filter the actual dropdown values 
	private _filter(value: string, key): any[] {
		const filterValue = value.toLowerCase();
		let clonedData = _.cloneDeep(this.data.options);
		this.dropdownOptions = clonedData.filter(option => (option[this.CONSTANT_KEY_MATERIAL_NAME].toLowerCase().includes(filterValue) || option[this.CONSTANT_KEY_MATERIAL_NUMBER].toLowerCase().includes(filterValue)));
		return;
	}

	ok() {
		let dataToSend = {
			isOKClicked: true,
			key: this.data.key,
			selectedDropdownValuesWithCount: this.selectedDropdownValuesWithCount
		}
		this.dialogRef.close(dataToSend)
	}

	// To remove the item form the selected dropdown list
	deleteDropdownOption(element) {
		// To check the item like how many time selected and update the count based on it
		const checkItemExist = this.selectedDropdownValuesWithCount.findIndex(item => item.key === element.key);
		if (checkItemExist != -1) {
			const count = this.selectedDropdownValuesWithCount[checkItemExist].count;
			if (count === 1) {
				this.selectedDropdownValuesWithCount.splice(checkItemExist, 1);
			} else {
				this.selectedDropdownValuesWithCount[checkItemExist].count = count > 1 ? (count - 1) : 0;
			}
		}
	}

	cancel() {
		let dataToSend = {
			isOKClicked: false,
			key: this.data.key,
			selectedDropdownValuesWithCount: this.dropdownValuesWithCountForParentModal
		}
		this.dialogRef.close(dataToSend);
	}

	// Function call on the value selected from the dropdown
	changeDropdownValue(value, name, event: KeyboardEvent) {
		event.stopPropagation();
		
		// To add/update the count of the items those are selected and store in selectedDropdownValuesWithCount array
		const getItemIndex = this.selectedDropdownValuesWithCount.findIndex(item => item.key === value);
		if (getItemIndex != -1) {
			this.selectedDropdownValuesWithCount[getItemIndex].count = this.selectedDropdownValuesWithCount[getItemIndex].count + 1;
		} else {
			this.selectedDropdownValuesWithCount.push({
				key: value,
				name,
				count: 1
			});
		}
	}

	// To check the item is selected or not and based on it apply the background-color
	checkSelected(value) {
		return this.selectedDropdownValuesWithCount.filter(item => (item.key === value && item.count > 0)).length > 0;
	}

	// To show the count of selected items in the item listing
	getCount(value): number {
		const getItem = this.selectedDropdownValuesWithCount.filter(item => (item.key === value));
		if (getItem.length > 0) {
			return getItem[0].count;
		}
		return 0;
	}

	getTranslatedDropdownHeader(dropdownKey): string {
		return this.commonService.getTranslatedName('name', this.data?.translationObject);
	}
}
