import { Injectable } from '@angular/core';
import { ChartsAndTablesDataNetwork } from '../networks/charts-and-tables-data-network.service';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { ChartDataTypeEnum } from '../../../../common/charts/enums/chart-data-type.enum';
import * as momentTimezone from 'moment-timezone';
import { TableDataTypeEnum } from '../../../../common/charts/enums/table-data-type.enum';
import { ChartsAndTablesFiltersInterface } from '../../../../common/charts/interfaces/charts-and-tables-filters.interface';
import { SortDirEnum } from '../../../../common/shared/enums/sort-dir.enum';

@Injectable()
export class ChartsAndTablesDataService {
    filters: ChartsAndTablesFiltersInterface = {
        lineId: null,
        stationId: null,
        range: 'CURRENT_MONTH',
        timezone: momentTimezone.tz.guess(),
        timePeriod: {
            begin: moment(moment().subtract(7, 'days')).format('YYYY-MM-DDTHH:mm'),
            end: moment().format('YYYY-MM-DDTHH:mm')
        },
        alarmChartType: null,
        alarmChartLabel: null
    };
    private _filtersChanged = new Subject<boolean>();

    constructor(private network: ChartsAndTablesDataNetwork) {}

    filtersChanged(): Observable<boolean> {
        return this._filtersChanged.asObservable();
    }

    emitFilterChanged() {
        this._filtersChanged.next(true);
    }

    loadDataForChart(dataType: ChartDataTypeEnum) {
        return this.network.loadChartData({
            ...this.filters,
            dataType: dataType
        });
    }

    getTableData(dataType: TableDataTypeEnum, sortDir = SortDirEnum.ASC): Observable<any> {
        return this.network.loadTableData({
            ...this.filters,
            dataType: dataType,
            sortDir: sortDir,
        })
    }

    isReady(): boolean {
        return !!this.filters.lineId;
    }
}