import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { ClockService } from '../services/clock.service';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
  constructor(
    private clockService: ClockService
  ) {
  }

  transform(value: string | Date | moment.Moment, format = 'DD.MM.YYYY HH:mm'): string {
    if (
      value instanceof Date ||
      typeof value === 'string'
    ) {
      value = moment(value);
    }

    return value?value
      ?.tz(this.clockService.timezone)
      ?.subtract(this.clockService.difference)
      ?.format(format):'';
  }
}