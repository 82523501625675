import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AlarmsAndEscalationsService } from '../../alarms-and-escalations.service';
import { forkJoin } from 'rxjs';
import { CsvParserService } from "../../../../../common/csv/services/csvParser.service";
import { NgForm } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { InfoboxService } from '../../../../../common/shared/services/infobox.service';
import { AddTranslationsModalComponent } from '../../../../../common/shared/components/modals/add-translations/add-translations-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CommonService } from '../../../../../common/shared/services/common.service';
import { TranslateService } from '../../../translate/services/translate.service';
import { AlarmEscalationMatrixInterface } from '../../../../../common/alarms-and-escalations/interfaces/alarm-escalation-matrix-interface';

@Component({
    selector: 'app-alarm-form',
    templateUrl: './alarm-form.component.html',
    styleUrls: ['./alarm-form.component.scss']
})
export class AlarmFormComponent implements OnInit {
    model: any = {
        customFields: [],
        color:"",
        escalationMatrix: [],
        data: [],
        line: [],
        station: [],
        productionSector: [],
        martix: [],
        priority: 0,
        selectStation: [],
        dropdownOptions: {},
        alarmColoring:{
            occuredBasicColor: "",
            occuredBlinkingColor: "",
            occuredBlinkingInterval: 5,
            forwardedBasicColor: "",
            forwardedBlinkingColor: "",
            forwardedBlinkingInterval: 5,
            escalatedBasicColor: "",
            escalatedBlinkingColor: "",
            escalatedBlinkingInterval: 5,
            subscribedBasicColor: "",
            subscribedBlinkingColor: "",
            subscribedBlinkingInterval: 5,
        },
        translationJson:{
            en:{
                name:''
            },de:{
                name:''
            }, zh: {
                name:''
            }
        },
        name: '',
        requireAlarmEndingReason: true,
        delayBeforeOccurTime: {
            minutes: '00',
            seconds: '00'
        }
    };
    alarmId = 0;

    allStations = [];
    allLines = [];
    allGroups = [];
    serverCalarm;
    @ViewChild(NgForm, { static: false }) form: NgForm;

    constructor(private activatedRoute: ActivatedRoute,
        private alarmsAndEscalationsService: AlarmsAndEscalationsService,
        private router: Router,
        private csvParser: CsvParserService,
        private toastService: InfoboxService,
        private commonService: CommonService,
        public translateService:TranslateService,
        public dialog: MatDialog) {

    }

    ngOnInit(): void {
        this.alarmId = +this.activatedRoute.snapshot.params.id;
        let sources = [
            this.alarmsAndEscalationsService.fetchStations(),
            this.alarmsAndEscalationsService.fetchLines(),
            this.alarmsAndEscalationsService.fetchGroupsList(),
        ];
        if (this.alarmId) {
            sources.push(this.alarmsAndEscalationsService.getConfiguredAlarmById(this.alarmId));
        }
        forkJoin(
            sources
        ).subscribe(responses => {
            // this.allStations = responses[0];
            this.allLines = responses[1];
            this.allGroups = responses[2];
            if(this.alarmId) {
                this.model.selectStation = responses[3]["station"];
                this.serverCalarm = responses[3];
                // this.model = responses[3];
                // this.model.customFields = responses[3]['data'];
                this.model.customFields = this.serverCalarm['data'];
                // let tData = this.serverCalarm['data'];
                // for (let key in tData) {
                //     this.model.customFields.push({
                //         type: tData[key],
                //         name: key
                //     })
                // }
                this.model.translationJson=this.serverCalarm['translationJson'];
            // If 'zh' key not exist in translationJson, Add that 
            if(!this.model.translationJson?.hasOwnProperty('zh')) {
                this.model.translationJson['zh'] = { name: '' } ;
            }
                this.model.dropdownOptions = this.serverCalarm['dropdownOptions'];
                this.model.name = this.model.translationJson[this.translateService.userLang].name;
                this.model.priority = this.serverCalarm['priority'];
                this.model.color = this.serverCalarm["color"];
                this.model.requireAlarmEndingReason = this.serverCalarm["requireAlarmEndingReason"];
                if(!!this.serverCalarm['alarmColoring'])
                    this.model.alarmColoring = this.serverCalarm['alarmColoring'];
                this.model.productionSector = [];
                this.serverCalarm['escalationMatrix'].forEach(es => {
                    let seconds;
                    let minutes;
                    let hours;
                    if (es.timeToEscalate) {
                        let time = es.timeToEscalate;
                        seconds = time % 60 < 10?'0'+Math.floor(time % 60):Math.floor(time % 60);
                        time = time / 60;
                        minutes = time % 60 < 10?'0'+Math.floor(time % 60):Math.floor(time % 60);
                        time = Math.floor(time / 60);
                        hours = time % 24 < 10?'0'+Math.floor(time % 24):Math.floor(time % 24);
                    }
                    this.model.productionSector.push({
                        id: es.productionSector.id,
                        name: es.productionSector.name,
                        alarmGroups: es.alarmGroup,
                        timeToEscalate: es.timeToEscalate,
                        escalationGroup: es.escalationGroup,
                        hours: hours,
                        minutes: minutes,
                        seconds: seconds
                    });
                });
            }
            if(this.serverCalarm?.delayBeforeOccurring) {
                const delaySeconds = this.serverCalarm?.delayBeforeOccurring;
                this.model.delayBeforeOccurTime.minutes = this.addLeadingZero(Math.floor(delaySeconds / 60));
                this.model.delayBeforeOccurTime.seconds = this.addLeadingZero(delaySeconds % 60);
            }
            let dataToend = {
                lines: this.model.productionSector.map(obj => obj.id).join(',')
            }
    
            this.alarmsAndEscalationsService.fetchLinesBySector(dataToend).subscribe(res => {
                this.allStations = res;
            })
        });
    }

    removeCustomField(i: any) {
        this.model.customFields.splice(i, 1);
    }

    public changeFileListener(files: FileList, field){
        // to parse the csv and set the data into json
        this.csvParser.parse(files[0], {
            headerAsKey: true,
            delimiter: ','
        }).pipe().subscribe((result: Array<any>) => {
            
            if(result?.length){
                let objectKeys: any;
                const updatedData = result.map((d,index) => {
                    objectKeys = Object.keys(d);
                    if (objectKeys.includes('Material_name') && objectKeys.includes('Material_number')) {
                        d['Unique_id'] = this.generateRandomString(15,index);
                        return d;
                    }
                    d['Unique_id'] = this.generateRandomString(15,index);
                    d['Material_name'] = d[Object.keys(d)[0]];
                    d['Material_number'] = d[Object.keys(d)[1]];
                    delete d[objectKeys[0]];
                    delete d[objectKeys[1]];
                    return d;
                });
                if (!!objectKeys) {
                    this.model.customFields[field].dropdownHeaders = objectKeys;
                  }
                  this.model.customFields[field].dropdownOptions = updatedData;
                  this.model.customFields[field].uploadedFile = files[0]?.name || '';
            }
            this.toastService.success('SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.FILE_UPLOADED_SUCCESS_MSG', {
                fileName: files[0].name
            });
        }, (error) => {
            
        });
      }
      //15
      generateRandomString(length,index) {
        const timestamp = new Date().getTime();
        const randomNumber = Math.floor(Math.random() * Math.pow(10, length));
        const randomString = `${timestamp}_${randomNumber}_${index}`;
        return randomString;
      }

    save() {
        // Check the form validation
        if (!this.form.valid) {
            return;
        }

        // Check the duplicate custom fields name exists or not
        let foundDuplicateName = this.model.customFields.find((nnn, index) =>{
            return this.model.customFields.find((x, ind)=> x.translationJson[this.translateService.userLang].name.trim().toLowerCase() === nnn.translationJson[this.translateService.userLang].name.trim().toLowerCase() && index !== ind )
        });
        if (!!foundDuplicateName) {
            this.toastService.error('SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ERROR_MESSAGE_FOR_UNIQUE_CUSTOM_FIELDS');
            return;
        }

        // Check the every dropdown field, that it has csv file uploaded or not
        let foundNotUploadedCsvFields = this.model.customFields.filter((field) => {
            return field.type === "Dropdown" && !field.hasOwnProperty('uploadedFile');
        });
        if (foundNotUploadedCsvFields?.length) {
            this.toastService.error('SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ERROR_MESSAGE_FOR_DROPDOWN_TYPE_FIELDS');
            return;
        }

        const minutes = +this.model?.delayBeforeOccurTime?.minutes || 0;
        const seconds = +this.model?.delayBeforeOccurTime?.seconds || 0;
        // Restrict form submit, if delay seconds is exceed 60 seconds
        if(seconds >= 60 ) return;
        const delaySeconds = minutes * 60 + seconds;

        let dataTosend = {
            "name": this.model.translationJson[this.translateService.userLang].name,
            "extraData":this.model.customFields,
            "dropdownOptions": this.model.dropdownOptions,
            "priority": this.model.priority,
            "escalationMatrix": [],
            "station": this.removeAllFromArray(this.model.selectStation),
            "line": [],
            "color":this.model.color,
            "alarmColoring": this.model.alarmColoring,
            "translationJson": this.model.translationJson,
            "requireAlarmEndingReason": this.model.requireAlarmEndingReason,
            "delayBeforeOccurring": delaySeconds
        }
        if (this.alarmId) {
            dataTosend['id'] = this.alarmId;
        }
        // this.model.customFields.forEach(cf => {
        //     dataTosend.extraData[cf.name] = cf.type;
        // });
        this.model.productionSector.forEach(ps => {
            if(ps !== "All"){
                let time = 0;
                if(ps?.seconds){
                    time = time + ps.seconds * 1;
                }
                if(ps?.minutes){
                    time = time + ps.minutes * 60;
                }
                if(ps?.hours){
                    time = time + ps.hours * 3600;
                }
                dataTosend.escalationMatrix.push(
                    {
                        "timeToEscalate": time,
                        "alarmGroup": ps.alarmGroups.id,
                        "escalationGroup": ps.escalationGroup?.id ? ps.escalationGroup?.id: null,
                        "productionSector": ps.id
                    }

                );

            dataTosend.line.push(ps)
            }
        });
        this.alarmsAndEscalationsService.saveCAlarm(dataTosend).subscribe(
            res => {

                setTimeout(
                    () => this.router.navigate(["/alarms-and-escalations/alarms"]),
                    100
                );
            }
        )


    }

    // To prevent the user to select the multiple custom fields with PHOTO/VIDEO type
    validateSelectionOfType(field, index, selectTypeEl?) {
        if (field.type === 'File') {
            const fileInputs = this.model.customFields.filter( (d) => d.type === 'File');
            if (!!fileInputs && fileInputs.length > 1) {
                this.model.customFields[index].type = '';
                if(selectTypeEl) selectTypeEl.value = '';
                this.toastService.error('SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ERROR_MESSAGE_FOR_FILE_TYPE_CUSTOM_FIELDS');
            }
        }
    }

    removeAllFromArray(data){
        let newData = [];
        data.forEach(element => {
            if(element !== 'All'){
                newData.push(element);
            }
        });

        return newData;
    }

    compareObjects(o1: any, o2: any) {
        if ((o1 == o2) || (o1.id == o2.id))
            return true;
        else return false
    }

    toggleAllSelection(){
        if(this.model.productionSector.includes("All")){
            this.model.productionSector = [...this.allLines, "All"];
            this.productionSectorChanged()
        }else{
            this.model.productionSector = [];
        }
    }

    selectAllStation(){
        if(this.model.selectStation.includes("All")){
            this.model.selectStation = [...this.allStations, "All"];
        }else{
            this.model.selectStation = [];
        }
    }

    selectOneStation(){
        if(this.model.selectStation.includes("All")){
            if(this.model.selectStation.length-1 !== this.allStations.length){
                let oldData = this.model.selectStation.slice();
                this.model.selectStation =[];
                oldData.forEach(element => {
                    if(element !== "All"){
                        this.model.selectStation.push(element);
                    }
                });
            }
        }else{
            if(this.model.selectStation.length === this.allStations.length){
                this.model.selectStation = [...this.model.selectStation, "All"];
            }
        }
    }

    productionSectorChanged() {
        if(this.model.productionSector.includes("All")){
            if(this.model.productionSector.length-1 !== this.allLines.length){
                let oldData = this.model.productionSector.slice();
                this.model.productionSector =[];
                oldData.forEach(element => {
                    if(element !== "All"){
                        this.model.productionSector.push(element);
                    }
                });
            }
        }else{
            if(this.model.productionSector.length === this.allLines.length){
                this.model.productionSector = [...this.model.productionSector, "All"];
            }
        }
        let dataToend = {
            lines: this.model.productionSector.map(obj => obj.id).join(',')
        }
        if (this.alarmId) {
            this.serverCalarm['escalationMatrix'].forEach(es => {
                this.model.productionSector.forEach((productionSector) => {
                    if(productionSector !== "All"){
                        if(es.productionSector.id === productionSector.id){
                            productionSector.alarmGroups= es.alarmGroup;
                            productionSector.timeToEscalate= es.timeToEscalate;
                            productionSector.escalationGroup= es.escalationGroup;
                            if (es.timeToEscalate) {
                                let time = es.timeToEscalate;
                                productionSector.seconds = time % 60 < 10?'0'+Math.floor(time % 60):Math.floor(time % 60);
                                time = time / 60;
                                productionSector.minutes = time % 60 < 10?'0'+Math.floor(time % 60):Math.floor(time % 60);
                                time = Math.floor(time / 60);
                                productionSector.hours = time % 24 < 10?'0'+Math.floor(time % 24):Math.floor(time % 24);
                            }
                            
                        }
                    }
                });
            });
        }
        

        this.alarmsAndEscalationsService.fetchLinesBySector(dataToend).subscribe(res => {
            this.allStations = res;
            const productionSectorsIds = this.model.productionSector.map((p) => {return p.id});
            if (this.model.selectStation.length > 0) {
                this.model.selectStation = this.model.selectStation.filter((s) => {
                    return productionSectorsIds.includes(s.line_id) || s === 'All';
                });
            }
        })

    }

    leadingZeros(input) {
        if(!isNaN(input.target.value) && input.target.value.length === 1) {
          input.target.value = '0' + input.target.value;
        }else{
          input.target.value = input.target.value * 1;
          if(!isNaN(input.target.value) && input.target.value.length === 1) {
            input.target.value = '0' + input.target.value;
          }
        }
    }

    openAddTranslationModal(name: string, translationJson, isTextArea = false) {
        if (name != '') {
            let defaultJson:any = {de:{},en:{},zh:{}};
            let key = isTextArea?'workstep_textarea' : name.trim().replace(' ', '_');
            defaultJson.de[key] = '';
            defaultJson.en[key] = '';
            const dialogRef = this.dialog.open(AddTranslationsModalComponent, {
                width: isTextArea? '60%':'40%',
                disableClose: true,
                data: {
                    isTextArea: isTextArea,
                    translationJson: translationJson || defaultJson,
                    key: key
                }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result.success) {
                    translationJson = result.data;
                    if (!!result.isAddMore) {
                        this.openAddTranslationModal(name,isTextArea);
                    }
                }
            })
        }
    }

    addCustomField(){
        this.model.customFields?.push({type:'String',name:'name',allowTriggeringAlarm: false, allowEndingAlarm: false, translationJson:{en:{name:''},de:{name:''},zh:{name:''}}, uniqueId: Date.now() + ( (Math.random()*100000).toFixed())})
    }

    // Return value with leading zero for single digit value 
    addLeadingZero(value: number | string): string {
        const strValue = value.toString();
        return strValue.length === 1 && !isNaN(Number(strValue)) ? '0' + strValue : strValue;
    }

    validateEscalationTimeInputs(field: AlarmEscalationMatrixInterface) {
        const { hours, minutes, seconds } = field;
        return (+hours === 0 || hours === '00')   
            && (+minutes === 0 || minutes === '00')
            && (+seconds === 0 || seconds === '00');
    }

    validateEscalationTimeValues(field: AlarmEscalationMatrixInterface) {
        const { hours, minutes, seconds } = field;
        const fieldValues = this.validateEscalationTimeInputs(field);

        return ((+hours > 0 && +minutes > 0 && +seconds > 0) 
            || (hours === '00' && minutes === '00' && seconds === '00') 
            || (!hours && !minutes && !seconds) 
            || fieldValues);
    }

    validateEscalationTime(field: AlarmEscalationMatrixInterface){
        const { escalationGroup } = field;
        return escalationGroup && this.validateEscalationTimeValues(field);        
    }   

    validateEscalationGroup(field: AlarmEscalationMatrixInterface) {
        return !this.validateEscalationTimeValues(field);
    }
}