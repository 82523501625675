import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LiveDebuggingComponent } from './live-debugging.component';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  imports: [
      CommonModule,
      FormsModule,
      HttpClientModule,
      RouterModule,
      SharedModule
  ],
  declarations: [
      LiveDebuggingComponent
  ],
    providers: [

    ]
})
export class LiveDebuggingModule { }
