<div class="group">
    <div class="disorder">
        <div class="disorder-root" (click)="expanded = !expanded" *ngIf="!isEditing()">
            <div class="code-and-icon">
                <div class="icon">
                    <mat-icon class="mat-icon-rtl-mirror" *ngIf="disorder.children?.length">
                        {{expanded ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </div>
                <div class="code">
                    <span>{{disorder.code}}</span>
                </div>
            </div>
            <div class="reason">
                <span >{{disorder.reason}}</span>
            </div>
            <div class="lines">
                <span>{{disorder.line?.name}}</span>
            </div>
            <div class="stations">
                <span >{{transferListToString(disorder.stations)}}</span>
            </div>
            <div class="actions">
                <div *ngIf="!isEditing() && !isAddEditListing()">
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                        <button color="primary" mat-raised-button type="button"
                                [routerLink]="['edit', disorder.id]">
                            <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                      inline="true">edit</mat-icon>
                        </button>
                        <button color="warn" mat-raised-button type="button" (click)="delete(disorder, $event)">
                            <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                      inline="true">delete</mat-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="isAddEditListing()" class="actions-container">
                    <div class="action-button">
                        <button mat-icon-button (click)="addChild($event)">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                    <div class="action-button">
                        <button mat-icon-button (click)="edit()">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                    <div class="action-button">
                        <button mat-icon-button (click)="delete(disorder, $event)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isEditing()">
        <app-edit-disorder-row [isChild]="false" [disorder]="disorder" (disorderSaved)="onDisorderSaved($event)"></app-edit-disorder-row>
    </div>
    <ng-container *ngIf="expanded">
        <app-disorder-row *ngFor="let child of disorder.children" [disorder]="child" [marginLeft]="20" (onDelete)="onChildDelete($event)"></app-disorder-row>
    </ng-container>
</div>
