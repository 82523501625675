import { Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AEGroupInterface } from '../../groups/interfaces/a-e-group.interface';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ForwardAlarmComponent } from './forward-alarm/forward-alarm.component';
import { first } from 'rxjs/operators';
import { AlarmsAndEscalationsService } from '../../alarms-and-escalations.service';
import { AlarmEventEnum } from '../../../../../common/alarms-and-escalations/enums/alarm-event.enum';
import { SaveFilterService } from '../../../../../common/shared/services/save-filter.service';
import { currentAlarmFilterKey } from '../../../../../common/filters/constants/filter-key.constants';
import { TableColumnVisibilityService } from '../../../../../common/user-table-settings/services/table-column-visibility.service';
import { UserTableTypeEnum } from '../../../../../common/user-table-settings/enums/user-table-type.enum';
import { UserTablePlaceEnum } from '../../../../../common/user-table-settings/enums/user-table-place.enum';
import * as _memont from 'moment';
import { DurationPipe } from '../../../../../common/shared/pipes/duration.pipe';
const moment = _memont;
import { UpdateReasonComponent } from '../../alarm-details/update-reason/update-reason.component';
import { UserPermissionsEnum } from '../../../../../common/auth/enums/user-permissions.enum';
import { AlarmLoginService } from '../../alarm-login.service';
import { LoginResponseInterface } from '../../../../../common/auth/interfaces/login-response.interface';
import { SomebodySubscribedModalComponent } from '../../somebody-subscribed-modal/somebody-subscribed-modal.component';
import { ActionLoginModalComponent } from '../../action-login-modal/action-login-modal.component';
import { UserInterface } from '../../../../../common/auth/interfaces/user.interface';
import { AuthService } from '../../../../app/auth/auth.service';
import { CommonService } from '../../../../../common/shared/services/common.service';
import { SocketMessageEnum } from '../../../../../common/socket/enums/socket-message.enum';
import { SocketService } from '../../../../app/_services/socket.service';
import { SocketChannel } from '../../../../../common/socket/utils/socket-channel';

@Component({
  selector: "app-current-alarms",
  templateUrl: "./current-alarms.component.html",
  styleUrls: ["./current-alarms.component.scss"],
})
export class CurrentAlarmsComponent implements OnInit {
  currentPage = 0;
  groups: AEGroupInterface[] = [];
  displayedColumns: string[] = [];
  permissions: [number] = [UserPermissionsEnum.AEEndAlarmFromDashboardManagement];
  columnsData = [
    {
      name: "colorStatus",
      id: "Status",
      isAlwaysVisible: true,
      isVisible: true,
    },
    {
      name: "status",
      id: "Status text",
      isAlwaysVisible: true,
      isVisible: true,
    },
    {
      name: "id",
      id: "Alarm id",
      isAlwaysVisible: true,
      isVisible: true,
    },
    {
      name: "lineName",
      id: "Production Sector",
      isAlwaysVisible: false,
      isVisible: true,
    },
    {
      name: "stationName",
      id: "Station",
      isAlwaysVisible: false,
      isVisible: true,
    },
    {
      name: "configuredAlarmName",
      id: "Alarm Type",
      isAlwaysVisible: false,
      isVisible: true,
    },
    {
      name: "customFieldsData",
      id: "Custom Fields",
      isAlwaysVisible: false,
      isVisible: false,
    },
    {
      name: "alarmGroupName",
      id: "Current alarm group",
      isAlwaysVisible: false,
      isVisible: true,
    },
    {
      name: "created",
      id: "Start",
      isAlwaysVisible: false,
      isVisible: true,
    },
    {
      name: "duration",
      id: "Duration",
      isAlwaysVisible: false,
      isVisible: true,
    },
    {
      name: "currentUser",
      id: "Current User",
      isAlwaysVisible: false,
      isVisible: true,
    },
    {
      name: "actions",
      id: "Actions",
      isAlwaysVisible: false,
      isVisible: true,
    },
  ];
  dataSource = new MatTableDataSource<any>([]);
  productionSectors = [];
  stations = [];
  filterData: any = {};
  alarmStatus = [0, 1, 2, 3, 4, 5, 7, 8];
  filterToSend: any = {};
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  showFilters = false;
  myInterval: any;
  isStationIdInFilter: boolean = false;
  private currentUser: UserInterface;
  data = [];
  isMobileScreen: boolean;
  private currentTime;
  socketSubs: string[] = [];

  constructor(
    private matDialog: MatDialog,
    private alarmsAndEscalationsService: AlarmsAndEscalationsService,
    private alarmLoginService: AlarmLoginService,
    private durationPipe: DurationPipe,
    private saveFilterService: SaveFilterService,
    private tableColumnVisibilityService: TableColumnVisibilityService,
    private authService: AuthService,
    private commonService: CommonService,
    private renderer: Renderer2, 
    private el: ElementRef,
    private socketService: SocketService,
  ) {}
  ngOnInit(): void {
    this.subscribeToCurrentTime();
    this.authService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });
    this.alarmsAndEscalationsService.fetchLines().subscribe((result) => {
      this.productionSectors = result;
    });
    // To get the saved filters from database
    this.saveFilterService
      .getFiltersUsingKey(currentAlarmFilterKey)
      .subscribe((filters) => {
        if (!!filters) {
          this.filterData.status = filters.status;
          this.filterData.productionSector = filters.productionSector;
          this.isStationIdInFilter = !!filters.station;
          if (!!filters.productionSector) {
            let dataToend = {
              lines: filters.productionSector,
            };

            // To fetch the stations based on the production sector
            this.alarmsAndEscalationsService
              .fetchLinesBySector(dataToend)
              .subscribe((res) => {
                this.stations = res;
                this.filterData.station = filters.station;
                if (this.isStationIdInFilter) {
                  this.filterToSend = {
                    ...this.filterData,
                  };
                  this.loadList();
                }
              });
          }
          this.filterToSend = {
            ...this.filterData,
          };
        }
        if (!this.isStationIdInFilter) {
          this.loadList();
        }
      });

    this.myInterval = setInterval(this.loadList, 5000);
    this.getColumns();

    this.renderer.listen('window', 'resize', (event) => {
      this.getWindowWidth();
    });

    this.getWindowWidth();
  }

  subscribeToCurrentTime() {
    this.socketService.join(SocketChannel.CURRENT_TIME);
    this.socketSubs.push(this.socketService.on(SocketMessageEnum.TIME, (data: any) => {
        this.currentTime = data.current_time;
    }));
  }

  ngOnDestroy(): void {
    this.socketSubs.forEach(s => this.socketService.off(s));
    clearInterval(this.myInterval);
  }


  getWindowWidth() {
    this.isMobileScreen = this.renderer.parentNode(this.el.nativeElement).ownerDocument.defaultView.innerWidth <= 768
  }

  async endAlarm(alarm) {
    let autoLoginResponse: any = await this.alarmLoginService.autoLogin(alarm);
    
    if (autoLoginResponse === null) {
      return;
    }

    if (autoLoginResponse === false) {
        this.openDisorderLoginModal(alarm);
        return;
    }
    
    this.decideWhichModalOpen(autoLoginResponse as LoginResponseInterface, alarm);
    
  }
  
  decideWhichModalOpen(result: LoginResponseInterface, alarm, openForwardModal: boolean = false) {
    if (result && result.token) {
        if (result.alarmAlreadySubscribed) {
          this.openAlreadySubscribedModal(result.token, result.alarmAlreadySubscribed.user, alarm, openForwardModal);
        } else {
          this.openLastModal(result.token, alarm, openForwardModal);
        }
    }
  }

  private openLastModal(token: string, alarm, openForwardModal: boolean) {
    if (openForwardModal) {
        this.openForwardModal(token, alarm);
    } else {
      this.openDisorderSolveModal(token, alarm);
    }
  }
  
  private openAlreadySubscribedModal(token: string, userAlreadySubscribed: string, alarm, openForwardModal: boolean = false) {
    const dialogRef = this.matDialog.open(SomebodySubscribedModalComponent, {
        width: '90%',
        maxWidth: '1000px',
        panelClass: 'somebody-subscribed',
        data: {
            token: token,
            lineId: alarm.line.id,
            stationId: alarm.station.id,
            alarmId: alarm.id,
            userAlreadySubscribed: userAlreadySubscribed
        }
    });

    dialogRef.afterClosed().subscribe((result) => {
        if (result) {
            this.alarmsAndEscalationsService.reSubscribe(alarm.id, alarm.notificationId, token).subscribe(() => {
                this.openLastModal(token, alarm, openForwardModal);
            })
        } else {
            this.logoutFromDisorderResolving(token, alarm);
        }
    })
  }

  private openDisorderSolveModal(token, alarm) {
    this.matDialog.open(UpdateReasonComponent, {
        data: {
          ...alarm,
          token: token,
          modalType: 'endAlarm'
        },
        width: '550px',
    }).afterClosed().pipe().subscribe(res => {
        if (res) {
          this.loadList();
        }
    });
  }

  private openForwardModal(token, alarm) {
    this.matDialog.open(ForwardAlarmComponent, {
      data: alarm,
      width: "350px",
    }).afterClosed().pipe(first()).subscribe((res) => {
      if (res) {
        this.loadList();
      }
    });
  }

  private openDisorderLoginModal(alarm, openForwardModal: boolean = false) {
    const dialogRef = this.matDialog.open(ActionLoginModalComponent, {
        width: '550px',
        maxWidth: '1000px',
        panelClass: 'login-modal',
        data: {
            extraLoginData: {
                alarmId: alarm.id,
                stationId: alarm.station.id,
                productItemId: null,
                isLoginForForwardAlarm: openForwardModal
            },
            endpoint: '/api/alarm/login',
            barcodeEndpoint: '/api/alarm/login/code',
            permissions: [UserPermissionsEnum.AESupervisor] 
        }
    });

    dialogRef.afterClosed().subscribe((result: LoginResponseInterface) => {
        this.decideWhichModalOpen(result, alarm, openForwardModal);
    });
  }

  logoutFromDisorderResolving(token: string, alarm) {
    const data = {
      alarmId: alarm.id,
      stationId: alarm.station.id,
      productItemId: null
    };
    this.alarmsAndEscalationsService.logout(data, token);
}

  getColumns() {
    this.tableColumnVisibilityService.loadTableMetaData(UserTableTypeEnum.CURRENT_ALARMS,UserTablePlaceEnum.SPA).subscribe((result:any)=>{
        if(result?.length > 0 && result.length === this.columnsData.length){
            this.columnsData = result;
        }
        this.setColumns();
    })
  }

  setColumns(){
      this.displayedColumns = [];
      this.columnsData.forEach((element) => {
          if (element.isVisible) {
            this.displayedColumns.push(element.name);
          }
      });
  }

  columnsChanged() {    
      let data = {
          tableTypeEnum: UserTableTypeEnum.CURRENT_ALARMS,
          placeEnum: UserTablePlaceEnum.SPA,
          columns: this.columnsData
      }
      this.tableColumnVisibilityService.saveTableMetaData(data).subscribe((result: any)=>{
          this.columnsData = result;
          this.setColumns();
      })
  }

  loadList = () => {
    let data = {
      page: this.currentPage,
      size: 10,
    };
    this.alarmsAndEscalationsService
      .adminGetAllCurrentAlarms(data, this.filterToSend)
      .subscribe((res) => {
        if (this.currentPage > 0 && res && res.length == 0) {
          this.currentPage = this.currentPage - 1;
          this.loadList;
        } else {
          this.dataSource = new MatTableDataSource<any>(res);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.data = res;
        }
      });
  };

  accept(element) {
    this.alarmsAndEscalationsService
      .acceptAlarm(element.id, element.notificationId)
      .subscribe((res) => {
        this.loadList();
      });
  }

  unSubscribeAlarm(element) {
    this.alarmsAndEscalationsService
      .unSubscribeAlarm(element.id, element.notificationId)
      .subscribe((res) => {
        this.loadList();
      });
  }

  async forward(element) {
    let autoLoginResponse: any = await this.alarmLoginService.autoLogin(element, true);

    if (autoLoginResponse === null) {
      return;
    }

    if (autoLoginResponse === false) {
        this.openDisorderLoginModal(element, true);
        return;
    }

    this.decideWhichModalOpen(autoLoginResponse as LoginResponseInterface, element, true);
  }

  next() {
    this.currentPage = this.currentPage + 1;
    this.loadList();
  }

  previous() {
    if (this.currentPage >= 1) {
      this.currentPage = this.currentPage - 1;
      this.loadList();
    }
  }

  changeFilters() {
    this.filterToSend = {};
    for (const [key, value] of Object.entries(this.filterData)) {
      this.filterToSend[key] = value;
    }

    // To save the filters
    this.saveFilterService.createObject(
      currentAlarmFilterKey,
      this.filterToSend
    );
    this.loadList();
  }

  calculateDuration(cd) {
    return this.calculateDurationFromDates(cd);
  }

  calculateDurationFromDates = (begin) => {
    const begin1 = moment.utc(new Date(begin), "HH:mm:ss");
    let end = moment.utc(new Date(this.currentTime), "HH:mm:ss");

    if (end.isSameOrBefore(begin1)) {
      end = end.add(1, "day");
    }

    let dif = moment.duration(end.diff(begin)).asSeconds();
    return this.durationPipe.transform(dif);
  };

  resetFilters() {
    this.filterToSend = {};
    this.filterData = {};
    this.currentPage = 0;
    // To save the filters
    this.saveFilterService.createObject(
      currentAlarmFilterKey,
      this.filterToSend
    );
    this.loadList();
  }

  productionSectorChanged() {
    this.filterData.station = null;
    this.stations = [];
    if (!this.filterData["productionSector"]) {
      return;
    }
    let dataToend = {
      lines: this.filterData["productionSector"],
    };

    this.alarmsAndEscalationsService
      .fetchLinesBySector(dataToend)
      .subscribe((res) => {
        this.stations = res;
      });
  }

  getTranslatedAlarm(alarm): string {
    return this.commonService.getTranslatedName('name', alarm?.configuredAlarm?.translationJson || null);
  }
}
