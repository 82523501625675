import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PermissionGroupInterface } from '../../../../../../../common/auth/interfaces/permission-group.interface';
import { UserPermissionsEnum } from '../../../../../../../common/auth/enums/user-permissions.enum';
import { RoleInterface } from '../../../../../../../common/auth/interfaces/role.interface';
import { UserPermissionsUtil } from '../../../../../../../common/auth/utils/user-permissions.util';
import { RolePermissionService } from '../../../role-permission.service';

@Component({
  selector: 'app-permission-group',
  styleUrls: ['permission-group.component.scss'],
  templateUrl: 'permission-group.component.html'
})
export class PermissionGroupComponent {
  @Input() groups: PermissionGroupInterface[];
  @Input() role: RoleInterface;
  @Input() disabled: boolean = false;
  @Output() notifyPermissionChange: EventEmitter<RoleInterface> = new EventEmitter<RoleInterface>();
  UserPermissionEnum = UserPermissionsEnum;

  constructor(
    public rolePermissionService: RolePermissionService
  ) {
  }

  valueChanged(event: boolean, children?: PermissionGroupInterface[]) {
    this.notifyParent(this.role);
    if (event || !children || !children.length) {
      return;
    }

    const allChildrenValues = UserPermissionsUtil.getGroupedValues(children);

    for (let [perm, value] of Object.entries(this.role.permissions)) {
      if (allChildrenValues.includes(+perm) && value) {
        this.role.permissions[perm] = false;
      }
    }
  }

  notifyParent(data: RoleInterface): void {
    this.notifyPermissionChange.emit(data);
  }

  onSubPermissionChange(): void {
    this.notifyParent(this.role);
  }
}