import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ColumnMetaDataInterface} from '../../interfaces/column-meta-data.interface';

@Component({
  selector: 'app-select-columns',
  templateUrl: './select-columns.component.html',
  styleUrls: ['./select-columns.component.scss']
})
export class SelectColumnsComponent {
    @Input() icon = "filter_list";
    @Input() columns: ColumnMetaDataInterface[] = [];
    @Output() columnsChanged = new EventEmitter();
    @Input() translatable: boolean = true;

    toggleColumnVisibility(column, event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        column.isVisible = !column.isVisible;
        this.columnsChanged.emit(true);
    }
}
