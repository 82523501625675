import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ClockSettingsInterface} from '../../interfaces/clock-settings.interface';
import {ColorsFormatUtil} from '../../../shared/utils/colors-format.util';
import { ChartComponent as ApexChart } from 'ng-apexcharts';
import {BasicConfiguratorWidget} from '../basic-configurator-widget';
import {WidgetTypeEnum } from '../../../widget/enums/widget-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { DurationPipe } from '../../../shared/pipes/duration.pipe';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
@Component({
    selector: 'configurator-circle-clock-widget',
    templateUrl: './configurator-circle-clock-widget.component.html',
    styleUrls: ['./configurator-circle-clock-widget.component.scss']
})
export class ConfiguratorCircleClockWidgetComponent extends BasicConfiguratorWidget implements OnInit, OnChanges, OnDestroy {

    chartOptions;
    chart: ApexChart;
    chartContainerRef: ElementRef
    chartWidth;
    chartHeight;
    fontResizeWidth: number;
    fontResizeHeight: number;
    @Input() label: string;
    @Input() time: number = 10000;
    @Input() percent: number = 20;
    @Input() height: number = 100;
    @Input() width: number = 100;
    @Input() fontColor: string = 'white';
    @Input() onStation = false;
    @Input() addPadding = false;
    @Input() widgetId: number;
    @Input() settings: ClockSettingsInterface;
    @Input() lineInterrupt = false;
    @Input() lineInterruptData = undefined;
    @Input() nextShift = null;
    @Input() widgetType = null;
    @Input() showTime = true;
    private fontResizeWidthMagicNumber: number;
    private fontResizeHeightMagicNumber: number;
    private resizing: boolean = false;
    calcFontSize: string = '16px';

    @ViewChild('chartComponent') set chartComponent(chartComponent: ApexChart) {
        this.chart = chartComponent;
    }
    @ViewChild('chartContainer') set chartContainer(chartContainer: ElementRef) {
        this.chartContainerRef = chartContainer;
        this.reCalculateDefaultSizes();
    }

    constructor(private translateService: TranslateService,private durationPipe: DurationPipe, private datePipe: CustomDatePipe) {
        super();
    }

    widgetResized() {
        this.reCalculateDefaultSizes(true);
    }

    checkLineInterrupted(){
        return !this.lineInterrupt;
    }

    get hasActiveShift(): boolean {
        return this.lineInterruptData && this.lineInterruptData.shiftActive;
    }

    ngOnInit(): void {
        this.fontChangedSubscription = this.fontChanged.subscribe((result: number) => {
            this.settings['fontSize'] = result;
        });
        this.fontResizeWidthMagicNumber = this.onStation ? 0.6 : 0.6;
        this.fontResizeHeightMagicNumber = this.onStation ? 0.25 : 0.4;

        setTimeout(() => {
            // this timeout, along with the async/awaits is due to some weird apex errors
            this.chartOptions = {
                chart: {
                    height: Math.min(this.height, this.width),
                    type: "radialBar",
                    sparkline: {
                        enabled: true
                      },
                    events: {
                        updated: (chartContext, config) => {
                            if (!this.resizing) {
                                return;
                            }

                            this.setWidthsAndHeights(config.globals.svgWidth, config.globals.svgHeight);

                            this.resizing = false;
                        },
                        mounted: (chartContext, config) => {
                            this.setWidthsAndHeights(config.globals.svgWidth, config.globals.svgHeight);
                        }
                    }
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: "65%",
                        },
                        dataLabels: {
                            show: false,
                        },
                    }
                },
                fill: {
                    type: "gradient",
                    colors: [this.getColorDependsOnPercent()],
                    gradient: {
                        shade: "dark",
                        type: "horizontal",
                        shadeIntensity: 0,
                        gradientToColors: [this.getGradientColorDependsOnPercent()],
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0]
                    }
                },
                stroke: {
                    lineCap: "round"
                },
                labels: [""],
            };
        }, 500)

        this.initData();
    }

    get timeColor(): string {
        return this.settings && this.settings.timeColor ? this.settings.timeColor : '#04B2D9';
    }

    get overTimeColor(): string {
        return this.settings && this.settings.overTimeColor ? this.settings.overTimeColor : '#d32f2f';
    }

    // get configured percentage for changing time-color to overtime-color
    get overTimeColorPercentage(){
        return this.settings && this.settings?.overTimeColorPercentage ? (100 - this.settings?.overTimeColorPercentage) : 100;
    } 

    getColorDependsOnPercent(): string {
        return this.percent < this.overTimeColorPercentage ? this.timeColor : this.overTimeColor;
    }

    getGradientColorDependsOnPercent(): string {
        return this.percent < this.overTimeColorPercentage ? ColorsFormatUtil.hexToRgba(this.timeColor, 0.7) : ColorsFormatUtil.hexToRgba(this.overTimeColor, 0.7);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if ((changes.settings && changes.settings.currentValue) || (changes.percent && changes.percent.currentValue)) {
            await this.updateColors();
        }
        if(WidgetTypeEnum.SHIFT_TIME_CLOCK === this.widgetType){
            if(this.lineInterrupt && this.lineInterruptData && !this.lineInterruptData?.pauseActive){
                if(!this.lineInterruptData?.lineStop){
                    this.percent = 0;                    
                }
            }
        }
        super.ngOnChanges(changes);
        if(changes && !this.objectContainsOnlyTimeRelatedKeys(changes)) {
            this.initData();
        }
    }

    objectContainsOnlyTimeRelatedKeys(obj: Object): boolean {
        let keys = Object.keys(obj);
        if((keys?.length === 1 && keys[0] === 'time') || (keys?.length === 2 && keys.includes('time') && keys.includes('percent'))) {
            return true;
        }
        return false;
    }

    initData(): void {
        this.calcFontSize = this.calculateFontSize();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    private async reCalculateDefaultSizes(resizing: boolean = false) {
        if (this.chartContainerRef) {
            setTimeout(async () => {
                this.resizing = resizing;
                this.width = this.chartContainerRef.nativeElement.clientWidth;
                this.height = this.chartContainerRef.nativeElement.clientHeight;
                if (!this.chart || this.height === 0 || this.width === 0) {
                    return;
                }
                await this.chart.updateOptions({
                    chart: {
                        height: Math.min(this.height, this.width),
                    }
                });
                await this.updateColors();
            }, 500);
        }
    }

    private async updateColors() {
        if (!this.chart) {
            return;
        }
        await this.chart.updateOptions({
            fill: {
                colors: [this.getColorDependsOnPercent()],
                gradient: {
                    gradientToColors: [this.getGradientColorDependsOnPercent()],
                }
            },
        });
    }

    private setWidthsAndHeights(width: number, height: number) {
        this.chartWidth = Math.min(width, height) * 0.65;
        this.chartHeight = Math.min(width, height) * 0.65;

        this.fontResizeWidth = Math.round(this.chartWidth * this.fontResizeWidthMagicNumber);
        this.fontResizeHeight = Math.round(this.chartHeight * this.fontResizeHeightMagicNumber);
        this.initData();
    }

    calculateFontSize(){
        let height = this.fontResizeHeight;
        let width = this.fontResizeWidth;
        let buttonText = [];
        if(this.checkLineInterrupted()){
            // to display shift/beat time
            if(!this.showTime){
                buttonText.push(this.translateService.instant('STATION.GENERAL.NO_PRODUCT'))
            }else{
                if(this.time) buttonText.push(this.durationPipe.transform(this.time));
                if(this.label) buttonText.push(this.translateService.instant(this.label));
            }
        }else{
            if(!this.hasActiveShift && this.nextShift !== null){
                // to display next shift text
                height = height * 0.8;
                buttonText.push(this.translateService.instant('STATION.GENERAL.NEXT_SHIFT'));
                if(this.nextShift?.shift?.name){
                    buttonText.push(this.checkTextLength(this.nextShift.shift.name));
                } 
                if(this.nextShift?.start && this.nextShift?.end){
                    buttonText.push(this.datePipe.transform(this.nextShift.start, 'DD.MM.yyyy'));
                    buttonText.push(this.datePipe.transform(this.nextShift.start, 'HH:mm') + ' - ' + this.datePipe.transform(this.nextShift.end, 'HH:mm'));
                }
            }else if(!this.hasActiveShift && this.nextShift === null){
                // to display no active shift
                buttonText.push(this.translateService.instant('STATION.GENERAL.NO_ACTIVE_SHIFT'));
            }else if(this.lineInterruptData && this.lineInterruptData.pauseActive && this.lineInterruptData?.lineStop === null){
                // display pause message
                height = height * 0.9;
                buttonText.push(this.translateService.instant('STATION.GENERAL.PAUSE'));
                if(this.lineInterruptData?.pauseName) {
                    buttonText.push(this.checkTextLength(this.lineInterruptData.pauseName));
                }
                if(this.lineInterruptData?.pauseTimeLeft){
                    buttonText.push(this.durationPipe.transform(this.lineInterruptData.pauseTimeLeft));
                }
            }else if(this.hasActiveShift && this.lineInterruptData?.lineStop !== null){
                height = height * 0.8;
                buttonText.push(this.translateService.instant('STATION.GENERAL.LINE_STOP'));
                if(this.lineInterruptData?.lineStopTime){
                    buttonText.push(this.durationPipe.transform(this.lineInterruptData.lineStopTime));
                }
                buttonText.push(this.translateService.instant('STATION.GENERAL.LINE_STOP_REASON'));
                if(this.lineInterruptData?.lineStop) {
                    buttonText.push(this.checkTextLength(this.lineInterruptData.lineStop));
                }
            }
        }
        let largeText = buttonText[0];
        if(buttonText.length > 0){
            buttonText.forEach((text)=>{
                if(text?.length > largeText?.length){
                    largeText = text;
                }
            })
        }
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext('2d');
        let text = ctx.measureText(largeText);
        if(width / (text.width * 0.12) < height * 0.5){
            return width / (text.width * 0.12) + 'px';
        }else{
            return height * 0.5 + 'px';
        }
    }

    checkTextLength(text){
        if(text?.length > 15){
            return text.substr(0, 14) + '...';
        }else{
            return text;
        }
    }
}
