<div *ngIf="!showList() && !loading" class="text-center mt-6 mb-6">
    <h5>{{ 'SPA.DEVICE.NO_DEVICES' | translate }}</h5>
</div>
<div class="devices">
    <div class="table" *ngIf="showList()">
        <div class="table-row table-header">
            <div class="column column-drag" *ngIf="!disableDrag()"></div>
            <div class="column-first">{{ 'SPA.DEVICE.VERSION' | translate }}</div>
            <div class="column" *ngIf="isSuperAdmin()">IP</div>
            <div class="column">{{ 'SPA.DEVICE.NAME' | translate }}</div>
            <div class="column">{{ 'SPA.DEVICE.TYPE' | translate }}</div>
            <div class="column">{{ 'SPA.DEVICE.CODE' | translate }}</div>
            <div class="column" style="width:90px">{{ 'SPA.DEVICE.STATUS' | translate }}</div>
            <div class="column" *ngIf="showStationBeat()">{{ 'SPA.DEVICE.BEAT' | translate }}</div>
            <div class="column buttons buttons-actions"></div>
            <div class="column update" *apConfiguration="[ConfigurationEnum.STATION_UPDATE]">
                <button color="primary" mat-raised-button type="button"  *ngIf="!isSuperAdmin()"
                        (click)="deviceUpdaterService.updateDevices()">
                    {{ 'SPA.DEVICE.UPDATE_ALL' | translate }}
                    <mat-icon class="rtl:-mr-1" inline="true">sync</mat-icon>
                </button>

                <ng-container *ngIf="isSuperAdmin()">
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="playbook">
                        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                            <mat-label>{{ 'SPA.DEVICE.PLAYBOOK_NAME' | translate }}</mat-label>
                            <input matInput type="text" [(ngModel)]="deviceUpdaterService.action">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                        <button color="primary" mat-raised-button type="button" (click)="deviceUpdaterService.runSuperAdminAction()">
                            {{ 'SPA.DEVICE.RUN_ON_ALL' | translate }}
                            <mat-icon class="rtl:-mr-1"
                                      inline="true">build</mat-icon>
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>
        <div cdkDropList (cdkDropListDropped)="drop($event)">
            <div class="table-row body-row" *ngFor="let device of devices" cdkDrag [cdkDragDisabled]="disableDrag()"
                 [ngClass]="{'updating': deviceUpdaterService.getPending(device.id)}">
                <div class="column column-drag" *ngIf="!disableDrag()" cdkDragHandle><i class="material-icons drag-handle">drag_handle</i></div>
                <div class="column column-first"><span class="hide-to-long">{{ device.version }}</span></div>
                <div class="column" *ngIf="isSuperAdmin()"> <span class="hide-to-long">{{ device.ip }}</span></div>
                <div class="column"><span class="hide-to-long">{{device.name}}</span></div>
                <div class="column"><span class="hide-to-long">{{ 'ENUMS.STATION_TYPE.' + getStationType(device?.type) | translate }}</span></div>
                <div class="column">
                    <span class="hide-to-long" *ngIf="device.code[0] === '#'">{{ 'SPA.DEVICE.ASSIGNED' | translate }}</span>
                    <span class="hide-to-long" *ngIf="device.code[0] !== '#'">{{device.code}}</span>
                </div>
                <div class="column" style="width:max-content">
                    <span class="mr-2 hide-to-long">{{ 'COMMON.OFF' | translate }}</span>
                    <mat-slide-toggle [checked]="device.active" (click)="changeActive(device, $event)">
                        {{ 'COMMON.ON' | translate }}
                    </mat-slide-toggle>
                </div>
                <div class="column beat-time" *ngIf="showStationBeat()">
                    <div>
                        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" style="width:60px">
                            <mat-label>
                                {{ 'SPA.LINES.HOURS' | translate }}
                            </mat-label>
                            <input matInput type="Number" [(ngModel)]="device.hour" (change)="leadingZeros($event)"
                            (keyup)="leadingZeros($event)"
                            (click)="leadingZeros($event)" min='0'>
                            <mat-error>{{ 'SPA.LINES.LINE_BEAT_ERROR' | translate }}</mat-error>
                        </mat-form-field>
                        <div class="dot-text">:</div>
                        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" style="width:60px">
                            <mat-label>
                                {{ 'SPA.LINES.MINUTES' | translate }}
                            </mat-label>
                            <input matInput type="Number" [(ngModel)]="device.minute" (change)="leadingZeros($event)"
                            (keyup)="leadingZeros($event)"
                            (click)="leadingZeros($event)" min='0'>
                            <mat-error>{{ 'SPA.LINES.LINE_BEAT_ERROR' | translate }}</mat-error>
                        </mat-form-field>
                        <div class="dot-text">:</div>
                        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" style="width:60px">
                            <mat-label>
                                {{ 'SPA.LINES.SECONDS' | translate }}
                            </mat-label>
                            <input matInput type="Number" [(ngModel)]="device.second" (change)="leadingZeros($event)"
                            (keyup)="leadingZeros($event)"
                            (click)="leadingZeros($event)" min='0'>
                            <mat-error>{{ 'SPA.LINES.LINE_BEAT_ERROR' | translate }}</mat-error>
                        </mat-form-field>
                    </div>
                    <!-- <button color="primary" mat-raised-button type="button" (click)="deviceService.updateDeviceBeat(device.id, ((device.minute * 60) + (device.second * 1) + (device.hour * 3600)))">
                        <mat-icon class="rtl:-mr-1"
                                  inline="true">save</mat-icon>
                    </button> -->
                    <button color="primary" mat-raised-button type="button" (click)="updateBeatDuration(device.id, ((device.minute * 60) + (device.second * 1) + (device.hour * 3600)))">
                        <mat-icon class="rtl:-mr-1"
                                  inline="true">save</mat-icon>
                    </button>
                </div>
                <div class="column buttons buttons-actions">
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="8px" fxLayoutGap.lt-sm="0">
                        <button color="primary" mat-raised-button type="button" [disabled]="device.active"
                                (click)="triggerAction(DeviceActionEnum.EDIT, device.id)">
                            <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                      inline="true">edit</mat-icon>
                        </button>
                        <button color="warn" mat-raised-button type="button" [disabled]="device.active" (click)="delete(device)">
                            <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                      inline="true">delete</mat-icon>
                        </button>
                        <button class="bg-blue" mat-raised-button type="button" (click)="reboot(device)">
                            <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                      inline="true">power_settings_new</mat-icon>
                        </button>
                        <ng-container *apConfiguration="[ConfigurationEnum.REMOTE_STATION_VIEW]">
                            <ng-container *apUserAccess="[UserPermissionsEnum.RemoteStationView]">
                                <a href="/live-debugging?ip={{device.ip}}" target="_blank" *ngIf="showCastBtn(device)">
                                    <button color="primary" mat-raised-button type="button">
                                        <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                                  inline="true">cast</mat-icon>
                                    </button>
                                </a>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="column buttons update update-body" *apConfiguration="[ConfigurationEnum.STATION_UPDATE]">
                    <ng-container class="center-align" *ngIf="!isSuperAdmin()">
                        <button color="primary" mat-raised-button type="button" (click)="deviceUpdaterService.updateDevices(device.id)">
                            <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                      inline="true">sync</mat-icon>
                        </button>
                    </ng-container>
                    <ng-container class="center-align" *ngIf="isSuperAdmin() && deviceUpdaterService.updaterEnabled()">
                        <button color="primary" mat-raised-button type="button" (click)="deviceUpdaterService.runSuperAdminAction(device.id)">
                            <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2 mr-3"
                                      inline="true">sync</mat-icon>
                            <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                      inline="true">build</mat-icon>
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<button mat-fab class="ap-fab" (click)="triggerAction(DeviceActionEnum.ADD, null)" *apUserAccess="[UserPermissionsEnum.StationManagement]">
    <mat-icon>add</mat-icon>
</button>
