<div mat-dialog-content>
    <h3>
        {{ 'SPA.ALARMS_ESCALATIONS.ALREADY_SUBSCRIBE.ALREADY_SUBSCRIBED_DESC' | translate:param}}
    </h3>
</div>
<div mat-dialog-actions class="controls">
    <button class="btn modal-close waves-effect waves-light login-submit"
            (click)="subscribe()">{{ 'SPA.ALARMS_ESCALATIONS.ALREADY_SUBSCRIBE.SUBSCRIBE' | translate }}</button>
    <button class="btn modal-close waves-effect waves-light login-cancel" (click)="close()"
    >{{ 'SPA.ALARMS_ESCALATIONS.ALREADY_SUBSCRIBE.CANCEL_BTN' | translate }}</button>
</div>
