import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-work-instructions-view',
  templateUrl: './work-instructions-view.component.html',
  styleUrls: ['./work-instructions-view.component.scss']
})
export class WorkInstructionsViewComponent implements OnInit, OnDestroy {
  loaded = false;
  safeUrl: SafeResourceUrl = null;
  private id = null;
  private apiUrl = environment.apiUrl;
  private authToken;

  constructor(
    private route: ActivatedRoute,
    private _location: Location,
    private _sanitizer: DomSanitizer,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.authToken = this.authService.userToken;
    this.setSafePDFSrc();
  }

  ngOnDestroy() {
  }

  public goBack() {
    this._location.back();
  }

  private setSafePDFSrc() {
    this.safeUrl =  this._sanitizer.bypassSecurityTrustResourceUrl(this.getFileSrc());
    this.loaded = true;
  }

  private getFileSrc() {
    return `${this.apiUrl}/api/work-instructions/view/${this.id}?authentication_token=${this.authToken}`;
  }
}
