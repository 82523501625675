import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import { StationFullInterface } from '../../../../common/station/interfaces/station-full.interface';
import { WorkInstructionDataInterface } from '../../../../common/work-instructions/interfaces/work-instruction-data.interface';
import { FileInput } from 'ngx-material-file-input';
import { WorkInstructionsService } from '../work-instructions.service';
import { InfoboxService } from '../../../../common/shared/services/infobox.service';

@Component({
  selector: 'app-work-instructions-edit',
  templateUrl: './work-instructions-edit.component.html',
  styleUrls: ['./work-instructions-edit.component.scss']
})
export class WorkInstructionsEditComponent implements OnInit {

    selectedStations: number[] = [];
    workInstruction: WorkInstructionDataInterface = {
        id: null,
        name: '',
        stations: [],
        file: '',
        folder: ''
    };
    fileName: string = '';
    loading: boolean = false;
    isWorkInstructionAvailable: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<WorkInstructionsEditComponent>,
        public workInstructionsService: WorkInstructionsService,
        private infoboxService: InfoboxService,
        @Inject(MAT_DIALOG_DATA) public data: {
            workInstruction: WorkInstructionDataInterface,
            stations: StationFullInterface[],
            lineId: number
        }
    ) {
        if(!!this.data.workInstruction) {
            this.isWorkInstructionAvailable = true;
            this.workInstruction = this.data.workInstruction;
            const folder = this.data.workInstruction.folder;
            this.workInstruction.folder = folder.substr(0, folder.lastIndexOf("/"));
        }
    }

    get stations(): StationFullInterface[] {
        return this.data.stations ? this.data.stations : [];
    }

    closeDialog() {
        this.dialogRef.close(false);
    }

    submit() {
      if ((!(/^[A-Za-z0-9/ äöü]*$/.test(this.workInstruction.folder))) || (!(/^[A-Za-z0-9/ äöü]*$/.test(this.workInstruction.name)))) {
        this.infoboxService.pushInfo({danger: true, text: 'SPA.WORK_INSTRUCTIONS.FOLDER_ERROR'});
        this.loading = false;
        return;
      }
      this.loading = true;
      this.workInstruction.stations = this.selectedStations;
      this.workInstructionsService.updateWorkInstruction(this.data.lineId, this.workInstruction).subscribe(
        res => {
          if (res) {
              this.loading = false;
              this.dialogRef.close(res);
          }
        }, err =>{
          this.loading = false;
        });
    }

    ngOnInit(): void {
        this.selectedStations = this.data.workInstruction.stations;
    }

    disableSave() {
      return ((!!!this.workInstruction.name || this.workInstruction.name === '') || this.selectedStations.length === 0);
    }

    fileChangeEvent(event: FileInput) {
      const file = event.files[0];
      this.workInstruction.file = file;
      this.workInstruction.name = this.workInstruction.name.length ? this.workInstruction.name : file.name;
      this.fileName = file.name;
    }
}
