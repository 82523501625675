export enum ConfigurationEnum {
  MULTILINE = 0,
  REPORTS = 4,
  CHARTS = 5,
  EMPLOYEES = 6,
  ALARMS_AND_ESCALATIONS = 7,
  FTP = 8,
  WORKSTEP_MANAGER = 9,
  TRACK_AND_TRACE = 10,
  STATION_UPDATE = 11,
  REMOTE_STATION_VIEW = 12,
  DEVICE_CONFIGURATION = 13,
  IO_MODULE = 14,
}