import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { InfoboxService } from '../../../../common/shared/services/infobox.service';
import { LineService } from '../line.service';
import { LineBeatTypeEnum } from '../../../../common/line/enums/line-beat-type.enum';
import { LineTypeEnum } from '../../../../common/line/enums/line-type.enum';
import { LineInterface } from '../../../../common/line/interfaces/line.interface';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LineTypeUtil } from '../../../../common/line/utils/line-type.util';
import { ConfigurationEnum } from '../../../../common/configuration/enums/configuration.enum';
import { StationOrderTypeEnum } from '../../../../common/line/enums/station-order-type.enum';

@Component({
  selector: 'app-line-add',
  templateUrl: './line-add.component.html',
  styleUrls: ['./line-add.component.scss']
})
export class LineAddComponent extends BreadcrumbComponent implements OnInit {
  line: any = {
    name: '',
    takt: 60,
    typeEnum: LineTypeEnum.PRODUCTION,
    beatTypeEnum: LineBeatTypeEnum.LINE_BASED_BEAT,
    stationOrderTypeEnum: StationOrderTypeEnum.SEQUENTIAL,
    processOrder: null,
    allowFractionValues: false,
    allowAutomaticTransfer: false,
    hours: '0' + 1,
    minutes: '0' + 0,
    seconds: '0' + 0
  }

  lineTypes = LineTypeEnum;
  lineBeatTypes = LineBeatTypeEnum;
  lineBeatTypesToChoose: string[] = [];
  stationOrderTypes = StationOrderTypeEnum;
  stationOrderTypesToChoose = [
    StationOrderTypeEnum.PARALLEL,
    StationOrderTypeEnum.SEQUENTIAL,
  ];
  ConfigurationEnum = ConfigurationEnum;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private lineService: LineService,
    private infoBoxService: InfoboxService,
    private _location: Location
  ) {
    super(route, router);
  }

  ngOnInit() {
    this.initBreadcrumbs();
    this.changeLineType();
  }

  isNoBeat(): boolean {
    return this.line.beatTypeEnum === LineBeatTypeEnum.NO_BEAT;
  }

  save() {
    let data: LineInterface = {
      name: this.line.name,
      takt: (this.line.minutes * 60) + (this.line.seconds * 1) + (this.line.hours * 3600),
      typeEnum: this.line.typeEnum,
      beatTypeEnum: this.line.beatTypeEnum,
      stationOrderTypeEnum: this.line.stationOrderTypeEnum,
      processOrder: this.line.processOrder,
      allowFractionValues: this.line.allowFractionValues,
      allowAutomaticTransfer: false
    }
    if (!!this.canShowAllowAutomaticTransfer) {
      // this can be editable also but only if shift is not running for this particular production sector.
      data.allowAutomaticTransfer = this.line.allowAutomaticTransfer;
    }
    this.lineService
      .addLine(data).subscribe(lineId => {
        this._location.back();
        this.infoBoxService.success('Line added');
      }, error => {
        this.line.takt /= 60;
      });
  }

  get canShowAllowAutomaticTransfer(): Boolean {
    return this.line.beatTypeEnum === LineBeatTypeEnum.LINE_BASED_BEAT && this.line.stationOrderTypeEnum == StationOrderTypeEnum.SEQUENTIAL;
  }

  leadingZeros(input) {
    if(!isNaN(input.target.value) && input.target.value.length === 1) {
      input.target.value = '0' + input.target.value;
    }else{
      input.target.value = input.target.value * 1;
      if(!isNaN(input.target.value) && input.target.value.length === 1) {
        input.target.value = '0' + input.target.value;
      }
    }
  }

  changeLineType() {
    this.line.beatTypeEnum = null;
    this.line.stationOrderTypeEnum = null;
    this.line.allowAutomaticTransfer = false;
    let lineBeatTypesToChoose = LineTypeUtil.allowedBeatTypes(this.line);
    if (lineBeatTypesToChoose.length === 1) {
      this.line.beatTypeEnum = lineBeatTypesToChoose[0];
    }

    this.lineBeatTypesToChoose = [...lineBeatTypesToChoose.map(beatType => this.lineBeatTypes[beatType])];

    if (this.line.beatTypeEnum === LineBeatTypeEnum.NO_BEAT) {
      this.line.stationOrderTypeEnum = StationOrderTypeEnum.NO_ORDER;
    }

    if (this.line.typeEnum === LineTypeEnum.PRODUCTION_REWORK) {
      this.line.stationOrderTypeEnum = StationOrderTypeEnum.PARALLEL;
    }
  }

  showStationOrderTypes(): boolean {
    return this.line.stationOrderTypeEnum !== StationOrderTypeEnum.NO_ORDER;
  }

  disableStationOrderType(): boolean {
    return this.line.typeEnum === LineTypeEnum.PRODUCTION_REWORK;
  }
}
