export enum SocketMessageEnum {
    //Shift
    SHIFT_DATA_NEW = 'shiftDataNew',
    SHIFT_STARTED = 'shiftStarted',
    SHIFT_ENDED = 'shiftEnded',
    BEAT_DATA = 'beatData',
    /* 
        - NEW_BEAT_STARTED is emmited after the beat is completed and products/empty-beats are successfully moved to the next station.
        - This is only when allow_automatic_transfer toggle button is active in line-configuration.
        - Only for production-sector-based and sequential stations.
    */
    NEW_BEAT_STARTED = 'newBeatStarted',
    PAUSE_DATA = 'pauseData',
    PAUSE_STARTED = 'pauseStarted',
    PAUSE_ENDED = 'pauseEnded',
    LINE_STOP_DATA = 'lineStopData',
    LINE_STOP_STARTED = 'lineStopStarted',
    LINE_STOP_ENDED = 'lineStopEnded',
    LINE_TEXT_DATA = 'lineTextData',
    LINE_TEXT_STARTED = 'lineTextStarted',
    LINE_TEXT_ENDED = 'lineTextEnded',
    SHIFT_CHANGED = 'shiftChanged',

    //Shift calculations
    SHIFT_GOAL_DATA = 'shiftGoalData',
    SHOULD_DATA = 'shouldData',
    IS_DATA = 'isData',
    AVERAGE_BEAT_DATA = 'averageBeatData',
    TREND_DATA = 'trendData',
    OEE_DATA = 'oeeData',
    SHOULD_IS_DATA = 'shouldIsData',
    AMOUNT_OF_PIECES_DATA = 'amountOfPiecesData',
    MQTT_TOPIC_DATA = 'mqttTopicData',
    MQTT_TOPIC_DATA_CHANGED = 'mqttTopicDataChanged',

    //User events
    LOGIN_STATION = 'login',
    LOGOUT_STATION = 'logout',
    USER_DELETED = 'userDeleted',
    ROLE_CHANGED = 'roleChanged',

    //Channels
    JOIN_MESSAGE = 'join',
    LEAVE_MESSAGE = 'leave',

    //Alarms
    ALARM_EVENT = 'alarmEvent',

    //Updater
    UPDATER_UPDATE_STATIONS = 'updateStations',
    UPDATER_STATUS_MESSAGE = 'updaterStatus',
    UPDATER_IS_ON = 'isUpdaterOn',
    UPDATER_ACTION_STATIONS = 'actionStations',

    //FTP
    FTP_CONTENT_CHANGED = 'ftpContentChanged',

    //Device
    DEVICE_UNASSIGNED_CONNECTED = 'unassignedDeviceConnected',
    DEVICE_CHANGED = 'deviceChanged',
    DEVICE_ACTIVE_STATE_CHANGE = 'deviceActiveStateChange',
    DEVICE_ASSIGNED = 'deviceAssigned',

    //Device settings
    DEVICE_UPDATE_WIDGETS = 'updateDeviceWidgets',
    DEVICE_ROTATE = 'rotateDevice',
    DEVICE_REBOOT = 'rebootDevice',
    DEVICE_UPDATE = 'updateDevice',

    //Work instructions
    WORK_INSTRUCTIONS_CHANGED = 'workInstructionsChanged',

    //Product
    PRODUCT_ITEM_CHANGED = 'productItemChanged',
    PRODUCT_ITEM_TRASHED = 'productItemTrashed',

    //Daemon
    TOO_MUCH_RAM_USAGE = 'tooMuchRam',

    //Backup
    BACKUP_STARTED = 'backupStarted',
    BACKUP_FINISHED = 'backupFinished',

    //IO Module
    IO_MODULE_ADDED = 'moduleAdded',
    IO_MODULE_DELETED = 'moduleDeleted',
    IO_MODULE_CHANGED = 'moduleChanged',
    IO_CHANGED = 'ioChanged',
    IO_REMOVED = 'ioRemoved',
    IO_CONNECTION_CHANGED = 'ioConnectionChanged',

    //Workstep
    WORKSTEP_IO_WIDGET_CHANGED = 'workstepIoWidgetChanged',
    WORKSTEP_GO_NEXT = 'workstepGoNext',

    //Slideshow
    SLIDESHOW_STATUS_CHANGED = 'slideshowStatusChanged',
    SLIDESHOW_CONTROLS_CHANGED = 'slideshowControlsChanged',

    //Dashboard
    DASHBOARD_LINE_CHANGED = 'dashboardLineChanged',
    DASHBOARD_STATION_CHANGED = 'dashboardStationChanged',

    //Timezone
    TIMEZONE_CHANGED = 'timezoneChanged',

    // Screwing Machine Widget
    SCREW_CHANGED = 'screwChanged',
    SCREW_CREATED = 'screwsCreated',

    // Order Configuration
    ORDER_CONFIGURATION_CHANGED = 'orderConfigurationChanged',

    //Station Info
    STATION_INFO_TILE_CHANGED = 'stationInfoTileChanged',
    STATION_STATUS_DATA = 'stationStatusData',

    //Current Time
    TIME = 'time',
}
