import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { AlarmReasonsFormComponent } from "../alarm-reasons-form/alarm-reasons-form.component";
import { first } from "rxjs/operators";
import { CdkDragDrop, CdkDragStart } from '@angular/cdk/drag-drop';
import { AlarmsAndEscalationsService } from '../../alarms-and-escalations.service';
import { CommonService } from '../../../../../common/shared/services/common.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-alarm-reasons-item',
    templateUrl: './alarm-reasons-item.component.html',
    styleUrls: ['./alarm-reasons-item.component.scss']
})
export class ALARMREASONSItemComponent implements OnInit {
    @Input() reason: any;
    @Input() parent: any;
    @Output() showLoading = new EventEmitter();
    @Output() onDelete = new EventEmitter();
    openChildrenPanel = false;
    oldReason;

    constructor(private matDialog: MatDialog,
        private alarmsAndEscalationsService: AlarmsAndEscalationsService,
        private commonService: CommonService) {
    }

    get getAlarms() {
        if (this.reason && this.reason.alarms && this.reason.alarms.join)
            return this.reason.alarms.join(', ');
        return ''
    }

    ngOnInit(): void {
        this.oldReason = _.cloneDeep(this.reason);
        if (!this.reason.children) {
            this.reason.children = [];
        }
        this.reason.parent = this.parent;
    }


    addReason() {
        this.matDialog.open(AlarmReasonsFormComponent, {
            width: '400px',
            data: {
                item: null,
                parent: this.reason
            }
        }).afterClosed().pipe(first()).subscribe(res => {
            if (res) {
                this.reason.children.push(res);
                this.openChildrenPanel = true;
            }
        });
    }

    editReason() {
        const data = {
            id: this.reason.id,
            reason:  this.reason.reason,
            configuredAlarms: this.reason.configuredAlarms,
            parentId: this.parent ? this.parent.id: '',
            translationJson: this.reason.translationJson
        }
        this.matDialog.open(AlarmReasonsFormComponent, {
            width: '400px',
            data: {
                item: {...data},
                parent: this.reason.parent
            }
        }).afterClosed().pipe(first()).subscribe(res => {
            if (res) {
                if(this.reason.id){
                    // update cur reason
                    this.reason.reason = res.reason;
                    this.reason.configuredAlarms = res.configuredAlarms;
                    this.reason.translationJson = res.translationJson;
                    this.oldReason.translationJson = JSON.parse(JSON.stringify(res.translationJson));
                }else{
                    const ch = this.reason.children;
                    this.reason = res;
                    this.reason.children = ch;
                }
               
            }else{
                this.reason.translationJson = JSON.parse(JSON.stringify(this.oldReason.translationJson));;
            }
        });
    }

    deleteReason() {

        this.alarmsAndEscalationsService.updateReason(this.reason.id , {deleted: 1 , reason : this.reason.reason , configuredAlarms: this.reason.configuredAlarms }).subscribe(res => {
            this.onDelete.emit(this.reason.id);
        });
    }

    drop(event: CdkDragDrop<any, any>) {
        //  this.showLoading.emit(true);

        if (event.item.data.id !== this.reason.id) {

            let data = {reason:event.item.data.reason , parentId : this.reason.id , configuredAlarms : []};
            this.alarmsAndEscalationsService.updateReason(event.item.data.id, data).subscribe(res => {
                if (i >= 0) {
                    event.item.data.parent.children.splice(i, 1);
                }

                this.reason.children.push(event.item.data)
                event.item.data.parent = this.reason;

            });
            const i = event.item.data.parent?.children.findIndex(c => c.id == event.item.data.id);

        }

    }

    childDeletd(cid){
       let rIndex =  this.reason.children.findIndex(c => c.id == cid);
       this.reason.children.splice(rIndex, 1);
    }

    getTranslatedName(name, translationObj): string {
        if (!!name) {
            return this.commonService.getTranslatedName(name, translationObj);
        }
    }

    getTranslatedAlarm(alarm): string {
        return this.commonService.getTranslatedName('name', alarm?.translationJson || null);
    }



}
