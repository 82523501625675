import { Component, OnInit } from '@angular/core';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { MqttConnectionDetailsInterface } from '../../../../common/mqtt/interfaces/mqtt-connection-details.interface';

@Component({
  selector: 'app-mqtt-connection-details',
  templateUrl: './mqtt-connection-details.component.html',
  styleUrls: ['./mqtt-connection-details.component.scss']
})
export class MqttConnectionDetailsComponent extends BreadcrumbComponent implements OnInit {

  timezone: string;
  timezones: string[];
  filteredOptions: Observable<string[]>;
  myControl = new UntypedFormControl();
  data: MqttConnectionDetailsInterface = null;

  constructor(
      private location: Location,
      private http: HttpClient,
      public route: ActivatedRoute,
      public router: Router
  ) {
    super(route, router);
  }

  ngOnInit(): void {
    this.initBreadcrumbs();
    this.http.get('/mqtt-connection-details').subscribe((data: MqttConnectionDetailsInterface) => {
      this.data = data;
    });
  }

  goBack() {
    this.location.back();
  }
}
