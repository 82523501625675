import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BackgroundFontSettingsInterface} from '../../interfaces/background-font-settings.interface';
import {ColorsFormatUtil} from '../../../shared/utils/colors-format.util';
import {BasicConfiguratorWidget} from '../basic-configurator-widget';

@Component({
  selector: 'configurator-production-info-widget',
  templateUrl: './configurator-production-info-widget.component.html',
  styleUrls: ['./configurator-production-info-widget.component.scss']
})
export class ConfiguratorProductionInfoWidgetComponent extends BasicConfiguratorWidget implements OnInit, OnChanges, OnDestroy {

    @Input() value: number = 0;
    @Input() unit: any = '';
    @Input() label: string = 'trend';
    @Input() fontUnit = 'px';
    @Input() settings: BackgroundFontSettingsInterface;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.fontChangedSubscription = this.fontChanged.subscribe((result: number) => {
            this.settings['fontSize'] = result;
        });
    }

    get color(): string {
        return this.settings && this.settings.fontColor ? this.settings.fontColor : '#ffffff';
    }

    get background(): string {
        const color = this.settings && this.settings.backgroundColor ? this.settings.backgroundColor : '#13787D';

        return `linear-gradient(90deg, ${color} 0%, ${ColorsFormatUtil.hexToRgba(color, 0.7)} 100%)`;
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
