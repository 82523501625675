import {Observable, Observer} from 'rxjs';
import {Injectable} from '@angular/core';
import {CSVParserError} from '../interfaces/csv-parser-error.interface';
import {CsvParserConfigInterface} from '../interfaces/csv-parser-config.interface';
import { ExportToCsv, Options } from 'export-to-csv';

@Injectable()
export class CsvParserService {

    private defaultCSVParserConfig = {
        headerAsKey: true,
        delimiter: ',',
    };

    parse(csvFile: File, config: CsvParserConfigInterface): Observable<Array<any>> {

        config = {
            ...this.defaultCSVParserConfig,
            ...config
        };

        const parser = Observable.create((observer: Observer<Array<any> | CSVParserError>) => {
            try {

                let csvRecords = null;

                if (this.isCSVFile(csvFile)) {

                    const reader = new FileReader();
                    reader.readAsText(csvFile);

                    reader.onload = () => {
                        const csvData = reader.result;
                        const csvRecordsArray = (csvData as string).trim().split(/\r\n|\n/);

                        const headersRow = this.getHeaderArray(csvRecordsArray, config);

                        csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length, config);

                        observer.next(csvRecords);
                        observer.complete();
                    };

                    reader.onerror = () => {
                        this.badCSVDataFormatErrorHandler(observer);
                    };

                } else {
                    this.notCSVFileErrorHandler(observer);
                }

            } catch (error) {
                this.unknownCSVParserErrorHandler(observer);
            }
        });

        return parser;
    }

    getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any, config: CsvParserConfigInterface) {
        const dataArr = [];
        const headersArray = (csvRecordsArray[0] as string).split(config.delimiter);

        const startingRowToParseData = config.headerAsKey ? 1 : 0;

        for (let i = startingRowToParseData; i < csvRecordsArray.length; i++) {
            const data = (csvRecordsArray[i] as string).split(config.delimiter);

            if (data.length === headerLength && config.headerAsKey) {

                const csvRecord = {};

                for (let j = 0; j < data.length; j++) {
                    csvRecord[headersArray[j]] = data[j].trim();
                }
                dataArr.push(csvRecord);
            } else {
                dataArr.push(data);
            }
        }
        return dataArr;
    }

    isCSVFile(file: any) {
        return file.name.endsWith('.csv');
    }

    getHeaderArray(csvRecordsArr: any, config: CsvParserConfigInterface) {
        const headers = (csvRecordsArr[0] as string).split(config.delimiter);
        const headerArray = [];
        for (const header of headers) {
            headerArray.push(header);
        }
        return headerArray;
    }

    notCSVFileErrorHandler(observer: Observer<any>) {
        const ngcCSVParserError: CSVParserError = {
            type: 'NOT_A_CSV_FILE',
            message: 'Selected file is not a csv File Type',
            code: 2,
        };
        observer.error(ngcCSVParserError);
    }

    unknownCSVParserErrorHandler(observer: Observer<any>) {
        const ngcCSVParserError: CSVParserError = {
            type: 'UNKNOWN_ERROR',
            message: 'Unknown error. Please refer to official documentation for library usage.',
            code: 404,
        };
        observer.error(ngcCSVParserError);
    }

    badCSVDataFormatErrorHandler(observer: Observer<any>) {
        const ngcCSVParserError: CSVParserError = {
            type: 'BAD_CSV_DATA_FORMAT',
            message: 'Unable to parse CSV File',
            code: 1,
        };
        observer.error(ngcCSVParserError);
    }

    downloadCSV(data: any[], options: Options) {
        options = {
            fieldSeparator: ';',
            quoteStrings: '',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: '',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            ...options
        };
        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);
    }
}
