import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import { LineInterface } from '../../../common/line/interfaces/line.interface';
import { StationInterface } from '../../../common/station/interfaces/station.interface';
import { DeviceTypeEnum } from '../../../common/device/enums/device-type.enum';
import { AEGroupInterface } from './groups/interfaces/a-e-group.interface';
import { Observable } from 'rxjs';
import { AlarmOccurredResolvedInterface } from '../../../common/alarms-and-escalations/interfaces/alarm-occurred-resolved.interface';
import {AlarmDataInterface} from '../../../common/alarms-and-escalations/interfaces/alarm-data.interface';
import {AlarmLogoutInterface} from '../../../common/alarms-and-escalations/interfaces/alarm-logout.interface';

@Injectable()
export class AlarmsAndEscalationsService {
    lines: LineInterface[] = [];
    allStations: StationInterface[] = [];

    constructor(
        private http: HttpClient
    ) {
        this.initDataIfNeeded();
    }

    initDataIfNeeded() {
        if (!this.lines.length) {
            this.fetchLines();
            this.fetchStations();
        }
    }

    fetchLines() {
       return  this.http.get<LineInterface[]>( `/line`);
    }

    fetchLinesBySector(data) {
        return  this.http.get<LineInterface[]>( `/configured-alarm/stations` , {params:data});
     }

    
    fetchStations() {
       return  this.http.get<StationInterface[]>( `/station/all`);
    }
    
    fetchGroupsList(): Observable<AEGroupInterface[]> {
        return this.http.get<AEGroupInterface[]>( `/alarm-group`);
    }


    filterStationsToDropdown(lineIds: number[]): StationInterface[] {
        return _.filter(this.allStations, (station: StationInterface) => {
            return lineIds.includes(station.line_id)
                && [
                        DeviceTypeEnum.PRODUCTION,
                        DeviceTypeEnum.NO_PRODUCTION,
                        DeviceTypeEnum.PRODUCTION_REWORK,
                    ].includes(station.type)
                && !station.deleted;
        }) as any[];
    }

    getAllDisorderReasons(){
        return this.http.get<any[]>( `/disorder-reason`);
    }
    createReason(data){
        return this.http.post<any[]>( `/disorder-reason` , data);
    }
    updateReason(rId , data){
        return this.http.patch<any[]>( `/disorder-reason/${rId}` , data);
    }
    updateDisorderReason(rId , data) {
        return this.http.patch<any[]>( `/disorder-logs/update-reason/${rId}`, data);
    }

    alarmResolved(data: AlarmOccurredResolvedInterface): Observable<any> {
        return this.http.post<any>(`/api/alarm/resolved`, data);
    }
    deleteReason(rId){
        return this.http.delete<any[]>( `/disorder-reason/${rId}` );
    }
    getAllPreAlarms(){
        return this.http.get<any[]>( `/disorder-reason`);
    }
    getAllConfiguredAlarm(){
        return this.http.get<any[]>( `/configured-alarm/all`);
    }
    getAllConfiguredAlarmByStationId(stationId){
        return this.http.get<any[]>( `/configured-alarm/current/${stationId}`);
    }
    getAllDisorderReasonswithParentChild(configuredAlarmId, stationId, userToken){
        const params = new HttpParams({
            fromObject: {
                userToken: userToken
            }
        });
        return this.http.get<any[]>( `/disorder-reason/${configuredAlarmId}/${stationId}`, { params });
    }
    reSubscribe(alarmId: number, notificationId: number, token: string): Observable<AlarmDataInterface> {
        return this.http.post<AlarmDataInterface>(`/api/alarm/subscribe/${alarmId}/${notificationId}`, {
            userToken: token,
            emitLoginEvent: true
        });
    }
    logout(data: AlarmLogoutInterface, userToken: string): Observable<void> {
        const params = new HttpParams({
            fromObject: {
                userToken: userToken
            }
        });
        return this.http.post<void>('/api/alarm/logout', data, { params });
    }
    getDeviceWidgetConfiguration(stationId){
        return this.http.get<any[]>( `/device-configurator/device/${stationId}` );
    }
    saveCAlarm(data){
        return this.http.post<any[]>( `/configured-alarm/create/1` , data);
    }

    getConfiguredAlarmById(cId){
        return this.http.get<any[]>( `/configured-alarm/${cId}`);
    }

    // dashboard
    adminGetAllCurrentAlarms(data,dataToSDend){
        return this.http.post<any[]>( `/alarm/admin/all-current`, dataToSDend, {params:data});
    }

    adminGetAllResolvedAlarms(data:any){
        return this.http.post<any[]>( `/dashboard-alarm-log/admin/all-resolved`,{},{params:data});
    }


    adminGetAllResolvedAlarmsWithFilter(data:any,filterData:any){
        return this.http.post<any[]>( `/dashboard-alarm-log/admin/all-resolved`,filterData,{params:data});
    }
    

    adminGetAllNotResolved(){
        return this.http.get<any[]>( `/dashboard-alarm-log/admin/all-current`);
    }

    adminGetAlarmById(id){
        return this.http.get<any[]>( `/alarm/${id}`);
    }

    adminGetAlarmLogById(id){
        return this.http.get<any[]>( `/alarm/alarm-logs/${id}`);
    }


    acceptAlarm(alarmId ,notificationId  ){
        return this.http.post<any[]>( `/alarm/subscribe/${alarmId}/${notificationId}` , null);

    }
    unSubscribeAlarm(alarmId ,notificationId){
        return this.http.post<any[]>( `/api/alarm/unsubscribe/${alarmId}/${notificationId}` , null);
    }

    forwardAlarm(cid, alarmId: number, userToken: string): Observable<any> {
        return this.http.post(`/api/alarm/forward`, {
            configuredAlarm: cid,
            alarmId: alarmId,
            userToken: userToken,
            withoutSubscribe: true 
            //must remove withoutSubscribe: true 
        });
    }

    deleteAlarm(alarmId){
        return this.http.delete<any[]>( `/configured-alarm/${alarmId}`);
    }


}