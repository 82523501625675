import {Routes} from '@angular/router';
import {AuthGuard} from '../_guards/auth.guard';
import { UserPermissionsEnum } from '../../../common/auth/enums/user-permissions.enum';
import { StationListComponent } from './components/station-list/station-list.component';
import { StationAddComponent } from './components/station-add/station-add.component';
import { StationEditComponent } from './components/station-edit/station-edit.component';

export const STATION_ROUTES: Routes = [
    {
        path: '',
        component: StationListComponent,
        canActivate: [AuthGuard],
        data: {permissions: [UserPermissionsEnum.StationManagement]}
    },
    {
        path: 'add',
        component: StationAddComponent,
        canActivate: [AuthGuard],
        data: {permissions: [UserPermissionsEnum.StationManagement]}
    },
    {
        path: 'edit/:id',
        component: StationEditComponent,
        canActivate: [AuthGuard],
        data: {permissions: [UserPermissionsEnum.StationManagement]}
    }
];
