<div class="text-dynamic" [ngStyle]="{'width': widthStyle, 'height': heightStyle, 'font-size': fontSize + 'px', 'border-radius': borderRadius, 'background': background, 'color':color}"
     #componentContainer (resized)="resize($event)">
  <span #textContainer *ngIf="!isMoving">
    <ng-container *ngIf="translatable">{{text | translate}}</ng-container>
    <ng-container *ngIf="!translatable">{{text}}</ng-container>
  </span>
<!--  hack to have dynamic marquee speed-->
  <marquee #textContainer *ngIf="!lineText && isMoving && speed === 1" scrollAmount="10">{{text}}</marquee>
  <marquee #textContainer *ngIf="!lineText && isMoving && speed === 2" scrollAmount="20">{{text}}</marquee>
  <marquee #textContainer *ngIf="!lineText && isMoving && speed === 3" scrollAmount="30">{{text}}</marquee>
  <marquee #textContainer *ngIf="!lineText && isMoving && speed === 4" scrollAmount="40">{{text}}</marquee>
  <marquee #textContainer *ngIf="!lineText && isMoving && speed === 5" scrollAmount="50">{{text}}</marquee>
  <!-- line text dynamic marquee speed -->
  <marquee #textContainer *ngIf="lineText && isMoving && speed === 1" scrollAmount="7">{{text}}</marquee>
  <marquee #textContainer *ngIf="lineText && isMoving && speed === 2" scrollAmount="8.5">{{text}}</marquee>
  <marquee #textContainer *ngIf="lineText && isMoving && speed === 3" scrollAmount="10">{{text}}</marquee>
  <marquee #textContainer *ngIf="lineText && isMoving && speed === 4" scrollAmount="11.5">{{text}}</marquee>
  <marquee #textContainer *ngIf="lineText && isMoving && speed === 5" scrollAmount="13">{{text}}</marquee>
</div>
