import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { RoleInterface } from '../../../../common/auth/interfaces/role.interface';

@Injectable()
export class RolesService {
    constructor(private http: HttpClient) {

    }

    public fetchRoles(): Observable<RoleInterface[]> {
        return this.http.get<RoleInterface[]>('/roles');
    }

    public fetchRoleDetails(roleId: number): Observable<RoleInterface> {
        return this.http.get<RoleInterface>(`/roles/${roleId}`);
    }

    public patchPermissions(role: RoleInterface): Observable<any> {
        return this.http.patch(`/roles/${role.id}`, {permissions: role.permissions});
    }

    public removeRoleById(roleId: number): Observable<any> {
        return this.http.delete(`/roles/${roleId}`);
    }

    public postRole(roleName: string): Observable<RoleInterface> {
        return this.http.post<RoleInterface>(`/roles`, {role: roleName});
    }
}