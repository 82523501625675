<page-header titleTranslate="SPA.SHIFTS.RECURRING_SHIFTS"></page-header>
<div mat-dialog-content>
    <div class="content-modal" >
        <mat-radio-group fxLayout="column" fxLayoutGap="16px" [(ngModel)]="allFollowing">
            <mat-radio-button [value]="false">{{ 'SPA.SHIFTS.THIS_EVENT' | translate }}</mat-radio-button>
            <mat-radio-button [value]="true">{{ 'SPA.SHIFTS.THIS_AND_FOLLOWING' | translate }}</mat-radio-button>
        </mat-radio-group>
    </div>
</div>
<div mat-dialog-actions>
    <div class="footer" fxLayout="row" fxLayoutGap="8px">
        <button color="primary" mat-button (click)="close()"
                type="button">{{ 'COMMON.CANCEL' | translate }}
        </button>
        <button type="button"
                color="primary" mat-raised-button (click)="save()">{{ data.actionLabel | translate }}
        </button>
    </div>
</div>