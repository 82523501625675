import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss']
})
export class DownloadAppComponent implements OnInit {

  loading = false;
  downloadUrl = '';
  constructor(
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    let apiUrl = environment.apiUrl;

    if (!apiUrl) {
      // if apiUrl is empty, we are using gateway
      const location = window.location;
      const port = location.port ? `:${location.port}` : '';
      apiUrl = `${location.protocol}//${location.hostname}${port}`;
    }

    this.downloadUrl = `${apiUrl}/api/mobile-app/download`;
  }

  download() {
    this.loading = true;

    const params = new HttpParams({
      fromObject: {
        noContentType: 'true',
      }
    });

    this.http.get(this.downloadUrl, {
      params,
      responseType: 'blob'
    }).subscribe(data => {
      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');

      link.href = downloadURL;
      link.download = 'apronic.apk';
      link.click();

      this.loading = false;
    }, _ => {
      this.loading = false;
    })
  }
}
