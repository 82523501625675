import { Routes } from '@angular/router';
import { AuthGuard } from '../../_guards/auth.guard';
import { BackupComponent } from './backup.component';
import { UserPermissionsEnum } from '../../../../common/auth/enums/user-permissions.enum';

export const BACKUP_ROUTES: Routes = [
    {
        path: '',
        component: BackupComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: [UserPermissionsEnum.BackupSettingsManagement],
            breadcrumb: {
                label: 'SPA.NAV.BACKUP',
            }
        }
    }
];
