import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserAddComponent} from './users/user-add/user-add.component';
import {UserEditComponent} from './users/user-edit/user-edit.component';
import {UserManagementComponent} from './user-management.component';
import {HttpClientModule} from '@angular/common/http';
import {UserService} from './users/user.service';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import {UsersComponent} from './users/users.component';
import {ApFormsModule} from '../shared/forms/apForms.module';
import {RolesComponent} from './roles/roles.component';
import {RolesService} from './roles/roles.service';
import {RoleDetailsComponent} from './roles/details/roleDetails.component';
import {CreateRoleDialogComponent} from './roles/create/createRoleDialog.component';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import { NgxBarcodeModule } from 'ngx-barcode';
import { PermissionGroupComponent } from './roles/details/components/permission-group/permission-group.component';
import { RolePermissionService } from './roles/role-permission.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        SharedModule,
        MatButtonModule,
        MatMenuModule,
        MatTabsModule,
        MatSlideToggleModule,
        ApFormsModule,
        MatListModule,
        MatDialogModule,
        MatSelectModule,
        NgxBarcodeModule,
        ReactiveFormsModule
    ],
    declarations: [
        UserAddComponent,
        UserEditComponent,
        UserManagementComponent,
        UsersComponent,
        RolesComponent,
        RoleDetailsComponent,
        CreateRoleDialogComponent,
        PermissionGroupComponent,
    ],
    providers: [
        UserService,
        RolesService,
        RolePermissionService,
    ],
    exports: [UserManagementComponent]
})
export class UserManagementModule {
}
