import { Component, OnInit } from '@angular/core';
import { PushNotificationService } from '../../_services/push-notification.service';

@Component({
  selector: 'app-alarm-dashboard',
  templateUrl: './alarm-dashboard.component.html',
  styleUrls: ['./alarm-dashboard.component.scss']
})
export class AlarmDashboardComponent implements OnInit {

  constructor(
    private pushNotificationService: PushNotificationService,
  ) { }

  ngOnInit(): void {
    this.pushNotificationService.subscribeToPushNotifications();
  }

} 
