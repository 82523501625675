import {Component, Input, OnInit} from '@angular/core';
import {ChartInterface} from '../../../../common/charts/interfaces/chart.interface';
import {ApexAxisChartSeries, ApexAxisSingleChartSeries} from '../../../../common/charts/interfaces/apex';
import {ChartsAndTablesService} from '../charts-and-tables.service';
import {TranslateService} from '@ngx-translate/core';
import {TimeService} from '../../../../common/shared/services/time.service';
import {Subscription} from 'rxjs';
import {ChartsAndTablesDataService} from '../services/charts-and-tables-data.service';
import * as moment from 'moment-timezone';
import {DATE_TIME_SECONDS_FORMAT_NO_YEAR} from '../../../../common/shared/constants/time.constants';
import {ChartDataResponseInterface} from '../../../../common/charts/interfaces/chart-data-response.interface';
import {ChartDataTypeEnum} from '../../../../common/charts/enums/chart-data-type.enum';
import {ChartToTableMapper} from '../chart-to-table.mapper';
import { forEach } from 'lodash';
import {Subject} from 'rxjs';
import { CommonService } from '../../../../common/shared/services/common.service';

@Component({
  selector: 'app-apex-chart',
  templateUrl: './apex-chart.component.html',
  styleUrls: ['./apex-chart.component.scss']
})
export class ApexChartComponent implements OnInit {

    series: ApexAxisChartSeries | ApexNonAxisChartSeries;
    show = false;
    multiple = false;
    renewSubscription: Subscription;
    changingChartValue: Subject<any> = new Subject();
    showResetButton: boolean = false;
    multipleChartArr: any = [];
    reasonsTranslationData: any = {};
    alarmsTranslationData: any = {};
    @Input() chart: ChartInterface;
    @Input() allCharts: any[];

    constructor(
        private chartsAndTablesService: ChartsAndTablesService,
        private translate: TranslateService,
        private timeService: TimeService,
        private chartDataService: ChartsAndTablesDataService,
        private commonService: CommonService
    ) {
        this.renewSubscription = this.chartDataService.filtersChanged().subscribe(() => {
            this.loadChartData();
        })
    }

    ngOnInit() {
        if (this.chartDataService.isReady()) {
            this.loadChartData();
        }
    }

    loadChartData() {
        this.multipleChartArr = [];
        
        if(this.chart.dataType !== ChartDataTypeEnum.ALARM_AMOUNT) {
            this.chartDataService.loadDataForChart(this.chart.dataType).subscribe((result) => {
                this.showResetButton = false;
                if (!result) {
                    this.show = false;
                    return;
                }
                if (this.chart.dataType === ChartDataTypeEnum.DISORDER_AMOUNT) {
                    // Create the translation object for reasons
                    this.createNewTranslationJson('reasonTranslations', 'alarmReason', result);
                    // Create the translation object for alarms
                    this.createNewTranslationJson('alarmTranslations', 'alarmAmount', result);
                };
                this.prepareChartData(result);
            })
        }
    }

    ngOnDestroy() {
        if (this.renewSubscription) {
            this.renewSubscription.unsubscribe();
        }
    }

    prepareChartData(data: ChartDataResponseInterface) {
        if (this.chart.dataType === ChartDataTypeEnum.DISORDER_DURATION) {
            this.prepareNonAxisChart(data);
            this.chart.options.title.text = this.translate.instant(this.chart.options.title.text);
            this.series = [...this.series] as ApexAxisChartSeries | ApexNonAxisChartSeries;
            this.chart.options = {...this.chart.options};
            
        } else if(this.chart.dataType === ChartDataTypeEnum.DISORDER_AMOUNT) {
            this.prepareNonAxisChart(data);
        } else {
            this.prepareAxisChart(data);
            this.chart.options.title.text = this.translate.instant(this.chart.options.title.text);
            this.series = [...this.series] as ApexAxisChartSeries | ApexNonAxisChartSeries;
            this.chart.options = {...this.chart.options};
        }
        this.show = true;
    }

    private prepareNonAxisChart(data: ChartDataResponseInterface) {
        // To check the chart is for DISORDER_AMOUNT
        if (this.chart.dataType == ChartDataTypeEnum.DISORDER_AMOUNT) {

            // Get the charts for DISORDER_AMOUNT and ALARM_AMOUNT
            const alarmChart = this.allCharts.filter(chart => (chart.dataType === ChartDataTypeEnum.DISORDER_AMOUNT || chart.dataType === ChartDataTypeEnum.ALARM_AMOUNT));

            // To create the dynamic chart object for alarms and disorders
            forEach(alarmChart, (value, key) => {
                const chartType = value.dataType == ChartDataTypeEnum.DISORDER_AMOUNT ? 'alarmReason' : 'alarmAmount';
                
                // Set labels and series as per new data
                value.options['labels'] = data.chartArray[chartType].chartLabels;
                value.options['series'] = data.chartArray[chartType].series as ApexNonAxisChartSeries;
                if (chartType === 'alarmReason') {
                    value.options['labels'] = this.commonService.getTranslationFromJson(data.chartArray[chartType].chartLabels, this.reasonsTranslationData);
                } else if (chartType === 'alarmAmount') {
                    value.options['labels'] = this.commonService.getTranslationFromJson(data.chartArray[chartType].chartLabels, this.alarmsTranslationData);
                }

                // To set the colors of the chart component
                if (data.chartArray[chartType].colors != null) {
                    value.options['colors'] = data.chartArray[chartType].colors;
                }

                // Append the click event of any chart
                value.options.chart.events = {
                    dataPointSelection: (event, chartContext, config) => {
                        this.triggerChartChange(chartContext, config);
                    }
                }

                value.options.title.text = this.translate.instant(value.options.title.text);

                // Create the chart object
                const chartData = {
                    'series': data.chartArray[chartType].series as ApexNonAxisChartSeries,
                    'label':data.chartArray[chartType].chartLabels,
                    'chart': value
                };

                this.multipleChartArr.push(chartData);
                this.multiple = true;
            });
            this.multipleChartArr = this.multipleChartArr.reverse();
        } else {
            this.series = data.series as ApexNonAxisChartSeries;
            this.chart.options['labels'] = data.chartLabels;
        }
    }

    // Trigger chart change while clicking on Alarm charts
    triggerChartChange(chartContext, config) {
        this.showResetButton = true;
        const chartType = chartContext.w.config.title.text === this.translate.instant('SPA.CHARTS.DISORDER_AMOUNT') ? 'amount' : 'reason';
        const chartLabel = chartContext.w.globals.labels[config.dataPointIndex];
        this.chartDataService.filters.alarmChartLabel = chartLabel;
        this.chartDataService.filters.alarmChartType = chartType;
        if (chartType === 'amount') {
            this.chartDataService.filters.alarmChartLabel = this.commonService.getKeysByTranslatedValue(this.reasonsTranslationData, chartLabel)[0];
        } else if (chartType === 'reason') {
            this.chartDataService.filters.alarmChartLabel = this.commonService.getKeysByTranslatedValue(this.alarmsTranslationData, chartLabel)[0];
        }
        this.loadAlarmChartData();
    }

    // Reset filters of alarm charts
    resetFilters() {
        this.showResetButton = false;
        this.chartDataService.filters.alarmChartLabel = null;
        this.chartDataService.filters.alarmChartType = null;
        this.loadAlarmChartData();
    }

    loadAlarmChartData() {
        this.chartDataService.loadDataForChart(ChartDataTypeEnum.DISORDER_AMOUNT).subscribe(chartData => {
            if (chartData) {
                // Create the translation object for reasons
                this.createNewTranslationJson('reasonTranslations', 'alarmReason', chartData);
                // Create the translation object for alarms
                this.createNewTranslationJson('alarmTranslations', 'alarmAmount', chartData);
                this.changingChartValue.next({
                    chartData,
                    'reasonsTranslationData': this.reasonsTranslationData,
                    'alarmsTranslationData': this.alarmsTranslationData
                });
            }
        });
    }

    private prepareAxisChart(data: ChartDataResponseInterface) {
        this.series = data.series as ApexAxisChartSeries;
        this.chart.options['xaxis'] = {
            categories: data.chartLabels
        };

        this.series = this.series.map((el: ApexAxisSingleChartSeries) => {
            el.name = this.translate.instant(el.name);

            return el;
        });

        this.chart.options.xaxis.categories = this.chart.options.xaxis.categories.map(label => {
            if (moment(label, DATE_TIME_SECONDS_FORMAT_NO_YEAR).isValid()) {
                return this.timeService.convertTimeUTCtoLocal(label, DATE_TIME_SECONDS_FORMAT_NO_YEAR, DATE_TIME_SECONDS_FORMAT_NO_YEAR);
            }

            if (moment(label, DATE_TIME_SECONDS_FORMAT_NO_YEAR).isValid()) {
                return this.timeService.convertTimeUTCtoLocal(label, DATE_TIME_SECONDS_FORMAT_NO_YEAR, DATE_TIME_SECONDS_FORMAT_NO_YEAR);
            }

            return label;
        });
    }

    exportChartData(chartData = null) {
        let chartToTableData;
        
        if (!!chartData) {
            chartToTableData = ChartToTableMapper.map(chartData.chart.dataType);
        } else {
            chartToTableData = ChartToTableMapper.map(this.chart.dataType);
        }

        if (!chartToTableData) {
            console.log('cannot export this chart');
            return;
        }

        this.chartDataService.getTableData(chartToTableData.tableDataTypeEnum).subscribe(tableData => {
            this.chartsAndTablesService.exportTable(
                !!chartData ? chartData.chart.options.title.text : this.chart.options.title.text,
                this.chartDataService.filters.timePeriod,
                chartToTableData.headers,
                tableData
            );
        });
    }

    /*
    Creating the json object with key and tranlation value, so use for the labels for chart
    Example Like Json for reasons
    {
        "R1": "R1 in german",
        "R2": "R2 in german",
    }
    */
    private createNewTranslationJson(typeOfTranslations: string, typeOfChartArr: string, data: any) {
        const translationData = {};
        const translations = data.chartArray[typeOfTranslations];
        const labels = data.chartArray[typeOfChartArr].chartLabels;
        const language = localStorage?.getItem('language') || 'de';
        
        for (var i = 0; i < labels.length; i++) {
            var reason = labels[i];
            if (translations.hasOwnProperty(labels[i])) {

                var translation = translations[labels[i]];

                if (translation && translation.hasOwnProperty(language) && translation[language].hasOwnProperty('name') && translation[language]['name'] !== '') {

                    translationData[reason] = translation[language]['name'];
                } else {
                    translationData[reason] = reason; // Translation not found, add reason it self to the translations object
                }
            } else {
                translationData[reason] = reason; // Reason not found, add reason it self to the translations object
            }
        }
        if (typeOfChartArr === 'alarmReason') {
            this.reasonsTranslationData = { ...translationData };
        } else if(typeOfChartArr === 'alarmAmount') {
            this.alarmsTranslationData = { ...translationData };
        }
    }
}
