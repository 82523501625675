import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {LineComponent} from './line.component';

import {LineEditComponent} from './line-edit/line-edit.component';
import { BeatSelectionPopupComponent } from './beat-selection-popup/beat-selection-popup.component';
import {RouterModule} from '@angular/router';
import {StationsModule} from '../stations/stations.module';
import {LineDetailComponent} from './line-detail/line-detail.component';
import {LineAddComponent} from './line-add/line-add.component';
import {SharedModule} from '../shared/shared.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import {AuthGuard} from '../_guards/auth.guard';
import {MatExpansionModule} from '@angular/material/expansion';
import { ProductItemsNetwork } from '../products/networks/product-items.network';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        StationsModule,
        SharedModule,
        MatTabsModule,
        MatDialogModule,
        MatSelectModule,
        MatExpansionModule,
        ReactiveFormsModule
    ],
    declarations: [
        LineComponent,
        LineEditComponent,
        LineDetailComponent,
        LineAddComponent,
        BeatSelectionPopupComponent
    ],
    providers: [
        ProductItemsNetwork,
        AuthGuard
    ],
    exports: [LineComponent]
})
export class LineModule {
}
