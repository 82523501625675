import * as moment from 'moment-timezone';
import { Injectable } from '@angular/core';
import {DATE_TIME_FORMAT, TIME_FORMAT, TIME_FORMAT_EXTENDED} from '../constants/time.constants';
import {TimesInterface} from '../interfaces/times-interface';


@Injectable()
export class TimeService {
    convertTimesLocalToUTC(localTime: TimesInterface, timeFormat = TIME_FORMAT, dateTimeFormat = DATE_TIME_FORMAT): TimesInterface {
        const begin = localTime.begin > localTime.end
            ? moment.utc(moment(localTime.begin, timeFormat).tz(moment.tz.guess())).subtract(1, 'day')
            : moment.utc(moment(localTime.begin, timeFormat).tz(moment.tz.guess()));
        const end = moment.utc(moment(localTime.end, timeFormat).tz(moment.tz.guess()));

        return {
            begin: begin.format(dateTimeFormat),
            end: end.format(dateTimeFormat)
        };
    }

    convertTimesUTCtoLocal(utcTime: TimesInterface, formatIn = TIME_FORMAT, formatOut = TIME_FORMAT): TimesInterface {
        return {
            begin: moment(moment.utc(utcTime.begin, formatIn).tz(moment.tz.guess())).format(formatOut),
            end: moment(moment.utc(utcTime.end, formatIn).tz(moment.tz.guess())).format(formatOut),
        };
    }

    convertTimeUTCtoLocal(time: string, formatIn = TIME_FORMAT, formatOut = TIME_FORMAT): string {
        return moment(moment.utc(time, formatIn).tz(moment.tz.guess())).format(formatOut);
    }

    secondsToTime(seconds: number, format = TIME_FORMAT_EXTENDED): string {
        return moment.utc(seconds * 1000).format(format);
    }
}