import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AlarmNotificationInterface } from '../../_utils/interfaces/alarm.interface';
import { AlarmForwardDataInterface } from '../../../../common/alarms-and-escalations/interfaces/alarm-forward-data.interface';
import { SupervisorListCurrentAlarmDataInterface } from '../../../../common/alarms-and-escalations/interfaces/supervisor-list-current-alarm-data.interface';
import { SupervisorListPastAlarmDataInterface } from '../../../../common/alarms-and-escalations/interfaces/supervisor-list-past-alarm-data.interface';
import { EventsListFiltersInterface } from './interfaces/events-list-filters.interface';

@Injectable()
export class SupervisorService {

    constructor(
        private http: HttpClient,
    ) {}

    public getCurrentEvents(): Observable<SupervisorListCurrentAlarmDataInterface[]> {
        return this.http.get<SupervisorListCurrentAlarmDataInterface[]>(`/alarm-supervisor-list/current`);
    }

    public getPastEvents(filters: EventsListFiltersInterface): Observable<[SupervisorListPastAlarmDataInterface[], number]> {
        const params = new HttpParams({
            fromObject: {
                page: filters.page.toString(),
                pageSize: filters.pageSize.toString(),
                sortBy: filters.sortBy,
                sortDir: filters.sortDir.toUpperCase(),
                lines: filters.lines.includes(-1) ? '' : filters.lines.join(';'),
                stations: filters.stations.includes(-1) ? '' : filters.stations.join(';'),
                users: filters.users.includes(-1) ? '' : filters.users.join(';'),
                usersResolved: filters.usersResolved.includes(-1) ? '' : filters.usersResolved.join(';'),
                reason: filters.reason,
                alarmId: filters.alarmId,
                startFrom: filters.startFrom ? filters.startFrom.startOf('day').toISOString() : '',
                startTo: filters.startTo ? filters.startTo.endOf('day').toISOString() : '',
                endFrom: filters.endFrom ? filters.endFrom.startOf('day').toISOString() : '',
                endTo: filters.endTo ? filters.endTo.endOf('day').toISOString() : '',
            }
        });
        return this.http.get<[SupervisorListPastAlarmDataInterface[], number]>(`/alarm-supervisor-list/past`, {params: params});
    }

    public subscribe(alarmId: number, notificationId: number): Observable<AlarmNotificationInterface> {
        return this.http.post<AlarmNotificationInterface>(`/alarm/subscribe/${alarmId}/${notificationId}`, null);
    }

    public decline(alarmId: number, notificationId: number): Observable<void> {
        return this.http.post<void>(`/alarm/cancel/${alarmId}/${notificationId}`, null);
    }

    public forward(data: AlarmForwardDataInterface): Observable<boolean> {
        return this.http.post<boolean>(`/alarm/forward`, data);
    }
}
