import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import { ChartInterface } from '../../../common/charts/interfaces/chart.interface';
import { DATE_FORMAT_DOTS, DATE_TIME_SECONDS_FORMAT_DOTS } from '../../../common/shared/constants/time.constants';
import { TranslateService } from '@ngx-translate/core';
import { TimesInterface } from '../../../common/shared/interfaces/times-interface';
import { CsvParserService } from '../../../common/csv/services/csvParser.service';
import { InfoboxService } from '../../../common/shared/services/infobox.service';

@Injectable()
export class ChartsAndTablesService {
  private readonly timezone: string;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private csvParserService: CsvParserService,
    private infoboxService: InfoboxService,
  ) {
    this.timezone = moment.tz.guess();
  }

  getCharts(): Observable<ChartInterface[]> {
    return this.http.get<ChartInterface[]>('/charts');
  }

  exportTable(tableTransKey: string, timePeriod: TimesInterface, headers: string[], data: any[]) {
    if (!data.length) {
      this.infoboxService.error('SPA.CHARTS.CSV.EMPTY');
      return;
    }

    const table = this.translateService.instant(tableTransKey);
    const title = this.translateService.instant('SPA.CHARTS.CSV.TITLE', {
      table,
      now: moment().format(DATE_TIME_SECONDS_FORMAT_DOTS),
      begin: moment(timePeriod.begin).format(DATE_FORMAT_DOTS),
      end: moment(timePeriod.end).format(DATE_FORMAT_DOTS),
    });

    const translatedHeaders = [];

    for (const header of headers) {
      translatedHeaders.push(this.translateService.instant(header));
    }

    this.csvParserService.downloadCSV(data, {
      title,
      showTitle: true,
      headers: translatedHeaders,
      filename: `${table}_${moment().format('DD_MM_YYYY')}`
    });
  }
}
