import {Routes} from '@angular/router';
import {AuthGuard} from '../_guards/auth.guard';
import { UserPermissionsEnum } from '../../../common/auth/enums/user-permissions.enum';
import { AndonComponent } from './andon.component';
import { AndonAddComponent } from './components/andon-add/andon-add.component';
import { AndonEditComponent } from './components/andon-edit/andon-edit.component';
import { AndonListComponent } from './components/andon-list/andon-list.component';

export const ANDON_ROUTES: Routes = [
    {
        path: '',
        component: AndonComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: [UserPermissionsEnum.AndonManagement],
            breadcrumb: {
                label: 'SPA.ANDON.HEADER',
            }
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'list'
            },
            {
                path: 'list',
                component: AndonListComponent,
                canActivate: [AuthGuard],
                data: {
                    permissions: [UserPermissionsEnum.AndonManagement],
                    breadcrumb: {
                        label: 'SPA.ANDON.LIST',
                    }
                }
            },
            {
                path: 'add',
                component: AndonAddComponent,
                canActivate: [AuthGuard],
                data: {
                    permissions: [UserPermissionsEnum.AndonManagement],
                    breadcrumb: {
                        label: 'SPA.ANDON.ADD',
                    }
                }
            },
            {
                path: 'edit/:id',
                component: AndonEditComponent,
                canActivate: [AuthGuard],
                data: {
                    permissions: [UserPermissionsEnum.AndonManagement],
                    breadcrumb: {
                        label: 'SPA.ANDON.EDIT',
                    }
                }
            }
        ]
    },
];
