<div #chartContainer  class="chart" (resized)="widgetResized()">
    <div class="time-section" [ngStyle]="{'color': fontColor}">
        <div class="text" [ngStyle]="{ height: chartHeight * 0.2 + 'px', width: chartWidth * 0.4 + 'px', fontSize : calculateFontSize(), textAlign: 'center'}">{{ roundValue() }}</div>
        <div class="text" [ngStyle]="{ height: chartHeight * 0.2 + 'px', width: chartWidth * 0.4 + 'px', fontSize : calculateFontSize(), textAlign: 'center'}">{{ label }}</div>
        <!-- <app-dynamic-font-container [saveCalculatedFont]="false" [calculateOnce]="true" [widgetId]="widgetId" [name]="label"
                                    [text]="roundValue()" [height]="chartHeight * 0.3" [width]="chartWidth * 0.3" [translatable]="false"
                                    [delayStart]="1000" [editMode]="editMode" [name]="label + '.VALUE'"></app-dynamic-font-container> -->
        <!-- <app-dynamic-font-container [saveCalculatedFont]="onStation" [widgetId]="widgetId" [text]="label" [height]="chartHeight * 0.3" [width]="chartWidth * 0.3"
                                    [delayStart]="1000" [editMode]="editMode"></app-dynamic-font-container> -->
    </div>
    <apx-chart #chartComponent [ngClass]="{'station': onStation}" *ngIf="chartOptions"
               [series]="[percent]"
               [chart]="chartOptions.chart"
               [plotOptions]="chartOptions.plotOptions"
               [title]="chartOptions.title"
               [fill]="chartOptions.fill"
               [labels]="chartOptions.labels"
               [stroke]="chartOptions.stroke"
               [ngStyle]="{'top' : top}"
    ></apx-chart>
</div>