<ng-container *ngIf="role">
    <div class="row mb-4">
        <div fxFlex="auto" fxLayoutAlign="space-between center">
            <h3 class="subheading-2 font-medium text-secondary m-0 ng-tns-c207-263"> {{role.role | titlecase}} </h3>
            <button (click)="toggleAllPermission()" color="primary" mat-raised-button type="button">
                {{ (showSelectAll ? 'SPA.USERS.ROLES.SELECT_ALL' : 'SPA.USERS.ROLES.DESELECT_ALL') | translate }}
            </button>
        </div>
    </div>
    <div class="row checkbox-container">
        <app-permission-group
          [groups]="groupedPermissions"
          [role]="role"
          (notifyPermissionChange)="onPermissionChange($event)"
        ></app-permission-group>
    </div>
    <div class="row">
        <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
            <button color="warn" mat-button type="button" [routerLink]="['..']"> {{ 'COMMON.CANCEL' | translate }}</button>
            <ap-async-button text="COMMON.SAVE" (onClick)="save()" [inProgress]="inProgress"></ap-async-button>
        </div>
    </div>
</ng-container>