import {NgModule} from '@angular/core';
import {ValidationComponent} from './validation/validation.component';
import {SharedModule} from '../shared.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import {AsyncButtonComponent} from './async-button/asyncButton.component';


const IM_EX_MODULES = [];
const EXPORTED_MODULES = [
    ...IM_EX_MODULES,
];
const IMPORTED_MODULES = [
    ...IM_EX_MODULES,
    SharedModule,
    MatButtonModule,
    MatProgressSpinnerModule
];
const COMPONENTS = [
    ValidationComponent,
    AsyncButtonComponent
];
const PIPES = [];
const DIRECTIVES = [];
const PROVIDERS = [];

@NgModule({
    imports: [IMPORTED_MODULES],
    exports: [
        ...EXPORTED_MODULES,
        ...COMPONENTS,
        ...PIPES,
        ...DIRECTIVES
    ],
    declarations: [
        ...COMPONENTS,
        ...PIPES,
        ...DIRECTIVES
    ],
    providers: [PROVIDERS]
})
export class ApFormsModule {

}
