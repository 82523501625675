import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { SlideshowDataInterface } from '../interfaces/slideshow-data.interface';
import { FtpSlideInterface } from '../../../../common/ftp/interfaces/ftp-slide.interface';

@Injectable()
export class SlidesNetwork {
    constructor(private http: HttpClient) {}

    fetchGroupsList(stationId: number): Observable<SlideshowDataInterface> {
        return this.http.get<SlideshowDataInterface>( `/ftp/slide/${stationId}`);
    }

    delete(stationId: number, slideId: number): Observable<any> {
        return this.http.delete<any>( `/ftp/slide/${stationId}/${slideId}`);
    }

    create(stationId: number, form: FtpSlideInterface): Observable<FtpSlideInterface> {
        return this.http.post<FtpSlideInterface>( `/ftp/slide/${stationId}`, this.addStationId(stationId, form));
    }

    edit(stationId: number, form: FtpSlideInterface): Observable<FtpSlideInterface> {
        return this.http.patch<FtpSlideInterface>( `/ftp/slide/${stationId}/${form.id}`, this.addStationId(stationId, form));
    }

    private addStationId(stationId: number, form: FtpSlideInterface) {
        return {
            ...form,
            ...{stationId: stationId}
        }
    }
}