<mat-card>
  <page-header
    titleTranslate="SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.Header"
  ></page-header>
  <div class="header" (click)="showFilters = !showFilters">
    <page-header titleTranslate="COMMON.FILTERS"></page-header>
    <mat-icon>{{ showFilters ? "expand_more" : "chevron_right" }}</mat-icon>
  </div>
  <div class="column-setting" *ngIf="isMobileScreen">
    <app-select-columns
      [columns]="columnsData"
      icon="settings"
      (columnsChanged)="columnsChanged()"
      [translatable]="false"
    ></app-select-columns>
  </div>
  <ng-container *ngIf="showFilters">
    <div
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutGap="16px"
      fxLayoutGap.lt-sm="0"
      style="align-items: baseline"
    >
      <mat-form-field
        fxFlex="auto"
        appearance="outline"
        class="vex-flex-form-field"
        color="primary"
      >
        <mat-label
          >{{
            "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.PRODUCTION_SECTOR"
              | translate
          }}
        </mat-label>
        <mat-select
          [(ngModel)]="filterData.productionSector"
          (selectionChange)="productionSectorChanged()"
        >
          <mat-option [value]=""
            >{{
              "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.SELECT_PRODUCTION_SECTOR"
                | translate
            }}
          </mat-option>
          <mat-option
            *ngFor="let line of productionSectors"
            [value]="line.id"
            >{{ line.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field
        *ngIf="filterData.productionSector"
        fxFlex="auto"
        appearance="outline"
        class="vex-flex-form-field"
        color="primary"
      >
        <mat-label>{{
          "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.STATION" | translate
        }}</mat-label>
        <mat-select [(ngModel)]="filterData.station">
          <mat-option [value]="">{{
            "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.SELECT_STATION"
              | translate
          }}</mat-option>
          <mat-option *ngFor="let station of stations" [value]="station.id">{{
            station.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div fxLayout="row" fxLayoutGap="8px">
        <button
          class="submit-btn"
          (click)="changeFilters()"
          color="primary"
          mat-raised-button
        >
          {{
            "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.UPDATE"
              | translate
          }}
        </button>

        <button
          class="submit-btn"
          (click)="resetFilters()"
          color="warn"
          mat-raised-button
        >
          {{
            "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.RESET" | translate
          }}
        </button>
      </div>
    </div>
  </ng-container>
  <div class="flex">
    <div style="width: 95%" *ngIf="!isMobileScreen">
      <div style="overflow-x: auto">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          (matSortChange)="sortHalarms($event)"
        >
          <ng-container matColumnDef="alarmId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.ALARM_ID"
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.alarmId }}</td>
          </ng-container>

          <ng-container matColumnDef="lineName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.PRODUCTION_SECTOR"
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.lineName }}</td>
          </ng-container>

          <ng-container matColumnDef="stationName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.STATION"
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.stationName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="configuredAlarmName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.ALARM_TYPE"
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ getTranslatedAlarm(element) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="reason">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.REASON"
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ getTranslatedName("name", element.reasonTranslations) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="creator">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.CREATOR"
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.creator }}</td>
          </ng-container>
          <ng-container matColumnDef="resolvedBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.RESOLVED_FROM"
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.resolvedBy }}
            </td>
          </ng-container>

          <ng-container matColumnDef="occurred">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.START"
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.occurred">{{
                element.occurred | customDate
              }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="resolved">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.END"
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.resolved | customDate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.DURATION"
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.duration | duration }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.DETAILS"
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div
                fxLayout="row"
                fxLayout.lt-sm="column"
                fxLayoutGap="16px"
                fxLayoutGap.lt-sm="0"
              >
                <a
                  color="primary"
                  mat-raised-button
                  type="button"
                  [routerLink]="['../', element.alarm?.id]"
                >
                  <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2" inline="true"
                    >list
                  </mat-icon>
                </a>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <div class="pagination">
        <span (click)="previous()" class="pagination-icon"
          ><mat-icon matTooltip="Previous">keyboard_arrow_left</mat-icon></span
        >
        <span (click)="next()" class="pagination-icon"
          ><mat-icon matTooltip="Next">keyboard_arrow_right</mat-icon></span
        >
      </div>
    </div>

    <div style="width: 100%" *ngIf="isMobileScreen">
      <mat-list role="list" *ngFor="let element of data">
        <mat-list-item role="listitem" *ngIf="displayedColumns.includes('alarmId')">
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.ALARM_ID"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine> {{ element.alarmId }} </span>
        </mat-list-item>
        <mat-list-item role="listitem" *ngIf="displayedColumns.includes('lineName')">
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.PRODUCTION_SECTOR"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine> {{ element.lineName }} </span>
        </mat-list-item>
        <mat-list-item role="listitem" *ngIf="displayedColumns.includes('stationName')">
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.STATION"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine> {{ element.stationName }} </span>
        </mat-list-item>
        <mat-list-item role="listitem" *ngIf="displayedColumns.includes('configuredAlarmName')">
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.ALARM_TYPE"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine> {{ getTranslatedAlarm(element) }} </span>
        </mat-list-item>
        <mat-list-item role="listitem" *ngIf="displayedColumns.includes('reason')">
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.REASON"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine>
            {{ getTranslatedName("name", element.reasonTranslations) }}
          </span>
        </mat-list-item>
        <mat-list-item role="listitem" *ngIf="displayedColumns.includes('creator')">
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.CREATOR"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine> {{ element?.creator }} </span>
        </mat-list-item>
        <mat-list-item role="listitem" *ngIf="displayedColumns.includes('resolvedBy')">
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.RESOLVED_FROM"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine> {{ element?.resolvedBy }} </span>
        </mat-list-item>
        <mat-list-item role="listitem">
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.START"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine> {{ element.occurred | customDate }} </span>
        </mat-list-item>
        <mat-list-item role="listitem">
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.END"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine> {{ element.resolved | customDate }} </span>
        </mat-list-item>
        <mat-list-item role="listitem" *ngIf="displayedColumns.includes('duration')">
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.DURATION"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine> {{ element.duration | duration }} </span>
        </mat-list-item>
        <mat-list-item role="listitem">
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.DETAILS"
                  | translate
              }}
            </strong></span
          >
          <div
            fxLayout="row"
            fxLayout.lt-sm="column"
            fxLayoutGap="16px"
            fxLayoutGap.lt-sm="0"
          >
            <a
              color="primary"
              mat-raised-button
              type="button"
              [routerLink]="['../', element.alarm?.id]"
            >
              <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2" inline="true"
                >list
              </mat-icon>
            </a>
          </div>
        </mat-list-item>
      </mat-list>
      <div class="pagination">
        <span (click)="previous()" class="pagination-icon"
          ><mat-icon matTooltip="Previous">keyboard_arrow_left</mat-icon></span
        >
        <span (click)="next()" class="pagination-icon"
          ><mat-icon matTooltip="Next">keyboard_arrow_right</mat-icon></span
        >
      </div>
    </div>

    <div class="column-setting" *ngIf="!isMobileScreen">
      <app-select-columns
        [columns]="columnsData"
        icon="settings"
        (columnsChanged)="columnsChanged()"
        [translatable]="false"
      ></app-select-columns>
    </div>
  </div>
</mat-card>
