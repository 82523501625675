import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StationFullInterface } from '../../../common/station/interfaces/station-full.interface';

@Injectable()
export class StationService {
    constructor(
        private http: HttpClient,
    ) {
    }

    getAllStations(): Observable<StationFullInterface[]> {
        return this.http
            .get<StationFullInterface[]>('/station/all');
    }

    getStationsByLineId(lineId): Observable<StationFullInterface[]> {
        return this.http.get<StationFullInterface[]>( `/station/line/${lineId}`);
    }

    saveStationsMapping(data){
        return this.http.post<any[]>( `/device-configurator/io-mappings/stations-mapping`, data);
      }
}
