import { Routes } from '@angular/router';
import {ConfigurationComponent} from './configuration.component';
import {AuthGuard} from '../_guards/auth.guard';
import { UserPermissionsEnum } from '../../../common/auth/enums/user-permissions.enum';

export const CONFIGURATION_ROUTES: Routes = [
    {
        path: '',
        component: ConfigurationComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: [UserPermissionsEnum.SuperAdmin],
            breadcrumb: {
                label: 'SPA.SYSTEM_CONFIG.HEADER',
            }
        }
    }
];
