import { Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';
import { InfoboxService } from '../../../../../common/shared/services/infobox.service';
import { UserInterface } from '../../../../../common/auth/interfaces/user.interface';
import { NgxBarcodeComponent } from 'ngx-barcode';
import * as svg from 'save-svg-as-png'; //types do not exist :(
import { TranslateService } from '@ngx-translate/core';
import { ModalsService } from '../../../../../common/shared/services/modals.service';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { BARCODE_START, BARCODE_END } from '../../../../../common/auth/constants/barcode.constants';
import { LanguageEnum } from '../../../../../common/shared/enums/language.enum';
import { SmartwatchInterface } from '../../../../../common/smartwatch/interfaces/smartwatch.interface';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  form: UntypedFormGroup;
  @ViewChild('barcode') barcode: NgxBarcodeComponent;
  userId: number;
  roles;
  barcodeString?: string = null;
  showDummyPassword: boolean = true;
  languages = LanguageEnum;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  smartwatches: SmartwatchInterface[]  = [];
  user: UserInterface;

  constructor(
    private _location: Location,
    private route: ActivatedRoute,
    private userService: UserService,
    private infoboxService: InfoboxService,
    private translateService: TranslateService,
    private modalService: ModalsService,
    private el: ElementRef
  ) { }

  ngOnInit() {
    this.initForm();
    this.userId = +this.route.snapshot.params.id;
    this.userService.getUser(this.userId).subscribe((user) => {
       this.user = user;
       this.patchForm(user);
      });
    this.userService.getUserRoles()
      .subscribe((roles) => this.roles = roles);
    this.userService.getUserBarcode(this.userId)
      .subscribe(data => {
        this.setBarcodeString(data.barcodeString);
      });
    this.getSmartwatchList();
  }

  initForm() {
      this.form = new UntypedFormGroup({
          id: new UntypedFormControl(undefined),
          firstname: new UntypedFormControl('', Validators.required),
          name: new UntypedFormControl('', Validators.required),
          email: new UntypedFormControl(''),
          coin:new UntypedFormControl(''),
          role: new UntypedFormControl('', Validators.required),
          username: new UntypedFormControl('', Validators.required),
          password: new UntypedFormControl(''),
          showpassword: new UntypedFormControl('******'),
          showConfirmPassword: new UntypedFormControl('******'),
          confirmPassword: new UntypedFormControl(''),
          watch: new UntypedFormControl(''),
          language: new UntypedFormControl('', Validators.required)
      });
      this.form.get('coin').valueChanges.subscribe((value)=>{
        if(!this.disabledResetBarcode()){
          this.setBarcodeString(BARCODE_START + value + BARCODE_END)
        }else{
          this.setBarcodeString(null);
        }
      })
      this.form.get('showpassword').valueChanges.subscribe((value)=>{
        value = value.length > 0 && value != '*****' ? value.substring(value.length - 1).trim() : '';
        setTimeout(() => {
          const element = this.el.nativeElement.querySelector('.password-field');
          element.focus();
        }, 10);
        this.showDummyPassword = false;
        this.form.get('password').setValue(value);
        setTimeout(() => {
          this.setPasswordMatchValidation();
        },0)
      });
      this.form.get('showConfirmPassword').valueChanges.subscribe((value)=>{
        value = value.length > 0 && value != '*****' ? value.substring(value.length - 1).trim() : '';
        setTimeout(() => {
          const element2 = this.el.nativeElement.querySelector('.confirm-password-field');
          element2.focus();
        }, 10);
        this.showDummyPassword = false;
        this.confirmPassword.setValue(value);
        setTimeout(() => {
          this.setPasswordMatchValidation();
        }, 0)
      });
      
      // on passwords field value changes
      this.password.valueChanges.subscribe(() => this.setPasswordMatchValidation());
      this.confirmPassword.valueChanges.subscribe(() => this.setPasswordMatchValidation());
  }

  setBarcodeString(value){
    if (value === null && !this.disabledResetBarcode()){
      this.barcodeString = BARCODE_START + this.form.get('coin').value + BARCODE_END;
    } else {
      this.barcodeString = value;
    }
  }

  get username(): string {
      return this.form ? this.form.get('username').value : null;
  }

  patchForm(user: UserInterface) {
    this.form.patchValue({
      id: user.id,
      firstname: user.firstname,
      name: user.name,
      email: user.email,
      coin:user.coin,
      role: user.role.id,
      username: user.username,
      watch: user?.watch || null,
      language: user.language
    });
      if (this.username === 'admin') {
          this.form.get('role').disable();
      }
  }

  goBack() {
    this._location.back();
  }

  save() {
    if (this.form.valid) {
      this.userService.updateUser(this.form.value)
        .subscribe(
          () => {
            this.infoboxService.success('SPA.USERS.EDIT.SUCCESS');
            this.form.markAsPristine();
            this._location.back();
          }
        );
    }
  }

  updateBarcode() {
    if (!this.barcodeString) {
      this.actualUpdateBarcode();
      return;
    }

    this.modalService.confirm('SPA.USERS.EDIT.BARCODE.RESET')
      .subscribe(confirmation => {
        if (!confirmation) {
          return;
        }

        this.actualUpdateBarcode();
      });
  }

  disabledDownloadBarcode(): boolean {
    return !this.barcodeString || !this.username || this.username.length === 0;
  }

  disabledResetBarcode():boolean {
    return this.form.get('coin').value === null || this.form.get('coin').value === '';
  }

  downloadBarcode() {
    svg.saveSvgAsPng(this.barcode.bcElement.nativeElement.firstChild, this.getBarcodeFileName());
  }

  private getBarcodeFileName(): string {
    return this.translateService.instant('SPA.USERS.EDIT.BARCODE.FILE_NAME', {
      username: this.username
    });
  }

  private actualUpdateBarcode() {
    this.userService.updateUserBarcode(this.userId)
      .subscribe(data => {
        this.setBarcodeString(data.barcodeString);
      });
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  get confirmPassword(): AbstractControl {
      return this.form.get('confirmPassword');
  }

  setPasswordMatchValidation() {
      let passwordValue = this.password.value;
      let confirmPasswordValue = this.confirmPassword.value;

      // Set value not match error in confirmPassword
      if((passwordValue || confirmPasswordValue) && passwordValue !== confirmPasswordValue) {
        this.confirmPassword.setErrors({valueMismatch: true});
        if(!this.confirmPassword.touched) this.confirmPassword.markAsTouched();
      } else {
        this.confirmPassword.setErrors(null);
      }
  }

    getSmartwatchList() {
        this.userService.getUnconfiguredSmartwatches().subscribe((res) => {
            this.smartwatches = res.filter((s) => !s.user || s.user.id === this.userId);
            
            if(this.user?.watch?.id) {
              const userWatch = this.smartwatches.find((smartwatch) => smartwatch.id === this.user.watch.id);
              if(userWatch) this.form.get('watch').patchValue(userWatch);
            } 
        });
    }
}
