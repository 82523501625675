import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Socket } from '../../../common/socket/models/socket.model';
import { BaseSocketService } from '../../../common/socket/services/base-socket.service';
import { SocketChannel } from '../../../common/socket/utils/socket-channel';
import { SocketMessageEnum } from '../../../common/socket/enums/socket-message.enum';

@Injectable()
export class SocketService extends BaseSocketService {
    constructor() {
        super();
        this.createSocket();
    }

    createSocket() {
        this._socket = new Socket(environment.apiUrl);
    }

    join(channel: SocketChannel) {
        this._socket.join(channel);
    }

    leave(channel: SocketChannel) {
        this._socket.leave(channel);
    }

    on(event: SocketMessageEnum, callback: (content) => void): string {
        return this._socket.on(event, callback);
    }

    off(identifier: string) {
        this._socket.off(identifier);
    }
}
