import {Injectable} from "@angular/core";
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {forEach, isArray, isObject, mapValues} from "lodash-es";

type Dictionary<T> = {[key: string]: T;};

@Injectable()
export class FormManipulatorService {
    public fillFormGroup(formGroup: UntypedFormGroup, data: Dictionary<any>, flat = false): void {
        forEach(data, (controlData: any, controlKey: string) => {
            let control = formGroup.controls[controlKey];

            if (control) {
                switch (control.constructor) {
                    case UntypedFormControl:
                        control.setValue(controlData);
                        break;
                    case UntypedFormGroup:
                        this.fillFormGroup(control as UntypedFormGroup, controlData, flat);
                        break;
                    case UntypedFormArray:
                        this.fillFormArray(control as UntypedFormArray, controlData, flat);
                        break;
                }
            }
        });
    }

    public fillFormArray(formArray: UntypedFormArray, data: any[], flat = false) {
        formArray.controls = [];

        data.forEach(item => {
            formArray.push(this.createFormGroup(item, flat));
        });
    }

    private createFormGroup(data: Dictionary<any>, flat = false): UntypedFormGroup {
        let formGroupConfig = mapValues(data, o => {
            if (isArray(o)) {
                let nestedControl = new UntypedFormArray([]);
                this.fillFormArray(nestedControl, o);
                return nestedControl;
            } else if (isObject(o) && !flat) {
                return this.createFormGroup(o);
            } else {
                return new UntypedFormControl(o);
            }
        });

        return new UntypedFormGroup(formGroupConfig);
    }
}