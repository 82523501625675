<vex-secondary-toolbar>
    <vex-breadcrumbs [crumbs]="crumbs" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>
<div class="page-content">
<!--    <div class="row ap-card shadow">-->
<!--        <div mat-tab-nav-bar class="tabs-nav responsive-tabs">-->
<!--            <a mat-tab-link routerLink="supervisor" *ngIf="showSupervisorList()"-->
<!--                            routerLinkActive #supervisor="routerLinkActive"-->
<!--                            [active]="supervisor.isActive">-->
<!--                <i class="material-icons">contact_phone</i>-->
<!--                <span class="tab-title">{{'SPA.ALARMS_ESCALATIONS.SUPERVISOR.HEADER' | translate}}</span>-->
<!--            </a>-->
<!--            <a mat-tab-link routerLink="groups" *ngIf="showGroupList()"-->
<!--                     [active]="rlaStations.isActive"-->
<!--               routerLinkActive #rlaStations="routerLinkActive"-->
<!--                    mat-tab-link>-->
<!--                <i class="material-icons">supervisor_account</i>-->
<!--                <span class="tab-title">{{'SPA.ALARMS_ESCALATIONS.GROUPS.HEADER' | translate}}</span>-->
<!--            </a>-->
<!--            <a mat-tab-link routerLink="disorder"  *ngIf="showDisorderList()"-->
<!--               [active]="disorders.isActive"  routerLinkActive-->
<!--               #disorders="routerLinkActive">-->
<!--                <i class="material-icons">report_problem</i>-->
<!--                <span class="tab-title">{{'SPA.ALARMS_ESCALATIONS.DISORDERS.HEADER' | translate}}</span>-->
<!--            </a>-->
<!--        </div>-->
        <router-outlet></router-outlet>
<!--    </div>-->
</div>
