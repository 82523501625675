import { UserPermissionsEnum } from '../enums/user-permissions.enum';
import { PermissionGroupInterface } from '../interfaces/permission-group.interface';

export class UserPermissionsUtil {
  static getGroupedPermissions(): PermissionGroupInterface[] {
    const permissionGroups = this.groupedPermissions;
    const groupedPermissionValues = this.getGroupedValues(this.groupedPermissions);

    for (const p in UserPermissionsEnum) {
      if (!isFinite(+p) || groupedPermissionValues.includes(+p)) {
        continue;
      }

      permissionGroups.push({
        value: +p,
      });
    }

    return permissionGroups;
  }

  static getGroupedValues(groups: PermissionGroupInterface[] = []): UserPermissionsEnum[] {
    let data: UserPermissionsEnum[] = [];

    for (const group of groups) {
      data.push(group.value);

      if (group.children && group.children.length) {
        data = [...data, ...this.getGroupedValues(group.children)];
      }
    }

    return data;
  }

  private static groupedPermissions: PermissionGroupInterface[] = [
    {
      value: UserPermissionsEnum.ProductsManagement,
      children: [
        {
          value: UserPermissionsEnum.ProductionFlowManagement,
        },
        {
          value: UserPermissionsEnum.WorkstepsManagement,
        },
        {
          value: UserPermissionsEnum.DeleteProductsManagement,
        },
        {
          value: UserPermissionsEnum.ProductItemTrash,
        },
        {
          value: UserPermissionsEnum.ProductItemDetailsPage,
          children: [
            {
              value: UserPermissionsEnum.ProductItemDataManipulationPage,
            }
          ]
        },
      ]
    },
    {
      value: UserPermissionsEnum.LineManagement,
      children: [
        {
          value: UserPermissionsEnum.StationManagement,
        },
        {
          value: UserPermissionsEnum.RemoteStationView,
        },
        {
          value: UserPermissionsEnum.WorkInstructionsManagement,
        },
      ],
    },
    {
      value: UserPermissionsEnum.SystemSettingsManagement,
      children: [
        {
          value: UserPermissionsEnum.EmailSettingsManagement,
        },
        {
          value: UserPermissionsEnum.FtpSettingsManagement,
        },
        {
          value: UserPermissionsEnum.BackupSettingsManagement,
        },
        {
          value: UserPermissionsEnum.ClockSettingsManagement,
        },
        {
          value: UserPermissionsEnum.GeneralSettingsManagement,
        }
      ]
    },
    {
      value: UserPermissionsEnum.StationWorker,
      isStation: true,
      children: [
        {
          value: UserPermissionsEnum.StationLogout,
          isStation: true,
        },
        {
          value: UserPermissionsEnum.StationProductItemIncludeLater,
          isStation: true,
        },
        {
          value: UserPermissionsEnum.StationProductItemDataManipulationPage,
          isStation: true,
        },
      ],
    },
    {
      value: UserPermissionsEnum.AEManagement,
      children: [
        {
          value: UserPermissionsEnum.StationDisorderAutologin,
          isStation: true,
        },
        {
          value: UserPermissionsEnum.AESupervisor,
        },
        {
          value: UserPermissionsEnum.AEForwardAlarmManagement,
        },
        {
          value: UserPermissionsEnum.AEGroupsManagement,
        },
        {
          value: UserPermissionsEnum.AEDisorderReasonManagement,
        },
        {
          value: UserPermissionsEnum.AEEndAlarmFromDashboardManagement,
        },
        {
          value: UserPermissionsEnum.AEAlarmDashboardManagement,
        },
        {
          value: UserPermissionsEnum.AEAlarmsListingManagement,
        }
      ],
    },
  ];
}