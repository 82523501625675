import {Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {LINE_ROUTES} from './line/line.routing';
import {USER_ROUTES} from './user-management/user-management.routing';
import {DISORDER_ROUTES} from './disorder/disorder.routing';
import {SHIFT_PAUSE_ROUTES} from './shifts/shift.routing';
import {REPORTS_ROUTES} from './reports/reports.routing';
import {EMAIL_CONFIGURATION_ROUTES} from './system-settings/email-configuration/email-configuration.routing';
import {FTP_CONFIGURATION_ROUTES} from './system-settings/ftp-configuration/ftp-configuration.routing';
import {AuthGuard} from './_guards/auth.guard';
import {CONFIGURATION_ROUTES} from './configuration/configuration.routing';
import {ALARMS_AND_ESCALATIONS_ROUTES} from './alarms-and-escalations/alarms-and-escalations.routing';
import {SpaLayoutComponent} from './spa-layout/spa-layout.component';
import {LIVE_DEBUGGING_ROUTING} from './live-debugging/live-debugging.routing';
import { ConfigurationEnum } from '../../common/configuration/enums/configuration.enum';
import { UserPermissionsEnum } from '../../common/auth/enums/user-permissions.enum';
import { ChartsAndTablesComponent } from './charts-and-tables/charts-and-tables.component';
import { ANDON_ROUTES } from './andon/andon.routing';
import { WORK_INSTRUCTIONS_ROUTES } from './work-instructions/work-instructions.routing';
import { BACKUP_ROUTES } from './system-settings/backup/backup.routing';
import { SystemStoppedComponent } from '../../common/maintenance/components/system-stopped/system-stopped.component';
import { MOBILE_APP_ROUTES } from './mobile-app/mobile-app.routing';
import { DownloadAppComponent } from './mobile-app/download-app/download-app.component';
import { TIMEZONE_CONFIGURATION_ROUTES } from './system-settings/timezone/timezone.routing';
import {
    MQTT_CONNECTION_DETAILS_ROUTES
} from './system-settings/mqtt-connection-details/mqtt-connection-details.routing';
import { GENERAL_SETTINGS_ROUTES } from './system-settings/general-settings/general-settings.routing';

export const appRoutes: Routes = [
    {path: 'login', component: LoginComponent},
    {path: 'stopped', component: SystemStoppedComponent},
    {path: 'download-app', component: DownloadAppComponent},
    {
        path: '',
        component: SpaLayoutComponent,
        children: [
            {path: '', pathMatch: 'full', redirectTo: 'dashboard'},
            {path: 'dashboard', loadChildren: () => import('app/dashboard/dashboard.module').then(m => m.DashboardModule)},
            {
                path: 'charts',
                component: ChartsAndTablesComponent,
                canActivate: [AuthGuard],
                data: {
                    permissions: [UserPermissionsEnum.ChartsPage],
                    configuration: ConfigurationEnum.CHARTS,
                    breadcrumb: {
                        label: 'SPA.NAV.CHARTS',
                    }
                }
            },
            {path: 'work-instructions', children: WORK_INSTRUCTIONS_ROUTES},
            {path: 'line', children: LINE_ROUTES},
            {path: 'andon', children: ANDON_ROUTES},
            {path: 'products', loadChildren: () => import('app/products/products.module').then(m => m.ProductsModule)},
            {path: 'orders', loadChildren: () => import('app/orders/orders.module').then(m => m.OrdersModule)},
            {path: 'users', children: USER_ROUTES},
            {path: 'disorder', children: DISORDER_ROUTES},
            {path: 'shifts', children: SHIFT_PAUSE_ROUTES},
            {path: 'io-modules', loadChildren: () => import('app/io-module/io-module.module').then(m => m.IoModuleModule)},
            {path: 'reports', children: REPORTS_ROUTES},
            {path: 'email-configuration', children: EMAIL_CONFIGURATION_ROUTES},
            {path: 'events-and-actions', loadChildren: () => import('app/events-and-actions/events-and-actions.module').then(m => m.EventsAndActionsModule)},
            {path: 'alarms-and-escalations', children: ALARMS_AND_ESCALATIONS_ROUTES},
            {path: 'system-configuration', children: CONFIGURATION_ROUTES},
            {path: 'mobile-app', children: MOBILE_APP_ROUTES},
            {path: 'ftp-configuration', children: FTP_CONFIGURATION_ROUTES},
            {path: 'backup', children: BACKUP_ROUTES},
            {path: 'timezone', children: TIMEZONE_CONFIGURATION_ROUTES},
            {path: 'mqtt-connection-details', children: MQTT_CONNECTION_DETAILS_ROUTES},
            {path: 'general-settings', children: GENERAL_SETTINGS_ROUTES},
            {path: 'live-debugging', children: LIVE_DEBUGGING_ROUTING},
            {path: 'device-configurator', loadChildren: () => import('app/device-configurator/device-configurator.module').then(m => m.DeviceConfiguratorModule)},
            {path: 'smartwatches', loadChildren: () => import('app/smartwatches/smartwatches.module').then(m => m.SmartwatchesModule)},
            {path: '**', redirectTo: 'login', pathMatch: 'full'}

        ]
    },

];
