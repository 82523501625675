import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { WorkInstructionsComponent } from './work-instructions.component';
import { WorkInstructionsAddComponent } from './work-instructions-add/work-instructions-add.component';
import { WorkInstructionsService } from './work-instructions.service';
import { WorkInstructionsViewComponent } from './work-instructions-view/work-instructions-view.component';
import { SharedModule } from '../shared/shared.module';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import { WorkInstructionsEditComponent } from './work-instructions-edit/work-instructions-edit.component';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import { MaterialFileInputModule } from 'ngx-material-file-input';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    MatSelectModule,
    MatDialogModule,
    MaterialFileInputModule
  ],
  declarations: [
    WorkInstructionsComponent,
    WorkInstructionsAddComponent,
    WorkInstructionsViewComponent,
    WorkInstructionsEditComponent
  ],
  providers: [
    WorkInstructionsService
  ]
})
export class WorkInstructionsModule { }
