import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GeneralSettingsDataInterface } from '../../../../../common/general-settings/interfaces/general-settings-data.interface';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class GeneralSettingsService {
    constructor(
        private http: HttpClient,
    ) {
        this.apiUrl = environment.apiUrl;
    }

    apiUrl: String;

    // To create/update any key in general settings
    updateGeneralSettings(data: GeneralSettingsDataInterface): Observable<any> {
        return this.http.patch( this.apiUrl + `/api/general-settings`, data);
    }

    // To get specific key and value of general setting
    getGeneralSettingValueByKey(key): Observable<GeneralSettingsDataInterface> {
        return this.http.get<GeneralSettingsDataInterface>( this.apiUrl + `/api/general-settings/key/${key}`);
    }

    // To get all the general settings value
    getAllGeneralSettingsValue(): Observable<GeneralSettingsDataInterface[]> {
        return this.http.get<GeneralSettingsDataInterface[]>( this.apiUrl + `/api/general-settings`);
    }
}