import { Component, OnInit } from "@angular/core";
import { LineService } from "./line.service";
import { AuthService } from "../auth/auth.service";
import { BreadcrumbComponent } from "../shared/components/breadcrumb.component";
import { ActivatedRoute, Router } from "@angular/router";
import { LineBeatTypeEnum } from "../../../common/line/enums/line-beat-type.enum";
import { LineInterface } from "../../../common/line/interfaces/line.interface";
import { ConfigurationEnum } from "../../../common/configuration/enums/configuration.enum";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-line",
  templateUrl: "./line.component.html",
  styleUrls: ["./line.component.scss"],
})
export class LineComponent extends BreadcrumbComponent implements OnInit {
  lines: LineInterface[];
  ConfigurationEnum = ConfigurationEnum;
  loading = true;
  constructor(
    private translateService: TranslateService,
    public lineService: LineService,
    public authService: AuthService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    super(route, router);
  }

  ngOnInit() {
    this.initBreadcrumbs();
    this.lineService.getLines().subscribe(
      (lines) => {
        this.lines = lines;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  showLineBeat(line: LineInterface): boolean {
    return (
      line.beatTypeEnum === LineBeatTypeEnum.LINE_BASED_BEAT ||
      line.beatTypeEnum === LineBeatTypeEnum.FIXED_BEAT_LENGTH
    );
  }

  lineBeatText(line: LineInterface): string {
    let beatSeconds;
    let beatMinutes;
    let beatHours;
    if (line.takt) {
      let takt = line.takt;
      beatSeconds = takt % 60 < 10?'0'+Math.floor(takt % 60):Math.floor(takt % 60);
      takt = takt / 60;
      beatMinutes = takt % 60 < 10?'0'+Math.floor(takt % 60):Math.floor(takt % 60);
      takt = Math.floor(takt / 60);
      beatHours = takt % 24 < 10?'0'+Math.floor(takt % 24):Math.floor(takt % 24);
    }
    return this.showLineBeat(line)
      ? this.translateService.instant("SPA.LINES.TILE_TEXT") + beatHours + ":" + beatMinutes + ":" + beatSeconds
      : "";
  }

  getIconForType(line: LineInterface): string {
    switch (line.beatTypeEnum) {
      case LineBeatTypeEnum.LINE_BASED_BEAT:
        return "swap_calls";
      case LineBeatTypeEnum.FIXED_BEAT_LENGTH:
        return "swap_calls";
      case LineBeatTypeEnum.STATION_BASED_BEAT:
        return "refresh";
      case LineBeatTypeEnum.NO_BEAT:
        return "alarm_off";
    }
    return null;
  }
}
