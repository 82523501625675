import {DisorderInterface, DisorderStationInterface} from '../../../_utils/interfaces/disorder.interface';
import {ROW_MODE} from '../components/group-disorder/group-disorder.component';
import { LineInterface } from '../../../../../common/line/interfaces/line.interface';

export class DisorderRowModel implements DisorderInterface {
    constructor(
        public isRoot: boolean,
        public id: number,
        public code: string,
        public reason: string,
        public line: LineInterface,
        public stations: DisorderStationInterface[],
        public parentId: number,
        public children: DisorderRowModel[] = [],
        public mode: ROW_MODE = ROW_MODE.LISTING
    ) {}

    isNewRoot(): boolean {
        return this.isRoot && !this.id;
    }

    updateModel(disorder: DisorderRowModel) {
        this.id = disorder.id;
        this.parentId = disorder.parentId;
        this.code = disorder.code;
        this.reason = disorder.reason;
        this.line = disorder.line;
        this.stations = disorder.stations;
    }

    addEmptyChild() {
        this.children.push(new DisorderRowModel(
            false,
            undefined,
            '',
            '',
            null,
            [],
            this.id,
            [],
            ROW_MODE.EDITING
        ));
    }
}