import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import { Subscription } from 'rxjs';
import {get, intersection} from 'lodash-es';
import { ConfigurationService } from '../../configuration/services/configuration.service';
import { UserPermissionsEnum } from '../../../../common/auth/enums/user-permissions.enum';

@Directive({
    selector: '[apUserAccess]'
})
export class UserAccessDirective implements OnInit, OnDestroy {
    @Input('apUserAccess') public accessList: UserPermissionsEnum[];
    private hasView = false;
    private subscription = new Subscription();

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private authService: AuthService,
    ) {
    }

    public ngOnInit(): void {
        this.subscription.add(
            this.authService.currentUser$
                .subscribe(currentUser => this.setVisibilityForUser(currentUser))
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private setVisibilityForUser(currentUser) {
        if (!this.accessList.length) {
            this.setView();
            return;
        }

        let userPermissions = get(currentUser, 'permissions', []);

        const hasPermissions = intersection(this.accessList, userPermissions).length > 0;

        if (hasPermissions && !this.hasView) {
            this.setView();
        } else if (!hasPermissions && this.hasView) {
            this.hideView();
        }
    }

    private setView() {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
    }

    private hideView() {
        this.viewContainer.clear();
        this.hasView = false;
    }
}