import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule, isDevMode, ErrorHandler} from '@angular/core';
import {RouterModule, Router} from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import {AuthService} from './auth/auth.service';
import {AuthGuard} from './_guards/auth.guard';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {appRoutes} from './app.routing';
import {InfoboxService} from '../../common/shared/services/infobox.service';
import {LineModule} from './line/line.module';
import {UserManagementModule} from './user-management/user-management.module';
import {DisorderModule} from './disorder/disorder.module';
import {NgChartsModule} from 'ng2-charts';
import {LineService} from './line/line.service';
import {ShiftModule} from './shifts/shift.module';
import {SocketService} from './_services/socket.service';
import {TranslateService} from './translate/services/translate.service';
import {ReportsModule} from './reports/reports.module';
import {EmailConfigurationModule} from './system-settings/email-configuration/email-configuration.module';
import {FtpConfigurationModule} from './system-settings/ftp-configuration/ftp-configuration.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from './core/core.module';
import {ToastrModule} from 'ngx-toastr';
import { MatSidenavModule } from '@angular/material/sidenav';
import {ConfigurationModule} from './configuration/configuration.module';
import {SettingsService} from './core/services/settings.service';
import {AlarmsAndEscalationsModule} from './alarms-and-escalations/alarms-and-escalations.module';
import {LiveDebuggingModule} from './live-debugging/live-debugging.module';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {VexModule} from '../@vex/vex.module';
import {LayoutModule} from '../@vex/layout/layout.module';
import {SidenavModule} from '../@vex/layout/sidenav/sidenav.module';
import {ConfigPanelModule} from '../@vex/components/config-panel/config-panel.module';
import {SidebarModule} from '../@vex/components/sidebar/sidebar.module';
import {QuickpanelModule} from '../@vex/layout/quickpanel/quickpanel.module';
import {ToolbarModule} from '../@vex/layout/toolbar/toolbar.module';
import {FooterModule} from '../@vex/layout/footer/footer.module';
import {SpaLayoutModule} from './spa-layout/spa-layout.module';
import {SharedModule} from './shared/shared.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { ChartsAndTablesModule } from './charts-and-tables/charts-and-tables.module';
import {NgIdleModule} from '@ng-idle/core';
import { AndonModule } from './andon/andon.module';
import { WorkInstructionsModule } from './work-instructions/work-instructions.module';
import { BackupModule } from './system-settings/backup/backup.module';
import { BackupModeService } from '../../common/backups/services/backup-mode.service';
import { SystemStoppedComponent } from '../../common/maintenance/components/system-stopped/system-stopped.component';
import { MobileAppModule } from './mobile-app/mobile-app.module';
import { LoginLogoutService } from './auth/login-logout.service';
import { ClockService } from '../../common/shared/services/clock.service';
import { clockFactory } from '../../common/shared/functions/clock-factory.function';
import { TimezoneModule } from './system-settings/timezone/timezone.module';
import { StorageService } from '../../common/shared/services/storage.service';
import { FontDataService } from '../../common/shared/components/dynamic-font-container/font-data.service';
import { CommonService } from '../../common/shared/services/common.service';
import { MqttConnectionDetailsModule } from './system-settings/mqtt-connection-details/mqtt-connection-details.module';
import { GeneralSettingsModule } from './system-settings/general-settings/general-settings.module';
import { MissingTranslationHandler } from '@ngx-translate/core';
import { KeyMissingTranslationHandler } from './translate/handler/key-missing-translation-handler';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PushNotificationService } from "../app/_services/push-notification.service";

export function configurationFactory(provider: SettingsService, authService: AuthService) {
    if (authService.loggedIn()) {
        authService.initInterval();
    }

    return () => provider.loadSettings();
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        SystemStoppedComponent
    ],
    imports: [
        AlarmsAndEscalationsModule,
        BrowserAnimationsModule,
        BrowserModule,
        NgChartsModule,
        CoreModule,
        ConfigurationModule,
        DisorderModule,
        ShiftModule,
        EmailConfigurationModule,
        BackupModule,
        FtpConfigurationModule,
        FormsModule,
        HttpClientModule,
        LineModule,
        MatSidenavModule,
        ReportsModule,
        VexModule,
        SpaLayoutModule,
        SharedModule,
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
        }),
        ToastrModule.forRoot(),
        UserManagementModule,
        WorkInstructionsModule,
        LiveDebuggingModule,
        MatSelectModule,
        LayoutModule,
        SidenavModule,
        ConfigPanelModule,
        SidebarModule,
        QuickpanelModule,
        ToolbarModule,
        FooterModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        ChartsAndTablesModule,
        AndonModule,
        NgIdleModule.forRoot(),
        MobileAppModule,
        TimezoneModule,
        MqttConnectionDetailsModule,
        GeneralSettingsModule,
        ServiceWorkerModule.register('../custom-service-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        AuthGuard,
        AuthService,
        InfoboxService,
        LoginLogoutService,
        LineService,
        TranslateService,
        SocketService,
        StorageService,
        SettingsService,
        ClockService,
        { provide: APP_INITIALIZER, useFactory: configurationFactory, deps: [SettingsService, AuthService], multi: true },
        { provide: APP_INITIALIZER, useFactory: clockFactory, deps: [ClockService, AuthService, SocketService], multi: true },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'de-DE'
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {

                parse: {
                    dateInput: ['l', 'LL'],
                },
                display: {
                    dateInput: 'L',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: MissingTranslationHandler, useClass: KeyMissingTranslationHandler },
        BackupModeService,
        FontDataService,
        CommonService,
        PushNotificationService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
