import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeviceFullInterface } from '../../../../../../common/device/interfaces/device-full.interface';
import { ProcessOrderInterface } from '../../../../../../common/process/interfaces/process-order.interface';
import { DeviceNetwork } from '../network/device.network';
import { InfoboxService } from '../../../../../../common/shared/services/infobox.service';
import { DeviceDataInterface } from '../../../../../../common/device/interfaces/device-data.interface';
import { Location } from '@angular/common';
import { DeviceTypeEnum } from '../../../../../../common/device/enums/device-type.enum';
import { DeviceTypeUtil } from '../../../../../../common/device/utils/device-type.util';
import { LineService } from '../../../../line/line.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalsService } from '../../../../../../common/shared/services/modals.service';
import { DeviceRotationEnum } from '../../../../../../common/device/enums/device-rotation.enum';

@Injectable()
export class DeviceService {
  private lineId: number;

  constructor(
    private deviceNetwork: DeviceNetwork,
    private infoBoxService: InfoboxService,
    private _location: Location,
    private lineService: LineService,
    private modalsService: ModalsService,
    private translateService: TranslateService
  ) {
  }

  init(lineId?: number) {
    this.lineId = lineId || null;

    this.deviceNetwork.init(lineId);
  }

  showDeviceType(type: DeviceTypeEnum): boolean {
    return this.lineId
      ? !!this.lineService.line && DeviceTypeUtil.allowedDeviceTypeForLine(type, this.lineService.line)
      : type === DeviceTypeEnum.ANDON;
  }

  getDevices(): Observable<any[]> {
    return this.deviceNetwork.getDevices(this.lineId);
  }

  getDevice(id: number): Observable<DeviceFullInterface> {
    return this.deviceNetwork.getDevice(id);
  }

  deleteDevice(id: number): Observable<boolean> {
    return this.deviceNetwork.deleteDevice(id, this.lineId || null);
  }

  addDevice(data: DeviceDataInterface) {
    if (this.lineId) {
      data.lineId = this.lineId;
    }

    this.deviceNetwork.addDevice(data)
      .subscribe(_ => {
        this.infoBoxService.pushInfo({ success: true, text: 'SPA.DEVICE.ADD.SUCCESS' });
        this.goBack();
      });
  }

  updateDevice(id: number, data: DeviceDataInterface, callback: () => void) {
    this.deviceNetwork.updateDevice(id, data)
      .subscribe(_ => {
        this.infoBoxService.pushInfo({ success: true, text: 'SPA.DEVICE.EDIT.EDIT_SUCCESS' });
        callback();
      });
  }

  goBack() {
    this._location.back();
  }

  changeActive(device: DeviceFullInterface) {
    this.deviceNetwork.changeActive(device.id).subscribe(value => {
      device.active = value;
    });
  }

  rebootDevice(device: DeviceFullInterface) {
    this.deviceNetwork.rebootDevice(device.id).subscribe(res => {
      if (!res) {
        return;
      }

      this.infoBoxService.success('SPA.DEVICE.REBOOT_MODAL.REBOOTING', {
        deviceName: device.name
      });
    });
  }

  updateDeviceBeat(deviceId: number, beatTime: number, type: any = null) {
    // const beatSeconds = beatTime * 60;
    this.deviceNetwork.updateDeviceBeat(deviceId, beatTime, type).subscribe(() => {
      this.infoBoxService.pushInfo({ success: true, text: 'SPA.DEVICE.EDIT.BEAT_EDIT_SUCCESS' });
    });
  }

  async changeRotation(device: DeviceFullInterface, rotation: DeviceRotationEnum) {
    const canRotate = await this.deviceNetwork.canRotate(device.id).toPromise();

    if (canRotate) {
      this.actualRotate(device, rotation);
      return;
    }

    const header = this.translateService.instant('SPA.DEVICE.ROTATE_MODAL.HEADER');
    const confirmation = await this.modalsService.confirm(header).toPromise();

    if (confirmation) {
      this.actualRotate(device, rotation);
    }
  }

  unassign(id: number, callback: () => void) {
    this.deviceNetwork.unassign(id)
      .subscribe(_ => {
        this.infoBoxService.pushInfo({ success: true, text: 'SPA.DEVICE.EDIT.CODE_REMOVE_SUCCESS' });
        callback();
      });
  }

  changeOrder(data: ProcessOrderInterface[]): Observable<boolean> {
    return this.deviceNetwork.changeOrder(this.lineId, data);
  }

  private async actualRotate(device: DeviceFullInterface, rotation: DeviceRotationEnum) {
    const result = await this.deviceNetwork.changeRotation(device.id, rotation).toPromise();

    if (result) {
      this.infoBoxService.success('SPA.DEVICE.ROTATE_MODAL.ROTATING', {deviceName: device.name});
    }
  }
}
