import { LineInterface } from '../../line/interfaces/line.interface';
import { LineBeatTypeEnum } from '../../line/enums/line-beat-type.enum';
import { LineTypeEnum } from '../../line/enums/line-type.enum';
import { DeviceTypeEnum } from '../enums/device-type.enum';

export class DeviceTypeUtil {
  static allowedDeviceTypeForLine(type: DeviceTypeEnum, line: LineInterface): boolean {
    switch (type) {
      case DeviceTypeEnum.NO_PRODUCTION:
        return line.beatTypeEnum === LineBeatTypeEnum.NO_BEAT;
        case DeviceTypeEnum.VIRTUAL_STATION:
        	return (line.typeEnum === LineTypeEnum.NO_BEAT || (line.typeEnum === LineTypeEnum.PRODUCTION && line.beatTypeEnum === 			LineBeatTypeEnum.FIXED_BEAT_LENGTH) || (line.typeEnum === LineTypeEnum.PRODUCTION_REWORK && line.beatTypeEnum === 			LineBeatTypeEnum.FIXED_BEAT_LENGTH));
      case DeviceTypeEnum.PRODUCTION:
        return line.typeEnum === LineTypeEnum.PRODUCTION;
      case DeviceTypeEnum.REWORK:
        return line.typeEnum === LineTypeEnum.REWORK;
      case DeviceTypeEnum.PRODUCTION_REWORK:
        return line.typeEnum === LineTypeEnum.PRODUCTION_REWORK;
      default:
        return false;
    }
  }
}