import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplatesComponent } from './templates/templates.component';
import { ExportComponent } from './export/export.component';
import { ReportsComponent } from './reports.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ReportsNetworkService } from './services/reports.network.service';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MomentDateModule} from '@angular/material-moment-adapter';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MomentDateModule,
    MaterialFileInputModule,
    MatFormFieldModule
  ],
  declarations: [
    ReportsComponent,
    ExportComponent,
    TemplatesComponent
  ],
  providers: [
    ReportsNetworkService
  ]
})
export class ReportsModule { }
