<div class="row">
    <page-header *ngIf="articleImportDate" title="{{'SPA.LINES.PARTS_IMPORT.HEADER' | translate:{date: articleImportDate | customDate} }}"></page-header>
    <div class="col s12">
        <div class="row">
            <div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" *ngIf="!import">
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                    <mat-label>{{ 'SPA.LINES.PARTS_IMPORT.SELECT_CSV' | translate }} *</mat-label>
                    <ngx-mat-file-input #fileInput [(ngModel)]="file" (ngModelChange)="importFileChange($event)" accept=".csv">
                    </ngx-mat-file-input>
                    <mat-icon matSuffix>folder</mat-icon>
                </mat-form-field>
            </div>
            <div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" *ngIf="!upload && !import">
                <button class="submit-btn" (click)="saveCSV()" color="primary" mat-raised-button>
                    {{ 'SPA.LINES.PARTS_IMPORT.SAVE_CSV' | translate }}
                </button>
                <button class="submit-btn" (click)="forceImport()" color="primary" mat-raised-button>
                    {{ 'SPA.LINES.PARTS_IMPORT.TRIGGER_IMPORT' | translate }}
                </button>
            </div>
            <mat-progress-bar *ngIf="upload && !(uploadPercentage === 100)" class="mt-10"
                              mode="determinate" [value]="uploadPercentage">
            </mat-progress-bar>
            <ngx-loading [show]="uploadPercentage === 100 || import" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
        </div>
    </div>
</div>
