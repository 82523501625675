<vex-secondary-toolbar>
  <vex-breadcrumbs [crumbs]="crumbs" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>
<div class="page-content">
  <div class="row ap-card shadow">
    <page-header titleTranslate="SPA.LINES.LINE_ADD.ADD_LINE"></page-header>
    <div class="row">
      <form #lineAddForm="ngForm" (ngSubmit)="save()">
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
          <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.LINES.LINE_ADD.LINE_NAME' | translate }}</mat-label>
            <input matInput required [(ngModel)]="line.name" [ngModelOptions]="{standalone: true}">
            <mat-error>{{ 'Please, enter valid line name' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
          <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.LINES.LINE_ADD.LINE_TYPE' | translate }}</mat-label>
            <mat-select required [(value)]="line.typeEnum" (selectionChange)="changeLineType()">
              <mat-option *ngFor="let typeKey of lineTypes | enumToArray" [value]="lineTypes[typeKey]">
                {{ 'ENUMS.LINE_TYPE.' + typeKey | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" fxLayoutAlign=" end">
          <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.LINES.LINE_ADD.BEAT_TYPE' | translate }}</mat-label>
            <mat-select required [(value)]="line.beatTypeEnum"
                        [disabled]="lineBeatTypesToChoose.length === 1">
              <mat-option *ngFor="let typeKey of lineBeatTypesToChoose" [value]="lineBeatTypes[typeKey]">
                {{ 'ENUMS.LINE_BEAT_TYPE.' + typeKey | translate }}
                
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="!isNoBeat()">
            <div>
              {{ 'SPA.LINES.LINE_ADD.BEAT_TIME' | translate }}
            </div>
            <div>
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary"
                *ngIf="!isNoBeat()" style="width:100px">
                    <mat-label>
                        {{ 'SPA.LINES.HOURS' | translate }}
                    </mat-label>
                    <input matInput type="Number" [(ngModel)]="line.hours" [ngModelOptions]="{standalone: true}" (change)="leadingZeros($event)"
                    (keyup)="leadingZeros($event)"
                    (click)="leadingZeros($event)" min='0'>
                    <mat-error>{{ 'SPA.LINES.LINE_BEAT_ERROR' | translate }}</mat-error>
                </mat-form-field>
                <div class="dot-text">:</div>
                  <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary"
                                    *ngIf="!isNoBeat()" style="width:100px">
                    <mat-label>
                        {{ 'SPA.LINES.MINUTES' | translate }}
                    </mat-label>
                    <input matInput type="Number" [(ngModel)]="line.minutes" [ngModelOptions]="{standalone: true}" (change)="leadingZeros($event)"
                            (keyup)="leadingZeros($event)"
                            (click)="leadingZeros($event)" min='0'>
                    <mat-error>{{ 'SPA.LINES.LINE_BEAT_ERROR' | translate }}</mat-error>
                  </mat-form-field>
                  <div class="dot-text">:</div>
                  <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary"
                                    *ngIf="!isNoBeat()" style="width:100px">
                    <mat-label>
                      {{ 'SPA.LINES.SECONDS' | translate }}
                    </mat-label>
                    <input matInput type="Number" [(ngModel)]="line.seconds" [ngModelOptions]="{standalone: true}" (change)="leadingZeros($event)"
                            (keyup)="leadingZeros($event)"
                            (click)="leadingZeros($event)" min='0'>
                    <mat-error>{{ 'SPA.LINES.LINE_BEAT_ERROR' | translate }}</mat-error>
                </mat-form-field>
            </div>
          </div>
          <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" *ngIf="showStationOrderTypes()">
            <mat-label>{{ 'SPA.LINES.LINE_ADD.STATION_ORDER' | translate }}</mat-label>
            <mat-select required [(value)]="line.stationOrderTypeEnum" [disabled]="disableStationOrderType()">
              <mat-option *ngFor="let stationOrderType of stationOrderTypesToChoose" [value]="stationOrderType">
                {{ 'ENUMS.STATION_ORDER.' + stationOrderTypes[stationOrderType] | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          fxLayout="row"
          class="content-row"
          fxLayout.lt-sm="column"
          fxLayoutGap="16px"
          fxLayoutGap.lt-sm="0"
          style="padding: 10px;width:300px;justify-content: space-between;"
        >      
          <mat-slide-toggle
            class="checkbox"
            [checked]="line.allowFractionValues === true"
            (change)="line.allowFractionValues = $event.checked"
          ></mat-slide-toggle>
          <mat-label>{{
            "SPA.LINES.LINE_ADD.ALLOW_FRACTION_VALUES" | translate
          }}</mat-label>
        </div>

        <div
          fxLayout="row"
          class="content-row"
          fxLayout.lt-sm="column"
          fxLayoutGap="16px"
          fxLayoutGap.lt-sm="0"
          style="padding: 10px;width:300px;justify-content: space-between;"
          *ngIf="canShowAllowAutomaticTransfer"
        >      
          <mat-slide-toggle
            class="checkbox"
            [checked]="line.allowAutomaticTransfer === true"
            (change)="line.allowAutomaticTransfer = $event.checked"
          ></mat-slide-toggle>
          <mat-label>{{
            "SPA.LINES.LINE_ADD.ALLOW_AUTOMATIC_TRANSFER" | translate
          }}</mat-label>
        </div>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="mt-10">
          <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
            <button color="primary" mat-raised-button type="submit" [disabled]="!lineAddForm.valid">
              {{ 'SPA.LINES.LINE_ADD.ADD_LINE' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
