<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{ 'SPA.DEVICE.IO_MAPPING.SELECT_ALARM' | translate }}</mat-label>
        <mat-select name='cAlarm' (selectionChange)='dataChanged("alarm")' [compareWith]="compareObjects" name='selectedCalarm'
            [(ngModel)]='selectedCalarm'>
            <mat-option *ngFor="let alarm of stationCAlarms" [value]="alarm">
                {{getTranslatedAlarm(alarm)}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{ 'SPA.DEVICE.IO_MAPPING.ALARM_BEHAVIOUR' | translate }}</mat-label>
        <mat-select name='alarmBehaviourType' (selectionChange)='dataChanged("alarmBehaviourType")'
            [compareWith]="compareObjects" [(ngModel)]='selectedBehaviour'>
            <mat-option *ngFor="let behaviourType of behaviourTypeList" [value]="behaviourType.id">
                {{ behaviourType?.name | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{ 'SPA.DEVICE.IO_MAPPING.INPUT_FOR_TRIGGERING_ALARM' | translate }}</mat-label>
        <input type="text"
               matInput
               [(ngModel)]='selectedInput'
               [formControl]="inputAutoComplete"
               [matAutocomplete]="auto"
               (focusout)="onFocusOut($event, 'selectedInput')"
               >
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayInputFn" (optionSelected)="dataChanged()">
            <mat-option *ngFor="let input of filteredInputOptions | async" [value]="input" [disabled]="isDisableInput(input)">
                {{input.name}}
            </mat-option>
        </mat-autocomplete>
        <mat-icon style="font-size:20px" [inline]="true" matSuffix>
            arrow_drop_down
        </mat-icon>
    </mat-form-field>

    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{ 'SPA.DEVICE.IO_MAPPING.OUTPUT_FOR_TRIGGERING_ALARM' | translate }}</mat-label>
        <input type="text"
               matInput
               [(ngModel)]='selectedOutput'
               [formControl]="outputAutoComplete"
               [matAutocomplete]="output"
               (focusout)="onFocusOut($event, 'selectedOutput')"
               >
        <mat-autocomplete #output="matAutocomplete" [displayWith]="displayOutputFn" (optionSelected)="dataChanged()">
            <mat-option *ngFor="let output of filteredOutputOptions | async" [value]="output" [disabled]="isDisableOutput(output)">
                {{output.name}}
            </mat-option>
        </mat-autocomplete>
        <mat-icon style="font-size:20px" [inline]="true" matSuffix>
            arrow_drop_down
        </mat-icon>
    </mat-form-field>

    <button class="deleteBtn" (click)='deleteMap()' color="warn" mat-raised-button
        type="button" style="height: 51px; margin-top: 2px;">
        <mat-icon inline="true">delete</mat-icon>
    </button>
</div>