import { Component, ElementRef, Input, OnChanges, Output, SimpleChanges, ViewChild, EventEmitter } from '@angular/core';
import { TimerUtil } from '../../utils/timer.util';
import { FontDataService } from './font-data.service';

@Component({
  selector: 'app-dynamic-font-container',
  templateUrl: './dynamic-font-container.component.html',
  styleUrls: ['./dynamic-font-container.component.scss'],
})
export class DynamicFontContainerComponent implements OnChanges {

  calculationDone = false;
  fontSize = 30;
  prevFontSize;
  increasingScale = 2;
  timerUtil;
  firstTimeCalculated = false;
  textContainer: ElementRef;
  @Input() padding = 6;
  @Input() text: string;
  @Input() height: number;
  @Input() width: number;
  @Input() delayStart = 0;
  @Input() editMode = false;
  @Input() containerAs100Percent = false;
  @Input() widgetId: number;
  @Input() name: string;
  @Input() saveCalculatedFont = true;
  @Input() translatable = true;
  @Input() calculateOnce = false;
  @Input() borderRadius = '0';
  @Input() isMoving = false;
  @Input() speed = 3;
  @Input() color:string;
  @Input() background:string;
  @Input() forcedFontSize: number;
  @Input() skipWidthCheck = false;
  @Input() changeFont = false;
  @Input() lineText = false;
  @Output() fontCalculated = new EventEmitter<number>();
  @ViewChild('componentContainer', {read: ElementRef, static: true}) componentContainer: ElementRef;
  @ViewChild('textContainer') set textContainerElem(textContainerElem: ElementRef) {
    this.textContainer = textContainerElem;
  }

  constructor(
      private fontConfigService: FontDataService,
  ) {
    this.timerUtil = new TimerUtil();
  }

  get widthStyle(): string {
    return this.containerAs100Percent ? '100%' : this.width + 'px';
  }

  get heightStyle(): string {
    return this.containerAs100Percent ? '100%' : this.height + 'px';
  }

  resize(event) {
    if (this.editMode && !this.changeFont) {
      this.calculationDone = false;
      this.width = event.newRect.width;
      this.height = event.newRect.height;
      this.startCalculation();
    }
    if(!this.editMode && this.changeFont){
      this.calculationDone = false;
      this.startCalculation();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.forcedFontSize) {
      this.fontSize = this.forcedFontSize;
      return;
    }

    if ((!this.calculateOnce || !this.firstTimeCalculated) && changes.text) {
      this.calculationDone = false;
      this.startCalculation();
      this.firstTimeCalculated = true;
    }

    if (this.editMode && (changes.width || changes.height)) {
      this.calculationDone = false;
      this.startCalculation();
    }
  }

  startCalculation() {
    if (this.calculationDone) {
      return;
    }
    const fromStorage = this.fontConfigService.get(this.getUniqueId());
    if (this.saveCalculatedFont && fromStorage) {
      this.fontSize = fromStorage;
      this.calculationDone = true;
      this.fontCalculated.emit(this.fontSize);
    }
    if (this.delayStart) {
      this.timerUtil.setTimer(this.delayStart, 1000, () => {
        this.delayStart = 0;
        this.timerUtil.stopTimer();
        this.startCalculation();
      });
      return;
    }
    setTimeout(() => {
      const textWidth = this.textContainer.nativeElement.offsetWidth;
      const textHeight = this.textContainer.nativeElement.offsetHeight;
      if(this.text != undefined || this.text != null){
        if ((this.skipWidthCheck || textWidth + this.padding < this.width) && textHeight + this.padding < this.height) {
            this.prevFontSize = this.fontSize;
            this.fontSize += this.increasingScale;
            this.startCalculation();
        } else if ((!this.skipWidthCheck && textWidth + this.padding > this.width) || textHeight + this.padding > this.height) {
          if (this.prevFontSize === this.fontSize - this.increasingScale) {
            this.finishCalculations();
            this.fontSize -= this.increasingScale;
            return;
          }

          this.prevFontSize = this.fontSize;
          this.fontSize -= this.increasingScale;
          this.startCalculation();
        } else {
          this.finishCalculations();
        }
      }
    });
  }

  private finishCalculations() {
    this.calculationDone = true;

    if (this.saveCalculatedFont) {
      this.fontConfigService.set(this.getUniqueId(), this.fontSize);
      this.fontCalculated.emit(this.fontSize);
    }
  }

  private getUniqueId(): string {
    const name = this.name ? this.name : this.text
    return this.widgetId + '-' + name;
  }
}
