<div class="card p-2 relative hover:shadow-16 trans-shadow"
     fxLayout="column"
     fxLayoutAlign="start center" [ngStyle]="{'background' : background}">
    <div [ngClass]="iconClass" class="icon rounded-full w-12 h-12 flex items-center justify-center" *ngIf="icon">
        <mat-icon >{{icon}}</mat-icon>
    </div>
    <div class="hide-to-long">
        <h2 class="display-2 font-bold mt-4 mb-2" *ngIf="value !== null" [ngStyle]="{'font-size' : valueFontSize + 'px'}">{{ value }}</h2>
    </div>
    <h3 class="subheading-2 font-medium text-secondary m-0" *ngIf="label !== null">{{ label }}</h3>

    <h4 [class.text-green]="change > 0" *ngIf="change !== null"
        [class.text-red]="change <= 0"
        class="subheading-1 font-medium mt-4 mb-0">
        <ic-icon *ngIf="change > 0"
                 [icon]="faCaretUp"
                 [inline]="true"
                 height="20px"
                 width="20px"></ic-icon>
        <ic-icon *ngIf="change <= 0"
                 [icon]="faCaretDown"
                 [inline]="true"
                 height="20px"
                 width="20px"></ic-icon>
        <span>{{ change }}%</span>
    </h4>
</div>
