import {Component, Inject} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
    styleUrls: ['confirmModal.component.scss'],
    templateUrl: 'confirmModal.component.html'
})
export class ConfirmModalComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { title, content, btnCancel, btnConfirm }) {
    }

    public onNoClick(): void {
        this.dialogRef.close(false);
    }
}