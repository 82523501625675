import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { ChartComponent as ApexChart } from 'ng-apexcharts';
import {GaugeChartSettingsInterface} from '../../interfaces/gauge-chart-settings.interface';
import {ColorsFormatUtil} from '../../../shared/utils/colors-format.util';
import {BasicConfiguratorWidget} from '../basic-configurator-widget';

@Component({
  selector: 'configurator-gauge-chart-widget',
  templateUrl: './configurator-gauge-chart-widget.component.html',
  styleUrls: ['./configurator-gauge-chart-widget.component.scss']
})
export class ConfiguratorGaugeChartWidgetComponent extends BasicConfiguratorWidget implements OnInit, OnChanges, OnDestroy {

    chartOptions;
    percent: number = 10;
    minValue = 0;
    maxValue = 100;
    chart: ApexChart;
    chartContainerRef: ElementRef;
    chartWidth;
    chartHeight;
    @Input() label: string;
    @Input() value: number = 50;
    @Input() height: number = 100;
    @Input() width: number = 100;
    @Input() widgetId: number;
    @Input() fontColor: string = 'white';
    @Input() onStation = false;
    @Input() settings: GaugeChartSettingsInterface;
    @Input() type:number;

    @ViewChild('chartComponent') set chartComponent(chartComponent: ApexChart) {
        this.chart = chartComponent;
        this.recalculateFontSize();
    }
    @ViewChild('chartContainer') set chartContainer(chartContainer: ElementRef) {
        this.chartContainerRef = chartContainer;
        this.reCalculateDefaultSizes();
    }

    constructor() {
        super();
    }

    roundValue(): string {
        return this.value ? this.type === 4?parseFloat(this.value.toString()).toFixed(2) + '%':parseFloat(this.value.toString()).toFixed(2) : this.type === 4?'0%':'0';
    }

    widgetResized() {
        this.reCalculateDefaultSizes();
    }

    private recalculateFontSize() {
        if (this.chart) {
            setTimeout(async () => {
                //@ts-ignore
                this.chartWidth = this.chart.chartElement.nativeElement.clientWidth;
                //@ts-ignore
                this.chartHeight = this.chart.chartElement.nativeElement.clientHeight;
            }, 100)
        }
    }

    async ngOnInit() {
        if (!this.hasSettings()) {
            this.settings = this.prepareDefaultSettings();
        }
        // this timeout, along with the async/awaits is due to some weird apex errors
        setTimeout(() => {
            this.chartOptions = {
                chart: {
                    height: Math.min(this.height, this.width),
                    type: "radialBar",
                    sparkline: {
                        enabled: true
                      },
                },

                plotOptions: {
                    radialBar: {
                        startAngle: -115,
                        endAngle: 115,
                        hollow: {
                            size: "65%",
                        },
                        dataLabels: {
                            show: false,
                        },

                    }
                },
                fill: {
                    type: "gradient",
                    colors: ['#04B2D9'],
                    gradient: {
                        shade: "dark",
                        type: "horizontal",
                        shadeIntensity: 0,
                        gradientToColors: ["#1d62e8"],
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0]
                    }
                },
                stroke: {
                    lineCap: "round"
                },
                labels: [],
            };
        }, 300);
    }

    hasSettings(): boolean {
        return !!this.settings && !!this.settings.colors && !!this.settings.colors.length;
    }

    prepareDefaultSettings() {
        return {
            lineId: null,
            fontSize: 15,
            colors: [{
                from: 0,
                to: 100,
                backgroundColor: '#04B2D9'
            }]
        };
    }

    private getColorAccordingToPercent(percent: number): string {
        let color = '#04B2D9';
        for (let colorData of this.settings.colors) {
            if (colorData.from <= percent && colorData.to >= percent) {
                color = colorData.backgroundColor;
            }
        }

        return color;
    }

    private async reCalculateDefaultSizes() {
        if (this.chartContainerRef) {
            setTimeout(async () => {
                this.width = this.chartContainerRef.nativeElement.clientWidth;
                this.height = this.chartContainerRef.nativeElement.clientHeight;
                if (!this.chart || this.height === 0 || this.width === 0) {
                    return;
                }

                await this.chart.updateOptions({
                    chart: {
                        height: Math.min(this.height, this.width),
                    }
                });
            }, 500);
        }
    }

    private async updateChartTime() {
        let max;
        if(this.value > 100){
            max = this.value - this.minValue;
        }else{
            max = this.maxValue - this.minValue;
        }
        const size = this.value - this.minValue;
        this.percent = (size / max) * 100;
        if (!this.chart) {
            return;
        }

        const currentColor = this.getColorAccordingToPercent(this.percent);

        await this.chart.updateOptions({
            fill: {
                colors: [currentColor],
                gradient: {
                    gradientToColors: [ColorsFormatUtil.hexToRgba(currentColor, 0.7)],
                }
            },
        });
    }

    private async calcMinMax() {
        this.minValue = this.settings.colors[0].from;
        this.maxValue = this.settings.colors[this.settings.colors.length - 1].to;
        await this.updateChartTime();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.value && changes.value != undefined) {
            await this.updateChartTime();
        }
        if (changes.settings && changes.settings.currentValue) {
            await this.calcMinMax();
            super.ngOnChanges(changes);
            this.calculateFontSize();
        }
    }

    calculateFontSize(){
        let height = this.chartHeight * 0.2;
        let width = this.chartWidth * 0.4;
        let buttonText = [];
        buttonText.push(this.roundValue());
        buttonText.push(this.label);

        
        let largeText = buttonText[0];
        if(buttonText.length > 0){
            buttonText.forEach((text)=>{
                if(text?.length > largeText?.length){
                    largeText = text;
                }
            })
        }

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext('2d');
        let text = ctx.measureText(largeText);
        if(width / (text.width * 0.11) < height * 0.5){
            return width / (text.width * 0.11) + 'px';
        }else{
            return height * 0.5 + 'px';
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
