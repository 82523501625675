import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { 
	chartFilterKey, 
	dashboardFilterKey,
	ioModuleFilterKey,
	productItemsFilterKey,
	productItemDataCollectionFilterKey,
	dashboardInternalStationFilterKey,
	dashboardInternalChartFilterKey,
	currentAlarmFilterKey,
	historicAlarmFilterKey,
	eventsAndActionFilterKey
 } from '../../filters/constants/filter-key.constants';
import { ChartsFiltersInterface } from '../../filters/interfaces/charts-filters.interface';
import { DashboardFiltersInterface } from '../../filters/interfaces/dashboard-filters.interface';
import { IoFiltersInterface } from '../../filters/interfaces/io-filters.interface';
import { ProductItemsFiltersInterface } from '../../filters/interfaces/product-item-filters.interface';
import { DashboardInternalStationFiltersInterface } from '../../filters/interfaces/dashboard-internal-station-filters.interface';
import { DashboardInternalChartFiltersInterface } from '../../filters/interfaces/dashboard-internal-chart-filters.interface';
import { ProductItemsDataCollectionFiltersInterface } from '../../filters/interfaces/product-item-data-collection-filters.interface';
import { CurrentAlarmFiltersInterface } from '../../filters/interfaces/current-alarm-filters.interface';
import { HistoricAlarmFiltersInterface } from '../../filters/interfaces/historic-alarm-filters.interface';
import { EventsAndActionFiltersInterface } from '../../filters/interfaces/events-and-action-filters.interface';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

@Injectable()
export class SaveFilterService {

	constructor(private http: HttpClient) {
	}

	createObject(key: string, data: any) {
		let finalObject: any = null;
		switch (key) {
			// chart page
			case chartFilterKey:
				const updatedChartFilters: ChartsFiltersInterface = {
					from: data.timePeriod.begin,
					range: data.range,
					to: data.timePeriod.end,
					lineId: data.lineId,
					stationId: data.stationId,
				};
				finalObject = {
					key: chartFilterKey,
					filter: updatedChartFilters
				};
				this.save(finalObject).toPromise();
				break;
			
			// global dashboard page
			case dashboardFilterKey:
				const updatedDashboardFilters: DashboardFiltersInterface = {
					status: data,
				};
				finalObject = {
					key: dashboardFilterKey,
					filter: updatedDashboardFilters
				};
				this.save(finalObject).toPromise();
				break;
			
			// IO module page
			case ioModuleFilterKey:
				const updatedIoFilters: IoFiltersInterface = {
					status: data,
				};
				finalObject = {
					key: ioModuleFilterKey,
					filter: updatedIoFilters
				};
				this.save(finalObject).toPromise();
				break;
			
			// Product Item listing page
			case productItemsFilterKey:
				const updatedProductItemFilters: ProductItemsFiltersInterface = {
					startDate: data.startDate,
					endDate: data.endDate,
					lineId: data.lineId,
					stationId: data.stationId,
					typeEnums: data.typeEnums,
				};
				finalObject = {
					key: productItemsFilterKey,
					filter: updatedProductItemFilters
				};
				this.save(finalObject).toPromise();
				break;
			
			// Product Item details data collection page
			case productItemDataCollectionFilterKey:
				const updatedProductItemDataCollectionFilters: ProductItemsDataCollectionFiltersInterface = {
					worksteps: data.worksteps.selection,
					types: data.types.selection,
					revisions: data.revisions.selection,
					users: data.users.selection,
					stations: data.stations.selection,
					lines: data.lines.selection,
					dateFrom: data.dateFrom ? moment(data.dateFrom) : null,
					dateTo: data.dateTo ? moment(data.dateTo) : null,
				};
				finalObject = {
					key: productItemDataCollectionFilterKey,
					filter: updatedProductItemDataCollectionFilters
				};
				this.save(finalObject).toPromise();
				break;

			// Dashboard internal page - station filter
			case dashboardInternalStationFilterKey:
				const updatedDashboardStationFilters: DashboardInternalStationFiltersInterface = {
					status: data
				};
				finalObject = {
					key: dashboardInternalStationFilterKey,
					filter: updatedDashboardStationFilters
				};
				this.save(finalObject).toPromise();
				break;

			// Dashboard internal page - chart filter
			case dashboardInternalChartFilterKey:
				const updatedDashboardChartFilters: DashboardInternalChartFiltersInterface = {
					from: moment(data.from).toISOString(),
					to: moment(data.to).toISOString(),
					typeEnum: data.typeEnum.toString()
				};
				finalObject = {
					key: dashboardInternalChartFilterKey,
					filter: updatedDashboardChartFilters
				};
				this.save(finalObject).toPromise();
				break;
			
			// Alarm Dashboard - current alarm
			case currentAlarmFilterKey:
				const updatedCurrentAlarmFilters: CurrentAlarmFiltersInterface = {
					productionSector: data?.productionSector || null,
					station: data?.station || null,
					status: data.hasOwnProperty('status') ? data.status : null,
				};
				finalObject = {
					key: currentAlarmFilterKey,
					filter: updatedCurrentAlarmFilters
				};
				this.save(finalObject).toPromise();
				break;

			// Alarm Dashboard - historic alarm
			case historicAlarmFilterKey:
					const updatedHistoricAlarmFilters: HistoricAlarmFiltersInterface = {
						productionSector: data?.productionSector || null,
						station: data?.station || null
					};
					finalObject = {
						key: historicAlarmFilterKey,
						filter: updatedHistoricAlarmFilters
					};
					this.save(finalObject).toPromise();
					break;

			// Events and Action listing filter
			case eventsAndActionFilterKey:
				const updatedEventsFilters: EventsAndActionFiltersInterface = {
					status: data?.types || null,
				};
				finalObject = {
					key: eventsAndActionFilterKey,
					filter: updatedEventsFilters
				};
				this.save(finalObject).toPromise();
				break;
		}
	}

	// function to save the filters in the database
	save(data: any): Observable<any> {
		return this.http.patch<any>(
			`/api/user/filter/edit`,
			data
		);
	}

	// function to get the filters back for the specific page
	getFiltersUsingKey(key: string): Observable<any> {
		return this.http.get<any>(`/api/user/filter/${key}`);
	}

}
