import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationService } from './services/configuration.service';
import { ConfigurationComponent } from './configuration.component';
import {SharedModule} from '../shared/shared.module';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ApFormsModule} from '../shared/forms/apForms.module';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';

@NgModule({
    declarations: [
        ConfigurationComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        RouterModule,
        ApFormsModule,
        MatSlideToggleModule
    ],
    exports: [],
    providers: [
        ConfigurationService,
    ],
})
export class ConfigurationModule {}