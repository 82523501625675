<vex-secondary-toolbar>
    <vex-breadcrumbs [crumbs]="crumbs" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>
<div class="page-content">
    <div class="row ap-card shadow">
        <div class="h-full flex flex-col">
            <div class="calendar-bg relative text-contrast-white flex-none">
                <div class="bg-contrast-black opacity-25 absolute top-0 left-0 right-0 bottom-0 z-0 w-full h-full"></div>
                <div class="container py-4 px-6 h-40 z-10 relative flex items-end">
                    <div class="flex-auto flex flex-col sm:flex-row justify-between items-center">
                        <div class="headline">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</div>

                        <div class="actions flex flex-col sm:flex-row justify-end items-center">
                            <div class="chevrons">
                                <button [(viewDate)]="viewDate" (click)="calendarNavigationCalled()" (viewDateChange)="dateChanged()" [view]="view" mat-icon-button mwlCalendarPreviousView>
                                    <mat-icon>keyboard_arrow_left</mat-icon>
                                </button>
                                <button [(viewDate)]="viewDate" [view]="view" (viewDateChange)="dateChanged()" (click)="calendarNavigationCalled()" mat-icon-button mwlCalendarNextView>
                                    <mat-icon>keyboard_arrow_right</mat-icon>
                                </button>
                            </div>
                            <div class="flex">
                                <button (click)="view = CalendarView.Month" mat-button>MONTH</button>
                                <button (click)="view = CalendarView.Week" mat-button>WEEK</button>
                                <button (click)="view = CalendarView.Day" mat-button>DAY</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <vex-scrollbar [ngSwitch]="view" class="px-0 shadow flex-auto relative" vexContainer>
                <mwl-calendar-month-view
                        (dayClicked)="dayClicked($event.day)"
                        (eventClicked)="editEvent($event.event)"
                        *ngSwitchCase="'month'"
                        [activeDayIsOpen]="activeDayIsOpen"
                        [events]="shiftService.events"
                        [refresh]="refresh"
                        [cellTemplate]="defaultTemplate"
                        [openDayEventsTemplate]="activeDayTemplate"
                        [weekStartsOn]="1"
                        [locale]="actualLang()"
                        [viewDate]="viewDate">
                    <ng-template
                            #defaultTemplate
                            let-day="day"
                            let-openDay="openDay"
                            let-locale="locale"
                            let-tooltipPlacement="tooltipPlacement"
                            let-highlightDay="highlightDay"
                            let-unhighlightDay="unhighlightDay"
                            let-eventClicked="eventClicked"
                            let-tooltipTemplate="tooltipTemplate"
                            let-tooltipAppendToBody="tooltipAppendToBody">
                        <div class="cal-cell-top">
                            <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
                        </div>
                        <div class="cal-events" *ngIf="day.events.length > 0">
                            <div
                                    class="cal-event"
                                    *ngFor="let event of day.events; trackBy:trackByEventId"
                                    [style.backgroundColor]="event.color?.primary"
                                    [ngClass]="event?.cssClass"
                                    (mouseenter)="highlightDay.emit({event: event})"
                                    (mouseleave)="unhighlightDay.emit({event: event})"
                                    [mwlCalendarTooltip]="event.shift.name | calendarEventTitle:'monthTooltip':event"
                                    [tooltipPlacement]="tooltipPlacement"
                                    [tooltipEvent]="event"
                                    [tooltipTemplate]="tooltipTemplate"
                                    [tooltipAppendToBody]="tooltipAppendToBody"
                                    mwlDraggable
                                    [class.cal-draggable]="event.draggable"
                                    dragActiveClass="cal-drag-active"
                                    [dropData]="{event: event, draggedFrom: day}"
                                    [dragAxis]="{x: event.draggable, y: event.draggable}"
                                    (mwlClick)="eventClicked.emit({ event: event })">
                            </div>
                        </div>
                    </ng-template>
                    <ng-template
                            #activeDayTemplate
                            let-events="events"
                            let-eventClicked="eventClicked"
                            let-isOpen="isOpen">
                        <div class="cal-open-day-events" *ngIf="isOpen">
                            <div *ngFor="let event of events; trackBy:trackByEventId"
                                    [ngClass]="event?.cssClass"
                                    mwlDraggable
                                    [class.cal-draggable]="event.draggable"
                                    dragActiveClass="cal-drag-active"
                                    [dropData]="{event: event}"
                                    [dragAxis]="{x: event.draggable, y: event.draggable}">
                                      <span
                                              class="cal-event"
                                              [style.backgroundColor]="event.color?.primary">
                                      </span>
                                        &ngsp;
                                    <mwl-calendar-event-title
                                            [event]="event"
                                            [customTemplate]="eventTitleTemplate"
                                            view="month"
                                            (mwlClick)="eventClicked.emit({event: event})">
                                    </mwl-calendar-event-title>
                                        &ngsp;
                                    <mwl-calendar-event-actions
                                            [event]="event"
                                            [customTemplate]="eventActionsTemplate">
                                    </mwl-calendar-event-actions>
                                    <ng-template #eventTitleTemplate let-event="event" let-view="view">
                                          <span class="cal-event-title"
                                                  [attr.aria-hidden]="{} | calendarA11y: 'hideEventTitle'"
                                          ><strong>{{event.shift.name}}</strong> {{event.start | customDate:'DD.MM HH:mm'}} - {{event.end | customDate:'DD.MM HH:mm'}}
                                          </span>
                                    </ng-template>
                            </div>
                            <div class="add-shift" *ngIf="events.length > 0 && showAddShiftButton">
                                <button type="button" class="bg-blue" mat-raised-button (click)="addShift()">{{ 'SPA.SHIFTS.ADD_SHIFT' | translate }}</button>
                            </div>
                        </div>
                    </ng-template>
                </mwl-calendar-month-view>
                <mwl-calendar-week-view
                        (hourSegmentClicked)="timeClicked($event.date)"
                        (eventClicked)="editEvent($event.event)"
                        *ngSwitchCase="'week'"
                        [events]="shiftService.events"
                        [refresh]="refresh"
                        [weekStartsOn]="1"
                        [eventTitleTemplate]="eventTitleTemplate"
                        [viewDate]="viewDate">
                    <ng-template #eventTitleTemplate let-event="event" let-view="view">
                          <span class="cal-event-title"
                                [attr.aria-hidden]="{} | calendarA11y: 'hideEventTitle'"
                          ><strong>{{event.shift.name}}</strong> {{event.start | customDate:'HH:mm'}} - {{event.end | customDate:'HH:mm'}}
                          </span>
                    </ng-template>
                </mwl-calendar-week-view>
                <mwl-calendar-day-view
                        (hourSegmentClicked)="timeClicked($event.date)"
                        (eventClicked)="editEvent($event.event)"
                        *ngSwitchCase="'day'"
                        [events]="shiftService.events"
                        [refresh]="refresh"
                        [eventTitleTemplate]="eventTitleTemplate"
                        [viewDate]="viewDate">
                    <ng-template #eventTitleTemplate let-event="event" let-view="view">
                          <span class="cal-event-title"
                                [attr.aria-hidden]="{} | calendarA11y: 'hideEventTitle'"
                          ><strong>{{event.shift.name}}</strong> {{event.start | customDate:'HH:mm'}} - {{event.end | customDate:'HH:mm'}}
                          </span>
                    </ng-template>
                </mwl-calendar-day-view>
            </vex-scrollbar>
        </div>
        <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
    </div>
</div>
