<vex-secondary-toolbar>
    <vex-breadcrumbs [crumbs]="crumbs" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>
<div class="page-content">
    <div class="row ap-card shadow">
        <div class="col s12">
            <div class="header" (click)="showFilters = !showFilters">
                <page-header titleTranslate="COMMON.FILTERS"></page-header>
                <mat-icon>{{showFilters ? 'expand_more' : 'chevron_right'}}</mat-icon>
            </div>
            <ng-container *ngIf="showFilters">
                <div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                        <mat-label>{{ 'SPA.CHARTS.RANGE' | translate }}</mat-label>
                        <mat-select [(ngModel)]="filters.range" (selectionChange)="rangeChange($event.value)">
                            <mat-option [value]="">{{ 'SPA.CHARTS.SELECT_RANGE' | translate }}</mat-option>
                            <mat-option value="YESTERDAY">{{ 'SPA.CHARTS.YESTERDAY' | translate }}</mat-option>
                            <mat-option value="CURRENT_WEEK">{{ 'SPA.CHARTS.CURRENT_WEEK' | translate }}</mat-option>
                            <mat-option value="LAST_WEEK">{{ 'SPA.CHARTS.LAST_WEEK' | translate }}</mat-option>
                            <mat-option value="CURRENT_MONTH">{{ 'SPA.CHARTS.CURRENT_MONTH' | translate }}</mat-option>
                            <mat-option value="LAST_MONTH">{{ 'SPA.CHARTS.LAST_MONTH' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                        <mat-label>{{ 'SPA.CHARTS.FROM' | translate }}</mat-label>
                        <input type="text" matInput [matDatepicker]="picker"
                               [(ngModel)]="filters.timePeriod.begin" (ngModelChange)="changeBeginDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                        <mat-label>{{ 'SPA.CHARTS.TO' | translate }}</mat-label>
                        <input type="text" matInput [matDatepicker]="picker2"
                               [(ngModel)]="filters.timePeriod.end" (ngModelChange)="changeEndDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
                <div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                        <mat-label>{{ 'SPA.CHARTS.LINE' | translate }}</mat-label>
                        <mat-select [(ngModel)]="filters.lineId" (ngModelChange)="changeLine($event)">
                            <mat-option *ngFor="let line of lines" [value]="line.id">{{line.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                        <mat-label>{{ 'SPA.CHARTS.STATIONS' | translate }}</mat-label>
                        <mat-select [(ngModel)]="filters.stationId">
                            <mat-option [value]="null">{{ 'SPA.CHARTS.ALL' | translate }}</mat-option>
                            <mat-option *ngFor="let station of stations" [value]="station.id">{{station.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button class="submit-btn" (click)="resetFilters()"
                            color="warn" mat-raised-button>{{ 'SPA.CHARTS.RESET' | translate }}
                    </button>
                    <button class="submit-btn" [disabled]="disableFilterButton()" (click)="changeFilters()"
                            color="primary" mat-raised-button>{{ 'SPA.CHARTS.UPDATE' | translate }}
                    </button>
                </div>
            </ng-container>


            <app-apex-chart *ngFor="let chart of charts" [chart]="chart" [allCharts]="charts"></app-apex-chart>
            <app-disorder-list></app-disorder-list>
            <app-line-stop-list></app-line-stop-list>
        </div>
    </div>
</div>
<div hidden="true" id="content"></div>