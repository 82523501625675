import {Component, OnInit, ViewChild} from '@angular/core';
import {AEGroupInterface} from "../groups/interfaces/a-e-group.interface";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatSort} from "@angular/material/sort";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";

@Component({
    selector: 'app-alarms',
    templateUrl: './alarms.component.html',
    styleUrls: ['./alarms.component.scss']
})
export class AlarmsComponent implements OnInit {

    constructor(private matDialog: MatDialog) {

    }

    ngOnInit(): void {
    }


}
