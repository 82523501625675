<div class="disorder-row" [formGroup]="disorderForm">
    <div class="code">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.ALARMS_ESCALATIONS.DISORDERS.CODE' | translate }}</mat-label>
            <input matInput required formControlName="code" id="code">
            <span class="helper-text" [attr.data-error]="'SPA.ALARMS_ESCALATIONS.INPUT_ERROR' | translate"></span>
        </mat-form-field>
    </div>
    <div class="reason">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.ALARMS_ESCALATIONS.DISORDERS.REASON' | translate }}</mat-label>
            <input matInput required formControlName="reason" id="reason">
            <span class="helper-text" [attr.data-error]="'SPA.ALARMS_ESCALATIONS.INPUT_ERROR' | translate"></span>
        </mat-form-field>
    </div>
    <div class="lines">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" *ngIf="!isChild">
            <mat-label>{{ 'SPA.ALARMS_ESCALATIONS.LINES' | translate }}</mat-label>
            <mat-select formControlName="lineId" required>
                <mat-option *ngFor="let line of aeService.lines" [value]="line.id">{{line.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="stations">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" *ngIf="!isChild">
            <mat-label>{{ 'SPA.ALARMS_ESCALATIONS.STATIONS' | translate }}</mat-label>
            <mat-select formControlName="stationIds" required multiple>
                <mat-option *ngFor="let station of stations" [value]="station.id">{{station.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="actions mb-5">
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
            <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
                <button color="primary" mat-raised-button [disabled]="!formValid()" type="button" (click)="save()"> {{ 'SPA.ALARMS_ESCALATIONS.SAVE_BTN' | translate }}</button>
                <button color="warn" mat-button (click)="cancel()"> {{ 'SPA.ALARMS_ESCALATIONS.CANCEL_BTN' | translate }}</button>
            </div>
        </div>
    </div>
</div>
