<vex-secondary-toolbar>
    <vex-breadcrumbs [crumbs]="crumbs" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>
<div class="page-content">
    <div class="row ap-card shadow flex-col">
        <page-header titleTranslate="SPA.MQTT_CONNECTION_DETAILS.LABEL"></page-header>
        <div class="row">
            <div class="col s12">
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" *ngIf="data">
                    <mat-form-field fxFlex="auto"  appearance="outline"  class="vex-flex-form-field">
                        <mat-label>{{'SPA.MQTT_CONNECTION_DETAILS.USERNAME' | translate}}</mat-label>
                        <input type="text"
                           matInput
                           [(ngModel)]="data.username"
                           disabled
                        >
                    </mat-form-field>
                    <mat-form-field fxFlex="auto"  appearance="outline"  class="vex-flex-form-field">
                        <mat-label>{{'SPA.MQTT_CONNECTION_DETAILS.PASSWORD' | translate}}</mat-label>
                        <input type="text"
                               matInput
                               [(ngModel)]="data.password"
                               disabled
                        >
                    </mat-form-field>
                    <mat-form-field fxFlex="auto"  appearance="outline"  class="vex-flex-form-field">
                        <mat-label>{{'SPA.MQTT_CONNECTION_DETAILS.PORT' | translate}}</mat-label>
                        <input type="text"
                               matInput
                               [(ngModel)]="data.port"
                               disabled
                        >
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
            <button color="warn" mat-button type="button" (click)="goBack()"> {{ 'COMMON.BACK' | translate }}</button>
        </div>
    </div>
</div>
