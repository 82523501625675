export class SocketChannel {
    static SHIFT = 'shift';
    static ALARMS_AND_ESCALATIONS = 'alarms_and_escalations';
    static DEVICE = 'device';
    static FTP = 'ftp';
    static WORK_INSTRUCTIONS = 'work_instructions';
    static PRODUCT = 'product';
    static BLOCK = 'block';
    static IO_MODULE = 'io_module';
    static MQTT_MODULE = 'mqtt_module';
    static USER_EVENTS = 'user_events';
    static WORKSTEP = 'workstep';
    static SLIDESHOW = 'slideshow';
    static DASHBOARD = 'dashboard';
    static TIMEZONE = 'timezone';
    static CURRENT_TIME = 'current_time'
    static STATION_INFO = 'station_info'
    static SCREWING_MACHINE = 'screwing_machine';
    static ORDER_CONFIGURATION = 'order_configuration';
    
    static SETTINGS(id: number): string {
        return `SETTING${id}`;
    }

    static SHIFT_CALCULATIONS(lineId: number): string {
        return `shift_calculations_${lineId}`;
    }
}