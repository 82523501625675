import { NgModule } from '@angular/core';
import { ChartsAndTablesService } from './charts-and-tables.service';
import { ChartsAndTablesComponent } from './charts-and-tables.component';
import { DisorderListComponent } from './disorder-list/disorder-list.component';
import { LineStopListComponent } from './line-stop-list/line-stop-list.component';
import { SharedModule } from '../shared/shared.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import { CsvParserService } from '../../../common/csv/services/csvParser.service';
import { ApexChartComponent } from './apex-chart/apex-chart.component';
import {ChartsAndTablesDataService} from './services/charts-and-tables-data.service';
import {ChartsAndTablesDataNetwork} from './networks/charts-and-tables-data-network.service';
import {ChartModule} from '../../@vex/components/chart/chart.module';

@NgModule({
  imports: [
    MatSelectModule,
    SharedModule,
    MatDatepickerModule,
    FormsModule,
    NgChartsModule,
    ChartModule
  ],
  exports: [],
  declarations: [
    ChartsAndTablesComponent,
    DisorderListComponent,
    LineStopListComponent,
    ApexChartComponent,
  ],
  providers: [
    ChartsAndTablesService,
    CsvParserService,
    ChartsAndTablesDataService,
    ChartsAndTablesDataNetwork
  ],
})
export class ChartsAndTablesModule { }
