<page-header titleTranslate="SPA.EXPORTS.TEMPLATE_BTN"></page-header>

<div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{ 'SPA.TEMPLATES.NAME' | translate }}</mat-label>
        <input matInput required [(ngModel)]="templateName">
    </mat-form-field>
    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{ 'SPA.TEMPLATES.TEMPLATE' | translate }} *</mat-label>
        <ngx-mat-file-input #fileInput [(ngModel)]="file" (ngModelChange)="fileAdded($event)" accept=".xlsx, .xls">
        </ngx-mat-file-input>
        <mat-icon matSuffix>folder</mat-icon>
    </mat-form-field>
    <button class="submit-btn" [disabled]="!canSubmit()" (click)="onSubmit()" color="primary" mat-raised-button>{{ 'SPA.TEMPLATES.CREATE_BTN' | translate }}</button>
</div>
<div class="table" *ngIf="templates.length > 0">
    <div class="table-row table-header">
        <div class="column-first">{{ 'SPA.TEMPLATES.NAME' | translate }}</div>
        <div class="column">{{ 'SPA.TEMPLATES.FILE_EXTENSION' | translate }}</div>
        <div class="column"></div>
    </div>
    <div class="table-row body-row" *ngFor="let template of templates">
        <div class="column column-first">{{template.name}}</div>
        <div class="column">{{template.extension}}</div>
        <div class="column actions">
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <button color="primary" mat-raised-button type="button" (click)="downloadTemplate(template)">
                    <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                              inline="true">save</mat-icon>
                </button>
                <button color="warn" mat-raised-button type="button" (click)="delete(template)">
                    <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                              inline="true">delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
</div>
