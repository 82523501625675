import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-actual-mobile-version',
  templateUrl: './actual-mobile-version.component.html',
  styleUrls: ['./actual-mobile-version.component.scss']
})
export class ActualMobileVersionComponent implements OnInit {

  uploadedFileName: string;
  constructor(
      private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.getAppInfo();
  }

  getAppInfo() {
    this.http.get('/api/mobile-app').subscribe((result: any) => {
      this.uploadedFileName = result?.name || null;
    });
  }
}
