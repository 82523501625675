import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../_guards/auth.guard';
import { WorkInstructionsComponent } from './work-instructions.component';
import { WorkInstructionsAddComponent } from './work-instructions-add/work-instructions-add.component';
import { WorkInstructionsViewComponent } from './work-instructions-view/work-instructions-view.component';
import { UserPermissionsEnum } from '../../../common/auth/enums/user-permissions.enum';


export const WORK_INSTRUCTIONS_ROUTES: Routes = [
    {
        path: '',
        component: WorkInstructionsComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: [UserPermissionsEnum.WorkInstructionsManagement],
        }
    },
    {
        path: 'add',
        component: WorkInstructionsAddComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: [UserPermissionsEnum.WorkInstructionsManagement],
        }
    },
    {
        path: 'view/:id',
        component: WorkInstructionsViewComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: [UserPermissionsEnum.WorkInstructionsManagement],
        }
    },
];
