export enum ProductItemTypeEnum {
  PENDING = 1,
  IN_PROGRESS = 2,
  REWORK = 3,
  FAILURE = 4,
  DONE = 5,
  TRASH = 6,
  MARKED_FOR_REWORK = 7,
  READY_FOR_TEST = 8,
  BETWEEN_LINES = 9,
  BETWEEN_STATIONS = 10,
  BEING_RETRANSFERED = 11,
}