<div class="toolbar w-full px-gutter" fxLayout="row" fxLayoutAlign="start center" vexContainer>
  <button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
    <mat-icon [icIcon]="icMenu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async"
     [routerLink]="['/']"
     class="ltr:mr-4 rtl:ml-4 block"
     fxLayout="row"
     fxLayoutAlign="start center">
    <img class="w-8 select-none" src="assets/img/demo/logo.svg">
    <h1 [fxHide]="mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">VEX</h1>
  </a>

  <!--<button *ngIf="isHorizontalLayout$ | async"-->
          <!--[fxHide]="mobileQuery"-->
          <!--[matMenuTriggerFor]="addNewMenu"-->
          <!--color="primary"-->
          <!--mat-raised-button-->
          <!--type="button">ADD NEW-->
  <!--</button>-->

  <mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
    <button mat-menu-item>
      <mat-icon [icIcon]="icPersonAdd"></mat-icon>
      <span>Add Contact</span>
    </button>

    <button mat-menu-item>
      <mat-icon [icIcon]="icDoneAll"></mat-icon>
      <span>Add Task</span>
    </button>

    <button mat-menu-item>
      <mat-icon [icIcon]="icAssignmentTurnedIn"></mat-icon>
      <span>Add Project</span>
    </button>

    <button [matMenuTriggerFor]="documentMenu" mat-menu-item>
      <mat-icon [icIcon]="icBallot"></mat-icon>
      <span>Add Document</span>
    </button>
  </mat-menu>

  <mat-menu #documentMenu="matMenu">
    <button mat-menu-item>
      <mat-icon [icIcon]="icDescription"></mat-icon>
      <span>Add Quote</span>
    </button>

    <button mat-menu-item>
      <mat-icon [icIcon]="icAssignment"></mat-icon>
      <span>Add Invoice</span>
    </button>

    <button mat-menu-item>
      <mat-icon [icIcon]="icReceipt"></mat-icon>
      <span>Add Receipt</span>
    </button>
  </mat-menu>

  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [fxHide]="mobileQuery"
       class="px-gutter"
       fxFlex="none"
       fxLayout="row"
       fxLayoutAlign="start center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div>

  <span fxFlex></span>

  <div class="-mx-1 flex items-center">

    <!--<div class="px-1">-->
      <!--<vex-toolbar-notifications></vex-toolbar-notifications>-->
    <!--</div>-->

    <!--<div class="px-1">-->
      <!--<button (click)="openQuickpanel()" mat-icon-button type="button">-->
        <!--<mat-icon [icIcon]="icBookmarks"></mat-icon>-->
      <!--</button>-->
    <!--</div>-->
    <div class="px-1">
      <app-clock></app-clock>
    </div>

    <div class="px-1">
      <button class="lang-btn" [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
        <img class="langIcon" [src]="langImgSrc">
      </button>
    </div>

    <div class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>

    <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">

      <button mat-menu-item *ngFor="let lang of languages | stringEnumToArray" (click)="langChange(languages[lang])">
        <img class="langIcon" [src]="setIcon(languages[lang])">
        <span>{{ 'SPA.LANGUAGE_TYPE.'+lang | translate}}</span>
      </button>
    </mat-menu>
  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [fxHide]="mobileQuery"></vex-navigation>
