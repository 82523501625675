import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginResponseInterface } from '../../../common/auth/interfaces/login-response.interface';

@Injectable()
export class LoginLogoutService {

    constructor(
        private http: HttpClient,
    ) {
    }

    login(model: any): Observable<LoginResponseInterface> {
        return this.http.post<LoginResponseInterface>('/api/login', model);
    }

    getNewToken(): Observable<LoginResponseInterface> {
        return this.http.get<LoginResponseInterface>('/api/login/new-token');
    }

    logout(automatic: boolean) {
        const urlMiddle = automatic ? 'automatic/' : '';
        const url = `/logout/${urlMiddle}spa`;

        return this.http.delete(url);
    }
}
