import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { IoTypesUtil } from '../../../../../../common/io-module/utils/io-types.util';
import { map, Observable, startWith } from 'rxjs';
import { CommonService } from '../../../../../../common/shared/services/common.service';
import { AlarmBehaviourTypeEnum } from '../../../../../../common/station-mapping/enums/alarm-behaviour-type.enum';
import { AesStationsIoMappingService } from '../aes-stations-io-mapping.service';

@Component({
  selector: 'app-aes-stations-io-mapping-row',
  templateUrl: './aes-stations-io-mapping-row.component.html',
  styleUrls: ['./aes-stations-io-mapping-row.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AesStationsIoMappingRowComponent,
      multi: true
    }]
})
export class AesStationsIoMappingRowComponent implements OnInit, ControlValueAccessor {

  @Input() inputs;
  @Input() outputs;
  @Input() stationId;
  @Input() noAlarmOutput;
  @Output() deletMap = new EventEmitter();
  @Output() childChanged = new EventEmitter();
  stationCAlarms;
  mappingValue: any;
  onChange: any = () => { }
  onTouch: any = () => { }
  selectedCalarm;
  selectedInput;
  selectedOutput;
  selectedBehaviour;
  behaviourTypeList = [
    {
      name: 'SPA.DEVICE.IO_MAPPING.ALARM_ACTIVE_UNTIL_MANUAL_TERMINATION',
      id: AlarmBehaviourTypeEnum.ALARM_ACTIVE_UNTIL_MANUAL_TERMINATION,
    },
    {
      name: 'SPA.DEVICE.IO_MAPPING.ALARM_ACTIVE_UNTIL_SIGNAL_CHANGE',
      id: AlarmBehaviourTypeEnum.ALARM_ACTIVE_UNTIL_SIGNAL_CHANGE,
    },
    {
      name: 'SPA.DEVICE.IO_MAPPING.ALARM_ACTIVE_UNTIL_NEW_SIGNAL',
      id: AlarmBehaviourTypeEnum.ALARM_ACTIVE_UNTIL_NEW_SIGNAL,
    }
  ];

  inputAutoComplete = new UntypedFormControl();
  filteredInputOptions:Observable<any[]>;
  outputAutoComplete = new UntypedFormControl();
  filteredOutputOptions:Observable<any[]>;
  initialSelectedInput;
  initialSelectedOutput;

  constructor(private aesStationsIoMappingService: AesStationsIoMappingService,private commonService: CommonService) { }

  ngOnInit(): void {
    this.aesStationsIoMappingService.getAllConfiguredAlarmByStationId(this.stationId).subscribe(res => {
      this.stationCAlarms = res;
    });
    this.filteredInputOptions = this.inputAutoComplete.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._inputFilter(name as string) : this.inputs.slice();
      }),
    );
    this.filteredOutputOptions = this.outputAutoComplete.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._outputFilter(name as string) : this.outputs.slice();
      }),
    );
  }

  writeValue(value: any) {
    if(value){
  
        this.selectedCalarm = value.configuredAlarm || {};
        this.selectedBehaviour = value.alarmBehaviourType || null;
        this.selectedInput = value?.input || value?.mqttInput || null;
        this.selectedOutput = value?.output || value.mqttOutput  || null;
        this.initialSelectedInput = this.selectedInput ? JSON.parse(JSON.stringify(this.selectedInput)) : null; 
        this.initialSelectedOutput = this.selectedOutput ? JSON.parse(JSON.stringify(this.selectedOutput)) : null;    
    }
    // this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }
  registerOnTouched(onTouched: Function) {
    this.onTouch = onTouched;
  }

  isMqttOption(option): boolean {
    return option && IoTypesUtil.isMQTTTopic(option);
  }

  dataChanged(fieldName?: string){
    let isMqttInput: boolean = this.isMqttOption(this.selectedInput);
    let isMqttOutput: boolean = this.isMqttOption(this.selectedOutput);

    const value = {
      configuredAlarm: this.selectedCalarm,
      alarmBehaviourType: this.selectedBehaviour,
      input: (!isMqttInput ? this.selectedInput : null) || null,
      output: (!isMqttOutput ? this.selectedOutput  : null) || null,
      mqttInput: (isMqttInput ? this.selectedInput : null) || null,
      mqttOutput: (isMqttOutput ? this.selectedOutput  : null) || null
    }
    this.onChange(value);
    this.childChanged.emit(fieldName);
  }

  deleteMap(){
    this.deletMap.emit();
  }



  compareObjects(o1: any, o2: any) {
    if ((o1 && o2) && ((o1 == o2) || ((o1.id && o1.id) && o1.id == o2.id) || ((o1.ioId && o1.ioId) && o1.ioId == o2.ioId)))
      return true;
    else return false
  }

  getTranslatedAlarm(alarm): string {
    return this.commonService.getTranslatedName('name', alarm?.translationJson || null);
  }

  private _inputFilter(name: string) {
    const filterValue = name.toLowerCase();
    return this.inputs.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  displayInputFn(input): string {
    return input && input.name ? input.name : '';
  }

  private _outputFilter(name: string) {
    const filterValue = name.toLowerCase();
    return this.outputs.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  displayOutputFn(output): string {
    return output && output.name ? output.name : '';
  }

  isDisableInput(input) {
    // check input and selected output is match or not
    if(this.isMqttOption(input) && this.isMqttOption(this.selectedOutput)) {
      return input?.id === this.selectedOutput?.id;

    // check input and selected noAlarmOutput(for mqtt) is match or not
    } else if(this.noAlarmOutput && this.isMqttOption(input) && this.isMqttOption(this.noAlarmOutput)){
      return input?.id === this.noAlarmOutput?.id;
    }

    return false;
  }

  isDisableOutput(output) {
    // check output and selected input is match or not
    if(this.isMqttOption(output) && this.isMqttOption(this.selectedInput)) {
      return output?.id === this.selectedInput?.id;

    // check output and selected noAlarmOutput(for io and mqtt) is match or not
    } else if((this.noAlarmOutput && this.isMqttOption(output) && this.isMqttOption(this.noAlarmOutput)) 
      || (this.noAlarmOutput && !this.isMqttOption(output) && !this.isMqttOption(this.noAlarmOutput))){
      return output?.id === this.noAlarmOutput?.id;
    } 

    return false;
  }

  // on focus out in input/output field 
  onFocusOut(event, fieldName: string){
    // set the previous selected value, if field contain incorrect value 
    setTimeout(() => {
      if(event.target.value) {
        if(fieldName === 'selectedInput' && this.initialSelectedInput) {
          this.selectedInput = this.initialSelectedInput;
        }
        if(fieldName === 'selectedOutput' && this.initialSelectedOutput) {
          this.selectedOutput = this.initialSelectedOutput;
        }
      } else {
        if(fieldName === 'selectedInput' && this.initialSelectedInput) {
          this.selectedInput = this.initialSelectedInput = null;
          this.dataChanged();
        } else if(fieldName === 'selectedOutput' && this.initialSelectedOutput) {
          this.selectedOutput = this.initialSelectedOutput = null;
          this.dataChanged();
        }
      }
    }, 100);
  }
}
