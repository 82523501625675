import {
  Component, OnDestroy,
  OnInit,
} from '@angular/core';
import { ClockService } from '../../services/clock.service';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss'],
})
export class ClockComponent implements OnInit, OnDestroy {
    time: Date = new Date;
    interval;
    constructor(private clockService: ClockService) {
    }

    ngOnInit(): void {
       this.startInterval();
       this.clockService.timezoneChanged.subscribe(() => {
           this.refreshTime();
       });
    }

    startInterval() {
        this.interval = setInterval(() => {
            this.time = new Date;
        }, 60000); // tick every minute
    }

    refreshTime() {
        this.time = new Date;
        clearInterval(this.interval);
        this.startInterval();
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
    }
}
