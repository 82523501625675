import { Routes } from '@angular/router';
import { AuthGuard } from '../../_guards/auth.guard';
import { UserPermissionsEnum } from '../../../../common/auth/enums/user-permissions.enum';
import { MqttConnectionDetailsComponent } from './mqtt-connection-details.component';

export const MQTT_CONNECTION_DETAILS_ROUTES: Routes = [
    {
        path: '',
        component: MqttConnectionDetailsComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: [UserPermissionsEnum.Public],
            configuration: null,
            breadcrumb: {
                label: 'SPA.MQTT_CONNECTION_DETAILS.LABEL',
            }
        },
    }
];
