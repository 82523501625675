<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{ 'COMMON.SEARCH' | translate }}</mat-label>
        <input matInput type="text" name="searchPhrase" [(ngModel)]="searchPhrase" (ngModelChange)="search()">
        <mat-icon class="mr-2" matPrefix>search</mat-icon>
    </mat-form-field>
</div>

<div class="table" #table>
    <div class="table-row table-header">
        <div class="column-first">{{ 'SPA.USERS.ROLE' | translate }}</div>
        <div class="column">{{ 'SPA.USERS.USERNAME' | translate }}</div>
        <div class="column">{{ 'SPA.USERS.FIRST_NAME' | translate }}</div>
        <div class="column">{{ 'SPA.USERS.LAST_NAME' | translate }}</div>
        <div class="column">{{ 'SPA.USERS.EMAIL' | translate }}</div>
        <div class="column">{{ 'SPA.USERS.COIN' | translate }}</div>
        <div class="column"></div>
    </div>
    <div class="table-row body-row" *ngFor="let user of users">
        <div class="column column-first">{{user.role.role | titlecase}}</div>
        <div class="column">{{user.username}}</div>
        <div class="column">{{user.firstname}}</div>
        <div class="column">{{user.name}}</div>
        <div class="column">{{user.email}}</div>
        <div class="column">{{user.coin}}</div>
        <div class="column actions">
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <button color="primary" mat-raised-button type="button"
                        [routerLink]="['/users/list', user.id]">
                    <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                              inline="true">edit</mat-icon>
                </button>
                <button color="warn" mat-raised-button type="button" [disabled]="disableDelete(user)" (click)="removeUser(user)">
                    <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                              inline="true">delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
</div>

<button mat-fab class="ap-fab" [routerLink]="['/users/list/add']">
    <mat-icon>add</mat-icon>
</button>
