<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="mt-10">
    <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
        <button color="warn" mat-button type="button" (click)="goBack()"> {{ 'COMMON.BACK' | translate }}</button>
    </div>
</div>
<div class="row" [ngClass]="{'center-align': !loaded, 'loader': !loaded, 'pdf': loaded}">
  <!-- PDF wird heruntergeladen -->
  <div *ngIf="!loaded" class="col s4 offset-s4">
    <div class="preloader-wrapper big active">
      <div class="spinner-layer spinner-blue-only">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div>
        <div class="gap-patch">
          <div class="circle"></div>
        </div>
        <div class="circle-clipper right">
          <div class="circle"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- PDF wurde heruntergeladen und kann angezeigt werden -->
  <ng-container *ngIf="safeUrl">
    <div class="col s9 showContent">
      <iframe type="application/pdf" [src]="safeUrl" width="100%" height="100%">

      </iframe>
    </div>
  </ng-container>
</div>