import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { TimeService } from "../../../common/shared/services/time.service";
import { EnumToArrayPipe } from "./pipes/enumToArray.pipe";
import { SaveFilterService } from "../../../common/shared/services/save-filter.service";
import { StringEnumToArrayPipe } from "./pipes/stringEnumToArray.pipe";
import { SecondaryToolbarModule } from "../../@vex/components/secondary-toolbar/secondary-toolbar.module";
import { IconModule } from "@visurel/iconify-angular";
import { BreadcrumbsModule } from "../../@vex/components/breadcrumbs/breadcrumbs.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { PageLayoutModule } from "../../@vex/components/page-layout/page-layout.module";
import { ContainerModule } from "../../@vex/directives/container/container.module";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { NgxLoadingModule } from "ngx-loading";
import { ConfigurationDirective } from "./directives/configuration.directive";
import { ValueTileComponent } from "./components/value-tile/value-tile.component";
import { DevicesListComponent } from "./components/devices/components/devices-list/devices-list.component";
import { DeviceService } from "./components/devices/services/device.service";
import { DeviceUpdaterService } from "./components/devices/services/device.updater.service";
import { DeviceNetwork } from "./components/devices/network/device.network";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DeviceAddComponent } from "./components/devices/components/device-add/device-add.component";
import { MatNativeDateModule } from "@angular/material/core";
import { MatLegacyOptionModule as MatOptionModule } from "@angular/material/legacy-core";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { DeviceEditComponent } from "./components/devices/components/device-edit/device-edit.component";
import { UnassignedDeviceNetwork } from "./components/devices/network/unassigned-device.network";
import { PageHeaderComponent } from "../../../common/shared/components/page-header/page-header.component";
import { IconSelectComponent } from "./components/icon-select/icon-select.component";
import { ConfiguratorCircleClockWidgetComponent } from "../../../common/device-configurator/components/configurator-circle-clock-widget/configurator-circle-clock-widget.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { ConfiguratorGaugeChartWidgetComponent } from "../../../common/device-configurator/components/configurator-gauge-chart-widget/configurator-gauge-chart-widget.component";
import { ConfiguratorProductionInfoWidgetComponent } from "../../../common/device-configurator/components/configurator-production-info-widget/configurator-production-info-widget.component";
import { AngularResizeEventModule } from "angular-resize-event";
import { UserAccessDirective } from "./directives/userAccess.directive";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { ConfirmModalComponent } from "../../../common/shared/components/modals/confirm/confirmModal.component";
import { AddTranslationsModalComponent } from "../../../common/shared/components/modals/add-translations/add-translations-modal.component";
import { ModalsService } from "../../../common/shared/services/modals.service";
import { ConfiguratorBarDividerWidgetComponent } from "../../../common/device-configurator/components/configurator-bar-divider-widget/configurator-bar-divider-widget.component";
import { ColorPickerComponent } from "./components/color-picker/color-picker.component";
import { ColorTwitterModule } from "ngx-color/twitter";
import { PeriodicityComponent } from "./components/periodicity/periodicity.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from "@angular-material-components/datetime-picker";
import { NgxMatDateFnsDateModule } from "ngx-mat-datefns-date-adapter";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { QrCodeModule } from "ng-qrcode";
import { DurationPipe } from "../../../common/shared/pipes/duration.pipe";
import { RouterModule } from "@angular/router";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { CdkTableModule } from "@angular/cdk/table";
import { CustomDatePipe } from "../../../common/shared/pipes/custom-date.pipe";
import { ClockComponent } from "../../../common/shared/components/clock/clock.component";
import { DynamicFontContainerComponent } from "../../../common/shared/components/dynamic-font-container/dynamic-font-container.component";
import { BarcodeScannerLivestreamOverlayModule } from "ngx-barcode-scanner";
import { AesStationsIoMappingComponent } from "./components/aes-stations-io-mapping/aes-stations-io-mapping.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { AesStationsIoMappingService } from "./components/aes-stations-io-mapping/aes-stations-io-mapping.service";
import { AesStationsIoMappingRowComponent } from "./components/aes-stations-io-mapping/aes-stations-io-mapping-row/aes-stations-io-mapping-row.component";
import { SelectColumnsComponent } from "../../../common/user-table-settings/components/select-columns/select-columns.component";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { QuillTextareaComponent } from "./../workstep-manager/components/widgets/widget-textbox-modal/quill-textarea/quill-textarea.component";
import { QuillModule } from "ngx-quill";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import Quill from "../../../node_modules/quill"
import { SecondsToTimeFormatPipe } from "../../../common/shared/pipes/seconds-to-time-format.pipe";
import { SpinnerComponent } from "../../../common/shared/components/spinner/spinner.component";
import { CaptureImageVideoComponent } from "../../../common/shared/components/modals/capture-image-video/capture-image-video.component";
import { ImageViewModalComponent } from "../../../common/shared/components/modals/image-view-modal/image-view-modal.component";

var Size = Quill.import('attributors/style/size');
Size.whitelist = ['8px', '12px', '14px', '16px', '18px','20px', '22px', '24px', '26px', '28px', '32px', '36px', '38px', '44px', '52px', '58px', '64px', "68px", "72px", "80px", "86px", "90px"];
Quill.register(Size, true);

const COMPONENTS = [
  PageHeaderComponent,
  ValueTileComponent,
  DevicesListComponent,
  DeviceAddComponent,
  DeviceEditComponent,
  IconSelectComponent,
  ConfiguratorCircleClockWidgetComponent,
  ConfiguratorGaugeChartWidgetComponent,
  ConfiguratorProductionInfoWidgetComponent,
  ConfirmModalComponent,
  AddTranslationsModalComponent,
  ConfiguratorBarDividerWidgetComponent,
  ColorPickerComponent,
  PeriodicityComponent,
  ClockComponent,
  DynamicFontContainerComponent,
  AesStationsIoMappingComponent,
  SelectColumnsComponent,
  QuillTextareaComponent,
  CaptureImageVideoComponent,
  SpinnerComponent
];

const PIPES = [
  EnumToArrayPipe,
  StringEnumToArrayPipe,
  DurationPipe,
  CustomDatePipe,
  SecondsToTimeFormatPipe
];

const DIRECTIVES = [UserAccessDirective, ConfigurationDirective];

const MODULES = [
  CommonModule,
  TranslateModule,
  MatProgressSpinnerModule,
  MatButtonModule,
  SecondaryToolbarModule,
  IconModule,
  BreadcrumbsModule,
  FlexLayoutModule,
  PageLayoutModule,
  ContainerModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  DragDropModule,
  MatSlideToggleModule,
  FormsModule,
  NgApexchartsModule,
  AngularResizeEventModule,
  MatDialogModule,
  ColorTwitterModule,
  ReactiveFormsModule,
  MatDatepickerModule,
  NgxMatDatetimePickerModule,
  NgxMatDateFnsDateModule,
  NgxMatNativeDateModule,
  MatRadioModule,
  MatCheckboxModule,
  MatNativeDateModule,
  NgxMatTimepickerModule,
  QrCodeModule,
  RouterModule,
  MatSelectModule,
  MatTableModule,
  CdkTableModule,
  MatOptionModule,
  BarcodeScannerLivestreamOverlayModule,
  MatExpansionModule,
  MatMenuModule,
  MatAutocompleteModule
];

@NgModule({
  imports: [
    NgxLoadingModule.forRoot({}),
    QuillModule.forRoot({
      modules: {
          toolbar: [
              ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
              [{ size: Size.whitelist }],
              ['blockquote'],
              [{ header: 1 }, { header: 2 }],               // custom button values
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],      // superscript/subscript
              [{ indent: '-1' }, { indent: '+1' }],          // outdent/indent
              [{ direction: 'rtl' }],                         // text direction
              [{ color: [] }, { background: [] }],          // dropdown with defaults from theme
              [{ 'font': [] }],
              [{ align: [] }],
              ['clean'],                                         // remove formatting button
          ]
      }
    }),
    ...MODULES,
  ],
  declarations: [
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES,
    AesStationsIoMappingRowComponent,
    ImageViewModalComponent,
  ],
  exports: [
    QuillModule,
    NgxLoadingModule,
    ...COMPONENTS,
    ...PIPES,
    ...MODULES,
    ...DIRECTIVES,
    AesStationsIoMappingComponent,
  ],
  providers: [
    TimeService,
    DeviceService,
    SaveFilterService,
    DeviceUpdaterService,
    DeviceNetwork,
    UnassignedDeviceNetwork,
    ModalsService,
    AesStationsIoMappingService,
    ...PIPES,
  ],
})
export class SharedModule {}
