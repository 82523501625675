import { Injectable } from '@angular/core';
import { FtpNetworkService } from './ftp-network.service';
import { FtpStationInterface } from '../interfaces/ftp-station.interface';

@Injectable()
export class FtpStationService {
  public ftpStation: FtpStationInterface = {
    id: 0,
    stationId: 0,
    ftpFolder: '',
    useFtpFile: true,
    searchBySerialNumber: false
  };

  constructor(
    private ftpNetworkService: FtpNetworkService,
  ) {
  }

  init(stationId: number) {
    this.ftpNetworkService.getFtpStation(stationId).subscribe(ftpStation => {
      this.ftpStation = ftpStation; 
    });
  }

  updateFolder() {
    return this.ftpNetworkService.updateFtpStationFolder(this.ftpStation);
  }
}