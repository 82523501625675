<mat-card>

    <page-header titleTranslate="SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.HEADER"></page-header>

    <div style="overflow-x: scroll;">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef
                    mat-sort-header> {{ 'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.NAME' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="users">
                <th mat-header-cell *matHeaderCellDef
                    mat-sort-header> {{ 'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.USERS' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                    {{displayUSers(element.users)}}
                    <!-- <span *ngFor="let user of element.users">
                        {{user.name }}
                    </span> -->
                </td>
            </ng-container>

            <ng-container matColumnDef="sendOccurredNotifications">
                <th mat-header-cell *matHeaderCellDef
                    mat-sort-header> {{ 'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.SEND_NOTIFICATIONS' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                    {{ checkNotification(element) }}
                </td>
            </ng-container>


            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0"
                         fxLayoutAlign="end">
                        <a color="primary" mat-raised-button type="button"
                           [routerLink]="['./form', element.id]">
                            <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                      inline="true">edit
                            </mat-icon>
                        </a>

                        <button color="warn" mat-raised-button type="button" (click)="delete(element.id)">
                            <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                      inline="true">delete
                            </mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
    </div>

</mat-card>

<button mat-fab class="ap-fab" [routerLink]="['./form']">
    <mat-icon>add</mat-icon>
</button>

