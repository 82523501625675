<div mat-dialog-content>
    <form [formGroup]="form" app-code-login (codeInputted)="loginCode($event)">
        <div class="page-header">
            <page-header titleTranslate="SPA.LOGIN.HEADER" [onStation]="true"></page-header>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
            <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field green" color="primary">
                <mat-label>{{ 'SPA.LOGIN.USERNAME' | translate }}</mat-label>
                <input matInput formControlName="username">
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
            <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field green" color="primary">
                <mat-label>{{ 'SPA.LOGIN.PASSWORD' | translate }}</mat-label>
                <input matInput type="password" formControlName="password" (keyup.enter)="login()">
            </mat-form-field>
        </div>
    </form>
</div>
<div class="footer-btns" style="display: block;">
    <div fxLayoutGap="16px" style="float:right ">
        <button color="warn" mat-raised-button type="button" (click)="close()"> {{ 'SPA.LOGIN.CANCEL_BTN' | translate }}</button>
        <button color="primary" mat-raised-button (click)="login()" [disabled]="!form.valid"> {{ 'SPA.LOGIN.SUBMIT_BTN' | translate }}</button>
    </div>
</div>
