import { Observable, Subject } from 'rxjs';
import { io, Socket as SocketIO } from 'socket.io-client';
import {SocketMessageEnum} from '../enums/socket-message.enum';
import { ModelSubscriptionIdentifierUtil } from '../../shared/utils/model-subscription-identifier.util';

export class Socket {
    private isConnected = new Subject<boolean>();
    private readonly actualSocket: SocketIO;
    private subscribedEvents: {event: SocketMessageEnum | string, identifier: string, callback: (content) => void}[] = [];

    constructor(apiUrl: string, channels: string[] = []) {
        this.actualSocket = io(`${apiUrl}`, { rejectUnauthorized: false, secure: true, autoConnect: false });
        this.connect(apiUrl, channels);
    }

    connect(apiUrl, channels) {
        this.actualSocket.open();
        this.actualSocket.on('connect', () => {
            console.log(`Connected to socket on: ${apiUrl}`);
            channels.forEach(channel => {
                this.join(channel);
            });
            this.isConnected.next(true);
        });
        this.actualSocket.on('disconnect', () => {
            this.actualSocket.open();
            this.isConnected.next(false);
        });
    }

    on(event: SocketMessageEnum | string, callback: (content) => void) {
        const identifier = ModelSubscriptionIdentifierUtil.generateIdentifier(this.subscribedEvents);

        const newSubEvent = {
            event: event,
            identifier: identifier,
            callback: callback
        };

        this.subscribedEvents.push(newSubEvent);

        this.actualSocket.on(event, newSubEvent.callback);

        return identifier;
    }

    emit(event: string, data, callback: (data) => void = null) {
        if (callback) {
            this.actualSocket.emit(event, data, callback);
        } else {
            this.actualSocket.emit(event, data);
        }
    }

    off(identifier: string) {
        if (!this.actualSocket) {
            return;
        }

        const index = this.subscribedEvents.findIndex(s => s.identifier === identifier);

        if (index === -1) {
            return;
        }

        const subscribedEvent = this.subscribedEvents[index];

        this.actualSocket.off(subscribedEvent.event, subscribedEvent.callback);

        this.subscribedEvents.splice(index, 1);
    }

    join(channelName) {
        if (!this.actualSocket) {
            return;
        }
        console.log('Joining channel ' + channelName);
        this.actualSocket.emit(SocketMessageEnum.JOIN_MESSAGE, { channelName });
    }

    leave(channelName) {
        if (!this.actualSocket) {
            return;
        }
        this.actualSocket.emit(SocketMessageEnum.LEAVE_MESSAGE, { channelName });
    }

    connected(): Observable<boolean> {
        return this.isConnected.asObservable();
    }

    offAll() {
        this.subscribedEvents.forEach(s => {
            this.actualSocket.off(s.event);
        });

        this.subscribedEvents = [];
    }

    close() {
        this.actualSocket.close();
    }
}
