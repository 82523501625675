import {Injectable} from '@angular/core';
import { BaseSocketService } from '../../socket/services/base-socket.service';
import { SocketMessageEnum } from '../../socket/enums/socket-message.enum';
import { SocketChannel } from '../../socket/utils/socket-channel';
import { TimerUtil } from '../../shared/utils/timer.util';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { RedirectUrlInterface } from '../../shared/interfaces/redirect-url.interface';
import * as _ from 'lodash';
@Injectable({
    providedIn: 'root'
})
export class BackupModeService {
    inProgress = false;
    statusChecking = false;
    timerUtil: TimerUtil;
    redirectUrl: RedirectUrlInterface = null;

    constructor(private http: HttpClient, private router: Router) {
        this.timerUtil = new TimerUtil();
    }

    backupModeStarted() {
        this.inProgress = true;
        this.startStatusChecking();
        if (!this.redirectUrl) {
            this.redirectUrl = {
                url: _.clone(this.router.url),
                queryParams: null
            };
        }
        this.router.navigate(['/stopped']);
    }

    backupModeStopped() {
        this.inProgress = false;
        this.statusChecking = false;
        this.timerUtil.stopTimer();
        const url = this.redirectUrl ? _.clone(this.redirectUrl.url) : '/';
        this.redirectUrl = null;
        location.replace(url);
    }

    init(socketService: BaseSocketService) {
        socketService.getSocket().join(SocketChannel.BLOCK);
        socketService.getSocket().on(SocketMessageEnum.BACKUP_STARTED, (data: any) => {
            this.backupModeStarted();
        });
    }

    private startStatusChecking() {
        if (!this.statusChecking) {
            this.statusChecking = true;

            this.timerUtil.setTimer(0, 2000, () => {
                this.http.get('/api/blocked').subscribe((status: boolean) => {
                    if (!status) {
                        this.backupModeStopped();
                    }
                })
            });
        }
    }
}
