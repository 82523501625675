import { HttpEventType } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { LineService } from '../line/line.service';
import { InfoboxService } from '../../../common/shared/services/infobox.service';
import { Disorder } from './disorder.model';
import {FileInput} from 'ngx-material-file-input';

@Component({
  selector: 'app-disorder',
  templateUrl: './disorder.component.html',
  styleUrls: ['./disorder.component.scss']
})
export class DisorderComponent implements OnInit {
  public disorders: Disorder[] = [];
  lineId;
  private partsImportFile;
  public uploadPercentage = 0;
  public upload = false;
  public articleImportDate = '';
  public import = false;
  public file;

  constructor(
    public authService: AuthService,
    private lineService: LineService,
    private infoboxService: InfoboxService
  ) { }

  ngOnInit() {
    this.lineId = this.lineService.getSelectedLine();
    this.lineService.getImportDate(this.lineId).subscribe(response => {
      this.articleImportDate = response.body[0].article_import;
    });
  }

  importFileChange(data: FileInput) {
      this.partsImportFile = data.files[0];
  }

  saveCSV() {
    const selectedFile = this.partsImportFile;
    if (!selectedFile) {
      this.infoboxService.pushInfo({
        danger: true,
        text: 'SPA.LINES.SELECT_CSV_ERROR'
      });
      return;
    }
    this.upload = true;
    // CSV schicken zum speichern
    // create a new multipart-form for file
    const formData: FormData = new FormData();
    formData.append('file', selectedFile, selectedFile.name);

    this.lineService.uploadArticles(this.lineId, formData).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          // calculate the progress percentage
          this.uploadPercentage = Math.round((100 * event.loaded) / event.total);
        }

        if (event.type === HttpEventType.Response) {
          if (event.status === 201) {
            this.infoboxService.pushInfo({
              success: true,
              text: 'SPA.LINES.PARTS_IMPORT.SUCCESS'
            });
            this.lineService.getImportDate(this.lineId).subscribe(response => {
              this.articleImportDate = response.body[0].article_import;
            });
          }
          this.upload = false;
          this.uploadPercentage = 0;
        }
      },
      error => {
        this.infoboxService.pushInfo({
          danger: true,
          text: 'SPA.LINES.PARTS_IMPORT.ERROR'
        });
        this.upload = false;
        this.uploadPercentage = 0;
      }
    );
  }

  forceImport() {
    this.import = true;
    this.lineService.forceImport(this.lineId).subscribe((res) => {
      this.import = false;
      this.lineService.getImportDate(this.lineId).subscribe(response => {
        this.articleImportDate = response.body[0].article_import;
      });
      switch (res.status) {
        case 201:
          this.infoboxService.pushInfo({ success: true, text: 'SPA.LINES.PARTS_IMPORT.SUCCESS' });
          break;
      }
    }, error => {
      this.import = false;
      this.uploadPercentage = 0;
      switch (error.status) {
        case 401:
          this.infoboxService.pushInfo({ danger: true, text: 'SPA.LINES.PARTS_IMPORT.AUTH_ERROR' });
          break;
        case 400:
          this.infoboxService.pushInfo({ warning: true, text: 'SPA.LINES.PARTS_IMPORT.NO_FILE_ERROR' });
      }
    });
  }
}
