<h1 matDialogTitle>{{'SPA.ALARMS_ESCALATIONS.ALARM_REASONS.IMPORT_FROM_CSV_Form.HEADER' | translate }}</h1>

<mat-dialog-content>
    <input type="file" (change)="importFileChange($event)" class="file-input">
    <table mat-table matSort class="csv-tbl-data" [dataSource]="result" *ngIf="result" datapagesize="10">

        <ng-container *ngFor="let key of getKeys">
            <ng-container [matColumnDef]="key">
                <th mat-header-cell *matHeaderCellDef
                    mat-sort-header> {{ key}} </th>
                <td mat-cell *matCellDef="let element"> {{element[key]}} </td>
            </ng-container>
        </ng-container>

        <ng-container matColumnDef="btns_col">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <span class="delete-btn" (click)="removeItem(element)">×</span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="getDisplayCols"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayCols;"></tr>

    </table>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="save()">{{'COMMON.SAVE' |translate}}</button>
    <button mat-button matDialogClose>{{'COMMON.CANCEL' |translate}}</button>
</mat-dialog-actions>

