import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { FtpNetworkService } from './services/ftp-network.service';
import {BreadcrumbComponent} from '../../shared/components/breadcrumb.component';
import {ActivatedRoute, Router} from '@angular/router';
import { FtpConfigurationInterface } from '../../../../common/ftp/interfaces/ftp-configuration.interface';
import { InfoboxService } from '../../../../common/shared/services/infobox.service';

@Component({
  selector: 'app-ftp-configuration',
  templateUrl: './ftp-configuration.component.html',
  styleUrls: ['./ftp-configuration.component.scss']
})
export class FtpConfigurationComponent extends BreadcrumbComponent implements OnInit {
  public configuration: FtpConfigurationInterface = {
    user: '',
    host: '',
    password: '',
    isSecure: false,
    updateTime: 5,
    slideshowsStopped: false,
    mainFolder: '',
  };
  public saveButtonDisabled = true;

  constructor(
    private ftpNetworkService: FtpNetworkService,
    private infoboxService: InfoboxService,
    private _location: Location,
    public route: ActivatedRoute,
    public router: Router
  ) {
      super(route, router);
  }

  ngOnInit() {
    this.initBreadcrumbs();
    this.ftpNetworkService.getConfiguration().subscribe(data => {
      if (data) {
        this.configuration = data;
      }
    });
  }

  goBack() {
    this._location.back();
  }

  async save(form: NgForm) {
    if (form.valid) {
      this.saveButtonDisabled = true;
      this.ftpNetworkService.updateConfiguration(this.configuration).subscribe(data => {
        this.configuration = data;
        this.infoboxService.success('SPA.FTP_CONFIGURATION.UPDATE_SUCCESS');
      }, () => {
        this.saveButtonDisabled = false;
        this.infoboxService.error('SPA.FTP_CONFIGURATION.ERROR.UPDATE');
      });
    }
  }

  async test(){
      this.ftpNetworkService.checkConnection(this.configuration).subscribe(success => {
        if (success) {
          this.infoboxService.success('SPA.FTP_CONFIGURATION.TEST_SUCCESS');
          this.saveButtonDisabled = false;
        } else {
          this.infoboxService.error('SPA.FTP_CONFIGURATION.ERROR.TEST');
        }
      })
  }

  async stopAllSlideshows() {
    this.ftpNetworkService.stopAllSlideshows().subscribe(success => {
      if (success) {
        this.configuration.slideshowsStopped = true;
        this.infoboxService.success('SPA.FTP_CONFIGURATION.SLIDESHOWS_STOPPED');
      } else {
        this.infoboxService.error('SPA.FTP_CONFIGURATION.ERROR.SLIDESHOW_STOP');
      }
    });
  }

  async restartAllSlideshows() {
    this.ftpNetworkService.restartAllSlideshows().subscribe(success => {
      if (success) {
        this.configuration.slideshowsStopped = false;
        this.infoboxService.success('SPA.FTP_CONFIGURATION.SLIDESHOWS_RESTARTED');
      } else {
        this.infoboxService.error('SPA.FTP_CONFIGURATION.ERROR.SLIDESHOW_RESTART');
      }
    });
  }

}
