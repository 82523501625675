import { Injectable } from '@angular/core';
import { UserPermissionsEnum } from '../../../../common/auth/enums/user-permissions.enum';
import { ConfigurationService } from '../../configuration/services/configuration.service';

@Injectable()
export class RolePermissionService {

    constructor( private configurationService: ConfigurationService) {}

    requiredForSystemRoles = [
        UserPermissionsEnum.UserManagement,
        UserPermissionsEnum.AEDisorderReasonManagement,
        UserPermissionsEnum.AEGroupsManagement,
        UserPermissionsEnum.AESupervisor
    ];

    isDisabledByConfiguration(permission: UserPermissionsEnum): boolean {
        switch (permission) {
          case UserPermissionsEnum.SuperAdmin:
          case UserPermissionsEnum.Public:
            //both are automatically added to appropriate people
            return true;
          case UserPermissionsEnum.ProductsManagement:
          case UserPermissionsEnum.ProductionFlowManagement:
          case UserPermissionsEnum.ProductItemTrash:
          case UserPermissionsEnum.ProductItemDetailsPage:
          case UserPermissionsEnum.ProductItemDataManipulationPage:
          case UserPermissionsEnum.StationProductItemIncludeLater:
            return !this.configurationService.trackAndTrace;
          case UserPermissionsEnum.WorkstepsManagement:
            return !this.configurationService.trackAndTrace ||
              !this.configurationService.workstepManager;
          case UserPermissionsEnum.ReportsPage:
            return !this.configurationService.reports;
          case UserPermissionsEnum.ChartsPage:
            return !this.configurationService.charts;
          case UserPermissionsEnum.AEDisorderReasonManagement:
          case UserPermissionsEnum.AEGroupsManagement:
          case UserPermissionsEnum.AESupervisor:
          case UserPermissionsEnum.StationDisorderAutologin:
            return !this.configurationService.alarmsAndEscalations;
          case UserPermissionsEnum.RemoteStationView:
            return !this.configurationService.remoteStationView;
          case UserPermissionsEnum.DeviceConfigurationManagement:
            return !this.configurationService.deviceConfiguration;
          case UserPermissionsEnum.FtpSettingsManagement:
            return !this.configurationService.ftp;
          default:
            return false;
        }
    }
}