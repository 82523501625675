import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'ap-async-button',
    styleUrls: ['asyncButton.component.scss'],
    templateUrl: 'asyncButton.component.html'
})
export class AsyncButtonComponent {
    @Input() public inProgress: boolean;
    @Input() public text: string;
    @Input() public disabled: boolean;
    @Output() public onClick = new EventEmitter();

    public clicked() {
        this.onClick.emit();
    }
}