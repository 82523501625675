import { Component, Input, OnInit } from '@angular/core';
import { IoTypeEnum } from '../../../../../common/io-module/enums/io-type.enum';
import { MqttDataFormatEnum } from '../../../../../common/mqtt/enums/mqtt-data-format.enum';
import { SingleIoInterface } from '../../../../../common/io-module/interfaces/single-io.interface';
import { WorkstepIoWidgetSingleDataInterface } from '../../../../../common/workstep-manager/interfaces/workstep-widget-data/workstep-io-widget-data.interface';
import { IoDataTypeEnum } from '../../../../../common/io-module/enums/io-data-type.enum';
import { IoTypesUtil } from '../../../../../common/io-module/utils/io-types.util';
import { AesStationsIoMappingService } from './aes-stations-io-mapping.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { forkJoin, map, Observable, startWith } from 'rxjs';
import { InfoboxService } from '../../../../../common/shared/services/infobox.service';
import { TranslateService } from '@ngx-translate/core';
import { AlarmBehaviourTypeEnum } from '../../../../../common/station-mapping/enums/alarm-behaviour-type.enum';

@Component({
  selector: 'app-aes-stations-io-mapping',
  templateUrl: './aes-stations-io-mapping.component.html',
  styleUrls: ['./aes-stations-io-mapping.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AesStationsIoMappingComponent,
      multi: true
    }]
})
export class AesStationsIoMappingComponent implements OnInit, ControlValueAccessor {
  @Input() stations;
  stationsIOMapping = [];
  stationCAlarms = {};
  inputs = [];
  outputs = [];
  isLoadingData: boolean = true;
  onChange: any = () => { }
  onTouch: any = () => { }
  outputAutoComplete = new UntypedFormControl();
  filteredOutputOptions:Observable<any[]>;
  noAlarmOutput;
  initialNoAlarmOutput;

  writeValue(value: any) {
    // if (value ) {
    //   if(value.length){
    //     this.stationsIOMapping = value;
    //   }else{
    //     if(this.stationsIOMapping.length == 0 )
    //     for (let station of this.stations) {

    //       this.stationsIOMapping.push({
    //         station: station,
    //         noAlarmOutput: {},
    //         mappingAlarms: []
    //       });


    //     }
    //   }
    // }

  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }
  registerOnTouched(onTouched: Function) {
    this.onTouch = onTouched;
  }

  constructor(private aesStationsIoMappingService: AesStationsIoMappingService,
    private infoBoxService: InfoboxService,
    private translate: TranslateService,
    ) { }

  ngOnInit(): void {
    let stationIds = this.stations.map(st => st.id);

    this.isLoadingData = true;
    forkJoin(
      this.aesStationsIoMappingService.getIOAndMqttListByType(IoTypeEnum.INPUT, MqttDataFormatEnum.BOOLEAN),
      this.aesStationsIoMappingService.getIOAndMqttListByType(IoTypeEnum.OUTPUT, MqttDataFormatEnum.BOOLEAN),
      this.aesStationsIoMappingService.getStationsMappingByStationIds(stationIds.join(','))
    ).subscribe(responses => {
      this.prepareInputs(responses[0]);
      this.prepareOutputs(responses[1]);
      let serverMapping = responses[2];
      for (let station of this.stations) {
        let smobj = serverMapping.find(sm => sm.station.id == station.id) || {
          station: station,
          mappingAlarms: []
        };
        this.stationsIOMapping.push(smobj);
        this.noAlarmOutput = smobj?.noAlarmOutputForIo || smobj?.noAlarmOutputForMqtt || null;
        this.initialNoAlarmOutput = this.noAlarmOutput ? JSON.parse(JSON.stringify(this.noAlarmOutput)) : null;
        this.isLoadingData = false;
      }

      this.onChange(this.stationsIOMapping);
    })
    // 

this.filteredOutputOptions = this.outputAutoComplete.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._outputFilter(name as string) : this.outputs.slice();
      }),
    );

  }

  prepareInputs(ios: any[]) {
    this.inputs = ios.filter((io) => IoTypesUtil.isInput(io) && IoTypesUtil.isDigitalBoolean(io)).map((io) => {
      return this.prepareIoObject(io);
    });
    // Push boolean type mqtt topic option in inputs
    ios.map((io) => {
      if(IoTypesUtil.isMQTTTopic(io)) {
        this.inputs.push(io);
      }
    });
  }

  prepareOutputs(ios: any[]) {
    this.outputs = ios.filter((io) => IoTypesUtil.isOutput(io) && IoTypesUtil.isDigitalBoolean(io)).map((io) => {
      return this.prepareIoObject(io);
    });
    // Push boolean type mqtt topic option in outputs
    ios.map((io) => {
      if(IoTypesUtil.isMQTTTopic(io)) {
        this.outputs.push(io);
      }
    });
  }


  prepareIoObject(io: any): any {
    const data = io.data as any;
    return {
      id: io.id,
      name: io.name,
      typeEnum: io.typeEnum,
      dataTypeEnum: io.dataTypeEnum,
      value: this.isAI(io)
        ? { from: null, to: null }
        : this.isCounter(io)
          ? 0
          : false,
      label: data?.processUnit
    };
  }

  isAI(io): boolean {
    return io.dataTypeEnum === IoDataTypeEnum.ANALOG && io.typeEnum === IoTypeEnum.INPUT;
  }

  isCounter(io: WorkstepIoWidgetSingleDataInterface | SingleIoInterface): boolean {
    return io.dataTypeEnum === IoDataTypeEnum.DIGITAL_COUNTER;
  }


  addMap(stationMapIndex) {
    this.stationsIOMapping[stationMapIndex].mappingAlarms.push({
      alarm: {},
      input: null,
      output: null,
      mqttInput: null,
      mqttOutput: null,
      alarmBehaviourType: AlarmBehaviourTypeEnum.ALARM_ACTIVE_UNTIL_NEW_SIGNAL,
    });
    this.dataChanged();
  }

  delete(index){
    this.noAlarmOutput = null;
    this.initialNoAlarmOutput = null;
    this.stationsIOMapping[index] = {
      ...this.stationsIOMapping[index], 
      noAlarmOutputForIo: null,
      noAlarmOutputForIoId: null,
      noAlarmOutputForMqtt: null,
      noAlarmOutputForMqttId: null,
    };
  }
  
  deleteMap(stationMapIndex, mapInedx) {
    this.stationsIOMapping[stationMapIndex].mappingAlarms.splice(mapInedx, 1);
    this.dataChanged();
  }

  compareObjects(o1: any, o2: any) {
    if ((o1 && o2) && ((o1 == o2) || ((o1.id && o1.id) && o1.id == o2.id) || ((o1.ioId && o1.ioId) && o1.ioId == o2.ioId)))
      return true;
    else return false
  }

  dataChanged(stationMapIndex?: number, mapIndex?: number, changedField?) {
    // const value = {
    //   alarm: this.selectedCalarm,
    //   input:this.selectedInput || {},
    //   output: this.selectedOutput  || {}
    // }

    // set payload data based on output io/mqtt type 
    if(stationMapIndex >= 0 && this.noAlarmOutput) {
      let isIO: boolean = IoTypesUtil.isOutput(this.noAlarmOutput);
      let isMqtt: boolean = IoTypesUtil.isMQTTTopic(this.noAlarmOutput);
      this.stationsIOMapping[stationMapIndex] = {
        ...this.stationsIOMapping[stationMapIndex], 
        noAlarmOutputForIo: isIO ? this.noAlarmOutput : null,
        noAlarmOutputForIoId: isIO ? this.noAlarmOutput?.id : null,
        noAlarmOutputForMqtt: isMqtt ? this.noAlarmOutput : null,
        noAlarmOutputForMqttId: isMqtt ? this.noAlarmOutput?.id : null,
      };
      this.initialNoAlarmOutput = this.noAlarmOutput ? JSON.parse(JSON.stringify(this.noAlarmOutput)) : null;   
    }

    this.setBehaviourData(stationMapIndex, mapIndex, changedField);
    this.onChange(this.stationsIOMapping);
  }

  private _outputFilter(name: string) {
    const filterValue = name.toLowerCase();
    return this.outputs.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  displayOutputFn(output): string {
    return output && output.name ? output.name : '';
  }

  // set uniq behaviour for same alarm 
  setBehaviourData(stationMapIndex: number, mapIndex: number, changedField: string){
    if(Number.isInteger(stationMapIndex) && Number.isInteger(mapIndex) && changedField){
      const mappingAlarms = this.stationsIOMapping[stationMapIndex].mappingAlarms.filter(el => el?.configuredAlarm);
      const currentAlarm = mappingAlarms[mapIndex];

      // When behaviour field changes, set same behaviour in other same alarms 
      if(changedField === 'alarmBehaviourType' && currentAlarm){
        let isChanged: boolean = false;
        mappingAlarms.map((alarm, alarmIndex: number) => {
          if(alarmIndex !== mapIndex && alarm?.configuredAlarm?.id == currentAlarm?.configuredAlarm?.id) {
            alarm.alarmBehaviourType = currentAlarm?.alarmBehaviourType || null;
            isChanged = true;
          }
        });

        // if alarm behaviour change in other alarm display message
        if(isChanged) {
          this.infoBoxService.warning(
            this.translate.instant('SPA.DEVICE.IO_MAPPING.ALARM_BEHAVIOUR_MESSAGE', { alarmName: currentAlarm.configuredAlarm.name})
          );
        }
        
      } else if(changedField === 'alarm' && currentAlarm) {
        // find same alarm from mapping alarm list
        let sameAlarm = mappingAlarms.find((alarm, alarmIndex: number) => 
            alarmIndex !== mapIndex && alarm?.configuredAlarm?.id == currentAlarm?.configuredAlarm?.id
        );

        // get other same alarm behaviour and set it to current alarm
        if(sameAlarm) currentAlarm.alarmBehaviourType = sameAlarm?.alarmBehaviourType || null;
      }
    }
  }

  // disable noAlarmOutput option if it is selected in alarm input/output options
  isDisableSelectOutput(stationMapIndex, outputOption): boolean {
    let isDisable: boolean  = false;
    let ioList = [];
    let mqttList = [];

    const mappingAlarms = this.stationsIOMapping[stationMapIndex].mappingAlarms;
    // Push input/output data in io or mqtt list
    mappingAlarms.map((alarm) => {
      if(alarm?.input) ioList.push(alarm?.input);
      if(alarm?.output) ioList.push(alarm?.output);
      if(alarm?.mqttInput) mqttList.push(alarm?.mqttInput);
      if(alarm?.mqttOutput) mqttList.push(alarm?.mqttOutput);
    });

    // check current noAlarmOutput option is exist in mqtt option list
    if(outputOption && IoTypesUtil.isMQTTTopic(outputOption)) {
      let isMqttOptionInAlarms = mqttList.find((el) => el?.id === outputOption?.id);
      isDisable = isMqttOptionInAlarms ? true : false;

    // check current noAlarmOutput option is exist io option list
    } else {
      let isIoOptionAlarms = ioList.find((el) => el.id === outputOption.id);
      isDisable = isIoOptionAlarms ? true : false;
    }
    return isDisable;
  }

  // on focus out in input/output field 
  onFocusOut(event, stationMapIndex: number){
    setTimeout(() => {
      // set the previous selected value, if field contain incorrect value
      if(this.initialNoAlarmOutput) {
        this.noAlarmOutput = this.initialNoAlarmOutput;
      }
    }, 100)
  }
}
