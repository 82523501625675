<mat-card>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
    <page-header
            [titleTranslate]="alarmsGroupId ?  'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.ALARM_GROUPS_FORM.UPDATE_ALARM_GROUP' :'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.ALARM_GROUPS_FORM.ADD_ALARM_GROUP'"></page-header>
    <div>

        <form #form="ngForm"  (submit)="onSubmit($event)">
            <h3>{{'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.ALARM_GROUPS_FORM.GENERAL_DATA' | translate }}</h3>
            <div class="cols-3">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.ALARM_GROUPS_FORM.NAME' | translate }}</mat-label>
                    <input matInput
                           type="text"
                           name="NAME" [(ngModel)]="model.name"
                           required
                           placeholder="{{ 'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.ALARM_GROUPS_FORM.NAME' | translate }}">
                </mat-form-field>
                <mat-form-field appearance="outline" color="primary">
 
                    <mat-label>{{ 'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.ALARM_GROUPS_FORM.USERS' | translate }}</mat-label>
                    <mat-select   required name="users" [(ngModel)]="model.userIds" multiple   >
                        <mat-option *ngFor="let user of usersList"  [value]="user.id" >{{user.firstname + ' ' + user.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <h3>{{'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.ALARM_GROUPS_FORM.ALARMING_OPTIONS' | translate }}</h3>
            <div class="cols-3">
                <mat-slide-toggle style="margin-bottom: 14px" name="sendOccurredNotifications"
                                  [(ngModel)]="model.sendOccurredNotifications">{{ 'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.ALARM_GROUPS_FORM.SEND_EMAIL_NOTIFICATION_FOR_OCCURED_ALARM' | translate }}
                </mat-slide-toggle>
            </div>
            <div class="cols-3">
                <mat-slide-toggle style="margin-bottom: 14px" name="sendForwardedNotifications"
                                  [(ngModel)]="model.sendForwardedNotifications">{{ 'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.ALARM_GROUPS_FORM.SEND_EMAIL_NOTIFICATION_FOR_FORWARDED_ALARM' | translate }}
                </mat-slide-toggle>
            </div>
            <div class="cols-3">
                <mat-slide-toggle style="margin-bottom: 14px" name="sendEscalatedNotifications"
                                  [(ngModel)]="model.sendEscalatedNotifications">{{ 'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.ALARM_GROUPS_FORM.SEND_EMAIL_NOTIFICATION_FOR_ESCALATED_ALARM' | translate }}
                </mat-slide-toggle>
            </div>
            <div class="cols-3">
                <mat-slide-toggle style="margin-bottom: 14px" name="sendEndedNotifications"
                                  [(ngModel)]="model.sendEndedNotifications">{{ 'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.ALARM_GROUPS_FORM.SEND_EMAIL_NOTIFICATION_FOR_ENDED_ALARM' | translate }}
                </mat-slide-toggle>
            </div>
            <div class="d-flex">
                <span class="flex-grow-1"></span>
                  <button  mat-button type="button" (click)="goBack()">{{ 'COMMON.BACK' | translate }}</button>
                <button mat-raised-button [disabled]="saveButtonDisabled" [disabled]="!form.valid" type="submit"
                        color="primary">{{ (alarmsGroupId ? 'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.ALARM_GROUPS_FORM.UPDATE_ALARM_GROUP' : 'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.ALARM_GROUPS_FORM.ADD_ALARM_GROUP') | translate }}</button>
            </div>
        </form>

    </div>
</mat-card>

