import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { asapScheduler, Subject } from 'rxjs';
// @ts-ignore
import ApexCharts from 'apexcharts';
import {ApexChartOptionsInterface} from '../../../../common/charts/interfaces/apex-chart-options.interface';
import {TranslateService} from '@ngx-translate/core';
import { CommonService } from '../../../../common/shared/services/common.service';

@Component({
  selector: 'vex-chart',
  template: `
    <div #chart style="display: flex;justify-content: center;"></div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComponent implements OnInit, OnChanges {

  @Input() options: ApexChartOptionsInterface;
  @Input() series: any;
  @Input() autoUpdateSeries = true;
  @Input() changingAlarmData: Subject<any>;
  public chart: ApexCharts;
  @ViewChild('chart', { static: true }) private chartElement: ElementRef;

  constructor(private cd: ChangeDetectorRef,
              private ngZone: NgZone,
              private translate: TranslateService,
              private commonService: CommonService) {}


  ngOnInit() {
    asapScheduler.schedule(() => {
      this._createElement();
    });
    // Calls only when clicking on alarm charts
    this.changingAlarmData && this.changingAlarmData.subscribe((v) => { 
      this.setAlarmChartSeries(v);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    asapScheduler.schedule(() => {
      if (this.autoUpdateSeries && Object.keys(changes).filter(c => c !== 'series').length === 0) {
        this.chart.updateSeries(this.series, true);
        return;
      }

      this._createElement();
    });
  }

  public render(): Promise<void> {
    return this.chart.render();
  }

  private _createElement() {
    // Update the y-axis value for disorder duration chart only
    if (this.options?.title?.text === this.translate.instant('SPA.CHARTS.DISORDER_DURATION')) {
        let optionsNeedToUpdate: any = this.options;
        optionsNeedToUpdate.yaxis[0] = {
          labels: {
            formatter: (value) => {
              return value.toFixed(1)
            }
          }
        }
        this.options = optionsNeedToUpdate;
    }
    if (this.series) {
      this.options.series = this.series;
    }

    if (this.chart) {
      this.chart.destroy();
    }

    this.ngZone.runOutsideAngular(() => {
      this.chart = new ApexCharts(
        this.chartElement.nativeElement,
        this.options
      );

      this.render();
    });
  }

  // Update the series and label based on new values
  private setAlarmChartSeries(alarmData) {
    let alarmChartData = alarmData.chartData;
    let reasonsTranslations = alarmData.reasonsTranslationData;
    let alarmsTranslations = alarmData.alarmsTranslationData;
    const label = (this.chart.w.config.title.text === this.translate.instant('SPA.CHARTS.DISORDER_AMOUNT')) ? 'alarmReason' : 'alarmAmount';

    // Update Series
    this.series = alarmChartData['chartArray'][label].series;

    // Update Labels
    this.options['labels'] = alarmChartData['chartArray'][label].chartLabels;
    if (label === 'alarmReason') {
      this.options['labels'] = this.commonService.getTranslationFromJson(alarmChartData['chartArray'][label].chartLabels, reasonsTranslations);
    } else if (label === 'alarmAmount') {
      this.options['labels'] = this.commonService.getTranslationFromJson(alarmChartData['chartArray'][label].chartLabels, alarmsTranslations);
    }
    
    // Update Colors
    if (alarmChartData.chartArray[label].colors != null) {
      this.options['colors'] =  alarmChartData['chartArray'][label].colors;
    }
    this._createElement();
  }
}
