import {forwardRef, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpUrlInterceptor} from './httpUrl.interceptor';
import {HttpAuthInterceptor} from './httpAuth.interceptor';
import {HttpErrorInterceptor} from './httpError.interceptor';

@NgModule({
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: forwardRef(() => HttpUrlInterceptor), multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: forwardRef(() => HttpAuthInterceptor), multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: forwardRef(() => HttpErrorInterceptor), multi: true}
    ]
})
export class InterceptorsModule {
}
