import {Component, OnInit} from '@angular/core';
import {ConfigurationService} from './services/configuration.service';
import {BreadcrumbComponent} from '../shared/components/breadcrumb.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigurationEnum} from '../../../common/configuration/enums/configuration.enum';
import {ConfigurationDataInterface} from '../../../common/configuration/interfaces/configuration-data.interface';
import {InfoboxService} from '../../../common/shared/services/infobox.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent extends BreadcrumbComponent implements OnInit {

    inProgress = false;
    configurations: ConfigurationDataInterface[] = [];

    constructor(
        private configurationService: ConfigurationService,
        public route: ActivatedRoute,
        public router: Router,
        private infoboxService: InfoboxService,
    ) {
        super(route, router);
    }

    ngOnInit() {
        this.initBreadcrumbs();
        this.configurationService.settings.loadSettings().then((result) => {
            this.configurations = result;
        });
    }

    save() {
        this.inProgress = true;
        this.configurationService.updateConfiguration(this.configurations).subscribe((result) => {
            this.inProgress = false;
            this.configurations = result;
            this.infoboxService.success('COMMON.SAVED');
        })
    }

    findConfig(type: ConfigurationEnum): ConfigurationDataInterface {
        return this.configurations.find((config) => config.property === type);
    }

    prepareTransLabel(id): string {
        return 'ENUMS.SYSTEM_CONFIG.' + ConfigurationEnum[id];
    }

    settingExists(id: number): boolean {
        return !!ConfigurationEnum[id];
    }

    isWorkstepDisabled(): boolean {
        return !this.findConfig(ConfigurationEnum.TRACK_AND_TRACE).value
    }

    isWorkstep(type: ConfigurationEnum): boolean {
        return type === ConfigurationEnum.WORKSTEP_MANAGER;
    }

    configChanged(config: ConfigurationDataInterface) {
        if (config.property === ConfigurationEnum.TRACK_AND_TRACE && !config.value) {
            this.findConfig(ConfigurationEnum.WORKSTEP_MANAGER).value = false;
        }
    }
}
