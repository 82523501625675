import {Injectable} from '@angular/core';
import {AEGroupsNetwork} from '../networks/a-e-groups.network';
import {Observable} from 'rxjs';
import {AEGroupInterface} from '../interfaces/a-e-group.interface';
import {FormGroup} from '@angular/forms';
import { UserInterface } from '../../../../../common/auth/interfaces/user.interface';
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";

@Injectable()
export class AEGroupsService {
    constructor(private network: AEGroupsNetwork) {

    }

    fetchGroup(id): Observable<AEGroupInterface> {
        return this.network.fetchGroupDetails(id);
    }

    fetchGroups(): Observable<AEGroupInterface[]> {
        return this.network.fetchGroupsList();
    }

    delete(id: number): Observable<any> {
        return this.network.delete(id);
    }

    fetchUsers(): Observable<UserInterface[]> {
        return this.network.fetchUsersList();
    }

    save(form: AEGroupInterface): Observable<AEGroupInterface> {
        form.expectedResponseTime = 60* 60;
        return form.id ? this.network.edit(form, form.id) : this.network.create(form);
    }
}