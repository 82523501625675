import {Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BreadcrumbComponent} from '../shared/components/breadcrumb.component';
import {CalendarView, CalendarEvent} from 'angular-calendar';
import {Subject} from 'rxjs';
import {
    endOfDay,
    endOfMonth,
    isBefore,
    isSameDay,
    isSameMonth,
    startOfDay,
    startOfMonth,
    subSeconds
} from 'date-fns';
import {EventCalendarInterface} from '../../../common/shift/interfaces/event-calendar.interface';
import {ShiftModalComponent} from './components/shift-modal/shift-modal.component';
import {ModalsService} from '../../../common/shared/services/modals.service';
import {ShiftService} from './services/shift.service';
import * as _ from 'lodash';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {TranslateService} from '@ngx-translate/core';

registerLocaleData(localeDe);

@Component({
  selector: 'app-shifts',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShiftComponent extends BreadcrumbComponent implements OnDestroy {

    view: CalendarView = CalendarView.Month;
    CalendarView = CalendarView;
    viewDate: Date = new Date();
    viewDateBeforeChange: Date = new Date();
    refresh: Subject<any> = new Subject();
    activeDayIsOpen = false;
    showAddShiftButton = false;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        private shiftService: ShiftService,
        private modalsService: ModalsService,
        private langService: TranslateService,
    ) {
        super(route, router);
    }

    ngOnInit(): void {
        this.initBreadcrumbs();
        this.shiftService.init(startOfMonth(this.viewDate), endOfMonth(this.viewDate));
    }

    actualLang(): string {
        return this.langService.currentLang;
    }

    openShiftModal(event: EventCalendarInterface = null, date: Date = null) {
        this.modalsService.custom(ShiftModalComponent, {
            width: '700px',
            disableClose: true,
            data: {
                lines: this.shiftService.lines,
                users: this.shiftService.users,
                event: event,
                date: date,
                shiftService: this.shiftService
            }
        }).subscribe((saved) => {
            if (!!saved?.data) {
                this.shiftService.loadEventsData();
            }
            // If shift is deleted then close the entire bar which is showing shift's listing
            if (!!saved?.isDeleted) {
                this.activeDayIsOpen = false;
            }
        });
    }

    get loading(): boolean {
        return this.shiftService.loading;
    }

    addShift() {
        this.openShiftModal(null, this.viewDate);
    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        if (!events.length && !isBefore(date, subSeconds(startOfDay(new Date()), 1))) {
            this.openShiftModal(null, date);
            return;
        }

        if (isSameMonth(date, this.viewDate)) {
            this.activeDayIsOpen = !((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0);
            this.viewDate = date;
            this.showAddShiftButton = isBefore(startOfDay(new Date()), subSeconds(endOfDay(date), 1));
        }
    }

    timeClicked(date: Date) {
        if (isBefore(date, new Date())) {
            return;
        }
        this.openShiftModal(null, date);
    }

    editEvent(event): void {
        this.openShiftModal(event);
    }

    dateChanged() {
        if (!isSameMonth(this.viewDate, this.viewDateBeforeChange)) {
            this.viewDateBeforeChange = _.cloneDeep(this.viewDate);
            this.shiftService.changeDate(startOfMonth(this.viewDate), endOfMonth(this.viewDate));
        }
    }

    ngOnDestroy(): void {
        this.shiftService.leaveSocketChannel();
    }

    calendarNavigationCalled() : void {
        // To close the bar for showing the shifts listing, if user change the month
        this.activeDayIsOpen = false;
    }
}
