import {Component, OnInit} from '@angular/core';
import {CsvParserService} from "../../../../../common/csv/services/csvParser.service";
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";

@Component({
    selector: 'app-import-alarm-reasons-from-csv',
    templateUrl: './import-alarm-reasons-from-csv.component.html',
    styleUrls: ['./import-alarm-reasons-from-csv.component.scss']
})
export class ImportAlarmReasonsFromCSVComponent implements OnInit {
    result: Array<any>;

    constructor(public dialogRef: MatDialogRef<ImportAlarmReasonsFromCSVComponent>, private csvParser: CsvParserService) {
    }

    ngOnInit(): void {
    }

    importFileChange(event) {
        const files = event.target.files;

        this.csvParser.parse(files[0], {
            headerAsKey: true,
            delimiter: ','
        }).pipe().subscribe((result: Array<any>) => {
            result.shift();
            this.result = result;
        }, (error) => {
            console.log('Error', error);
        });
    }

    get getKeys() {
        if (this.result[0]) {
            return [...Object.keys(this.result[0])];
        }
        return []
    }

    get getDisplayCols() {
        return [...this.getKeys, 'btns_col']
    }

    removeItem(el: any) {
        const i = this.result.indexOf(el);
        console.log(el, i);
        if (i >= 0)
            this.result.splice(i, 1);
        this.result = [...this.result];
    }

    save() {
        this.dialogRef.close(this.result)
    }
}
