<div>
    <div class="header">
        <button class="add-btn mat-fab" (click)="add()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <div class="table">
        <div class="table-row table-header">
            <div class="column-first">{{ 'SPA.DEVICE.FTP_SETTING.NR' | translate }}</div>
            <div class="column">{{ 'SPA.DEVICE.FTP_SETTING.NAME' | translate }}</div>
            <div class="column">{{ 'SPA.DEVICE.FTP_SETTING.TYPE' | translate }}</div>
            <div class="column">{{ 'SPA.DEVICE.FTP_SETTING.SLIDE_TIME' | translate }}</div>
            <div class="column">{{ 'SPA.DEVICE.FTP_SETTING.SOURCE' | translate }}</div>
            <div class="column"></div>
        </div>
        <div class="table-row body-row" *ngFor="let slide of slides; let i = index">
            <div class="column column-first">{{ 'SPA.DEVICE.FTP_SETTING.SLIDE' | translate }} {{i + 1}}</div>
            <div class="column">{{slide.name}}</div>
            <div class="column">
                <span *ngIf="isAndon(slide)">Andon screen</span>
                <span *ngIf="!isAndon(slide)">{{ 'SPA.DEVICE.FTP_SETTING.SLIDES' | translate}} {{slide.typeEnum}}</span>
            </div>
            <div class="column">{{slide.slideTime}}</div>
            <div class="column">
                <div class="paths"><span *ngFor="let path of slide.paths">{{path}}</span></div>
            </div>
            <div class="column actions">
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                    <button color="primary" mat-raised-button type="button" (click)="edit(slide)">
                        <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                  inline="true">edit</mat-icon>
                    </button>
                    <button color="warn" mat-raised-button type="button" (click)="delete(slide)">
                        <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                  inline="true">delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>