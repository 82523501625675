import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { NgForm } from "@angular/forms";
import { AlarmsAndEscalationsService } from '../../alarms-and-escalations.service';
import { AddTranslationsModalComponent } from '../../../../../common/shared/components/modals/add-translations/add-translations-modal.component';
import { CommonService } from '../../../../../common/shared/services/common.service';
import { TranslateService } from '../../../translate/services/translate.service';

@Component({
    selector: 'app-alarm-reasons-form',
    templateUrl: './alarm-reasons-form.component.html',
    styleUrls: ['./alarm-reasons-form.component.scss']
})
export class AlarmReasonsFormComponent implements OnInit {

    reason = {
        "reason": "",
        "code": "",
        "parentId": null,
        "configuredAlarms": [],
        "translationJson":{en:{name:''}, de:{name:''}, zh: {name: ''}}
    };

    selectedAlarms = [];
    alarms: any[] = [];

    constructor(public dialogRef: MatDialogRef<AlarmReasonsFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { item?: any, parent?: any },
        private alarmsAndEscalationsService: AlarmsAndEscalationsService, private commonService: CommonService,
        public translateService:TranslateService,
        public dialog: MatDialog
    ) {
        if (this.data.parent) {
            this.reason.parentId = this.data.parent.id;
        }

    }

    ngOnInit(): void {
        if (this.data.item && this.data.item.id) {
            this.reason = { ...this.data.item};
            this.selectedAlarms = [].concat(this.data.item.configuredAlarms);
            if(this.reason?.translationJson && !this.reason?.translationJson?.zh) {
                this.reason.translationJson.zh = {
                    name: ""
                };
            }
        }



        this.alarmsAndEscalationsService.getAllConfiguredAlarm().subscribe(res => {
            this.alarms = res;
        }, err => { });
        // if (this.data?.item) {
        //     this.reason = {...this.data.item}
        // } else {
        //     this.reason.alarms = this.alarms
        // }
    }

    save() {
        this.reason.reason = this.reason.translationJson[this.translateService.userLang].name;
        this.reason.configuredAlarms = [];
        for (let i = 0; i < this.selectedAlarms.length; i++) {
            this.reason.configuredAlarms.push(this.selectedAlarms[i])
        }


        if(this.data && this.data.item &&this.data.item.id){
            this.alarmsAndEscalationsService.updateReason(this.data.item.id,this.reason).subscribe(res=>{
                this.dialogRef.close(this.reason);
            });
        }else{
            this.alarmsAndEscalationsService.createReason(this.reason).subscribe(res => {
                this.dialogRef.close(res);
            });
        }



    }

    compareObjects(o1: any, o2: any) {
        if (o1.id == o2.id)
            return true;
        else return false
    }

    close() {
        this.dialogRef.close();
    }

    openAddTranslationModal(name: string) {
        if (name != '') {
            const dialogRef = this.dialog.open(AddTranslationsModalComponent, {
                width: '40%',
                disableClose: true,
                data: {
                    translationJson: this.reason?.translationJson || {},
                    key: name
                }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result.success) {
                    this.reason.translationJson = result.data;
                    if (!!result.isAddMore) {
                        this.openAddTranslationModal(name);
                    }
                }
            })
        }
    }

    getTranslatedAlarm(alarm): string {
        return this.commonService.getTranslatedName('name', alarm?.translationJson || null);
    }

}
