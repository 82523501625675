import {Component, OnInit} from '@angular/core';
import {RolesService} from './roles.service';
import {remove} from 'lodash-es';
import {ModalsService} from '../../../../common/shared/services/modals.service';
import {CreateRoleDialogComponent} from './create/createRoleDialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, switchMap} from 'rxjs/operators';
import { RoleInterface } from '../../../../common/auth/interfaces/role.interface';

@Component({
    styleUrls: ['roles.component.scss'],
    templateUrl: 'roles.component.html'
})
export class RolesComponent implements OnInit {
    public roles: RoleInterface[] = [];
    public loading = false;
    constructor(private rolesService: RolesService,
                private router: Router,
                private route: ActivatedRoute,
                private modalsService: ModalsService) {

    }

    public ngOnInit(): void {
        this.loading = true;
        this.rolesService.fetchRoles().subscribe((roles) => {
            this.roles = roles;
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    public removeRoleById(roleToDelete): void {
        if (roleToDelete.isAdminRole) {
            return;
        }
        this.modalsService.confirm('SPA.USERS.RemoveRoleConfirmation').subscribe(res => {
            if (res) {
                this.rolesService.removeRoleById(roleToDelete.id).subscribe(() => {
                    remove(this.roles, (role) => role.id === roleToDelete.id);
                });
            }
        });
    }

    public createRole(): void {
        this.modalsService.custom(CreateRoleDialogComponent)
            .pipe(
                filter(r => !!r),
                switchMap(roleName => this.rolesService.postRole(roleName))
            )
            .subscribe((role: RoleInterface) => this.router.navigate([role.id], {relativeTo: this.route}));
    }
}