import {Component, Input} from '@angular/core';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent{

    @Input() titleTranslate: string;
    @Input() onStation = false;
    @Input() translatable: boolean = true;

    calculateFontSize(): number {
        return this.onStation ? 40 : 15;
    }
}
