export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_EXTENDED = 'HH:mm:ss';
export const TIME_FORMAT_NO_HOURS = 'mm:ss';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_TIME_FORMAT_NO_SECONDS = 'YYYY-MM-DD HH:mm';
export const DATE_TIME_SECONDS_FORMAT_DOTS = 'DD.MM.YYYY HH:mm:ss';
export const DATE_TIME_FORMAT_DOTS = 'DD.MM.YYYY HH:mm:ss';
export const DATE_FORMAT_DOTS = 'DD.MM.YYYY';
export const DATE_TIME_SECONDS_FORMAT_NO_YEAR = 'DD.MM HH:mm:ss';
export const DATE_TIME_FORMAT_FOR_TIME_IN_DATA_COLLECTION = 'DD-MM-YYYY HH:mm:ss';
export const HOUR_IN_SECONDS = 3600;
export const MILLISECONDS_IN_SECOND = 1000;