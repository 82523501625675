import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { trackById } from '../../../../utils/track-by';
import icPerson from '@iconify/icons-ic/twotone-person';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icMoveToInbox from '@iconify/icons-ic/twotone-move-to-inbox';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import {AuthService} from '../../../../../app/auth/auth.service';
import { StorageService } from '../../../../../../common/shared/services/storage.service';

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

  trackById = trackById;
  icPerson = icPerson;
  icChevronRight = icChevronRight;
  icAccountCircle = icAccountCircle;
  icMoveToInbox = icMoveToInbox;

  constructor(private cd: ChangeDetectorRef,
              private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
              private storage: StorageService,
              public authService: AuthService,
  ) { }

  ngOnInit() {
  }

  close() {
    this.popoverRef.close();
  }

    logout() {
        this.authService.logout();
        this.close();
    }
}
