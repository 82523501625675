<div class="row ap-card shadow">
  <app-device-edit [isAndon]="true" [deviceId]="andonId"></app-device-edit>

  <page-header titleTranslate="SPA.DEVICE.EDIT.SETTINGS" class="mt-5"></page-header>

  <div class="row">
    <div class="col s12 path">
      <app-andon-ftp-listing [stationId]="andonId"></app-andon-ftp-listing>
    </div>
  </div>
  <div class="row mt-6">
    <div class="col s12 path">
      <page-header titleTranslate="SPA.DEVICE.ANDON.CONTROLS"></page-header>
      <div class="list" *ngIf="slideshow">
        <div class="status {{ slideshow.status }}">
          {{ 'ENUMS.SLIDESHOW_STATUS.' + SlideshowStatusEnum[slideshow.status] | translate }}
        </div>
        <div class="item" *ngFor="let button of slideshow.buttons">
          <button
            color="primary"
            mat-raised-button
            class="filter-btn"
            (click)="clickButton(button.typeEnum)"
            [disabled]="slidesControlsService.isLoading"
          >
            <ng-container *ngIf="button.typeEnum === SlideshowButtonEnum.START_PAUSE">
              <mat-icon>play_arrow</mat-icon>
              <mat-icon>pause</mat-icon>
            </ng-container>
            <ng-container *ngIf="button.typeEnum === SlideshowButtonEnum.FORWARD">
              <mat-icon>fast_forward</mat-icon>
            </ng-container>
            <ng-container *ngIf="button.typeEnum === SlideshowButtonEnum.REWIND">
              <mat-icon>fast_rewind</mat-icon>
            </ng-container>
          </button>
          <ng-container *apConfiguration="[ConfigurationEnum.IO_MODULE]">
            <div class="io-select">
              <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                <mat-label>{{ 'ENUMS.SLIDESHOW_BUTTON.' + SlideshowButtonEnum[button.typeEnum] | translate}} </mat-label>
                  <mat-select
                    [(ngModel)]="button.ioId"
                    (ngModelChange)="setIo(button, $event)"
                  >
                    <ng-container *ngFor="let io of allIos">
                      <mat-option
                        [value]="io.id"
                        *ngIf="!io.connections.length || io.id === button.ioId"
                      >
                        {{ io.name }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
              </mat-form-field>
            <button class="deleteBtn" color="warn" mat-raised-button type="button" (click)="setIo(button, null)">
              <mat-icon inline="true">delete</mat-icon>
            </button>
          </div>
        </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>