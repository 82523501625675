import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { EventEndsOnEnum } from '../../../shifts/enums/event-ends-on.enum';
import { EventRepeatEnum } from '../../../../../common/schedule/enums/event-repeat.enum';
import { CalendarPeriodInterface } from '../../../../../common/schedule/interfaces/calendar-period.interface';

@Component({
  selector: 'app-periodicity',
  templateUrl: './periodicity.component.html',
  styleUrls: ['./periodicity.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PeriodicityComponent implements OnInit {

    repeatEnum = EventRepeatEnum;
    weekdays: string[];
    endsOn: EventEndsOnEnum = EventEndsOnEnum.NEVER;
    EventEndsOnEnum = EventEndsOnEnum;
    today = moment();

    @Input() period: CalendarPeriodInterface;
    @Input() isShiftType = false;

    constructor(
        private translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.init();
    }

    init() {
        this.setWeekdays();
        this.period.repeatEvery.repeatEnum = EventRepeatEnum.EVERY_WEEK;
    }

    showWeekDaysOptions(): boolean {
        return this.period.repeatEvery.repeatEnum === EventRepeatEnum.EVERY_WEEK;
    }

    setWeekdays() {
        moment.locale(this.translateService.currentLang);
        this.weekdays = Array.apply(null, Array(7)).map(function (_, i) {
            return moment(i, 'e').startOf('week').isoWeekday(i + 1).format('ddd');
        });
    }

    isDaySelected(day: string): boolean {
        return this.showWeekDaysOptions() && this.period.repeatOn && this.period.repeatOn.daysOfWeek && this.period.repeatOn.daysOfWeek.includes(this.getDayNumber(day));
    }

    toggleSpecificDay(day: string) {
        const dayNumber = this.getDayNumber(day);
        if (this.period.repeatOn.daysOfWeek.includes(dayNumber)) {
            if (this.period.repeatOn.daysOfWeek.length > 1) {
                const index = this.period.repeatOn.daysOfWeek.indexOf(dayNumber);
                if (index !== -1) {
                    this.period.repeatOn.daysOfWeek.splice(index, 1);
                }
            }
        } else {
            this.period.repeatOn.daysOfWeek.push(dayNumber);
        }
    }

    endsOnChanged() {
        this.period.ends.on = null;
        this.period.ends.after = null;
    }

    getDayNumber(day: string): number {
        const dayNumber = moment().isoWeekday(day).isoWeekday();
        return dayNumber === 7 ? 0 : dayNumber;
    }
}
