<page-header titleTranslate="SPA.USERS.CreateRoleHeader"></page-header>
<div mat-dialog-content>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.RoleName' | translate }}</mat-label>
            <input matInput type="text" name="searchPhrase" #nameInputRef>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="mt-10 footer">
        <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
            <button color="warn" mat-button type="button" [mat-dialog-close]="false"> {{ 'COMMON.CANCEL' | translate }}</button>
            <button color="primary" mat-raised-button [mat-dialog-close]="nameInputRef.value" [disabled]="!nameInputRef.value">
                {{ 'COMMON.SAVE' | translate }}
            </button>
        </div>
    </div>
</div>