export class ColorsFormatUtil {
    static hexToRGB(hex: string) {
      return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
              ,(m, r, g, b) => '#' + r + r + g + g + b + b)
              .substring(1).match(/.{2}/g)
              .map(x => parseInt(x, 16));
    }

    static rgbArrayToRgba(rgb: number[], alfa: number) {
        return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${alfa})`;
    }

    static hexToRgba(hex: string, alfa: number) {
        const rgb = this.hexToRGB(hex);

        return this.rgbArrayToRgba(rgb, alfa);
    }
}