<div #chartContainer class="chart" (resized)="widgetResized()">
  <div
    class="time-section"
    *ngIf="checkLineInterrupted() && chartOptions"
    [ngStyle]="{ color: fontColor }"
  >
    <ng-container *ngIf="showTime">
      <div class="clock-text" [ngStyle]="{ height: fontResizeHeight*0.75 + 'px', width: fontResizeWidth + 'px', fontSize : calcFontSize}">{{ time | duration }}</div>
      <div class="clock-text" [ngStyle]="{ height: fontResizeHeight*0.75 + 'px', width: fontResizeWidth + 'px', fontSize : calcFontSize}">{{ label | translate }}</div>
    </ng-container>
    <ng-container *ngIf="!showTime">
      <div class="clock-text" [ngStyle]="{ height: fontResizeHeight*0.75 + 'px', width: fontResizeWidth + 'px', fontSize : calcFontSize}">{{ label | translate }}</div>
    </ng-container>
  </div>
  <div
    class="time-section"
    *ngIf="!checkLineInterrupted() && chartOptions"
    [ngStyle]="{ color: fontColor }"
  >
    <!-- Display Pause message -->
    <ng-container *ngIf="lineInterruptData && lineInterruptData.pauseActive && lineInterruptData?.lineStop === null">
      <div class="clock-text" [ngStyle]="{ height: fontResizeHeight*0.75 + 'px', width: fontResizeWidth + 'px', fontSize : calcFontSize}">{{ 'STATION.GENERAL.PAUSE' | translate }}</div>

      <div class="clock-text"
        [ngStyle]="{ height: fontResizeHeight*0.75 + 'px', width: fontResizeWidth + 'px', fontSize : calcFontSize}">
        {{ lineInterruptData.pauseName | truncateEllipsis }}
      </div>
      
      <div class="clock-text" [ngStyle]="{ height: fontResizeHeight*0.75 + 'px', width: fontResizeWidth + 'px', fontSize : calcFontSize}">{{ lineInterruptData.pauseTimeLeft | duration }}</div>
    </ng-container>
    <!-- NO ACTIVE Shift message -->
    <ng-container *ngIf="!hasActiveShift && nextShift === null">

      <div class="clock-text" [ngStyle]="{ height: fontResizeHeight*0.75 + 'px', width: fontResizeWidth + 'px', fontSize : calcFontSize}">{{ 'STATION.GENERAL.NO_ACTIVE_SHIFT' | translate }}</div>
    </ng-container>
    <!-- display next shift text -->
    <ng-container *ngIf="!hasActiveShift && nextShift !== null">
      <div class="clock-text" [ngStyle]="{ height: fontResizeHeight*0.75 + 'px', width: fontResizeWidth + 'px', fontSize : calcFontSize}">{{ 'STATION.GENERAL.NEXT_SHIFT' | translate }}</div>

      <div class="clock-text"
        [ngStyle]="{ height: fontResizeHeight*0.75 + 'px', width: fontResizeWidth + 'px', fontSize : calcFontSize}">
        {{ nextShift.shift.name | truncateEllipsis }}
      </div>

      <div class="clock-text" [ngStyle]="{ height: fontResizeHeight*0.75 + 'px', width: fontResizeWidth + 'px', fontSize : calcFontSize}">{{ nextShift.start | date: 'dd.MM.yyyy' }}</div>
      
      <div class="clock-text" [ngStyle]="{ height: fontResizeHeight*0.75 + 'px', width: fontResizeWidth + 'px', fontSize : calcFontSize}">{{ nextShift.start | date: 'HH:mm' }} - {{ nextShift.end | date: 'HH:mm' }}</div>
    </ng-container>
    <!-- Display text when line is stop -->
    <ng-container *ngIf="hasActiveShift && lineInterruptData?.lineStop !== null">
      <div class="clock-text" [ngStyle]="{ height: fontResizeHeight*0.75 + 'px', width: fontResizeWidth + 'px', fontSize : calcFontSize}">{{ 'STATION.GENERAL.LINE_STOP' | translate }}</div>
      <div class="clock-text" [ngStyle]="{ height: fontResizeHeight*0.75 + 'px', width: fontResizeWidth + 'px', fontSize : calcFontSize}">{{ lineInterruptData?.lineStopTime | duration }}</div>
      <div class="clock-text" [ngStyle]="{ height: fontResizeHeight*0.75 + 'px', width: fontResizeWidth + 'px', fontSize : calcFontSize}">{{ 'STATION.GENERAL.LINE_STOP_REASON' | translate }}</div>
      <div class="clock-text"
        [ngStyle]="{ height: fontResizeHeight*0.75 + 'px', width: fontResizeWidth + 'px', fontSize : calcFontSize}">
        {{ lineInterruptData?.lineStop | truncateEllipsis }}
      </div>
    </ng-container>
  </div>
  <apx-chart
    #chartComponent
    *ngIf="chartOptions"
    [ngClass]="{ station: onStation }"
    [series]="[percent]"
    [chart]="chartOptions.chart"
    [plotOptions]="chartOptions.plotOptions"
    [title]="chartOptions.title"
    [fill]="chartOptions.fill"
    [stroke]="chartOptions.stroke"
    [labels]="[]"
  ></apx-chart>
</div>
