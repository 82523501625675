import {Injectable} from '@angular/core';
import {SlidesNetwork} from '../networks/slides.network';
import {Observable} from 'rxjs';
import { SlideshowDataInterface } from '../interfaces/slideshow-data.interface';
import { FtpSlideInterface } from '../../../../common/ftp/interfaces/ftp-slide.interface';

@Injectable()
export class SlidesService {
    constructor(private network: SlidesNetwork) {
    }

    getSlideGroups(stationId: number): Observable<SlideshowDataInterface> {
        return this.network.fetchGroupsList(stationId);
    }

    save(stationId: number, form: FtpSlideInterface): Observable<FtpSlideInterface> {
        return !!form.id ? this.network.edit(stationId, form) : this.network.create(stationId, form);
    }

    delete(stationId: number, slideId: number): Observable<any> {
        return this.network.delete(stationId, slideId);
    }
}