import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BreadcrumbComponent} from '../shared/components/breadcrumb.component';

@Component({
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent extends BreadcrumbComponent implements OnInit {
    public menuItems = [
        {
            path: '/users/list',
            name: 'SPA.USERS.UserList',
            icon: 'supervisor_account'
        },
        {
            path: '/users/roles',
            name: 'SPA.USERS.Roles',
            icon: 'loyalty'
        }
    ];

    constructor(
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(route, router);
    }

    ngOnInit(): void {
        this.initBreadcrumbs();
    }

    getCurrentItemName() {
        let currentItem = null;

        this.menuItems.forEach((item) => {
            if (this.router.isActive(item.path, false)) {
                currentItem = item;
            }
        });
        return currentItem ? currentItem.name : '';
    }
}
