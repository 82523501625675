import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisorderComponent } from './disorder.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    MaterialFileInputModule,
    MatProgressBarModule
  ],
  declarations: [
    DisorderComponent,
],
  providers: [
  ]
})
export class DisorderModule { }
