import {
  Component,
  Inject,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";
import { InfoboxService } from "../../../../shared/services/infobox.service";
import { Subject, debounceTime, Subscription } from 'rxjs';

declare var MediaRecorder: any;
@Component({
  selector: "capture-image-video",
  templateUrl: "./capture-image-video.component.html",
  styleUrls: ["./capture-image-video.component.scss"],
})
export class CaptureImageVideoComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public isLoadingConfiguredAlarms: boolean = true;
  @ViewChild("videoStream") videoStream: ElementRef;
  @ViewChild("recordedVideo") recordedVideo: ElementRef;
  @ViewChild("imageCanvas") imageCanvas: ElementRef;
  videoStreamData;
  imageURL;
  mediaRecorder: any;
  recordedBlobs: Blob[];
  isRecordingStart: boolean = false;
  displayStream = false;
  recordingDuration = "00:00";
  duration = 0;
  recordingInterval;
  displayMessage = false;
  isMobile: boolean = false;;
  deviceCameraResolutionWidth: number = 1080;
  deviceCameraResolutionHeight: number = 720;
  isLoading: boolean = false;
  cameraResolutionList = [
    { width: 7680, height: 4320 },  // 8K UHD
    { width: 3840, height: 2160 },  // 4K UHD
    { width: 2880, height: 1800 },  // WQXGA+ (QHD+)
    { width: 2560, height: 1600 },  // WQXGA
    { width: 2560, height: 1440 },  // QHD
    { width: 1920, height: 1080 },  // Full HD
    { width: 1600, height: 900 },   // HD+ (HD Plus)
    { width: 1440, height: 900 },   // HD+
    { width: 1280, height: 800 },   // WXGA
    { width: 1280, height: 720 },   // HD
    { width: 960, height: 540 },    // qHD
    { width: 854, height: 480 },    // FWVGA
    { width: 640, height: 480 },    // VGA
    { width: 320, height: 240 },    // QVGA
  ];
  captureSubject: Subject<void> = new Subject<void>();
  private captureSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<CaptureImageVideoComponent>,
    private _sanitizer: DomSanitizer,
    private infoBoxService: InfoboxService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    // Debounce the takeScreenShot function to prevent multiple rapid calls
    this.captureSubscription = this.captureSubject.pipe(debounceTime(100)).subscribe(() => {
      this.takeScreenShot();
    });
  }

  ngAfterViewInit() {
    const _image = this.videoStream.nativeElement;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this.videoStream.nativeElement.volume = 0;
      navigator.mediaDevices
        .getUserMedia({
          audio:true,
          video: {
            width: { ideal: 7680 },
            height: { ideal: 4320 },
            facingMode: "environment",
            advanced: this.cameraResolutionList,
          },
        })
        .then(
          (stream) => {
            const deviceVideoElement = document.createElement('video');
            deviceVideoElement.srcObject = stream;
            deviceVideoElement.onloadedmetadata = () => {
              // Get the video dimensions
              this.deviceCameraResolutionWidth = deviceVideoElement.videoWidth;
              this.deviceCameraResolutionHeight = deviceVideoElement.videoHeight;

              _image.srcObject = stream;
              _image.play();
              this.videoStreamData = stream;
              this.displayStream = true;
            }
          },
          (err) => {
            console.log(err);
            this.close();
            this.infoBoxService.error("No camera connected");
          }
        );
    } else {
      console.error('getUserMedia not supported on this browser/device.');
    }
  }

  captureScreenshot() {
    this.isLoading = true;
    this.videoStream?.nativeElement?.pause();
    this.captureSubject.next();
  }

  // To take picture from video stream
  takeScreenShot() {
    const height = this.deviceCameraResolutionHeight;
    const width = this.deviceCameraResolutionWidth;

    this.imageCanvas.nativeElement
      .getContext("2d")
      .drawImage(this.videoStream.nativeElement, 0, 0, width, height);
    let image = this.getImageBlob(
      this.imageCanvas.nativeElement.toDataURL("image/png")
    );

    let objectURL = URL.createObjectURL(image);
    this.imageURL = this._sanitizer.bypassSecurityTrustUrl(objectURL);
    this.close(this.imageURL, true);
    this.endStream();
    this.videoStream?.nativeElement?.play();
    this.isLoading = false;
  }

  getImageBlob(data) {
    const contentType = "image/png";
    const newData = data.replace(/^data:image\/(png|jpeg|jpg);base64,/, "");
    return this.getBlobData(newData, contentType);
  }

  getBlobData(b64Data, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  startRecording() {
    this.recordedBlobs = [];
    let options: any = { mimeType: "video/webm;codecs=vp8" };
    if (!MediaRecorder.isTypeSupported(options.mimeType)) {
      options = { mimeType: "video/webm" };
    }
    if (!MediaRecorder.isTypeSupported(options.mimeType)) {
      options = { mimeType: "video/mp4" };
    }
    try {
      this.mediaRecorder = new MediaRecorder(this.videoStreamData, options);
    } catch (err) {
      console.log(err);
    }

    this.mediaRecorder.start();
    this.recordingInterval = setInterval(() => {
      this.duration = this.duration + 1;
      this.recordingDuration =
        "00:" + (this.duration > 9 ? this.duration : "0" + this.duration);
      if (this.duration === 51) {
        this.displayMessage = true;
      }
      if (this.duration === 60) {
        this.stopRecording();
      }
    }, 1000);
    this.isRecordingStart = true; // collect 100ms of data
    this.onDataAvailableEvent();
    this.onStopRecordingEvent();
  }

  stopRecording() {
    this.mediaRecorder.stop();
    this.isRecordingStart = false;
    clearInterval(this.recordingInterval);
  }

  onDataAvailableEvent() {
    try {
      this.mediaRecorder.ondataavailable = (event: any) => {
        if (event.data && event.data.size > 0) {
          this.recordedBlobs.push(event.data);
        }
      };
    } catch (error) {
      console.log(error);
    }
  }

  onStopRecordingEvent() {
    try {
      this.mediaRecorder.onstop = (event: Event) => {
        const videoBuffer = new Blob(this.recordedBlobs, {
          type: "video/webm",
        });

        let objectURL = window.URL.createObjectURL(videoBuffer);
        this.close(this._sanitizer.bypassSecurityTrustUrl(objectURL), true);
      };
    } catch (error) {
      console.log(error);
    }
  }

  close(data = undefined, success = false) {
    this.dialogRef.close({
      success: success,
      data,
    });
  }

  endStream() {
    if (this.videoStream && this.videoStreamData) {
      this.videoStreamData.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  }

  ngOnDestroy() {
    this.endStream();
    if(this.captureSubscription) {
      this.captureSubscription.unsubscribe()
    }
  }

  getVideoStreamHeight(){
    return this.videoStream?.nativeElement?.clientHeight || 480;
  }

  getVideoStreamWidth(){
    return this.videoStream?.nativeElement?.clientWidth || 640;
  }
}
