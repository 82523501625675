import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { LanguageEnum } from '../../../enums/language.enum';

@Component({
  selector: 'add-translations-modal',
  templateUrl: './add-translations-modal.component.html',
  styleUrls: ['./add-translations-modal.component.scss'],
})
export class AddTranslationsModalComponent implements OnInit {
 
  languages = LanguageEnum;
  selectedLanguage: string;
  translationText: string = ''
  
  public isLoadingConfiguredAlarms: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<AddTranslationsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
  }

  ok(isAddMore: boolean = false) {
    const json = this.data.translationJson;
    if (json[this.selectedLanguage]) {
      json[this.selectedLanguage][this.data.key] = this.translationText;
    } else {
      json[this.selectedLanguage] = {
        [this.data.key]: this.translationText,
      };
    }
    this.dialogRef.close({
      success: true,
      isAddMore,
      data: json
    });
  }

  getTranslationByLang(language, isReturn: boolean = false) {
    let translationValue: string = '';
    if (!!this.data.translationJson && this.data.translationJson.hasOwnProperty(language) && this.data.translationJson[language].hasOwnProperty(this.data.key)) {
      translationValue = this.data.translationJson[language][this.data.key]
    } else {
      translationValue = this.data.key;
    }
    if (isReturn) {
      return translationValue;
    } else {
      this.translationText = translationValue;
    }
  }

  // getTranslation(lang, ): string {
  //   if (!!this.data.translationJson && this.data.translationJson.hasOwnProperty(lang) && this.data.translationJson[lang].hasOwnProperty(this.data.key)) {
  //     return this.data.translationJson[lang][this.data.key];
  //   }
  //   return this.data.key;
  // }

  cancel() {
    this.dialogRef.close({
      success: false
    });
  }


}
