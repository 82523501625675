import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import { DATE_TIME_FORMAT } from '../../../../common/shared/constants/time.constants';
import { LineInterface } from '../../../../common/line/interfaces/line.interface';
import {ReportExportInterface} from '../interfaces/report-export.interface';
import { ReportTemplateInterface } from '../../../../common/reports/interfaces/report-template.interface';

@Injectable()
export class ReportsNetworkService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getLines(): Observable<LineInterface[]> {
    return this.http.get<LineInterface[]>('/line');
  }

  createExport(exportObj: ReportExportInterface): Observable<any> {
    exportObj['timezone'] = moment.tz.guess();
    const createObj = {...exportObj};
    createObj.beginDate = exportObj.beginDate
      ? moment(exportObj.beginDate as string).startOf('day').format(DATE_TIME_FORMAT)
      : null;
    createObj.endDate = exportObj.endDate
      ? moment(exportObj.endDate as string).endOf('day').format(DATE_TIME_FORMAT)
      : null;

    return this.http.post<ReportExportInterface>(`/report-export`, createObj);
  }

  deleteExport(exportId: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`/report-export/${exportId}`);
  }

  getTemplates(): Observable<ReportTemplateInterface[]> {
    return this.http.get<ReportTemplateInterface[]>('/report-template');
  }

  deleteTemplate(templateId: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`/report-template/${templateId}`);
  }

  createTemplate(formData: FormData) {
    const params = new HttpParams({
      fromObject: {
        noContentType: 'true'
      }
    });
    return this.http.post<ReportTemplateInterface>(`/report-template`, formData, {
      params
    });
  }

  downloadTemplate(templateId: number): Observable<any> {
    return this.http.get(`/report-template/download/${templateId}`, {
      responseType: 'blob'
    });
  }
}