import icLayers from '@iconify/icons-ic/twotone-layers';
import icList from '@iconify/icons-ic/list';
import icCharts from '@iconify/icons-ic/outline-insert-chart';
import icTimelaps from '@iconify/icons-ic/timelapse';
import icAssignment from '@iconify/icons-ic/assignment';
import icGrid from '@iconify/icons-ic/grid-on';
import icAlarm from '@iconify/icons-ic/error-outline';
import icNotify from '@iconify/icons-ic/notifications-active';
import icSettings from '@iconify/icons-ic/settings';
import icUsers from '@iconify/icons-ic/supervisor-account';
import icProducts from '@iconify/icons-ic/track-changes';
import icSave from '@iconify/icons-ic/save';
import settings_applications from '@iconify/icons-ic/settings-applications';
import icTv from '@iconify/icons-ic/tv';
import mobile from '@iconify/icons-ic/stay-current-portrait';
import developer_board from '@iconify/icons-ic/developer-board';
import timer from '@iconify/icons-ic/timer';
import settings_remote from '@iconify/icons-ic/settings-remote';
import {NavigationItem} from '../@vex/interfaces/navigation-item.interface';
import { ConfigurationEnum } from '../../common/configuration/enums/configuration.enum';
import { UserPermissionsEnum } from '../../common/auth/enums/user-permissions.enum';

export const SIDENAV_ITEMS: NavigationItem[] = [
    {
        type: 'link',
        label: 'SPA.NAV.DASHBOARD',
        route: '/dashboard',
        permissions: [],
        configurationEnum: null,
        icon: icLayers
    },
    {
        type: 'link',
        label: 'SPA.NAV.CHARTS',
        route: '/charts',
        permissions: [UserPermissionsEnum.ChartsPage],
        configurationEnum: ConfigurationEnum.CHARTS,
        icon: icCharts
    },
    {
        type: 'dropdown',
        label: 'SPA.NAV.USERS',
        permissions: [UserPermissionsEnum.UserManagement],
        configurationEnum: null,
        icon: icUsers,
        route: '/users',
        children: [
            {
                type: 'link',
                label: 'SPA.USERS.UserList',
                route: '/users/list',
                permissions: [UserPermissionsEnum.UserManagement],
                configurationEnum: null,
            },
            {
                type: 'link',
                label: 'SPA.USERS.Roles',
                route: '/users/roles',
                permissions: [UserPermissionsEnum.UserManagement],
                configurationEnum: null,
            },
        ]
    },
    {
        type: 'link',
        label: 'SPA.NAV.LINES',
        route: '/line',
        permissions: [UserPermissionsEnum.LineManagement],
        configurationEnum: null,
        icon: icList
    },
    {
        type: 'link',
        label: 'SPA.NAV.ANDON',
        route: '/andon',
        permissions: [UserPermissionsEnum.AndonManagement],
        configurationEnum: null,
        icon: icTv
    },
    {
        type: 'link',
        label: 'SPA.NAV.SHIFTS',
        route: '/shifts',
        permissions: [UserPermissionsEnum.ShiftManagement],
        configurationEnum: null,
        icon: icTimelaps
    },
    {
        type: 'dropdown',
        label: 'SPA.NAV.INTERFACES',
        configurationEnum: ConfigurationEnum.IO_MODULE,
        permissions: [UserPermissionsEnum.IOModuleConfig, UserPermissionsEnum.IOMapping],
        icon: developer_board,
        route: '/io-modules',
        children: [
            {
                type: 'link',
                label: 'SPA.NAV.IO_MODULES',
                route: '/io-modules/config',
                permissions: [UserPermissionsEnum.IOModuleConfig],
                configurationEnum: null,
                icon: developer_board
            },
            {
                type: 'link',
                label: 'SPA.NAV.IO_MAPPING',
                route: '/io-modules/mapping',
                permissions: [UserPermissionsEnum.IOMapping],
                configurationEnum: null,
            },
            {
                type: 'link',
                label: 'SPA.NAV.MQTT_TOPICS',
                route: '/io-modules/mqtt-topics',
                permissions: [],
                configurationEnum: null,
            },
        ]
    },
    {
        type: 'dropdown',
        label: 'SPA.NAV.REPORTS',
        permissions: [UserPermissionsEnum.ReportsPage],
        configurationEnum: ConfigurationEnum.REPORTS,
        icon: icAssignment,
        route: '/reports',
        children: [
            {
                type: 'link',
                label: 'SPA.EXPORTS.LABEL',
                route: '/reports/export',
                permissions: [UserPermissionsEnum.ReportsPage],
                configurationEnum: ConfigurationEnum.REPORTS,
            },
            {
                type: 'link',
                label: 'SPA.EXPORTS.TEMPLATE_BTN',
                route: '/reports/templates',
                permissions: [UserPermissionsEnum.ReportsPage],
                configurationEnum: ConfigurationEnum.REPORTS,
            },
        ]
    },
    // need to set permission and config enum for order page
    {
        type: 'dropdown',
        label: 'SPA.NAV.ORDERS',
        permissions: [UserPermissionsEnum.ProductsManagement],
        configurationEnum: ConfigurationEnum.TRACK_AND_TRACE,
        icon: icAssignment,
        route: '/orders',
        children: [
            {
                type: 'link',
                label: 'SPA.NAV.DATABASE_CONFIGURATION',
                route: '/orders/database-configuration',
                permissions: [UserPermissionsEnum.ProductsManagement],
                configurationEnum: ConfigurationEnum.TRACK_AND_TRACE,
            },
        ]
    },
    {
        type: 'dropdown',
        label: 'SPA.NAV.PRODUCTS',
        permissions: [UserPermissionsEnum.ProductsManagement],
        configurationEnum: ConfigurationEnum.TRACK_AND_TRACE,
        icon: icProducts,
        route: '/products',
        children: [
            {
                type: 'link',
                label: 'PRODUCTS.ITEMS.HEADER',
                route: '/products/items',
                permissions: [UserPermissionsEnum.ProductsManagement],
                configurationEnum: ConfigurationEnum.TRACK_AND_TRACE,
                icon: icProducts
            },
            {
                type: 'link',
                label: 'SPA.NAV.PRODUCTS_SETTINGS',
                route: '/products/validators',
                permissions: [UserPermissionsEnum.ProductsManagement],
                configurationEnum: ConfigurationEnum.TRACK_AND_TRACE,
                icon: icProducts
            },
            {
                type: 'link',
                label: 'SPA.NAV.PRODUCTS',
                route: '/products/details',
                permissions: [UserPermissionsEnum.ProductsManagement],
                configurationEnum: ConfigurationEnum.TRACK_AND_TRACE,
                icon: icProducts
            }
        ]
    },
    {
        type: 'dropdown',
        label: 'SPA.NAV.ALARMS_AND_ESCALATIONS',
        permissions: [
            UserPermissionsEnum.AEAlarmDashboardManagement,
            UserPermissionsEnum.AEAlarmsListingManagement,
            UserPermissionsEnum.AEGroupsManagement,
            UserPermissionsEnum.AEDisorderReasonManagement
        ],
        configurationEnum: ConfigurationEnum.ALARMS_AND_ESCALATIONS,
        icon: icAlarm,
        route: '/alarms-and-escalations',
        children: [
            {
                type: 'link',
                label: 'SPA.ALARMS_ESCALATIONS.DASHBOARD.HEADER',
                route: '/alarms-and-escalations/dashboard',
                permissions: [
                    UserPermissionsEnum.AEAlarmDashboardManagement,
                ],
                configurationEnum: ConfigurationEnum.ALARMS_AND_ESCALATIONS,
            },
            {
                type: 'link',
                label: 'SPA.ALARMS_ESCALATIONS.ALARMS.HEADER',
                route: '/alarms-and-escalations/alarms',
                permissions: [
                    UserPermissionsEnum.AEAlarmsListingManagement,
                ],
                configurationEnum: ConfigurationEnum.ALARMS_AND_ESCALATIONS,
            },
            {
                type: 'link',
                label: 'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.HEADER',
                route: '/alarms-and-escalations/alarm-group',
                permissions: [
                    UserPermissionsEnum.AEGroupsManagement,
                ],
                configurationEnum: ConfigurationEnum.ALARMS_AND_ESCALATIONS,
            },
            {
                type: 'link',
                label: 'SPA.ALARMS_ESCALATIONS.ALARM_REASONS.HEADER',
                route: '/alarms-and-escalations/alarm-reasons',
                permissions: [
                    UserPermissionsEnum.AEDisorderReasonManagement,
                ],
                configurationEnum: ConfigurationEnum.ALARMS_AND_ESCALATIONS,
            },
        ]
    },
    {
        type: 'link',
        label: 'SPA.NAV.EVENTS_AND_ACTIONS',
        route: '/events-and-actions',
        permissions: [UserPermissionsEnum.EventsAndActionsManagement],
        configurationEnum: null,
        icon: icNotify
    },
    {
        type: 'dropdown',
        label: 'SPA.DEVICE_CONFIGURATOR.LABEL',
        route: '/device-configurator/list',
        // Need to add/update permission and configuration enum
        permissions: [UserPermissionsEnum.DeviceConfigurationManagement],
        configurationEnum: ConfigurationEnum.DEVICE_CONFIGURATION,
        icon: icGrid,
        children: [
            {
                type: 'link',
                label: 'SPA.NAV.USER_INTERFACES',
                route: '/device-configurator/list',
                permissions: [],
                configurationEnum: null,
                icon: icGrid
            },
            {
                type: 'link',
                label: 'SPA.NAV.SMARTWATCHES',
                route: '/smartwatches',
                permissions: [],
                configurationEnum: null,
                icon: icGrid
            },
        ],
    },
    {
        type: 'dropdown',
        label: 'SPA.NAV.SYSTEM_SETTINGS',
        permissions: [UserPermissionsEnum.SystemSettingsManagement],
        configurationEnum: null,
        icon: settings_applications,
        route: '/email-configuration',
        children: [
            {
                type: 'link',
                label: 'SPA.NAV.EMAIL_CONFIGURATION',
                route: '/email-configuration',
                permissions: [UserPermissionsEnum.EmailSettingsManagement],
                configurationEnum: null,
                icon: icProducts
            },
            {
                type: 'link',
                label: 'SPA.NAV.FTP_CONNECTION',
                route: '/ftp-configuration',
                permissions: [UserPermissionsEnum.FtpSettingsManagement],
                configurationEnum: ConfigurationEnum.FTP,
                icon: icProducts
            },
            {
                type: 'link',
                label: 'SPA.NAV.BACKUP',
                route: '/backup',
                permissions: [UserPermissionsEnum.BackupSettingsManagement],
                configurationEnum: null,
                icon: icSave
            },
            {
                type: 'link',
                label: 'SPA.NAV.TIMEZONE',
                route: '/timezone',
                permissions: [UserPermissionsEnum.ClockSettingsManagement],
                configurationEnum: null,
                icon: timer
            },
            {
                type: 'link',
                label: 'SPA.NAV.MQTT_CONNECTION_DETAILS',
                route: '/mqtt-connection-details',
                permissions: [UserPermissionsEnum.Public],
                configurationEnum: null,
                icon: settings_remote
            },
            {
                type: 'link',
                label: 'SPA.NAV.GENERAL_SETTINGS',
                route: '/general-settings',
                permissions: [UserPermissionsEnum.GeneralSettingsManagement],
                configurationEnum: null,
            },
        ]
    },
    {
        type: 'link',
        label: 'SPA.NAV.SYSTEM_CONFIGURATION',
        route: '/system-configuration',
        permissions: [UserPermissionsEnum.SuperAdmin],
        configurationEnum: null,
        icon: icSettings
    },
    {
        type: 'link',
        label: 'SPA.NAV.MOBILE',
        route: '/mobile-app',
        permissions: [UserPermissionsEnum.SuperAdmin],
        configurationEnum: null,
        icon: mobile
    },
];
