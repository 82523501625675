import { Component, OnInit, ViewChild } from '@angular/core';
import { EmailConfigurationService } from './email-configuration.service';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import {BreadcrumbComponent} from '../../shared/components/breadcrumb.component';
import {ActivatedRoute, Router} from '@angular/router';
import { EmailConfigurationInterface } from '../../../../common/email/interfaces/email-configuration.interface';
import { InfoboxService } from '../../../../common/shared/services/infobox.service';
import { ModalsService } from '../../../../common/shared/services/modals.service';

@Component({
  selector: 'app-email-configuration',
  templateUrl: './email-configuration.component.html',
  styleUrls: ['./email-configuration.component.scss']
})
export class EmailConfigurationComponent extends BreadcrumbComponent implements OnInit {
  checkEmailStatus: boolean = false;
  inProgress = false;
  checking: boolean = false;
  hasConfiguration = false;
  @ViewChild('emailConfigForm') emailConfigForm: NgForm;

  public configuration: EmailConfigurationInterface;

  constructor(
    private emailConfigurationService: EmailConfigurationService,
    private infoboxService: InfoboxService,
    private _location: Location,
    private modalsService: ModalsService,
    public route: ActivatedRoute,
    public router: Router
  ) {
      super(route, router);
  }

  ngOnInit() {
    this.initEmptyConfiguration();
    this.initBreadcrumbs();
    this.emailConfigurationService.getConfiguration().subscribe(data => {
      if (data) {
        this.configuration = data;
        this.hasConfiguration = true;
      }
    });
  }

  initEmptyConfiguration() {
    this.configuration = {
      address: '',
      host: '',
      port: '',
      ssl: false,
      login: '',
      password: '',
      serverHost: ''
    };
  }

  goBack() {
    this._location.back();
  }

  checkEmailConfiguration() {
    this.checking = true;

    this.emailConfigurationService.checkEmailConfiguration(this.configuration).subscribe(response =>{
      if (response.success) {
        this.checkEmailStatus = true;
        this.infoboxService.success('SPA.EMAIL_CONFIG.EMAIL_STATUS_SUCCESS');
      } else {
        this.checkEmailStatus = false;
        this.infoboxService.error('SPA.EMAIL_CONFIG.EMAIL_STATUS_ERROR');
      }
      this.checking = false;
    }, err => {
      this.checkEmailStatus = false;
      this.checking = false;
    }
    );
  }

  disabledSave() {
    if (this.checkEmailStatus) {
      return false;
    }

    return !!!this.emailConfigForm || this.emailConfigForm.pristine || !this.emailConfigForm.valid;
  }

  save(form: NgForm) {
    if (form.valid) {
      this.inProgress = true;
      this.emailConfigurationService.updateConfiguration(this.configuration).subscribe(data => {
        this.infoboxService.success('SPA.EMAIL_CONFIG.SUCCESS');
        form.form.markAsPristine();
        this.checkEmailStatus = false;
        this.inProgress = false;
        this.hasConfiguration = true;
      }, err => {
        this.infoboxService.error(err);
        this.inProgress = false;
      });
    }
  }

  removeConfiguration() {
    if (!this.hasConfiguration) {
      return;
    }

    this.modalsService.confirm('SPA.EMAIL_CONFIG.REMOVE_DESCRIPTION').subscribe((result) => {
      if (result) {
        this.inProgress = true;
        this.emailConfigurationService.deleteConfiguration().subscribe(data => {
          if (data) {
            this.initEmptyConfiguration();
            this.hasConfiguration = false;
          }
          this.inProgress = false;
        }, err => {
          this.inProgress = false;
        });
      }
    });
  }
}
