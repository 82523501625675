import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatTreeModule} from '@angular/material/tree';
import {RouterModule} from '@angular/router';
import {AlarmsAndEscalationsComponent} from './alarms-and-escalations.component';
import {AlarmsAndEscalationsService} from './alarms-and-escalations.service';
import {SupervisorComponent} from './supervisor/supervisor.component';
import {GroupsComponent} from './groups/groups.component';
import {DisorderComponent} from './disorder/disorder.component';
import {EditComponent} from './groups/components/edit/edit.component';
import {CreateComponent} from './groups/components/create/create.component';
import {ListComponent} from './groups/components/list/list.component';
import {AEGroupsService} from './groups/services/a-e-groups.service';
import {AEGroupsNetwork} from './groups/networks/a-e-groups.network';
import {MinutePipe} from '../_utils/pipes/minute.pipe';
import {FormComponent} from './groups/components/form/form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {SupervisorService} from './supervisor/supervisor.service';
import {CreateDisordedComponent} from './disorder/components/create-disorded/create-disorded.component';
import {EditDisordedComponent} from './disorder/components/edit-disorded/edit-disorded.component';
import {ListDisordedComponent} from './disorder/components/list-disorded/list-disorded.component';
import {DisorderRowComponent} from './disorder/components/group-disorder/disorder-row/disorder-row.component';
import {GroupDisorderComponent} from './disorder/components/group-disorder/group-disorder.component';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';

import {
    EditDisorderRowComponent
} from './disorder/components/group-disorder/edit-disorder-row/edit-disorder-row.component';
import {DisorderNetwork} from './disorder/networks/disorder.network';
import {DisorderService} from './disorder/services/disorder.service';
import {DisorderHeaderComponent} from './disorder/components/disorder-header/disorder-header.component';
import {ForwardComponent} from './supervisor/components/forward/forward.component';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {PastEventsListComponent} from './supervisor/components/past-events-list/past-events-list.component';
import {PastEventsFiltersComponent} from './supervisor/components/past-events-filters/past-events-filters.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MomentDateModule} from '@angular/material-moment-adapter';
import {NgxMatMomentModule} from '@angular-material-components/moment-adapter';
import {LineService} from '../line/line.service';
import {UserService} from '../user-management/users/user.service';
import {StationService} from '../stations/station.service';
import {AlarmStatusComponent} from './supervisor/components/alarm-status/alarm-status.component';
import {AlarmDashboardComponent} from './alarm-dashboard/alarm-dashboard.component';
import {AlarmsComponent} from './alarms/alarms.component';
import {AlarmsGroupsComponent} from './alarms-groups/alarms-groups.component';
import {CurrentAlarmsComponent} from './alarm-dashboard/current-alarms/current-alarms.component';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {HistoricAlarmsComponent} from './alarm-dashboard/histori-alarms/historic-alarms.component';
import {AlarmDetailsComponent} from './alarm-details/alarm-details.component';
import {ForwardAlarmComponent} from './alarm-dashboard/current-alarms/forward-alarm/forward-alarm.component';
import {UpdateReasonComponent} from './alarm-details/update-reason/update-reason.component';
import {AlarmDropdownOptionsModalComponent} from './alarm-details/alarm-dropdown-options-modal/alarm-dropdown-options-modal.component';

import {AlarmFormComponent} from './alarms/alarm-form/alarm-form.component';
import {AlarmsGroupsFormComponent} from './alarms-groups/alarms-groups-form/alarms-groups-form.component';
import { SomebodySubscribedModalComponent } from './somebody-subscribed-modal/somebody-subscribed-modal.component';
import {AlarmReasonsComponent} from './alarm-reasons/alarm-reasons.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {ALARMREASONSItemComponent} from './alarm-reasons/alarm-reasons-item/alarm-reasons-item.component';
import {AlarmReasonsFormComponent} from './alarm-reasons/alarm-reasons-form/alarm-reasons-form.component';
import {
    ImportAlarmReasonsFromCSVComponent
} from './alarm-reasons/import-alarm-reasons-from-csv/import-alarm-reasons-from-csv.component';
import {NgxGraphModule} from "@swimlane/ngx-graph";
import { AlarmGroupListComponent } from './alarms-groups/alarm-group-list/alarm-group-list.component';
import { AlarmsListComponent } from './alarms/alarms-list/alarms-list.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { TableColumnVisibilityService } from '../../../common/user-table-settings/services/table-column-visibility.service';
import { AlarmLoginService } from './alarm-login.service';
import { ActionLoginModalComponent } from '../alarms-and-escalations/action-login-modal/action-login-modal.component';
import {MatListModule} from '@angular/material/list';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatTabsModule,
        MatSortModule,
        MatPaginatorModule,
        RouterModule,
        SharedModule,
        ReactiveFormsModule,
        MatTableModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatSortModule,
        MatSelectModule,
        MatTreeModule,
        MatButtonModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MomentDateModule,
        NgxMatMomentModule,
        MatCardModule,
        MatExpansionModule,
        NgxGraphModule,
        MatMenuModule,
        MatListModule
    ],
    declarations: [
        AlarmsAndEscalationsComponent,
        SupervisorComponent,
        GroupsComponent,
        DisorderComponent,
        EditComponent,
        CreateComponent,
        ListComponent,
        MinutePipe,
        FormComponent,
        CreateDisordedComponent,
        EditDisordedComponent,
        ListDisordedComponent,
        DisorderRowComponent,
        GroupDisorderComponent,
        EditDisorderRowComponent,
        DisorderHeaderComponent,
        ForwardComponent,
        PastEventsListComponent,
        PastEventsFiltersComponent,
        AlarmStatusComponent,
        AlarmDashboardComponent,
        AlarmsComponent,
        AlarmsGroupsComponent,
        CurrentAlarmsComponent,
        HistoricAlarmsComponent,
        AlarmDetailsComponent,
        ForwardAlarmComponent,
        UpdateReasonComponent,
        AlarmDropdownOptionsModalComponent,
        AlarmFormComponent,
        AlarmsGroupsFormComponent,
        AlarmReasonsComponent,
        ALARMREASONSItemComponent,
        AlarmReasonsFormComponent,
        ImportAlarmReasonsFromCSVComponent,
        AlarmGroupListComponent,
        AlarmsListComponent,
        SomebodySubscribedModalComponent,
        ActionLoginModalComponent
    ],
    providers: [
        AlarmsAndEscalationsService,
        AlarmLoginService,
        SupervisorService,
        AEGroupsService,
        AEGroupsNetwork,
        DisorderNetwork,
        DisorderService,
        LineService,
        UserService,
        StationService,
        TableColumnVisibilityService
    ]
})
export class AlarmsAndEscalationsModule {
}
