import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { ProductItemsFiltersInterface } from '../../../../common/product/interfaces/product-items-filters.interface';
import { Pagination } from '../../../../common/paginate';
import { ProductItemListInterface } from '../../../../common/product/interfaces/product-item-list.interface';
import {ProductItemDetailsInterface} from '../../../../common/product/interfaces/product-item-details.interface';
import { ProductItemGetCountBodyInterface } from '../../../../common/product/interfaces/product-item-get-count-body.interface';

@Injectable()
export class ProductItemsNetwork {
    constructor(private http: HttpClient) { }

    public fetchItems(
      filters: ProductItemsFiltersInterface
    ): Observable<Pagination<ProductItemListInterface>> {
        const params = new HttpParams({
            fromObject: {
                'pageNo': filters.pageNo.toString(),
                'pageSize': filters.pageSize.toString(),
                'scannedCode': filters.scannedCode,
                'sortCol': filters.sortCol,
                'sortDir': filters.sortDir.toUpperCase(),
                'typeEnums': filters.typeEnums.toString(),
                'startDate': filters.startDate ? filters.startDate.toString() : '',
                'endDate': filters.endDate ? filters.endDate.toString() : '',
                'lineId': filters.lineId ? filters.lineId.toString() : '',
                'stationId': filters.stationId ? filters.stationId.toString() : '',
                'globalSearchForWorkstep': filters.globalSearchForWorkstep
            }
        });

        return this.http.get<Pagination<ProductItemListInterface>>('/product-item', { params });
    }

    trashProductItem(productItemId: number) {
        return this.http.post<ProductItemDetailsInterface>(`/product-item/trash/${productItemId}`, {});
    }

    restoreProductItem(productItemId: number) {
        return this.http.post<ProductItemDetailsInterface>(`/product-item/restore/${productItemId}`, {});
    }

    public exportExcel(body): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                    'pageNo': body.pageNo.toString(),
                    'pageSize': body.pageSize.toString(),
                    'scannedCode': body.scannedCode,
                    'sortCol': body.sortCol,
                    'sortDir': body.sortDir.toUpperCase(),
                    'typeEnums': body.typeEnums.toString(),
                    'startDate': body.startDate ? body.startDate.toString() : '',
                    'endDate': body.endDate ? body.endDate.toString() : '',
                    'lineId': body.lineId ? body.lineId.toString() : '',
                    'stationId': body.stationId ? body.stationId.toString() : '',
                    'globalSearchForWorkstep': body.globalSearchForWorkstep
            }
        });
        const headers = new HttpHeaders({
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'Content-Type': 'application/json',
        });

        return this.http.request('get', `/product-item/export-to-excel`, {headers, responseType: 'blob', params }).pipe(map((res) => 
            {
                return new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            })
        ).pipe(map(res => {
			const a = document.createElement('a')
			const objectUrl = URL.createObjectURL(res)
			a.href = objectUrl
			a.download = `product-items-listing.xlsx`;
			a.click();
			URL.revokeObjectURL(objectUrl);
	    }));
	}
    
    // Trigger Release event for Adjustable button widgets
    triggerAdjustableBtnReleaseEvent(productItemId: number): Observable<any> {
        return this.http.post(`/api/workstep-log/product/trigger-release-event/${productItemId}`, {});
    }

    getCountOfProductsBasedOnStatus(data: ProductItemGetCountBodyInterface): Observable<number>{
        return this.http.post<number>('/product-item/get-count/by-status', data);
    }
}
