import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FtpStationService } from '../../../system-settings/ftp-configuration/services/ftp-station.service';
import { InfoboxService } from '../../../../../common/shared/services/infobox.service';
import { DeviceService } from '../../../shared/components/devices/services/device.service';
import { DeviceTypeEnum } from '../../../../../common/device/enums/device-type.enum';

@Component({
  selector: 'app-station-edit',
  templateUrl: './station-edit.component.html',
  styleUrls: ['./station-edit.component.scss']
})
export class StationEditComponent implements OnInit, OnDestroy {

  @ViewChild('stationFtpForm') stationFtpForm;

  lineId: number;
  stationId: number;
  isVirtualStation = false;

  constructor(
    public ftpStationService: FtpStationService,
    private _location: Location,
    private route: ActivatedRoute,
    private infoBoxService: InfoboxService,
    private deviceService: DeviceService
  ) { }

  ngOnInit() {
    this.route.parent.parent.params.subscribe(params => {
        this.lineId = +params.id;
    });
    this.stationId = this.route.snapshot.params.id;
    this.ftpStationService.init(this.stationId);
    this.deviceService.init(this.lineId);
    this.deviceService.getDevice(this.stationId)
      .subscribe(device => {
        this.isVirtualStation = device.type === DeviceTypeEnum.VIRTUAL_STATION;
      });
  }

  ngOnDestroy(): void {
  }

  async saveFtp(){
    if (this.stationFtpForm.valid) {
      this.ftpStationService.updateFolder().subscribe(ftpStation => {
        ftpStation['mainFolder'] = this.ftpStationService.ftpStation.mainFolder;
        this.ftpStationService.ftpStation = ftpStation;
        this.infoBoxService.pushInfo({ success: true, text: 'SPA.DEVICE.EDIT.FOLDER_UPDATE_SUCCESS' });
        this.stationFtpForm.form.markAsPristine();
      });
    }
  }

  goBack() {
    this._location.back();
  }
}
