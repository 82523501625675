import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
    selector: 'app-beat-selection-popup',
    templateUrl: './beat-selection-popup.component.html',
    styleUrls: ['./beat-selection-popup.component.scss']
})
export class BeatSelectionPopupComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<BeatSelectionPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {
    }

    ngOnInit(): void {
    }

    shiftSelected(type) {
        this.dialogRef.close(type);
    }
}
