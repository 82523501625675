import {Injectable} from '@angular/core';
import {ShiftNetwork} from '../networks/shift-network';
import {ShiftCalendarInterface} from '../../../../common/shift/interfaces/shift-calendar.interface';
import {EventCalendarInterface} from '../../../../common/shift/interfaces/event-calendar.interface';
import {parseISO} from 'date-fns';
import {LineInterface} from '../../../../common/line/interfaces/line.interface';
import {UserInterface} from '../../../../common/auth/interfaces/user.interface';
import {LineService} from '../../line/line.service';
import {UserService} from '../../user-management/users/user.service';
import {ShiftCalendarDataInterface} from '../../../../common/shift/interfaces/shift-calendar-data.interface';
import {SocketService} from '../../_services/socket.service';
import {SocketMessageEnum} from '../../../../common/socket/enums/socket-message.enum';
import {ShiftStartedEndedSocket} from '../../../../common/shift/socket/shift-started-ended.socket';
import {Subject} from 'rxjs';
import {SocketChannel} from '../../../../common/socket/utils/socket-channel';
import {Socket} from '../../../../common/socket/models/socket.model';

@Injectable()
export class ShiftService {
    shifts: ShiftCalendarInterface[] = [];
    events: EventCalendarInterface[] = [];
    lines: LineInterface[] = [];
    users: UserInterface[] = [];
    loading = false;
    from: Date;
    to: Date;
    socket: Socket;
    private _eventStatusChanged = new Subject<EventCalendarInterface>();
    private socketSubs: string[] = [];
    constructor(
        private network: ShiftNetwork,
        private lineService: LineService,
        private userService: UserService,
        private socketService: SocketService,
    ) {
    }

    eventStatusChanged() {
        return this._eventStatusChanged.asObservable();
    }

    joinSocketChannel() {
        this.socket = this.socketService.getSocket();
        this.socket.join(SocketChannel.SHIFT);
    }

    leaveSocketChannel() {
        if (this.socket) {
            this.socketSubs.forEach(s => this.socket.off(s));
            this.socket.leave(SocketChannel.SHIFT);
        }
    }

    subscribeToShiftSockets() {
        this.socketSubs.push(this.socket.on(SocketMessageEnum.SHIFT_STARTED, (data: ShiftStartedEndedSocket) => {
           const event = this.events.find((eventData) => eventData.id === data.eventId);
           if (!!event) {
               event.isActive = true;
               this._eventStatusChanged.next(event);
           }
        }));
        this.socketSubs.push(this.socket.on(SocketMessageEnum.SHIFT_ENDED, (data: ShiftStartedEndedSocket) => {
            const event = this.events.find((eventData) => eventData.id === data.eventId);
            if (!!event) {
                event.isActive = false;
                event.isFinished = true;
                this._eventStatusChanged.next(event);
            }
        }));
    }

    init(from: Date, to: Date) {
        this.joinSocketChannel();
        this.subscribeToShiftSockets();
        this.changeDate(from, to);
        this.loadCommonData();
    }

    changeDate(from: Date, to: Date) {
        this.from = from;
        this.to = to;
        this.loadEventsData();
    }

    loadEventsData() {
        this.loading = true;
        this.network.fetchEventsData(this.from, this.to).subscribe((result) => {
            this.events = result.events;
            this.shifts = result.shifts;
            this.prepareEventsData();
            this.loading = false;
        }, () => {
            this.loading = false;
        })
    }

    save(data) {
        data.start = data.start.toISOString();
        data.end = data.end.toISOString();

        return !!data.id ? this.edit(data.shiftId, data.id, data) : this.create(data);
    }

    stopShift(shiftId: number,  eventId: number) {
        this.loading = true;
        this.network.stopShift(shiftId, eventId).subscribe(() => {
            this.loadEventsData();
        }, () => {
            this.loading = false;
        })
    }

    deleteShift(shiftId: number,  eventId: number, allFollowing: boolean) {
        this.loading = true;
        this.network.deleteShift(shiftId, eventId, allFollowing).subscribe(() => {
            this.loadEventsData();
        }, () => {
            this.loading = false;
        })
    }

    fetchEvent(shiftId: number,  eventId: number) {
        return this.network.fetchEvent(shiftId, eventId);
    }

    private create(form: ShiftCalendarDataInterface) {
        return this.network.createShift(form);
    }

    private edit(shiftId: number,  eventId: number, form: ShiftCalendarDataInterface) {
        return this.network.editShift(shiftId, eventId, form)
    }

    private loadCommonData() {
        this.lineService.getLines().subscribe((result) => {
            this.lines = result;
        });
        this.userService.getUsers().subscribe((result) => {
            this.users = result;
        })
    }

    private prepareEventsData() {
        for (let eventCalendar of this.events) {
            eventCalendar.shift = this.shifts.find((shift) => shift.id === eventCalendar.shiftId);
            eventCalendar.start = parseISO(eventCalendar.start as string);
            eventCalendar.end = parseISO(eventCalendar.end as string);
            eventCalendar.color = {
                primary: eventCalendar.shift.color,
                secondary: eventCalendar.shift.color
            }
        }
    }

}