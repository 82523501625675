<div class="dropdown">
  <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="dropdown-heading-icon" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon [icIcon]="icPerson"></mat-icon>
      </div>
      <div class="dropdown-heading">{{authService.decodedToken?.username}}</div>
    </div>
  </div>

  <div class="dropdown-content">
    <a (click)="close()"
       [routerLink]="['/users/list', this.authService.decodedToken?.id]"
       class="notification"
       fxLayout="row"
       fxLayoutAlign="start center"
       matRipple>
      <mat-icon [icIcon]="icAccountCircle"
                class="notification-icon text-teal"
                fxFlex="none"></mat-icon>
      <div fxFlex="auto">
        <div class="notification-label">{{ 'SPA.NAV.EDIT' | translate }}</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
    </a>
      <a
         class="notification"
         fxLayout="row"
         fxLayoutAlign="start center"
         (click)="logout()"
         matRipple>
          <mat-icon [icIcon]="icMoveToInbox"
                    class="notification-icon text-primary"
                    fxFlex="none"></mat-icon>
          <div fxFlex="auto">
              <div class="notification-label">{{ 'SPA.NAV.LOGOUT' | translate }}</div>
          </div>
          <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
      </a>
  </div>

</div>
