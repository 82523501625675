import { Component, Input, OnInit } from '@angular/core';
import icHome from '@iconify/icons-ic/twotone-home';
import { trackByValue } from '../../utils/track-by';

@Component({
  selector: 'vex-breadcrumbs',
    styleUrls: ['./breadcrumbs.component.scss'],
  template: `
      <div class="flex items-center" xmlns="http://www.w3.org/1999/html">
      <vex-breadcrumb>
        <a [routerLink]="['/dashboard']">
          <ic-icon [icon]="icHome" inline="true" size="20px"></ic-icon>
        </a>
      </vex-breadcrumb>
      <ng-container *ngFor="let crumb of crumbs; trackBy: trackByValue">
        <div class="w-1 h-1 bg-gray rounded-full ltr:mr-2 rtl:ml-2"></div>
        <vex-breadcrumb *ngIf="crumb">
            <a [routerLink]="[crumb.path]" *ngIf="crumb.path">
                <span>{{ crumb.label | translate}}</span>
            </a>
            <span *ngIf="!crumb.path">{{ crumb.label | translate}}</span>
        </vex-breadcrumb>
      </ng-container>
    </div>
  `
})
export class BreadcrumbsComponent implements OnInit {

  @Input() crumbs: {label: string, path?: string}[] = [];

  trackByValue = trackByValue;
  icHome = icHome;

  constructor() {
  }

  ngOnInit() {
  }
}
