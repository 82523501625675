import {Component, Inject, OnInit, Optional} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-somebody-subscribed-modal',
  templateUrl: './somebody-subscribed-modal.component.html',
  styleUrls: ['./somebody-subscribed-modal.component.scss']
})
export class SomebodySubscribedModalComponent implements OnInit {
    param = {value: ''};
    constructor(
        public dialogRef: MatDialogRef<SomebodySubscribedModalComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data
    ) {}

    ngOnInit() {
        this.param.value = this.data.userAlreadySubscribed;
    }

    subscribe() {
        this.dialogRef.close(true);
    }

    close() {
        this.dialogRef.close(false);
    }
}
