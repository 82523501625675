import { Injectable } from '@angular/core';
import { StorageService } from '../../services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class FontDataService {
    fontConfig: Map<string, number>;
    storageKey = 'fontConfig';
    constructor(
        private storage: StorageService
    ) {
        this.init();
    }

    removeAll() {
        this.storage.removeItem(this.storageKey);
    }

    get(key: string): number {
        return this.fontConfig.get(key);
    }

    set(key: string, value: number) {
        this.fontConfig.set(key, value);
        this.saveToLS();
    }

    private init() {
        const dataFromStorage = this.storage.getItem(this.storageKey);
        this.fontConfig = new Map<string, number>(dataFromStorage ? JSON.parse(dataFromStorage) : []);
    }

    private saveToLS() {
        this.storage.setItem(this.storageKey, JSON.stringify([...this.fontConfig]));
    }
}