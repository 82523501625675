import {NgModule, Optional, SkipSelf} from '@angular/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {InterceptorsModule} from './interceptors/interceptors.module';
import {FormsUtilsModule} from './forms/formsUtils.module';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import { VERSION } from '../../environments/version';

export function HttpLoaderFactory(http: HttpBackend) {
    let version = VERSION || '';
    version = version.replace(/\s/g, '');
    return new MultiTranslateHttpLoader(http, [
        {prefix: "assets/i18n/", suffix: ".json?v=" + version},
        {prefix: "assets/i18n/common/", suffix: ".json?v=" + version},
    ]);
}

const IM_EX_MODULES = [
    InterceptorsModule,
    FormsUtilsModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpBackend]
        }
    }),
];
const EXPORTED_MODULES = [
    ...IM_EX_MODULES
];
const IMPORTED_MODULES = [
    ...IM_EX_MODULES,
];
const COMPONENTS = [];
const PIPES = [];
const DIRECTIVES = [];
const PROVIDERS = [];

@NgModule({
    imports: [IMPORTED_MODULES],
    exports: [
        ...EXPORTED_MODULES,
        ...COMPONENTS,
        ...PIPES,
        ...DIRECTIVES
    ],
    declarations: [
        ...COMPONENTS,
        ...PIPES,
        ...DIRECTIVES
    ],
    providers: [PROVIDERS]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
}
