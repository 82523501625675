<page-header [titleTranslate]="data.title"></page-header>
<div mat-dialog-content>
    <p>{{data.content | translate}}</p>
</div>
<div mat-dialog-actions>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="mt-10 footer">
        <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
            <button color="warn" mat-button type="button" (click)="onNoClick()"> {{ data.btnCancel | translate }}</button>
            <button class="confirm-button" color="primary" mat-button [mat-dialog-close]="true">
                {{ data.btnConfirm | translate }}
            </button>
        </div>
    </div>
</div>