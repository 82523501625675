import { NgModule } from '@angular/core';
import { MobileAppComponent } from './mobile-app.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { DownloadAppComponent } from './download-app/download-app.component';
import { ActualMobileVersionComponent } from './actual-mobile-version/actual-mobile-version.component';

@NgModule({
  declarations: [MobileAppComponent, DownloadAppComponent, ActualMobileVersionComponent],
  imports: [
    SharedModule,
    MaterialFileInputModule,
  ],
})
export class MobileAppModule { }
