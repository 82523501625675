<vex-secondary-toolbar>
    <vex-breadcrumbs [crumbs]="crumbs" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>
<div class="page-content">
    <div class="row ap-card shadow">
        <div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
            <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                <mat-label>{{ 'SPA.MOBILE_APP.NAME' | translate }}</mat-label>
                <input matInput required [(ngModel)]="name">
            </mat-form-field>
        </div>
        <div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
            <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                <mat-label>{{ 'SPA.MOBILE_APP.FILE_TYPE' | translate }}</mat-label>
                <ngx-mat-file-input
                        required
                        [(ngModel)]="file"
                        (ngModelChange)="fileChangeEvent($event)"
                        [accept]="'.apk'"
                        [valuePlaceholder]="file?.name"
                >
                </ngx-mat-file-input>
                <mat-icon matSuffix>android</mat-icon>
            </mat-form-field>
        </div>
        <div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
            <app-actual-mobile-version></app-actual-mobile-version>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="mt-10">
            <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
                <button color="primary" mat-raised-button type="submit" (click)="upload()" [disabled]="!file && !name"> {{ 'SPA.MOBILE_APP.UPLOAD' | translate }}</button>
            </div>
        </div>
    </div>
</div>
