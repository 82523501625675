<vex-secondary-toolbar>
    <vex-breadcrumbs [crumbs]="crumbs" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>
<div class="page-content">
    <div class="row ap-card shadow">
        <div *ngIf="selectedLine" mat-tab-nav-bar class="tabs-nav responsive-tabs">
            <a *apUserAccess="[permissions.StationManagement]" [active]="rlaStations.isActive" routerLinkActive #rlaStations="routerLinkActive" mat-tab-link [routerLink]="['/line', selectedLine.id,'stations']">
                <i class="material-icons">desktop_windows</i>
                <span class="tab-title">{{'SPA.LINES.STATIONS_TITLE' | translate}}</span>
            </a>
            <a [active]="rlaEdit.isActive" routerLinkActive #rlaEdit="routerLinkActive" mat-tab-link [routerLink]="['/line', selectedLine.id, 'edit']">
                <i class="material-icons">settings</i>
                <span class="tab-title">{{'SPA.LINES.EDIT_TITLE' | translate}}</span>
            </a>
            <a *apUserAccess="[permissions.WorkInstructionsManagement]" [active]="rlaWorkInstructions.isActive" routerLinkActive #rlaWorkInstructions="routerLinkActive" mat-tab-link [routerLink]="['/line', selectedLine.id, 'work-instructions']">
                <i class="material-icons">insert_drive_file</i>
                <span class="tab-title">{{'SPA.LINES.GLOBAL_PDF_DOCUMENTS' | translate}}</span>
            </a>
            <!-- <a [active]="rlaDisorder.isActive" routerLinkActive #rlaDisorder="routerLinkActive" mat-tab-link [routerLink]="['/line', selectedLine.id, 'disorder']">
                <i class="material-icons">report_problem</i>
                <span class="tab-title">{{'SPA.LINES.DISORDER_TITLE' | translate}}</span>
            </a> -->
        </div>
        <router-outlet></router-outlet>
    </div>
</div>
