import {Routes} from '@angular/router';
import {UserManagementComponent} from './user-management.component';
import {UserAddComponent} from './users/user-add/user-add.component';
import {UserEditComponent} from './users/user-edit/user-edit.component';
import {UsersComponent} from './users/users.component';
import {RolesComponent} from './roles/roles.component';
import {RoleDetailsComponent} from './roles/details/roleDetails.component';
import {AuthGuard} from '../_guards/auth.guard';
import { UserPermissionsEnum } from '../../../common/auth/enums/user-permissions.enum';

export const USER_ROUTES: Routes = [
    {
        path: '',
        component: UserManagementComponent,
        canActivate: [AuthGuard],
        data: {permissions: [UserPermissionsEnum.UserManagement],
            breadcrumb: {
            label: 'SPA.USERS.HEADER',
                path: '/users/list'
        }},
        children: [
            {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
            },
            {
                path: 'list',
                component: UsersComponent,
                data: {
                    breadcrumb: {
                        label: 'SPA.USERS.UserList',
                    }
                }
            },
            {
                path: 'roles',
                component: RolesComponent,
                data: {
                    breadcrumb: {
                        label: 'SPA.USERS.Roles',
                    }
                }
            },
            {
                path: 'roles/:id',
                component: RoleDetailsComponent,
                data: {
                    breadcrumb: {
                        label: 'COMMON.EDIT',
                    }
                }
            },
            {
                path: 'list/add',
                component: UserAddComponent,
                data: {
                    breadcrumb: {
                        label: 'COMMON.CREATE',
                    }
                }
            },
            {
                path: 'list/:id',
                component: UserEditComponent,
                data: {
                    breadcrumb: {
                        label: 'COMMON.EDIT',
                    }
                }
            },
        ]
    }
];
