import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LineInterface } from '../../../common/line/interfaces/line.interface';
import { LineShiftInterface } from '../../../common/shift/interfaces/line-shift.interface';
import { LineShiftCreateInterface } from '../../../common/shift/interfaces/line-shift-create.interface';
import { ResetBeatInterface } from '../../../common/beat/interfaces/reset-beat.interface';
import { ProductItemGetCountBodyInterface } from '../../../common/product/interfaces/product-item-get-count-body.interface';
import { ProductItemsNetwork } from '../products/networks/product-items.network';

@Injectable({
    providedIn: 'root'
})
export class LineService {
  private _line: number;
  private lineObject: LineInterface = null;

  constructor(
    private http: HttpClient,
    private productItemNetwork: ProductItemsNetwork
  ) {
    this.apiUrl = environment.apiUrl;
  }

  apiUrl: String;

  getLines(): Observable<LineInterface[]> {
    return this.http.get<LineInterface[]>(this.apiUrl + '/api/line');
  }

  getLine(id: number): Observable<LineInterface> {
    return this.http.get<LineInterface>(this.apiUrl + `/api/line/${id}`);
  }

  resetBeatTime(lineId: number, data: ResetBeatInterface): Observable<any> {
    return this.http.patch<any>(`/beat-time/reset-beat/${lineId}`, data);
  }

  addLine(lineAddData: LineInterface): Observable<number> {
    return this.http.post<number>(
      this.apiUrl + `/api/line/`,
      lineAddData
    );
  }

  updateLine(lineData: LineInterface): Observable<LineInterface> {
    return this.http.patch<LineInterface>(this.apiUrl + `/api/line/${lineData.id}`, lineData);
  }

  deleteLine(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.apiUrl + `/api/line/${id}`);
  }

  getShiftsForLine(lineId: number): Observable<LineShiftInterface[]> {
    return this.http.get<LineShiftInterface[]>(`/line-shift/${lineId}`);
  }

  getActiveShiftsForLine(lineId: number): Observable<any> {
    return this.http.get<any>(`/shift-calendar/get/active-shift/${lineId}`);
  }

  updateBeatFrequency(lineId: number, value: boolean): Observable<any> {
    return this.http.patch<any>(`/beat-time/edit/${lineId}`, {
      restartNewBeatTime: value
    });
  }

  getAvailableShiftsForLine(lineId: number) {
    return this.http.get<any>(`/line-shift/${lineId}/available`);
  }

  addShiftToLine(data: LineShiftCreateInterface) {
    return this.http.post<any>(`/line-shift`, data);
  }

  deleteShiftFromLine(lineId: number, shiftId: number, weekday: number): Observable<boolean> {
    return this.http.delete<boolean>(`/line-shift/${lineId}/${shiftId}/${weekday}`);
  }

  getCountOfProductsBasedOnStatus(data: ProductItemGetCountBodyInterface) {
    return this.productItemNetwork.getCountOfProductsBasedOnStatus(data);
}

  public forceImport(lineID: number) {
    return this.http.post<any>(this.apiUrl + `/api/article/forceimport/${lineID}`, {}, {
      observe: 'response'
    });
  }

  public uploadArticles(lineID: number, formData): Observable<HttpEvent<any>> {
    const params = new HttpParams({
      fromObject: {
        ignoreUrlInterception: 'true'
      }
    });
    return this.http.post<any>(this.apiUrl + `/api/article/upload/${lineID}`, formData, {
      params,
      reportProgress: true,
      observe: 'events'
    });
  }

  public getImportDate(lineID: number) {
    return this.http.get<any>(this.apiUrl + `/api/article/importdate/1/${lineID}`, {
      observe: 'response'
    });
  }

  setSelectedLine(line) {
    this._line = +line;
  }

  getSelectedLine() {
    return this._line;
  }

  set line(line: LineInterface) {
    this.lineObject = line;
  }

  get line(): LineInterface {
    return this.lineObject;
  }
}
