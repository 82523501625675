import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserService} from './user.service';
import {ModalsService} from '../../../../common/shared/services/modals.service';
import { UserInterface } from '../../../../common/auth/interfaces/user.interface';
import { AuthService } from '../../auth/auth.service';

@Component({
    styleUrls: ['users.component.scss'],
    templateUrl: 'users.component.html'
})
export class UsersComponent implements OnInit {
    public users: UserInterface[] = [];
    public searchPhrase = '';
    loading = false;
    @ViewChild('table') table: ElementRef;
    private currentUser: UserInterface;

    constructor(
        private userService: UserService,
        private modalsService: ModalsService,
        private authService: AuthService,
    ) {}

    ngOnInit() {
        this.authService.currentUser$.subscribe(user => {
            this.currentUser = user;
        });
        this.loading = true;
        this.userService.getUsers().subscribe(users => {
            this.users = users;
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    disableDelete(user: UserInterface): boolean {
        return user.username === 'admin' || this.currentUser.id === user.id;
    }

    removeUser(user: UserInterface) {
        if (user.username === 'admin') {
            return;
        }
        this.modalsService.confirm('SPA.USERS.RemoveConfirmation').subscribe((result) => {
            if (result) {
                this.userService.deleteUser(user.id).subscribe(() => {
                    this.userService.getUsers().subscribe(users => {
                        this.users = users;
                    });
                });
            }
        });
    }

    search() {
        const field = this.searchPhrase.toLowerCase();
        const row = this.table.nativeElement.querySelectorAll('.body-row');
        for (const singleRow of row) {
            if (singleRow) {
                const td = singleRow.querySelectorAll('.column');
                for (const item of td) {
                    if (item) {
                        if (item.textContent.toString().toLowerCase().indexOf(field) >= 0) {
                            singleRow.hidden = false;
                            break;
                        } else {
                            singleRow.hidden = true;
                        }
                    }
                }
            }
        }
    }
}