import { Injectable } from '@angular/core';
import { SocketMessageEnum } from '../../../../../../common/socket/enums/socket-message.enum';
import { DeviceFullInterface } from '../../../../../../common/device/interfaces/device-full.interface';
import { DeviceUpdaterPendingInterface } from '../interfaces/device-updater-pending.interface';
import { SocketService } from '../../../../_services/socket.service';
import { InfoboxService } from '../../../../../../common/shared/services/infobox.service';
import { DeviceUpdaterStatusInterface } from '../interfaces/device-updater-status.interface';

const UPDATE_ACTION = 'update';

@Injectable()
export class DeviceUpdaterService {
  public action: string = UPDATE_ACTION;
  private devices: DeviceFullInterface[] = [];
  private pendingData: DeviceUpdaterPendingInterface[] = [];
  private socket;
  private isOn: boolean = false;
  private socketSubs: string[] = [];
  constructor(
    private socketService: SocketService,
    private infoBoxService: InfoboxService
  ) {
  }

  init(devices: DeviceFullInterface[]) {
    this.devices = devices;
    this.socket = this.socketService.getSocket();
    this.destroy();
    this.socket.join('updater');
    this.socketSubs.push(this.socket.on(SocketMessageEnum.UPDATER_STATUS_MESSAGE, (data: string | DeviceUpdaterStatusInterface) => {
      if (typeof data === 'string') {
        this.infoBoxService.error('SPA.DEVICE.UPDATER.ERROR');
        return;
      }

      this.handleStatus(data);
    }));
    this.socketSubs.push(this.socket.on(SocketMessageEnum.UPDATER_IS_ON, (isOn: boolean) => {
      this.isOn = isOn;
    }));
  }

  updaterEnabled(): boolean {
    return this.isOn || this.action === UPDATE_ACTION;
  }

  getPending(deviceId: number): DeviceUpdaterPendingInterface {
    return this.pendingData.find(pendingDatum => {
      return pendingDatum.id === deviceId;
    });
  }

  handleBusy() {
    this.infoBoxService.warning('SPA.DEVICE.UPDATER.BUSY');
    this.pendingData = [];
  }

  updateDevices(id?: number) {
    if (id) {
      const device = this.devices.find(s => s.id === id);
      if (device) {
        this.socket.emit(SocketMessageEnum.UPDATER_UPDATE_STATIONS, { 'stations': [device.id] });
      }
    } else {
      const ids = [];

      this.devices.forEach(device => {
        if (device.active) {
          ids.push(device.id);
        }
      });

      if (ids.length !== 0) {
        this.socket.emit(SocketMessageEnum.UPDATER_UPDATE_STATIONS, { 'stations': ids });
      }
    }
  }

  runSuperAdminAction(id?: number) {
    if (this.action === UPDATE_ACTION) {
      this.updateDevices(id);
      return;
    }

    if (id) {
      const device = this.devices.find(s => s.id === id);

      if (device) {
        this.socket.emit(SocketMessageEnum.UPDATER_ACTION_STATIONS, { 'stations': [device.ip], 'action': this.action })
      }
    } else {
      const ips = [];

      this.devices.forEach(device => {
        if (device.active) {
          this.addPending(device);
          ips.push(device.ip);
        }
      });

      if (ips.length !== 0) {
        this.socket.emit(SocketMessageEnum.UPDATER_ACTION_STATIONS, { 'stations': ips, 'action': this.action })
      }
    }

  }

  destroy() {
      this.socketSubs.forEach(s => this.socket.off(s));

      if (this.socket) {
        this.socket.leave('updater');
      }
  }

  private handleStatus(data: DeviceUpdaterStatusInterface) {
    switch (data.event) {
      case 'busy':
        this.handleBusy();
        break;
      case 'playbook_on_stats':
        this.pendingData = [];
        break;
      case 'runner_on_unreachable':
        this.handleUnreachable(data);
        break;
      case 'runner_on_failure':
        this.handleFailure(data);
        break;
      case 'runner_on_ok':
        break;
    }
  }

  private addPending(device: DeviceFullInterface) {
    const pending = this.getPending(device.id);

    if (pending) {
      return;
    }

    this.pendingData.push({
      id: device.id,
      fail: false,
      success: false,
    });
  }

  private handleUnreachable(data: DeviceUpdaterStatusInterface) {
    this.getIndex(data, (index, device) => {
      this.pendingData[index].fail = true;
      this.infoBoxService.error('SPA.DEVICE.UPDATER.UNREACHABLE', {deviceName: device.name});
    });
  }

  private handleFailure(data: DeviceUpdaterStatusInterface) {
    this.getIndex(data, (index, device) => {
      this.pendingData[index].fail = true;
      this.infoBoxService.error('SPA.DEVICE.UPDATER.FAILURE', {deviceName: device.name});
    });
  }

  private getIndex(data: DeviceUpdaterStatusInterface, callback: (index: number, device: DeviceFullInterface) => void) {
    const device = this.devices.find(s => s.ip === data.host);

    if (!!!device) {
      return;
    }

    const index = this.pendingData.findIndex(pending => pending.id === device.id);

    if (~index) {
      return;
    }

    callback(index, device);
  }
}