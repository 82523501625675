export enum WidgetTypeEnum {
  //Common
  MENU_BAR = 101,
  BAR_DIVIDER = 306,

  //Device
  SHIFT_TIME_CLOCK = 201,
  SHIFT_TIME_BAR = 202,
  BEAT_TIME_CLOCK = 203,
  BEAT_TIME_BAR = 204,
  ALARM_ESCALATION = 205,
  LINE_TEXT = 206,
  PRODUCTION_INFO_SHOULD = 207,
  PRODUCTION_INFO_IS = 208,
  PRODUCTION_INFO_TREND = 209,
  PRODUCTION_INFO_AVERAGE_BEAT = 210,
  TRACK_AND_TRACE = 211,
  STATION_TILES_FOR_STATION = 212,
  STATION_TILES_FOR_ANDON = 213,
  PRODUCTION_INFO_SHIFT_GOAL = 214,
  PRODUCTION_INFO_GAUGE_OEE = 215,
  PRODUCTION_INFO_GAUGE_SHOULD_IS = 216,
  ELAPSED_CLOCK = 217,
  REWORK_INPUT = 218,
  REWORK_BTN_BACK = 219,
  REWORK_BTN_TRASH = 220,
  REWORK_BTN_FINISH = 221,
  REWORK_BTN_RETRANSFER = 222,
  LINE_NAME = 223,
  MQTT_TOPIC = 224,
  FREE_TEXT = 225,
  CLOCK_WIDGET = 226,
  DEVICE_IMAGE = 227,
  MARK_FOR_REWORK = 228,
  ADD_ASSISTANCE = 229,
  INFO_TILES = 230,
  //Workstep
  IMAGE = 301,
  VIDEO = 302,
  PDF = 303,
  NEXT_PREV_BTNS = 304,
  TEXT_AREA = 305,
  INPUT_FIELD = 307,
  CHECKLIST = 308,
  NESTED_LIST = 309,
  IO_MANAGER = 310,
  IO_STRING = 311,
  STATION_ALARM_TILES = 312,
  IO_MODULE = 313,
  IMAGE_VIDEO_UPLOAD = 314,
  SCREWING_MACHINE = 315,
  ADJUSTABLE_BUTTON = 316,
  IMAGE_WITH_ACTIVE_ELEMENT = 317,
  
  // New widgets of 'image with active element' widget 
  RECTANGLE = 318,
  CIRCLE = 319
}
