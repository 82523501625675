<page-header titleTranslate="SPA.DEVICE.ADD.HEADER"></page-header>
<div class="row">
  <div class="col s12">
    <form [formGroup]="form" (ngSubmit)="save()">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
          <mat-label>{{ 'SPA.DEVICE.ADD.NAME' | translate }}</mat-label>
          <input matInput required formControlName="name" [maxlength]="maxNameLength">
          <mat-error>{{ 'SPA.DEVICE.ADD.NAME_ERROR' | translate }}</mat-error>
        </mat-form-field>
          <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
              <mat-label>{{ 'SPA.DEVICE.ADD.TYPE' | translate }}</mat-label>
              <mat-select required formControlName="type">
                  <ng-container *ngFor="let type of deviceTypes | stringEnumToArray">
                      <mat-option [value]="deviceTypes[type]" *ngIf="deviceService.showDeviceType(deviceTypes[type])">
                          {{ 'ENUMS.STATION_TYPE.' + type | translate | titlecase }}
                      </mat-option>
                  </ng-container>
              </mat-select>
          </mat-form-field>
      </div>
      <!-- <div class="col s12">
        <div
        fxLayout="row"
        class="content-row"
        fxLayout.lt-sm="column"
        fxLayoutGap="16px"
        fxLayoutGap.lt-sm="0"
        style="padding: 10px;"
        >
          <mat-label>{{'SPA.DEVICE.ADD.RUNNING_AS_WEB_APP' | translate}}</mat-label>
          <mat-slide-toggle
            class="checkbox"
            formControlName="isRunningAsWebApp"
            ></mat-slide-toggle>
        </div>
      </div> -->
      <div class="col s12" *ngIf="!isAndon()">
        <div
        fxLayout="row"
        class="content-row"
        fxLayout.lt-sm="column"
        fxLayoutGap="16px"
        fxLayoutGap.lt-sm="0"
        style="padding: 10px;"
        >
          <mat-label>{{'SPA.DEVICE.ADD.MULTIPLE_STATION' | translate}}</mat-label>
          <mat-slide-toggle
            class="checkbox"
            formControlName="isMultipleStation"
            ></mat-slide-toggle>
        </div>
      </div>
      <div *ngIf="isVirtualStation" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
          <mat-label>{{ 'SPA.DEVICE.ADD.UNASSIGNED_DEVICE' | translate }}</mat-label>
          <mat-select formControlName="unassignedDeviceId">
            <ng-container *ngFor="let unassignedDevice of unassignedDevices">
              <mat-option [value]="unassignedDevice.id" >
                {{ unassignedDevice.ip }}
                {{ unassignedDevice.macAddress }}
                ({{ unassignedDevice.width }}x{{ unassignedDevice.height }})
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        fxLayout="row"
        class="content-row"
        fxLayout.lt-sm="column"
        fxLayoutGap="16px"
        fxLayoutGap.lt-sm="0"
        style="padding: 10px;width:275px;justify-content: space-between;"
        *ngIf="isAndon()"
      >
        <mat-label>{{
          "SPA.DEVICE_CONFIGURATOR.SETTINGS.AUTOMATIC_REBOOT_ANDON" | translate
        }}</mat-label>
        <mat-slide-toggle
          class="checkbox"
          formControlName="isAndonAutomaticReboot"
        ></mat-slide-toggle>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="mt-10">
        <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
          <button color="warn" mat-button type="button" (click)="deviceService.goBack()"> {{ 'SPA.DEVICE.EDIT.BACK_BTN' | translate }}</button>
          <button color="primary" mat-raised-button type="submit"
                  [disabled]="form.pristine || !form.valid">
            {{ 'SPA.DEVICE.ADD.CREATE_BTN' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
