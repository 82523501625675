import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LineInterface } from '../../../../../common/line/interfaces/line.interface';
import { UserInterface } from '../../../../../common/auth/interfaces/user.interface';
import { EventCalendarInterface } from '../../../../../common/shift/interfaces/event-calendar.interface';
import { addDays, addHours, addMinutes, isAfter, isBefore, max, parseISO } from 'date-fns';
import { COLORS } from '../../../core/colors/colors';
import * as _ from 'lodash';
import { ShiftService } from '../../services/shift.service';
import { ModalsService } from '../../../../../common/shared/services/modals.service';
import { RecurringModalComponent } from '../recurring-modal/recurring-modal.component';
import * as moment from 'moment';
import { PauseCalendarInterface } from '../../../../../common/shift/interfaces/pause-calendar.interface';
import { Subscription } from 'rxjs';
import {
    ShiftCollisionErrorInterface,
    ShiftCollisionErrorsInterface,
} from '../../../../../common/shift/interfaces/shift-collision-errors.interface';
import { SocketService } from '../../../_services/socket.service';
import { SocketMessageEnum } from '../../../../../common/socket/enums/socket-message.enum';
import { PauseStartedEndedSocket } from '../../../../../common/pause/socket/pause-started-ended.socket';
import { EventRepeatEnum } from '../../../../../common/schedule/enums/event-repeat.enum';
import { CalendarPeriodInterface } from '../../../../../common/schedule/interfaces/calendar-period.interface';
import { WeekdayEnum } from '../../../../../common/schedule/enums/weekday.enum';
import { InfoboxService } from '../../../../../common/shared/services/infobox.service';

@Component({
  selector: 'app-shift-modal',
  templateUrl: './shift-modal.component.html',
  styleUrls: ['./shift-modal.component.scss'],
  providers: [TranslateService, ModalsService],
  encapsulation: ViewEncapsulation.None
})
export class ShiftModalComponent implements OnInit, OnDestroy {

    form: UntypedFormGroup;
    event: EventCalendarInterface;
    originalEventFetched: EventCalendarInterface;
    isPeriodical = false;
    loading = false;
    eventSub: Subscription;
    pausesSubs: Subscription[] = [];
    collisionErrors: ShiftCollisionErrorInterface[] = [];
    private socketSubs: string[] = [];
    startDateError = false;
    endDateError = false;
    constructor(
      public dialogRef: MatDialogRef<ShiftModalComponent>,
      public translateService: TranslateService,
      private socketService: SocketService,
      private infoBoxService: InfoboxService,
      @Inject(MAT_DIALOG_DATA) public data: {
          lines: LineInterface[],
          users: UserInterface[],
          event: EventCalendarInterface,
          date: Date,
          shiftService: ShiftService
      },
      private modalsService: ModalsService
    ) { }

    ngOnInit(): void {
        this.initForm();
        if (!!this.data.event) {
            this.loading = true;
            this.data.shiftService.fetchEvent(this.data.event.shiftId, this.data.event.id).subscribe((result) => {
                this.patchForm(result);
                this.loading = false;
            }, () => {
                this.loading = false;
            });
            this.subscribeToEventChanged();
            this.subscribeToPausesEvents();
        }
    }

    toDate(){
        if (!!this.form.get('end').value) {
            return this.form.get('end').value;
        }
        if (this.event) {
            return parseISO(this.event.end as string);
        } else {
            return this.minDateTo();
        }
    }

    changeEndDate(){
        if (!!this.form.get('end').value) {
            this.form.patchValue({end:this.form.get('end').value})
        }
        if (this.event) {
            this.form.patchValue({end:parseISO(this.event.end as string)})
        } else {
            this.form.patchValue({end:this.minDateTo()})
        }
    }

    subscribeToPausesEvents() {
        this.socketSubs.push(this.socketService.getSocket().on(SocketMessageEnum.PAUSE_STARTED, (data: PauseStartedEndedSocket) => {
            const pause = this.pauses.controls.find((pauseControl) => pauseControl.value.id === data.eventId);

            if (!!pause) {
                pause.patchValue({isActive: true});
                pause.disable();
            }
        }));
        this.socketSubs.push(this.socketService.getSocket().on(SocketMessageEnum.PAUSE_ENDED, (data: PauseStartedEndedSocket) => {
            const pause = this.pauses.controls.find((pauseControl) => pauseControl.value.id === data.eventId);

            if (!!pause) {
                pause.patchValue({isActive: false, isFinished: true});
                pause.disable();
            }
        }));
    }

    subscribeToEventChanged() {
        this.eventSub = this.data.shiftService.eventStatusChanged().subscribe((event) => {
            if (event.id === this.event.id) {
                this.event.isActive = event.isActive;
                this.event.isFinished = event.isFinished;
                if (event.isActive) {
                    this.form.get('start').disable();
                }
            }
        })
    }

    getLineName(lineId: number): string {
        const line = this.data.shiftService.lines.find(line => line.id === lineId);
        return line ? line.name : '';
    }

    minDateFrom() {
        return new Date;
    }

    minPauseDateFrom() {
        const minFromShift = !!this.form.get('start').value ? this.form.get('start').value : null;
        const minFromNow = new Date;

        return !!!minFromShift
          ? minFromNow
          : max([minFromShift, minFromNow]);
    }

    minPauseDateTo(index: number) {
        const pause = this.form.get('pauses').value[index];
        return !!pause && !!pause.start ? pause.start : this.minPauseDateFrom();
    }

    maxPauseDateTo() {
        return !!this.form.get('end').value ? this.form.get('end').value : null;
    }

    maxDateTo() {
        return !!this.form.get('start').value ? addDays(this.form.get('start').value, 1) : null;
    }

    minDateTo() {
        return !!this.form.get('start').value ? this.form.get('start').value : null;
    }

    close(data = null) {
        this.dialogRef.close(data);
    }

    save() {
        if (this.shouldBlockSave()) {
            return;
        }

        if (this.isPeriodical) {
            const period = this.form.get('period').value;

            this.fixPeriod(period);

            this.form.patchValue({period: this.fixWeekDayIssues(moment(this.form.get('start').value), period)});
        } else {
            this.form.patchValue({period: undefined});
        }

        if (this.isNew() || !this.isPeriodical) {
           this.saveAndClose();
        } else {
            this.askForRecurring().subscribe((data) => {
                if (!!data.executed) {
                    this.form.patchValue({
                        allFollowing: data.allFollowing,
                        period: undefined
                    });
                    this.saveAndClose();
                }
            });
        }
    }

    saveAndClose() {
        this.loading = true;
        this.data.shiftService.save(this.form.getRawValue()).subscribe((result: ShiftCollisionErrorsInterface) => {
            this.loading = false;
            if (!result.errors.length) {
                if(this.isPeriodical) {
                    this.infoBoxService.warning('SPA.SHIFTS.RECURRING_SHIFT_NOTICE');
                }
                this.close({ data: true });
            } else {
                this.collisionErrors = result.errors;
            }
        }, () => {
            this.loading = false;
        });
    }

    shouldBlockSave(): boolean {
        return !this.form.valid || this.isFinished() || !this.pausesCorrect() || this.startDateError || this.endDateError || this.isActive();
    }

    isFinished(): boolean {
        return !this.isNew() && this.event.isFinished;
    }

    isNew(): boolean {
        return !this.event || !this.event.id;
    }

    isActive(): boolean {
        return !this.isNew() && this.event.isActive;
    }

    initForm() {
        let clickedDate = this.data.date;

        if (!!clickedDate) {
            if (isBefore(clickedDate, new Date)) {
                const actualTime = new Date;
                clickedDate = addHours(clickedDate, actualTime.getHours() + 1);
            }
        }

        this.form = new UntypedFormGroup({
            id: new UntypedFormControl(undefined),
            shiftId: new UntypedFormControl(undefined),
            name: new UntypedFormControl('', Validators.required),
            color: new UntypedFormControl(COLORS[Math.floor(Math.random() * COLORS.length)]),
            start: new UntypedFormControl(clickedDate, Validators.required),
            end: new UntypedFormControl(null, Validators.required),
            lineIds: new UntypedFormControl([]),
            userIds: new UntypedFormControl([]),
            period: new UntypedFormControl({
                repeatEvery: {
                    count: 1,
                    repeatEnum: EventRepeatEnum.EVERY_DAY
                },
                repeatOn: {
                    daysOfWeek: [moment().isoWeekday()]
                },
                ends: {
                    on: null,
                    after: null
                }
            }),
            allFollowing: new UntypedFormControl(false),
            pauses:  new UntypedFormArray([])
        });
        this.pausesSubs.push(this.form.get('start').valueChanges.subscribe((result) => {
            const eventStart = moment(this.event?.start).format("YYYY-MM-DD");
            if(!!this.event?.start && !this.isNew() && !!this.event?.shift?.period) {
                // this.startDateError = !moment( (new Date(result)).toISOString().split('T')[0]).isSame(String(this.event?.start).split('T')[0]);
                this.startDateError = !moment( moment(result).format('YYYY-MM-DD')).isSame(eventStart);
            }
            if (this.isStartAfterEnd()) {
                this.form.patchValue({end: null});
            }
        }))

        this.form.get('end').valueChanges.subscribe((result) => {
            const eventEnd = moment(this.event?.end).format("YYYY-MM-DD");
            if(!!this.event?.end && !this.isNew() && !!this.event?.shift?.period) {
                // this.endDateError = !moment( (new Date(result)).toISOString().split('T')[0]).isSame(String(this.event?.end).split('T')[0]);
                this.endDateError = !moment( moment(result).format('YYYY-MM-DD')).isSame(eventEnd);
            }
        });
    }

    isStartAfterEnd(): boolean {
        return isBefore(this.form.get('end').value, this.form.get('start').value);
    }

    get formControls() {
        return this.form.controls;
    }

    get pauses() {
        return this.formControls.pauses as UntypedFormArray;
    }

    addPause() {
        if (this.isFinished()) {
            return;
        }
        let pauseName = this.translateService.instant('SPA.SHIFTS.PAUSE');
        const count = this.pauses.length + 1;
        pauseName = pauseName + ' ' + count;
        let start;
        let end;
        if(count === 1){
            if(moment.isMoment(this.form.get('start').value)){
                start = this.form.get('start').value ? addMinutes(this.form.get('start').value.toDate(), 1) : null;
            }else{
                start = this.form.get('start').value ? addMinutes(this.form.get('start').value, 1) : null
            }
        }else{
            if(moment.isMoment(this.form.get('pauses').value[this.pauses.length - 1].end)){
                start = this.form.get('pauses').value[this.pauses.length - 1].end ? addMinutes(this.form.get('pauses').value[this.pauses.length - 1].end.toDate(), 1) : null;
            }else{
                start = this.form.get('pauses').value[this.pauses.length - 1].end ? addMinutes(this.form.get('pauses').value[this.pauses.length - 1].end, 1) : null;
            }
        }
        if(moment.isMoment(start)){
            end = addMinutes(start.toDate(),1)
        } else {
            end = addMinutes(start,1)
        }
        if(moment(end) < moment(this.form.get('end').value)){
            this.pauses.push(new UntypedFormGroup({
                id: new UntypedFormControl(undefined),
                pauseId: new UntypedFormControl(undefined),
                name: new UntypedFormControl(pauseName, Validators.required),
                start: new UntypedFormControl(start, Validators.required),
                end: new UntypedFormControl(end, Validators.required),
                isActive: new UntypedFormControl(false),
                isFinished: new UntypedFormControl(false),
            }));
            this.addPauseChangesSubToCreated();
        }else{
            this.infoBoxService.error(`Cannot add pause after shift complete time`);
        }
    }

    addSavedPause(pause: PauseCalendarInterface) {
        const disabled = pause.isFinished || pause.isActive || this.isActive();

        this.pauses.push(new UntypedFormGroup({
            id: new UntypedFormControl(pause.id),
            pauseId: new UntypedFormControl(pause.pauseId),
            name: new UntypedFormControl({
                value: pause.name,
                disabled
            }, Validators.required),
            start: new UntypedFormControl(
              {
                  value: parseISO(pause.start as string),
                  disabled
              }, Validators.required),
            end: new UntypedFormControl(
              {
                  value: parseISO(pause.end as string),
                  disabled
              }, Validators.required),
            isActive: new UntypedFormControl(pause.isActive),
            isFinished: new UntypedFormControl(pause.isFinished),
        }));
        if (!disabled) {
           this.addPauseChangesSubToCreated();
        }
    }

    addPauseChangesSubToCreated() {
        const pauseCreated = this.pauses.controls[this.pauses.length - 1];
        this.pausesSubs.push(pauseCreated.valueChanges.subscribe((result) => {
            if (result.start && result.end && isBefore(result.end, result.start)) {
                pauseCreated.patchValue({end: null})
            }
        }));
    }

    removePause(index: number) {
        this.pauses.removeAt(index);
    }

    shouldDisableLineOption(lineId: number): boolean {
        return !this.isNew() && this.isActive()  && this.originalEventFetched.shift.lineIds.includes(lineId);
    }

    shouldDisableUserOption(userId: number): boolean {
        return !this.isNew() && this.isActive() && this.originalEventFetched.shift.userIds.includes(userId);
    }

    patchForm(event: EventCalendarInterface) {
        this.event = event;
        this.originalEventFetched =  _.cloneDeep(event);
        this.form.patchValue({
            id: this.event.id,
            shiftId: this.event.shiftId,
            name: this.event.shift.name,
            color: this.event.shift.color,
            lineIds: this.event.shift.lineIds,
            userIds: this.event.shift.userIds,
            start: parseISO(this.event.start as string),
            end: parseISO(this.event.end as string),
            isActive: event.isActive,
            isFinished: event.isFinished,
        });
        for (let pause of this.event.pauses) {
            this.addSavedPause(pause);
        }
        if (!!this.event.shift.period) {
            this.isPeriodical = true;
            this.form.patchValue({period: this.event.shift.period})
        }
        if (this.isActive()) {
            this.form.get('start').disable();
            this.form.get('end').disable();
            this.form.get('name').disable();
            this.form.get('lineIds').disable();
            this.form.get('userIds').disable();
        }

        if (this.isFinished()) {
            this.form.disable();
        }
    }

    askForRecurring(actionLabel: string = 'COMMON.SAVE') {
        return this.modalsService.custom(RecurringModalComponent, {
            width: '400px',
            disableClose: true,
            data: {
                actionLabel: actionLabel
            }
        })
    }

    changeColor(color: string) {
        this.form.patchValue({color: color});
    }

    stopShift() {
        this.data.shiftService.stopShift(this.event.shiftId, this.event.id);
        this.close({ data: false })
    }

    deleteShift() {
        if (this.isPeriodical) {
            this.askForRecurring('COMMON.DELETE').subscribe((data) => {
                if (!!data.executed) {
                    this.data.shiftService.deleteShift(this.event.shiftId, this.event.id, data.allFollowing);
                    this.close({ data: false, isDeleted: true })
                }
            });
        } else {
            this.data.shiftService.deleteShift(this.event.shiftId, this.event.id, false);
            this.close({ data: false, isDeleted: true })
        }
    }

    canDelete(): boolean {
        return !this.isNew() && !this.isActive() && !this.isFinished()
    }

    canStop(): boolean {
        return this.isActive();
    }

    pausesCorrect(): boolean {
        const pauses = this.form.get('pauses').value;
        let correct = true;
        if (!!!pauses.length) {
            return correct;
        }

        for (let i = 0; i < pauses.length; i++) {
            const singlePause = pauses[i];
            if (!singlePause.hasOwnProperty('start') && !singlePause.hasOwnProperty('end')) {
                continue;
            }

            if (
              (!!!singlePause.start || !!!singlePause.end) ||
              (
                !singlePause.isActive &&
                !singlePause.isFinished &&
                isBefore(singlePause.start, new Date)
              )
            ) {
                correct = false;
                break;
            }
            for (let k = i + 1; k < pauses.length; k++) {
                const pauseSecond = pauses[k];
                if(!pauseSecond){
                    break;
                }
                if (!!!pauseSecond.start || !!!pauseSecond.end || isBefore(pauseSecond.start,singlePause.end)) {
                    correct = false;
                    break;
                }
            }
        }

        return correct;
    }

    ngOnDestroy(): void {
        if (this.eventSub) {
            this.eventSub.unsubscribe();
        }
        this.socketSubs.forEach(s => this.socketService.getSocket().off(s));
        this.pausesSubs.forEach(p => p.unsubscribe());
    }

    private fixWeekDayIssues(localDate: moment.Moment, period: CalendarPeriodInterface): CalendarPeriodInterface {
        const utcDate = moment.utc(localDate);

        if (localDate.format('e') > utcDate.format('e')) {
            period.repeatOn.daysOfWeek = period.repeatOn.daysOfWeek.map(dow => {
                if (dow === WeekdayEnum.SUNDAY) {
                    return WeekdayEnum.MONDAY;
                }

                dow--;

                return dow;
            });
        } else if (localDate.format('e') < utcDate.format('e')) {
            period.repeatOn.daysOfWeek = period.repeatOn.daysOfWeek.map(dow => {
                if (dow === WeekdayEnum.SATURDAY) {
                    return WeekdayEnum.SUNDAY;
                }

                dow++;

                return dow;
            });
        }

        return period;
    }

    private fixPeriod(period: CalendarPeriodInterface) {
        if (!!!period?.ends?.on) {
            return;
        }

        let onMoment: moment.Moment;

        if (typeof period.ends.on === 'string') {
            onMoment = moment(period.ends.on);
        }

        if (moment.isMoment(period.ends.on)) {
            onMoment = period.ends.on;
        }

        period.ends.on = onMoment.add(12, 'hours').toISOString();
    }
}
