import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import {AddHistoryModalDataInterface} from '../interfaces/add-history-modal-data.interface';
import {WorkstepLogValidationResponseInterface} from '../../workstep-log/interfaces/workstep-log-validation-response.interface';
import {DataManipulationSaveInterface} from '../../data-collection/interfaces/data-manipulation-save.interface';
import {DataManipulationInterface} from '../../data-collection/interfaces/data-manipulation.interface';
import {ProductItemDetailsInterface} from '../interfaces/product-item-details.interface';
import {DataCollectionWorkstepInterface} from '../../data-collection/interfaces/data-collection-workstep.interface';
import {ProductItemTimelineLineInterface} from '../interfaces/product-item-timeline-line.interface';
import { ReworkCommentsInterface } from '../../rework-comments/interfaces/rework-comments.interface';
import { DataCollectionNestedDataInterface } from '../../data-collection/interfaces/data-collection-nested-data.interface';

@Injectable()
export class ProductDetailsNetwork {
    constructor(private http: HttpClient) {

    }

    getTimeline(productItemID: number) {
        return this.http.get<ProductItemTimelineLineInterface[]>(`/api/product-item-details/timeline/${productItemID}`);
    }

    getDataCollection(productItemID: number) {
        return this.http.get<DataCollectionWorkstepInterface[]>(`/api/product-item-details/data-collection/${productItemID}`);
    }

    getReworkComments(productItemID: number) {
        return this.http.get<ReworkCommentsInterface[]>(`/api/product-item-details/rework-comments/${productItemID}`);
    }

    getDetails(productItemID: number) {
        return this.http.get<ProductItemDetailsInterface>(`/api/product-item-details/${productItemID}`);
    }

    getWidgetData(productData: AddHistoryModalDataInterface) {
        return this.http.get<DataManipulationInterface>(
          `/api/product-item-details/data-manipulation/
          ${productData.productItemId}/${productData.workstepId}/${productData.stationId}/${productData.widgetId}`
        );
    }

    updateReworkData(reworkId: number, comment: string, reworkDone: boolean, resolvedStationId: number): Observable<any> {
        return this.http.patch<any>(`/api/rework-widget-comments/edit/${reworkId}`, {
            reworkComment: comment,
            reworkDone: reworkDone,
            resolvedStationId
        });
    }

    saveWidgetData(productData: AddHistoryModalDataInterface, data: DataManipulationSaveInterface) {
        return this.http.post<WorkstepLogValidationResponseInterface>(
            `/api/product-item-details/data-manipulation/
            ${productData.productItemId}/${productData.workstepId}/${productData.stationId}/${productData.widgetId}`,
            data
        );
    }

    exportProductData(productItemId: number, search?: string) {
        const headers = new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
        });
        let params = new HttpParams();
        if (search) {
            params = params.set('search', search);
        }

        return this.http.get(`/api/product-item-details/data-collection/${productItemId}`, {headers, responseType: 'json', params}).pipe(map((res: DataCollectionNestedDataInterface) => 
            {
                const jsonBlob = new Blob([JSON.stringify(res, null, 2)], { type: 'application/json' });
            return jsonBlob;
            })
        ).pipe(map(res => {
			const a = document.createElement('a')
			const objectUrl = URL.createObjectURL(res)
			a.href = objectUrl
			a.download = `product-items-listing.json`;
			a.click();
			URL.revokeObjectURL(objectUrl);
	    }));
    }

    getProductDataCollectionDetail(productItemID: number, search?: string) {
        let url: string = `/api/product-item-details/data-collection/${productItemID}`;
        if(search) {
            url += `?search=${search}`;
        }
        return this.http.get<DataCollectionNestedDataInterface>(url);
    }

    fetchWorkstepPdfBlob(apiUrl: string, workstepId: number, token: string) {
        return this.http.get(`${apiUrl}/api/workstep-file/view/${workstepId}?authentication_token=${token}`, { responseType: 'blob' });
    }
}