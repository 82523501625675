import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { StationService } from './station.service';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FtpConfigurationModule } from '../system-settings/ftp-configuration/ftp-configuration.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import { StationListComponent } from './components/station-list/station-list.component';
import { StationAddComponent } from './components/station-add/station-add.component';
import { StationEditComponent } from './components/station-edit/station-edit.component';
import { EditStationAeIoMappingComponent } from './components/station-edit/edit-station-ae-io-mapping/edit-station-ae-io-mapping.component';

@NgModule ({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    FtpConfigurationModule,
    MatTableModule,
    MatIconModule,
    MatDialogModule,
    MatSelectModule,
    ReactiveFormsModule,
    DragDropModule,
    MatSlideToggleModule,
    MatFormFieldModule
  ],
  declarations: [
    StationAddComponent,
    StationListComponent,
    StationEditComponent,
    EditStationAeIoMappingComponent,
  ],
  providers: [
    StationService,
  ],
  exports: [StationListComponent],
})
export class StationsModule { }
