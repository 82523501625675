<div class="row" *ngIf="lineId !== null">
    <div class="col s12">
        <form [formGroup]="form" (ngSubmit)="save()">
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" fxLayoutAlign=" end">
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                    <mat-label>{{ 'SPA.LINES.LINE_NAME' | translate }}</mat-label>
                    <input matInput required formControlName="name">
                    <mat-error>{{ 'SPA.LINES.LINE_NAME_ERROR' | translate }}</mat-error>
                </mat-form-field>
                <div *ngIf="!(isNoBeat() || isStationBasedBeat)">
                    <div>
                        {{ (isProduction() ? 'SPA.LINES.LINE_BEAT' : 'SPA.LINES.STATION_BEAT') | translate }}
                    </div>
                    <div>
                        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary"
                                    *ngIf="!isNoBeat()" style="width:100px">
                            <mat-label>
                                {{ 'SPA.LINES.HOURS' | translate }}
                            </mat-label>
                            <input matInput type="Number" formControlName="beatHours" (change)="leadingZeros($event)"
                            (keyup)="leadingZeros($event)"
                            (click)="leadingZeros($event)" min='0'>
                            <mat-error>{{ 'SPA.LINES.LINE_BEAT_ERROR' | translate }}</mat-error>
                        </mat-form-field>
                        <div class="dot-text">:</div>
                        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary"
                                    *ngIf="!isNoBeat()" style="width:100px">
                            <mat-label>
                                {{ 'SPA.LINES.MINUTES' | translate }}
                            </mat-label>
                            <input matInput type="Number" formControlName="beatMinutes" (change)="leadingZeros($event)"
                            (keyup)="leadingZeros($event)"
                            (click)="leadingZeros($event)" min='0'>
                            <mat-error>{{ 'SPA.LINES.LINE_BEAT_ERROR' | translate }}</mat-error>
                        </mat-form-field>
                        <div class="dot-text">:</div>
                        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary"
                                    *ngIf="!isNoBeat()" style="width:100px">
                            <mat-label>
                                {{ 'SPA.LINES.SECONDS' | translate }}
                            </mat-label>
                            <input matInput type="Number" formControlName="beatSeconds" (change)="leadingZeros($event)"
                            (keyup)="leadingZeros($event)"
                            (click)="leadingZeros($event)" min='0'>
                            <mat-error>{{ 'SPA.LINES.LINE_BEAT_ERROR' | translate }}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                    <mat-label>{{ 'SPA.LINES.LINE_EDIT.LINE_TYPE' | translate }}</mat-label>
                    <mat-select formControlName="typeEnum">
                        <mat-option *ngFor="let typeKey of lineTypes | enumToArray" [value]="lineTypes[typeKey]">{{ 'ENUMS.LINE_TYPE.' + typeKey | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                    <mat-label>{{ 'SPA.LINES.LINE_EDIT.BEAT_TYPE' | translate }}</mat-label>
                    <mat-select formControlName="beatTypeEnum">
                        <mat-option *ngFor="let typeKey of lineBeatTypes | enumToArray" [value]="lineBeatTypes[typeKey]">
                            {{ 'ENUMS.LINE_BEAT_TYPE.' + typeKey | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                    <mat-label>{{ 'SPA.LINES.LINE_ADD.STATION_ORDER' | translate }}</mat-label>
                    <mat-select formControlName="stationOrderTypeEnum">
                        <mat-option *ngFor="let typeKey of stationOrderTypes | enumToArray" [value]="stationOrderTypes[typeKey]">
                            {{ 'ENUMS.STATION_ORDER.' + typeKey | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div
                fxLayout="row"
                class="content-row"
                fxLayout.lt-sm="column"
                fxLayoutGap="16px"
                fxLayoutGap.lt-sm="0"
                style="padding: 10px;width:300px;justify-content: space-between;"
              >
                
                <mat-slide-toggle
                  class="checkbox"
                  formControlName="allowFractionValues"
                ></mat-slide-toggle>
                <mat-label>{{
                    "SPA.LINES.LINE_ADD.ALLOW_FRACTION_VALUES" | translate
                  }}</mat-label>
              </div>

            <div
                fxLayout="row"
                class="content-row"
                fxLayout.lt-sm="column"
                fxLayoutGap="16px"
                fxLayoutGap.lt-sm="0"
                style="padding: 10px;width:300px;justify-content: space-between;"
                *ngIf="canShowAllowAutomaticTransfer"
              >
                
                <mat-slide-toggle
                  class="checkbox"
                  formControlName="allowAutomaticTransfer"
                ></mat-slide-toggle>
                <mat-label>{{
                    "SPA.LINES.LINE_ADD.ALLOW_AUTOMATIC_TRANSFER" | translate
                  }}</mat-label>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="mt-10">
                <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
                    <button color="warn" mat-button type="button" (click)="delete()" [disabled]="!canDelete()" *apConfiguration="[ConfigurationEnum.MULTILINE]">
                        {{ 'SPA.LINES.DELETE_BTN' | translate }}
                    </button>
                    <button color="primary" mat-raised-button type="submit" [disabled]="form.pristine || !form.valid">
                        {{ 'SPA.LINES.SAVE_BTN' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
