import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ChartDataResponseInterface} from '../../../../common/charts/interfaces/chart-data-response.interface';
import { ChartsAndTablesFiltersInterface } from '../../../../common/charts/interfaces/charts-and-tables-filters.interface';

@Injectable()
export class ChartsAndTablesDataNetwork {
    constructor(private http: HttpClient) {
    }

    loadChartData(payload: ChartsAndTablesFiltersInterface): Observable<ChartDataResponseInterface> {
        return this.http.post<ChartDataResponseInterface>('/charts', payload);
    }

    loadTableData(payload: ChartsAndTablesFiltersInterface): Observable<any> {
        return this.http.post<any>('/table', payload);
    }
}