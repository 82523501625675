import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  HttpClient,
  HttpParams
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { StationFullInterface } from '../../../common/station/interfaces/station-full.interface';
import { WorkInstructionDataInterface } from '../../../common/work-instructions/interfaces/work-instruction-data.interface';

@Injectable()
export class WorkInstructionsService {
  constructor(
    private http: HttpClient,
  ) {
    this.apiUrl = environment.apiUrl;
  }

  apiUrl: String;

  getStations(): Observable<StationFullInterface[]> {
    return this.http.get<StationFullInterface[]>(this.apiUrl + '/api/station');
  }

  createWorkInstruction(lineId: number, workInstruction: WorkInstructionDataInterface): Observable<boolean> {
    const params = new HttpParams({
      fromObject: {
        noContentType: 'true'
      }
    });
    const formData = new FormData();
    formData.append('file', workInstruction.file);
    formData.append('name', workInstruction.name);
    let workInstructionFolder: string = workInstruction.name;
    if (!!workInstruction.folder) {
      workInstructionFolder = workInstruction.folder + '/' + workInstruction.name;
    }
    formData.append('folder', workInstructionFolder);
    formData.append('stations', JSON.stringify(workInstruction.stations));

    return this.http.post<boolean>(this.apiUrl + `/api/work-instructions/${lineId}`, formData, {
      params
    });
  }

  getWorkInstructions(lineId: number): Observable<WorkInstructionDataInterface[]> {
    return this.http.get<WorkInstructionDataInterface[]>(this.apiUrl + `/api/work-instructions/${lineId}`);
  }

  deleteWorkInstruction(lineId: number, id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.apiUrl + `/api/work-instructions/${lineId}/${id}`);
  }

  updateWorkInstruction(lineId: number, workInstruction: WorkInstructionDataInterface): Observable<boolean> {
    const params = new HttpParams({
      fromObject: {
        noContentType: 'true'
      }
    });
    const formData = new FormData();
    formData.append('file', workInstruction?.file || null);
    formData.append('name', workInstruction.name);
    let workInstructionFolder: string = workInstruction.name;
    if (!!workInstruction.folder) {
      workInstructionFolder = workInstruction.folder + '/' + workInstruction.name;
    }
    formData.append('folder', workInstructionFolder);
    formData.append('stations', JSON.stringify(workInstruction.stations));

    return this.http.patch<boolean>(this.apiUrl + `/api/work-instructions/${lineId}/${workInstruction.id}`, formData, {
      params
    });
  }
}
