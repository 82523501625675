
<mat-card>

    <page-header titleTranslate="SPA.ALARMS_ESCALATIONS.ALARM_DETAILS.Header"></page-header>

    <h3 class="sub-title">{{'SPA.ALARMS_ESCALATIONS.ALARM_DETAILS.GENERAL_INFORMATION' | translate }}</h3>

    <div class="alarm-details">
        <div class="item">
            <span class="alarm-details-title">{{'SPA.ALARMS_ESCALATIONS.ALARM_DETAILS.ALARM_ID' | translate }}</span>
            <span class="alarm-details-details">{{alarm.alarmId}}</span>
        </div>
        <div class="item">
            <span class="alarm-details-title">{{'SPA.ALARMS_ESCALATIONS.ALARM_DETAILS.ALARM_TYPE' | translate }}</span>
            <span class="alarm-details-details">{{ getTranslatedText('name', alarm?.translationJson) }}</span>
        </div>
        <div class="item">
            <span class="alarm-details-title" style="min-width: 150px;">{{'SPA.ALARMS_ESCALATIONS.ALARM_DETAILS.REASON' | translate }}</span>
            <span class="alarm-details-details alarm-reason-span" *ngIf="!!alarm.reason && alarm.reason != ''; else notResolved">
                {{ getTranslatedText('name', alarm?.reasonTranslationJson) }}
                <button color="primary" mat-raised-button  (click)="updateReason(alarm)">
                    <mat-icon class=""
                                inline="true">edit
                    </mat-icon>
                </button>
            </span>
            <ng-template #notResolved>
                <span class="alarm-details-details alarm-reason-span">-</span>
            </ng-template>
        </div>
        <div class="item">
            <span class="alarm-details-title">{{'SPA.ALARMS_ESCALATIONS.ALARM_DETAILS.PRODUCTION_SECTOR' | translate }}</span>
            <span class="alarm-details-details">{{alarm.productionSector}}</span>
        </div>
        <div class="item">
            <span class="alarm-details-title">{{'SPA.ALARMS_ESCALATIONS.ALARM_DETAILS.STATION' | translate }}</span>
            <span class="alarm-details-details">{{alarm.station}}</span>
        </div>
        <div class="item">
            <span class="alarm-details-title">{{'SPA.ALARMS_ESCALATIONS.ALARM_DETAILS.DURATION' | translate }}</span>
            <span class="alarm-details-details">{{alarm.duration | duration}}</span>
        </div>
        <div class="item">
            <span class="alarm-details-title">{{'SPA.ALARMS_ESCALATIONS.ALARM_DETAILS.CREATED_BY' | translate }}</span>
            <span class="alarm-details-details">{{alarm.creator}}</span>
        </div>
        <div class="item">
            <span class="alarm-details-title">{{'SPA.ALARMS_ESCALATIONS.ALARM_DETAILS.SOLVED_BY' | translate }}</span>
            <span class="alarm-details-details">{{alarm.resolvedFrom}}</span>
        </div>
        <div *ngFor="let d of alarm.data" class="item">

        
            <div class="alarm-details-title">{{ getTranslatedText('name', d?.translationJson)}}</div>
            <div *ngIf="!isImageData(d)" class="alarm-details-details">{{d.valueAsAString}}</div>
            <div *ngIf="isImageData(d)" class="alarm-details-details image-container">
                <div class="image" *ngFor="let file of d.value">
                    <img *ngIf="file?.fileType === 'image'" [src]="getImageSrc(file)" (click)="openViewModal(file)">
                    <div class="video-wrapper" *ngIf="file?.fileType === 'video'" (click)="openViewModal(file)">
                        <video #videoPlayer [src]="getImageSrc(file)"></video>
                        <i class="material-icons right play_arrow">play_arrow</i>
                    </div>
                </div>
            </div>
        </div>

        

        <div class="item">
            <span class="alarm-details-title">{{'SPA.ALARMS_ESCALATIONS.ALARM_DETAILS.FORWARDED' | translate }}</span>
            <span class="alarm-details-details">
                <mat-icon class="success mat-18" *ngIf="alarm.forwarded; else notForwarded">check</mat-icon>
                <ng-template #notForwarded>
                    <mat-icon class="danger mat-18">close</mat-icon>
                </ng-template>
            </span>
        </div>
        <div class="item">
            <span class="alarm-details-title">{{'SPA.ALARMS_ESCALATIONS.ALARM_DETAILS.ESCALATED' | translate }}</span>
            <span class="alarm-details-details">
                <mat-icon class="success mat-18" *ngIf="alarm.escalated; else notEscalated">check</mat-icon>
                <ng-template #notEscalated>
                    <mat-icon class="danger mat-18">close</mat-icon>
                </ng-template>
            </span>
        </div>
        <div class="item">
            <span class="alarm-details-title">{{'SPA.ALARMS_ESCALATIONS.ALARM_DETAILS.MARKED' | translate }}</span>
            <span class="alarm-details-details">
                {{ !!alarm.isDisorderUnderInvestigation ? 'YES' : 'NO' }}
            </span>
        </div>
        <div class="item">
            <span class="alarm-details-title">{{'SPA.ALARMS_ESCALATIONS.ALARM_DETAILS.COMMENT_ON_MARKUP' | translate }}</span>
            <span class="alarm-details-details">
                {{ alarm?.comment || '-' }}
            </span>
        </div>
        <div class="item">
            <span class="alarm-details-title">{{'SPA.ALARMS_ESCALATIONS.ALARM_DETAILS.COMMENT_ON_REWORK' | translate }}</span>
            <span class="alarm-details-details">
                {{ alarm?.reworkComment || '-' }}
            </span>
        </div>
    </div>

  <div style="margin-top:20px;"></div>
    <h3 class="sub-title">{{'SPA.ALARMS_ESCALATIONS.ALARM_DETAILS.ALARM_TIMELINE' | translate }}</h3>

    <div style="background: #f6f6f9; border-radius: 8px; width: 100%;" *ngIf="isTimelioneLoaded">
        <apx-chart
            [series]="newChartOptions.series"
            [chart]="newChartOptions.chart"
            [fill]="newChartOptions.fill"
            [legend]="newChartOptions.legend"
            [plotOptions]="newChartOptions.plotOptions"
            [xaxis]="newChartOptions.xaxis"
            [tooltip]="newChartOptions.tooltip"
            [dataLabels]="newChartOptions.dataLabels"
        ></apx-chart>
        <!-- <apx-chart
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [dataLabels]="chartOptions.dataLabels"
                [plotOptions]="chartOptions.plotOptions"
                [xaxis]="chartOptions.xaxis"
                [legend]="chartOptions.legend"
                [tooltip]="chartOptions.tooltip"

        ></apx-chart> -->
        <!-- <div style="display: flex; flex-direction: column; align-items: center">
            <mat-form-field appearance="outline" style="margin-left: 14px">
                <mat-label>A-Axis labels</mat-label>
                <mat-select [(ngModel)]="chartViewMode" (selectionChange)="chartViewModeChanged($event)" placeholder="">
                    <mat-option value="time">Time</mat-option>
                    <mat-option value="ticks">Ticks ({{unit}})</mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
    </div>

</mat-card>
