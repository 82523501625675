import { Component, OnInit, ViewChild } from "@angular/core";
import { AEGroupInterface    } from "../../groups/interfaces/a-e-group.interface";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatSort } from "@angular/material/sort";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { AEGroupsService } from "../../groups/services/a-e-groups.service";
import { ModalsService } from "../../../../../common/shared/services/modals.service";
// import { UserInterface } from "../../../../../../prodwatch.NEST/common/auth/interfaces/user.interface";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-alarm-group-list",
  templateUrl: "./alarm-group-list.component.html",
  styleUrls: ["./alarm-group-list.component.scss"],
})
export class AlarmGroupListComponent implements OnInit {
  groups: AEGroupInterface[] = [];
  displayedColumns: string[] = [
    "name",
    "users",
    "sendOccurredNotifications",
    "actions",
  ];
  dataSource: MatTableDataSource<AEGroupInterface>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  showFilters = false;

  constructor(
    private matDialog: MatDialog,
    private groupsService: AEGroupsService,
    private modalsService: ModalsService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {
    this.groupsService.fetchGroups().subscribe((result) => {
      this.groups = result;
      this.dataSource = new MatTableDataSource<AEGroupInterface>(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  changeFilters() {}

  delete(id: number) {
    this.modalsService.confirm('SPA.ALARMS_ESCALATIONS.GROUPS.REMOVE_DESCRIPTION').subscribe((result) => {
        if (result) {
           this.groupsService.delete(id).subscribe(() => {
               this.loadList();
           });
        }
    });
}

  displayUSers(users: any[])
  {
    var listOfNames= users.map(c=> c.firstname + ' ' + c.name);
    return listOfNames.join(', ');
  }

  checkNotification(element): string {
    
    return ((!!element.sendOccurredNotifications || !!element.sendForwardedNotifications ||  !!element.sendEscalatedNotifications || !!element.sendEndedNotifications) ? this.translate.instant(
      "SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.ENABLED"
    ) :this.translate.instant(
      "SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.DISABLED"
    ));
  }
}
