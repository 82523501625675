import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import { StorageService } from '../../../../common/shared/services/storage.service';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
    constructor(private storage: StorageService) {

    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.params.has('ignoreAuthInterception')) {
            req = this.overrideRequest(req);
        }

        return next.handle(req);
    }

    private overrideRequest(req: HttpRequest<any>): HttpRequest<any> {
        if (req.headers.has('Authorization')) {
            return req;
        }

        const headers = req.headers
            .append('Authorization', `Bearer ${this.storage.getItem('token')}`);

        return req.clone({headers});
    }
}
