import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DisorderInterface} from '../../../../../_utils/interfaces/disorder.interface';
import {ROW_MODE} from '../group-disorder.component';
import {DisorderRowModel} from '../../../models/disorder-row.model';
import {DisorderService} from '../../../services/disorder.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-disorder-row',
  templateUrl: './disorder-row.component.html',
  styleUrls: ['./disorder-row.component.scss']
})
export class DisorderRowComponent implements OnInit {

    expanded = false;
    @Input() disorder: DisorderRowModel;
    @Input() marginLeft: string;
    @Input() isLastNode = false;
    @Output() onDelete: EventEmitter<DisorderRowModel> = new EventEmitter<DisorderRowModel>();
    constructor(private disorderService: DisorderService) { }

    ngOnInit(): void {
    }

    isAddEditListing(): boolean {
        return this.disorder.mode === ROW_MODE.ADD_EDIT_LISTING;
    }

    isEditing(): boolean {
        return this.disorder.mode === ROW_MODE.EDITING;
    }

    edit() {
        this.disorder.mode = ROW_MODE.EDITING;
    }

    delete(disorder: DisorderRowModel, event) {
        this.onDelete.emit(disorder);
        event.stopPropagation();
    }

    onChildDelete(disorderToRemove: DisorderRowModel) {
        this.disorderService.deleteConfirmation().subscribe((result) => {
            if (result) {
                this.disorderService.delete(disorderToRemove.id).subscribe(() => {
                    this.disorder.children = _.filter(this.disorder.children, (disorder: DisorderRowModel) => {
                        return disorder.id !== disorderToRemove.id;
                    });
                })
            }
        });
    }

    onDisorderSaved(disorder: DisorderRowModel) {
        this.disorder.mode = ROW_MODE.ADD_EDIT_LISTING;
        if (disorder !== null) {
            this.disorder.updateModel(disorder);
        }
    }

    addChild(event) {
        this.expanded = true;
        event.stopPropagation();
        this.disorder.addEmptyChild();
    }
}
