import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { FtpStationInterface } from '../interfaces/ftp-station.interface';
import { map } from 'rxjs/operators';
import { FtpConfigurationInterface } from '../../../../../common/ftp/interfaces/ftp-configuration.interface';

@Injectable()
export class FtpNetworkService {
    private readonly apiUrl: string;

    constructor(
        private http: HttpClient,
    ) {
        this.apiUrl = environment.apiUrl;
    }

    getConfiguration(): Observable<FtpConfigurationInterface> {
        return this.http
            .get<FtpConfigurationInterface>( this.apiUrl + '/api/ftp/configuration')
            .pipe(
                map(response => {
                    response.updateTime /= 60; //It comes in seconds and needs to be in minutes
                    return  response;
                })
            );
    }

    updateConfiguration(configuration: FtpConfigurationInterface): Observable<FtpConfigurationInterface> {
        const configurationToSend = {...configuration};
        configurationToSend.updateTime *= 60; //It needs to be in seconds

        return this.http
            .post<FtpConfigurationInterface>(this.apiUrl + '/api/ftp/configuration', configurationToSend)
            .pipe(
                map(response => {
                    response.updateTime /= 60; //It comes in seconds and needs to be in minutes
                    return  response;
                })
            );
    }

    checkConnection(configuration: FtpConfigurationInterface): Observable<boolean>{
        const configurationToSend = {...configuration};
        configurationToSend.updateTime *= 60; //It needs to be in seconds

        return this.http
            .post<boolean>( this.apiUrl + '/api/ftp/configuration/check', configurationToSend);
    }

    stopAllSlideshows(): Observable<boolean> {
        return this.http
          .post<boolean>( this.apiUrl + '/api/ftp/configuration/stop-all-slideshows', null);
    }

    restartAllSlideshows(): Observable<boolean> {
        return this.http
          .post<boolean>( this.apiUrl + '/api/ftp/configuration/restart-all-slideshows', null);
    }

    getFtpStation(stationId: number): Observable<FtpStationInterface> {
        return this.http
          .get<FtpStationInterface>(this.apiUrl + `/api/ftp/station/${stationId}`);
    }

    updateFtpStationFolder(ftpStation: FtpStationInterface): Observable<FtpStationInterface> {
        return this.http
          .post<FtpStationInterface>(this.apiUrl + `/api/ftp/station/${ftpStation.stationId}`, ftpStation);
    }


}
