import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-recurring-modal',
  templateUrl: './recurring-modal.component.html',
  styleUrls: ['./recurring-modal.component.scss']
})
export class RecurringModalComponent {

    allFollowing = false;
    constructor(
        public dialogRef: MatDialogRef<RecurringModalComponent>,
        public translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: {
            actionLabel: string,
        },
    ) { }

    close() {
        this.dialogRef.close(false);
    }

    save() {
        this.dialogRef.close({
            executed: true,
            allFollowing: this.allFollowing
        });
    }
}
