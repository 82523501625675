import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LineInterface } from '../../../../../common/line/interfaces/line.interface';
import { LineService } from '../../../line/line.service';
import { DeviceActionEnum } from '../../../shared/components/devices/enums/device-action.enum';
import { DeviceClickAction } from '../../../shared/components/devices/components/devices-list/devices-list.component';

@Component({
  selector: 'app-station-list',
  templateUrl: './station-list.component.html',
  styleUrls: ['./station-list.component.scss']
})
export class StationListComponent implements OnInit, OnDestroy {
  constructor(
    public lineService: LineService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.route.parent.parent.params.subscribe(params => {
        this.lineService.setSelectedLine(+params.id);
        this.lineService.getLine(+params.id).subscribe((response) => {
          this.lineService.line = response;
      });
    });
  }

  get line(): LineInterface {
    return this.lineService.line;
  }

  clicked(action: DeviceClickAction) {
    const route = action.action === DeviceActionEnum.ADD
      ? ['/line', this.line.id, 'stations', 'add']
      : ['/line', this.line.id, 'stations', 'edit', action.deviceId];

    this.router.navigate(route);
  }

  ngOnDestroy() {
  }
}
