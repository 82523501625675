import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FtpConfigurationComponent } from './ftp-configuration.component';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { FtpNetworkService } from './services/ftp-network.service';
import { FtpStationService } from './services/ftp-station.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatSlideToggleModule,
    FormsModule
  ],
  declarations: [
    FtpConfigurationComponent
  ],
  providers: [
    FtpNetworkService,
    FtpStationService,
  ]
})
export class FtpConfigurationModule { }
