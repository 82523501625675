import {Component, OnInit} from '@angular/core';
import {AuthGuard} from '../_guards/auth.guard';
import {ActivatedRoute, Router} from '@angular/router';
import {BreadcrumbComponent} from '../shared/components/breadcrumb.component';
import { UserPermissionsEnum } from '../../../common/auth/enums/user-permissions.enum';

@Component({
    selector: 'app-alarms-and-escalations',
    templateUrl: './alarms-and-escalations.component.html',
    styleUrls: ['./alarms-and-escalations.component.scss']
})
export class AlarmsAndEscalationsComponent extends BreadcrumbComponent implements OnInit {

    public UserPermissionsEnum: UserPermissionsEnum;
    constructor(
        public authGuard: AuthGuard,
        public route: ActivatedRoute,
        public router: Router
    ){
        super(route, router);
    }

    showSupervisorList(): boolean {
        return  this.authGuard.hasPermission([UserPermissionsEnum.AESupervisor]);
    }

    showDisorderList(): boolean {
        return  this.authGuard.hasPermission([UserPermissionsEnum.AEDisorderReasonManagement]);
    }

    showGroupList(): boolean {
        return  this.authGuard.hasPermission([UserPermissionsEnum.AEGroupsManagement]);
    }

    ngOnInit(): void {
        this.initBreadcrumbs();
    }
}