import { Routes } from '@angular/router';
import { AuthGuard } from '../_guards/auth.guard';
import { ExportComponent } from './export/export.component';
import { TemplatesComponent } from './templates/templates.component';
import { ReportsComponent } from './reports.component';
import { ConfigurationEnum } from '../../../common/configuration/enums/configuration.enum';
import { UserPermissionsEnum } from '../../../common/auth/enums/user-permissions.enum';

export const REPORTS_ROUTES: Routes = [
    {
        path: '',
        component: ReportsComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: [UserPermissionsEnum.ReportsPage],
            configuration: ConfigurationEnum.REPORTS,
            breadcrumb: {
                label: 'SPA.NAV.REPORTS',
                path: '/reports/export'
            }
        },
        children: [
            {
                path: 'export',
                component: ExportComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: {
                        label: 'SPA.EXPORTS.LABEL',
                    }
                }
            },
            {
                path: 'templates',
                component: TemplatesComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: {
                        label: 'SPA.EXPORTS.TEMPLATE_BTN',
                    }
                }
            }
    ]}
];
