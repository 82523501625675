<page-header titleTranslate="SPA.EXPORTS.LABEL"></page-header>

<div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{ 'SPA.TEMPLATES.NAME' | translate }} *</mat-label>
        <input matInput [(ngModel)]="exportObj.name">
    </mat-form-field>
    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{ 'SPA.EXPORTS.TEMPLATE' | translate }} *</mat-label>
        <mat-select [(ngModel)]="exportObj.templateId" >
            <mat-option *ngFor="let template of templates" [value]="template.id">{{template.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{ 'SPA.EXPORTS.LINES' | translate }} *</mat-label>
        <mat-select [(ngModel)]="exportObj.lineId" >
            <mat-option *ngFor="let line of lines" [value]="line.id">{{line.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{ 'SPA.EXPORTS.RANGE' | translate }}</mat-label>
        <mat-select [(ngModel)]="rangeName" (ngModelChange)="rangeChange()">
            <mat-option [value]="">{{ 'SPA.EXPORTS.SELECT_RANGE' | translate }}</mat-option>
            <mat-option value="LAST_SHIFT">{{ 'SPA.EXPORTS.LAST_SHIFT' | translate }}</mat-option>
            <mat-option value="YESTERDAY">{{ 'SPA.EXPORTS.YESTERDAY' | translate }}</mat-option>
            <mat-option value="CURRENT_WEEK">{{ 'SPA.EXPORTS.CURRENT_WEEK' | translate }}</mat-option>
            <mat-option value="LAST_WEEK">{{ 'SPA.EXPORTS.LAST_WEEK' | translate }}</mat-option>
            <mat-option value="CURRENT_MONTH">{{ 'SPA.EXPORTS.CURRENT_MONTH' | translate }}</mat-option>
            <mat-option value="LAST_MONTH">{{ 'SPA.EXPORTS.LAST_MONTH' | translate }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{ 'SPA.EXPORTS.FROM' | translate }}</mat-label>
        <input type="text" matInput [matDatepicker]="picker" [(ngModel)]="exportObj.beginDate" [disabled]="disableDates()" [ngModelOptions]="{standalone: true}">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{ 'SPA.EXPORTS.FROM' | translate }}</mat-label>
        <input type="text" matInput [matDatepicker]="pickerEnd" [(ngModel)]="exportObj.endDate" [disabled]="disableDates()" [ngModelOptions]="{standalone: true}">
        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
        <mat-datepicker #pickerEnd></mat-datepicker>
    </mat-form-field>
</div>
<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
    <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
        <button color="primary" mat-raised-button [disabled]="disableExport()" (click)="createExport()">
            {{ 'SPA.EXPORTS.CREATE_BTN' | translate }}
        </button>
    </div>
</div>