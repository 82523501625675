<ng-container *ngIf="stations && stations.length >0">
    <page-header titleTranslate="SPA.DEVICE.EDIT.IO_MAPPING" class="mt-5"></page-header>

    <form #stationIOMappingForm="ngForm" >
        <app-aes-stations-io-mapping [stations] = 'stations' name = 'stationIoMapping' [(ngModel)] = 'stationsIOMapping'></app-aes-stations-io-mapping>
    
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="mt-10">
          <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
            <button color="warn" mat-button type="button" > {{ 'SPA.DEVICE.EDIT.BACK_BTN' | translate }}</button>
            <button color="primary" (click) = 'saveMapping()' mat-raised-button type="submit">
              {{ 'SPA.DEVICE.EDIT.SAVE_BTN' | translate }}
            </button>
          </div>
        </div>
      </form>
    
</ng-container>

