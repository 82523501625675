import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailConfigurationComponent } from './email-configuration.component';
import { EmailConfigurationService } from './email-configuration.service';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule } from '@angular/forms';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatSlideToggleModule
  ],
  declarations: [EmailConfigurationComponent],
  providers: [
    EmailConfigurationService
  ]
})
export class EmailConfigurationModule { }
