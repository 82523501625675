import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BreadcrumbComponent} from '../shared/components/breadcrumb.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent extends BreadcrumbComponent implements OnInit {
    constructor(
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(route, router);
    }

    ngOnInit(): void {
        this.initBreadcrumbs();
    }
}
