import { Subject, Subscription, timer } from 'rxjs';

export class TimerUtil {
    private _timerSubscription: Subscription;
    private _shouldStop: Subject<boolean> = new Subject<boolean>();
    private _intervalId = null;
    private _time: number;

    /**
     * Sets the timer
     *
     * Remember to call stopTimer
     *
     * @param dueTime in milliseconds
     * @param timerTime in milliseconds
     * @param callback
     */
    setTimer(dueTime: number, timerTime: number, callback: () => void) {
        const second = 1000;

        this._timerSubscription = timer(dueTime, timerTime).subscribe(t => {
            this._time = timerTime / second;
            clearInterval(this._intervalId);

            this._intervalId = setInterval(() => {
                this._time--;
            }, second);

            callback();
        });

        this._shouldStop.asObservable().subscribe(shouldStop => {
            if (shouldStop) {
                this._timerSubscription.unsubscribe();
                clearInterval(this._intervalId);
                this._intervalId = null;
            }
        });
    }

    stopTimer() {
        this._shouldStop.next(true);
    }

    get isSet(): boolean {
        return !!this._intervalId;
    }

    get time(): number {
        return this._time;
    }
}
