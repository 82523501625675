import {Component, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";

@Component({
    selector: 'app-alarms-groups',
    templateUrl: './alarms-groups.component.html',
    styleUrls: ['./alarms-groups.component.scss']
})
export class AlarmsGroupsComponent implements OnInit {

    constructor(private matDialog: MatDialog) {

    }

    ngOnInit(): void {
    }

}
