import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LanguageEnum } from '../enums/language.enum';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class CommonService {

    private _stationLangChangeSubject = new Subject<LanguageEnum>();
    private _spaLangChangeSubject = new Subject<LanguageEnum>();

    constructor(
		public dialog: MatDialog
	) {}

    getTranslatedName(name: string, translationObject: object | null): string {
      if(!!translationObject) {
          const language = localStorage?.getItem('language') || 'de';
          const languageObject = translationObject.hasOwnProperty(language) ? translationObject[language] : undefined;
          if (languageObject && languageObject.hasOwnProperty(name) && languageObject[name] !== '') {
              return languageObject[name];
          }
          else if (translationObject.hasOwnProperty('en') && translationObject['en'].hasOwnProperty(name) && translationObject['en'][name] !== ''){
              return translationObject['en'][name];
          }
          else if (translationObject.hasOwnProperty('de') && translationObject['de'].hasOwnProperty(name) && translationObject['de'][name] !== ''){
            return translationObject['de'][name];
          }
          else if (translationObject.hasOwnProperty('zh') && translationObject['zh'].hasOwnProperty(name) && translationObject['zh'][name] !== ''){
            return translationObject['zh'][name];
          } 
      }
      return name;
  }

    // Used for alarms/reason chart 
    getKeysByTranslatedValue(object: Object, value: string) {
        return Object.keys(object).filter(function(key) {
            return object[key] === value;
        });
    }

    // Used for alarms/reason chart 
    getTranslationFromJson(labels, tranlsationObj) {
        var translations: any = [];
        for (var i = 0; i < labels.length; i++) {
            var reason = labels[i];
            translations.push(tranlsationObj[reason]);
        }
        return translations;
    }


    stationLangChange(lang: LanguageEnum) {
        this._stationLangChangeSubject.next(lang);
    }

    spaLangChange(lang: LanguageEnum) {
        this._spaLangChangeSubject.next(lang);
    }

    get stationLangChangeSubject(): Observable<LanguageEnum> {
        return this._stationLangChangeSubject.asObservable();
    }

    get spaLangChangeSubject(): Observable<LanguageEnum> {
        return this._spaLangChangeSubject.asObservable();
    }

/*
  async openAddTranslationModal(name: string, translationJson?: {}) {
        if (name != '') {
            const dialogRef = this.dialog.open(AddTranslationsModalComponent, {
                width: '40%',
                disableClose: true,
                data: {
                    translationJson: translationJson,
                    key: name.trim().replace(' ', '_')
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result.success) {
                    if (!!result.isAddMore) {
                        this.openAddTranslationModal(name);
                    }
                    return {
                        'success': true,
                        'data': result.data
                    }
                } else {
                    return {
                        'success': true,
                        'data': null
                    }
                }
            })
        }
    }
    */

    checkTextLength(text) {
        if(text?.length > 15) {
            return text.substr(0, 14) + '...';
        } else {
            return text;
        }
    }
 
    calculateFontSize(width, height, text): number {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let textData = ctx.measureText(this.checkTextLength(text));
        if (width / (textData.width * 0.10) < height * 0.5) {
            return width / (textData.width * 0.10);
        } else {
            return height * 0.5;
        }
    }

    // give automatically scale font size based on element width, height and text value
    getAutoScaleFontSize(elementWidth: number, elementHeight: number, textValue: string): number {
        let fontSize: number = 12;
        if(elementWidth && elementHeight && textValue) {
            fontSize = this.calculateFontSize(elementWidth * 0.8, elementHeight / 2, this.checkTextLength(textValue));
        }
        return fontSize;
    }

    // Merge translations of language 
    mergeTranslations(target: Object, source: Object): Object {
        // Iterate over all keys in the source object
        for (const key of Object.keys(source)) {
            // If the key exists in both source and target objects and both values are objects, merge them recursively
            if (target.hasOwnProperty(key) && typeof target[key] === 'object' && typeof source[key] === 'object') {
                this.mergeTranslations(target[key], source[key]);
            } else {
                // Otherwise, update the value in the target object
                if(source[key]?.length){
                  target[key] = source[key];
                }
            }
        }
        return target;
    }
}
