import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import {HttpClient} from '@angular/common/http';
import {LoginResponseInterface} from '../../../../common/auth/interfaces/login-response.interface';
import {AuthService} from '../../../app/auth/auth.service';
import { AuthGuard } from '../../../app/_guards/auth.guard';
import { TranslateService } from "@ngx-translate/core";
import { InfoboxService } from '../../../../common/shared/services/infobox.service';

@Component({
  selector: 'app-action-login-modal',
  templateUrl: './action-login-modal.component.html',
  styleUrls: ['./action-login-modal.component.scss']
})
export class ActionLoginModalComponent implements OnInit {

    form: UntypedFormGroup;
    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private authGuard: AuthGuard,
        private translate: TranslateService,
        private toastService: InfoboxService,
        public dialogRef: MatDialogRef<ActionLoginModalComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        this.initForm();
    }

    initForm() {
        this.form = new UntypedFormGroup({
            username: new UntypedFormControl(null, Validators.required),
            password: new UntypedFormControl(null, Validators.required),
        });
    }

    login(code = undefined) {
        if (!code && !this.form.valid) {
            return;
        }

        const payload = code
            ? {...{code: code}, ...this.data.extraLoginData}
            : {...this.form.value, ...this.data.extraLoginData};

        this.http.post<LoginResponseInterface>(code ? this.data.barcodeEndpoint : this.data.endpoint, payload)
            .subscribe((result) => {
                if (!!!result || !!!result.token) {
                    this.toastService.pushInfo({
                        warning: true,
                        text: this.translate.instant('SPA.LOGIN.ERROR_INVALID')
                    });
                    this.close();
                } else {
                    if (result.message) {
                        const res = {
                            warning: true,
                            text: result.message
                        };
                    }
                    const hasPermissions = this.checkPermissions(result.token);

                    if (hasPermissions) {
                        this.close(result);
                    } else {
                        this.toastService.pushInfo({
                            warning: true,
                            text: this.translate.instant('SPA.LOGIN.INSUFFICIENT_ROLE')
                        });
                    }
                }
        })
    }

    checkPermissions(token: string) {
        const user = this.authService.decodeToken(token);

        return this.authGuard.hasPermissionsForSpecificUser(user, this.data.permissions);
    }

    close(data = undefined) {
        this.dialogRef.close(data);
    }

    loginCode(code: string) {
        this.login(code);
    }
}
