<div fxLayout="row" class="content-row repeat-every" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
    <div fxFlex="auto" class="label">
        <mat-label>{{ 'SPA.SHIFTS.REPEAT_EVERY' | translate }}</mat-label>
    </div>

    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" [style.fontSize.px]="12">
        <input #periodCount matInput type="number" [(ngModel)]="period.repeatEvery.count" [min]="1">
    </mat-form-field>
    <div style="display: flex; justify-content: center; align-items: center;" >
        {{ 'ENUMS.REPEAT.EVERY_WEEK' | translate }}
    </div>
    <mat-form-field style="display:none" fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" [style.fontSize.px]="12">
        <mat-select [(ngModel)]="period.repeatEvery.repeatEnum">
            <ng-container *ngFor="let typeKey of repeatEnum | enumToArray">
                <mat-option [value]="repeatEnum[typeKey]" *ngIf="(repeatEnum[typeKey] !== repeatEnum.EVERY_HOUR && repeatEnum[typeKey] !== repeatEnum.EVERY_DAY) || !isShiftType">
                    {{ 'ENUMS.REPEAT.' + typeKey | translate }}
                </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
</div>
<div fxLayout="row" class="content-row time-row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0" *ngIf="!isShiftType">
    <div class="label">
        <mat-label>{{ 'SPA.SHIFTS.START' | translate }}</mat-label>
    </div>
    <ngx-mat-timepicker class="vex-flex-form-field"
            [(ngModel)]="period.start">
    </ngx-mat-timepicker>
</div>
<div fxLayout="row" class="content-row weekdays" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" *ngIf="showWeekDaysOptions()">
    <div class="label">
        <mat-label>{{ 'SPA.SHIFTS.REPEAT_ON' | translate }}</mat-label>
    </div>
    <div class="days">
        <div class="day rounded px-2 py-1 font-medium text-xs" *ngFor="let day of weekdays"
             [ngClass]="{'selected': isDaySelected(day)}" (click)="toggleSpecificDay(day)">
            {{day}}
        </div>
    </div>
</div>
<div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
    <div class="label">
        <mat-label>{{ 'SPA.SHIFTS.ENDS' | translate }}</mat-label>
    </div>
    <div class="ends-select">
        <mat-radio-group fxLayout="column" [(ngModel)]="endsOn" (ngModelChange)="endsOnChanged()">
            <div class="end-element">
                <div class="select">
                    <mat-radio-button value="NEVER">{{ 'SPA.SHIFTS.NEVER' | translate }}</mat-radio-button>
                </div>
            </div>
            <div class="end-element">
                <div class="select">
                    <mat-radio-button value="ON">{{ 'SPA.SHIFTS.ON' | translate }}</mat-radio-button>
                </div>
                <mat-form-field [style.fontSize.px]="12" appearance="outline" class="vex-flex-form-field" color="primary" [ngClass]="{'disabled': endsOn !== EventEndsOnEnum.ON}">
                    <input type="text" matInput [matDatepicker]="picker" [(ngModel)]="period.ends.on" [min]="today" [disabled]="endsOn !== EventEndsOnEnum.ON">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="end-element">
                <div class="select">
                    <mat-radio-button value="AFTER">{{ 'SPA.SHIFTS.AFTER' | translate }}</mat-radio-button>
                </div>
                <mat-form-field [style.fontSize.px]="12" fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" [ngClass]="{'disabled': endsOn !== EventEndsOnEnum.AFTER}">
                    <input matInput type="number" [(ngModel)]="period.ends.after" [disabled]="endsOn !== EventEndsOnEnum.AFTER" [min]="1">
                    <span matSuffix>{{ 'SPA.SHIFTS.OCCURRENCES' | translate }}</span>
                </mat-form-field>
            </div>
        </mat-radio-group>

    </div>
</div>
