import {Component, Inject, OnInit} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {SlidesService} from '../../services/slides.service';
import { FtpSlideTypeEnum } from '../../../../../common/ftp/enums/ftp-slide-type.enum';
import { FtpSlideInterface } from '../../../../../common/ftp/interfaces/ftp-slide.interface';

@Component({
  selector: 'app-andon-ftp-form',
  templateUrl: './andon-ftp-form.component.html',
  styleUrls: ['./andon-ftp-form.component.scss']
})
export class AndonFtpFormComponent implements OnInit {
    form: UntypedFormGroup;
    types = FtpSlideTypeEnum;
    loading: boolean = false;
    constructor(
        private formBuilder: UntypedFormBuilder,
        private dialogRef: MatDialogRef<AndonFtpFormComponent>,
        private slidesService: SlidesService,
        @Inject(MAT_DIALOG_DATA) public data: {stationId: number, mainPath: string, slide? : FtpSlideInterface},
    ) { }

    ngOnInit() {
        this.initForm();
        this.form.get('typeEnum').valueChanges.subscribe(() => {
            this.typeChanged();
        });
        if (this.isEdit()) {
            this.pathForm(this.data.slide);
        }
    }

    isEdit(): boolean {
        return !!this.data.slide;
    }

    get fromControls() {
        return this.form.controls;
    }
    get paths() {
        return this.fromControls.paths as UntypedFormArray;
    }

    initForm() {
        this.form = this.formBuilder.group({
            id: new UntypedFormControl(undefined),
            name: new UntypedFormControl(null, Validators.required),
            typeEnum: new UntypedFormControl(this.types.FULLSCREEN, Validators.required),
            slideTime: new UntypedFormControl(null, Validators.required),
            paths: new UntypedFormArray([])
        });
        this.typeChanged();
    }

    pathForm(slide: FtpSlideInterface) {
        this.form.patchValue({
            id: slide.id,
            name: slide.name,
            typeEnum: slide.typeEnum,
            slideTime: slide.slideTime,
            paths: slide.paths.map((path: string) => { return {path: path}})
        })
    }

    typeChanged() {
        const numberOfPaths = this.mapEnumToNumberOfPaths(this.form.get('typeEnum').value);
        if (this.paths.length < numberOfPaths) {
            for (let i = this.paths.length; i < numberOfPaths; i++) {
                this.paths.push(this.formBuilder.group({
                    path: ['', Validators.required],
                }));
            }
        } else {
            for (let i = this.paths.length; i >= numberOfPaths; i--) {
                this.paths.removeAt(i);
            }
        }
    }

    mapEnumToNumberOfPaths(value: FtpSlideTypeEnum): number {
        switch (value) {
            case FtpSlideTypeEnum.FULLSCREEN:
                return 1;
            case FtpSlideTypeEnum.TWO_SLIDES:
                return 2;
            case FtpSlideTypeEnum.FOUR_SLIDES:
                return 4;
            default:
                return 0;
        }
    }

    submit() {
        if (this.form.valid) {
            this.loading = true;
            const formData = {
                ...this.form.value,
                ...{paths: this.form.value.paths.map(path => path.path)}
            };

            this.slidesService.save(this.data.stationId, formData).subscribe((result) => {
                this.loading = false;
                this.dialogRef.close(result);
            }, (e) => {
                this.loading = false;
            });
        }
    }
    close() {
        this.dialogRef.close();
    }
}
