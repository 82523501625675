import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AlarmsAndEscalationsService} from '../../../../alarms-and-escalations.service';
import * as _ from 'lodash';
import {DisorderService} from '../../../services/disorder.service';
import {DisorderRowModel} from '../../../models/disorder-row.model';
import {ROW_MODE} from '../group-disorder.component';
import { StationInterface } from '../../../../../../../common/station/interfaces/station.interface';
import {InfoboxService} from '../../../../../../../common/shared/services/infobox.service';

@Component({
  selector: 'app-edit-disorder-row',
  templateUrl: './edit-disorder-row.component.html',
  styleUrls: ['./edit-disorder-row.component.scss']
})
export class EditDisorderRowComponent implements OnInit {
    disorderForm: UntypedFormGroup;
    stations: StationInterface[] = [];

    @Input() disorder: DisorderRowModel;
    @Input() isChild = true;
    @Output() disorderSaved: EventEmitter<DisorderRowModel> = new EventEmitter<DisorderRowModel>();
    constructor(
        public aeService: AlarmsAndEscalationsService,
        private disorderService: DisorderService,
        private infoboxService: InfoboxService,
    ) { }

    ngOnInit() {
        this.aeService.fetchLines();
        this.aeService.fetchStations();
        this.initForm();
        if (!this.isChild) {
            this.disorderForm.get('lineId').valueChanges.subscribe(() => {
                this.stations = this.aeService.filterStationsToDropdown([this.disorderForm.get('lineId').value]);
                this.disorderForm.patchValue({
                    stationIds: _.filter(this.disorderForm.get('stationIds').value, (id) => {
                        return _.map(this.stations, 'id').includes(id)
                    })
                });
            });
        }
        this.patchForm(this.disorder);
    }

    initForm() {
        this.disorderForm = new UntypedFormGroup({
            id: new UntypedFormControl(undefined),
            code: new UntypedFormControl('', Validators.required),
            reason: new UntypedFormControl([]),
            lineId: new UntypedFormControl([]),
            stationIds: new UntypedFormControl([]),
            parentId: new UntypedFormControl(null)
        });
    }

    patchForm(disorder: DisorderRowModel) {
        this.disorderForm.patchValue({
            id: disorder.id,
            code: disorder.code,
            reason: disorder.reason,
            lineId: disorder.line ? disorder.line.id : null,
            parentId: disorder.parentId,
            stationIds:  _.map(disorder.stations, 'id'),
        })
    }

    save() {
        if (!this.disorderForm.valid) {
            return;
        }

        this.disorderService.save(this.disorderForm).subscribe((result) => {
            this.infoboxService.success('COMMON.SAVED');
            this.disorderSaved.emit(this.disorderService.mapSingleToModel(result, result.parentId, ROW_MODE.ADD_EDIT_LISTING));
        })
    }

    cancel() {
        this.disorderSaved.emit(null);
    }

    formValid() {
        if (this.isChild) {
            return this.disorderForm.valid;
        }

        return this.disorderForm.valid && !!this.disorderForm.get('lineId').value && this.disorderForm.get('stationIds').value.length > 0;
    }
}
