<div class="w-full h-full bg-pattern" fxLayout="column" fxLayoutAlign="center center">
    <div class="card overflow-hidden w-full max-w-xs">
        <div class="p-6 pb-2 header" fxLayout="column" fxLayoutAlign="center center">
            <div class="fill-current text-center">
                <img class="w-24" src="assets/apronic.png">
            </div>
        </div>
        <div [formGroup]="form" class="p-6" fxLayout="column" fxLayoutGap="16px" (keyup.enter)="login()">
            <div fxFlex="auto" fxLayout="column">
                <mat-form-field fxFlex="grow">
                    <mat-label>{{ 'SPA.LOGIN.USERNAME' | translate }}</mat-label>
                    <input id="username" formControlName="username" matInput required>
                    <mat-error *ngIf="form.get('username').hasError('required')">{{ 'SPA.LOGIN.USERNAME_ERROR' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="grow">
                    <mat-label>{{ 'SPA.LOGIN.PASSWORD' | translate }}</mat-label>
                    <input id="password" [type]="showPassword ? 'text' : 'password'" formControlName="password" matInput required>
                    <mat-icon class="visibility-icon" matSuffix (click)="showPassword = !showPassword">
                        {{ showPassword ? 'visibility_off' : 'visibility'}}
                    </mat-icon>
                    <mat-error *ngIf="form.get('password').hasError('required')">{{ 'SPA.LOGIN.PASSWORD_ERROR' | translate }}</mat-error>
                </mat-form-field>
                <mat-checkbox class="mb-4" [formControl]="stayLoggedIn">
                    {{ 'SPA.LOGIN.STAY_LOGGED_IN' | translate }}
                </mat-checkbox>
            </div>

            <button (click)="login()" color="primary" mat-raised-button type="button">
                {{ 'SPA.LOGIN.SUBMIT_BTN' | translate }}
            </button>

        </div>
    </div>
</div>
