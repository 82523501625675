import { Component, OnInit } from '@angular/core';
import { InfoboxService } from '../../../../common/shared/services/infobox.service';
import { ReportsNetworkService } from '../services/reports.network.service';
import {FileInput} from 'ngx-material-file-input';
import {ModalsService} from '../../../../common/shared/services/modals.service';
import { ReportTemplateInterface } from '../../../../common/reports/interfaces/report-template.interface';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {
  public templates: ReportTemplateInterface[] = [];
  public templateName: string = '';
  templateFile: File;
  file: File;
  loading = false;
  constructor(
    private reportsNetworkService: ReportsNetworkService,
    private infoboxService: InfoboxService,
    private modalsService: ModalsService
  ) { }

  ngOnInit() {
    this.getTemplates();
  }

  fileAdded(data: FileInput) {
      this.templateFile = data.files[0];
  }

  canSubmit(): boolean {
      return this.templateName.length > 0 && !!this.templateFile;
  }

  onSubmit() {
    if (!this.canSubmit()) {
      return ;
    }

    if (this.nameExists(this.templateName)) {
        this.modalsService.confirm(
            'SPA.TEMPLATES.REPLACE_MODAL.CONTENT',
            'SPA.TEMPLATES.REPLACE_MODAL.HEADER',
            'SPA.TEMPLATES.REPLACE_MODAL.REPLACE_BTN'
        ).subscribe((result) => {
            if (result) {
                this.uploadProcess();
            }
        });
    } else {
        this.uploadProcess();
    }
  }

  resetInputs() {
      this.file = null;
      this.templateFile = null;
      this.templateName = '';
  }

  nameExists(name: string): boolean {
      return !!this.templates.find((template) => template.name === name);
  }

  delete(template: ReportTemplateInterface) {
      this.modalsService.confirm(
          'SPA.TEMPLATES.DELETE_MODAL.CONTENT',
          'SPA.TEMPLATES.DELETE_MODAL.HEADER',
          'SPA.TEMPLATES.DELETE_MODAL.DELETE_BTN'
      ).subscribe((result) => {
          if (result) {
              this.reportsNetworkService.deleteTemplate(template.id).subscribe(_ => {
                  this.infoboxService.pushInfo({success: true, text: 'Report template deleted!'});
                  this.getTemplates();
              });
          }
      });
  }

  getTemplates() {
    this.loading = true;
    this.reportsNetworkService.getTemplates().subscribe(data => {
        this.templates = data;
        this.loading = false;
    }, () => {
        this.loading = false;
    })
  }

  downloadTemplate(template: ReportTemplateInterface) {
    this.loading = true;

    this.reportsNetworkService.downloadTemplate(template.id).subscribe((exportFile: Blob) => {
      FileSaver.saveAs(exportFile, `${template.name}${template.extension}`);
      this.loading = false;
    });
  }

  private uploadProcess() {
      this.loading = true;
      const formData: FormData = new FormData();
      formData.append('file', this.templateFile, this.templateFile.name);
      formData.append('templateName', this.templateName);

      this.reportsNetworkService.createTemplate(formData).subscribe(data => {
          this.infoboxService.pushInfo({success: true, text: 'Report template added!'});
          this.getTemplates();
          this.resetInputs();
      }, () => {
          this.loading = false;
      });
  }
}
