import {Component, OnInit, ViewChild} from '@angular/core';
import {AEGroupInterface} from "../../groups/interfaces/a-e-group.interface";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatSort} from "@angular/material/sort";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import { AlarmsAndEscalationsService } from '../../alarms-and-escalations.service';
import { CommonService } from '../../../../../common/shared/services/common.service';
@Component({
  selector: 'app-alarms-list',
  templateUrl: './alarms-list.component.html',
  styleUrls: ['./alarms-list.component.scss']
})
export class AlarmsListComponent implements OnInit {


  groups: AEGroupInterface[] = [];
  displayedColumns: string[] = [
    'name',
    'productionSector',
    'Stations',
    'alarmGroup',
    'actions',
  ];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  showFilters = false;

  constructor(private matDialog: MatDialog , private alarmsAndEscalationsService : AlarmsAndEscalationsService, private commonService: CommonService) {

  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {

    // this.groupsService.fetchGroups().subscribe((result) => {
      this.alarmsAndEscalationsService.getAllConfiguredAlarm().subscribe(res=>{
        this.dataSource = new MatTableDataSource<any>(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
 

    // });
  }

  changeFilters() {

  }

  delete(element) {
    this.alarmsAndEscalationsService.deleteAlarm(element.id).subscribe(res=>{
      if(res){
        console.log("deleted successfully !")
        this.loadList();
      }
    });

  }

  getTranslatedAlarm(alarm): string {
    return this.commonService.getTranslatedName('name', alarm?.translationJson || null);
  }
}
