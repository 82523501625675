<vex-secondary-toolbar>
    <vex-breadcrumbs [crumbs]="crumbs" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>
<div class="page-content">
    <!-- User Management Section -->
    <div class="row ap-card shadow flex-col" style="margin-bottom: 14px">
        <page-header titleTranslate="SPA.GENERAL_SETTINGS.USER_MANAGEMENT.HEADER"></page-header>
        <div class="row">
            <div class="col s12">
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field">
                        <mat-label>{{'SPA.GENERAL_SETTINGS.USER_MANAGEMENT.USER_LOGOUT_AFTER' | translate}}</mat-label>
                        <input type="number" matInput [formControl]="userLogoutAfter"
                            (keypress)="restrictKeyword($event)">
                        <mat-error>
                            {{'SPA.GENERAL_SETTINGS.USER_MANAGEMENT.ERROR_USER_LOGOUT_AFTER' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <span class="minutes-label">{{ 'SPA.GENERAL_SETTINGS.USER_MANAGEMENT.MINUTES' | translate }}</span>
                </div>
            </div>
        </div>
        <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
            <button color="warn" mat-button type="button" (click)="goBack()"> {{ 'COMMON.BACK' | translate }}</button>
            <button color="primary" mat-raised-button (click)="save()" [disabled]="isInvalid()">
                {{ 'COMMON.SAVE' | translate }}
            </button>
        </div>
    </div>

    <div class="row ap-card shadow flex-col" *ngIf="authService.isSuperAdmin()">
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" style="margin-bottom: 14px">
            <mat-slide-toggle [(ngModel)]="showBanner">
                {{ 'SPA.GENERAL_SETTINGS.USER_MANAGEMENT.SHOW_USER_BANNER' | translate }}
            </mat-slide-toggle>
        </div>
        <div >
            <div class="row" *ngIf="showBanner">
                <div class="col s12">
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field">
                            <mat-label>{{'SPA.GENERAL_SETTINGS.USER_MANAGEMENT.USER_BANNER_TEXT' | translate}}</mat-label>
                            <input type="text" matInput [formControl]="userBannerText">
                            <mat-error>
                                {{'SPA.GENERAL_SETTINGS.USER_MANAGEMENT.ERROR_USER_BANNER_TEXT' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
                    <button color="warn" mat-button type="button" (click)="goBack()"> {{ 'COMMON.BACK' | translate }}</button>
                    <button color="primary" mat-raised-button (click)="saveBannerText()" [disabled]="isInvalidBannerText()">
                        {{ 'COMMON.SAVE' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>