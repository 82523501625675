<div class="reason-item">

    <div class="item-title mat-elevation-z2" [class.has-children]="reason.children && reason.children.length"
         cdkDropList
         [id]="reason.name"
         (cdkDropListDropped)="drop($event)">


        <button mat-icon-button cdkDragHandle cdkDrag [cdkDragData]="reason">
            <mat-icon class="mat-18">drag_handle</mat-icon>
            <div class="example-custom-placeholder" *cdkDragPlaceholder>
                test
            </div>
        </button>
        <button mat-icon-button *ngIf="reason.children && reason.children.length > 0 ; else arrowPlaceholder"
                (click)="openChildrenPanel = !openChildrenPanel ">
            <mat-icon class="mat-18">keyboard_arrow_down</mat-icon>
        </button>
        <ng-template #arrowPlaceholder><span class="arrow-Placeholder"></span></ng-template>
        <span>{{ getTranslatedName('name', oldReason?.translationJson) }}</span>
        <span style="flex-grow: 1"></span>
        <!--        <span class="alarms-names">{{getAlarms}}</span>-->
        <span class="alarms-names" *ngIf="reason.parent.id == 0">
            <span *ngFor="let alarm of reason.configuredAlarms">
                {{ getTranslatedAlarm(alarm) }}
            </span>
        </span>
        <div>
            <button mat-icon-button (click)="addReason()">
                <mat-icon class="mat-18">add</mat-icon>
            </button>
            <button mat-icon-button (click)="editReason()">
                <mat-icon class="mat-18">edit</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="deleteReason()">
                <mat-icon class="mat-18">delete</mat-icon>
            </button>
        </div>
    </div>
    <div class="reason-item-children" *ngIf="openChildrenPanel">
        <app-alarm-reasons-item  (showLoading) = "showLoading"   (onDelete) = "childDeletd(item.id)" *ngFor="let item of reason.children" [reason]="item"
                                [parent]="reason"></app-alarm-reasons-item>
    </div>
</div>

