import { Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, finalize } from 'rxjs/operators';
import { Subscription, throwError } from 'rxjs';
import { RolesService } from '../roles.service';
import { InfoboxService } from '../../../../../common/shared/services/infobox.service';
import { ActivatedRoute } from '@angular/router';
import { RoleInterface } from '../../../../../common/auth/interfaces/role.interface';
import { PermissionGroupInterface } from '../../../../../common/auth/interfaces/permission-group.interface';
import { UserPermissionsUtil } from '../../../../../common/auth/utils/user-permissions.util';
import { Location } from '@angular/common';
import { RolePermissionService } from '../role-permission.service';

@Component({
    styleUrls: ['roleDetails.component.scss'],
    templateUrl: 'roleDetails.component.html'
})
export class RoleDetailsComponent implements OnInit, OnDestroy {
    groupedPermissions: PermissionGroupInterface[] = [];
    role: RoleInterface;
    inProgress = false;
    showSelectAll: boolean = true;

    private subscription = new Subscription();

    constructor(
        private _location: Location,
        private rolesService: RolesService,
        private route: ActivatedRoute,
        private infoboxService: InfoboxService,
        public rolePermissionService: RolePermissionService
    ) {
    }

    public ngOnInit(): void {
        this.subscription.add(
            this.route.params.subscribe(params => {
                this.fetchData(+params.id);
            })
        );

        this.groupedPermissions = UserPermissionsUtil.getGroupedPermissions();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public save() {
        this.inProgress = true;

        this.rolesService.patchPermissions(this.role)
            .pipe(
                catchError((e) => {
                    this.infoboxService.error('COMMON.GENERAL_ERROR_MESSAGE');
                    return throwError(e);
                }),
                finalize(() => this.inProgress = false)
            )
            .subscribe(() => {
                this.infoboxService.success('COMMON.SAVED');
                this._location.back();
            });
    }

    private fetchData(roleId: number) {
        this.rolesService.fetchRoleDetails(roleId).subscribe(role => {
            this.role = role;
            this.showSelectAll = !this.isAllPermissionOn(role);;
        });
    }

    // get permission array which is visible and not disable
    getPermissionsArray(permissionGroup: PermissionGroupInterface[]) {
        let permissionArr = [];
        permissionGroup.forEach((el) => {
            let value = el.value;
            // Exclude permission, if user role is admin and permission required for system roles 
            // Also exclude which are not visible on edit permission page
            if((this.role.isAdminRole && this.rolePermissionService.requiredForSystemRoles.includes(value)) 
                || this.rolePermissionService.isDisabledByConfiguration(el.value)) null;
            else permissionArr.push(el.value);

            if(el?.children) {
                permissionArr.push(...this.getPermissionsArray(el?.children));
            }
        });
        return permissionArr;
    }

    // toggle all permission select/deselect value
    toggleAllPermission() {
        let grpPermissions = this.getPermissionsArray(this.groupedPermissions);
        grpPermissions.forEach((key) => {
            this.role.permissions[key] = this.showSelectAll ? true : false;
        });
        this.showSelectAll = !this.showSelectAll
    }

    // check all permission is 'on' or not
    isAllPermissionOn(role: RoleInterface): boolean {
        let grpPermissions = this.getPermissionsArray(this.groupedPermissions);
        let allPermissionTrue: boolean = true;

        grpPermissions.forEach((key) => {
            if(!role.permissions[key]) {
                allPermissionTrue = false;
            }
        });
        return allPermissionTrue;
    }
    
    // Set the label on all permission toggle button
    onPermissionChange(role: RoleInterface): void {
        this.showSelectAll = !this.isAllPermissionOn(role);
    }
}