<mat-card>

    <page-header titleTranslate="SPA.ALARMS_ESCALATIONS.ALARMS.HEADER"></page-header>

    <div class="alarm-list">
        <div>
            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef
                        mat-sort-header> {{ 'SPA.ALARMS_ESCALATIONS.ALARMS.NAME' | translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ getTranslatedAlarm(element) }}</td>
                </ng-container>

                <ng-container matColumnDef="productionSector">
                    <th mat-header-cell *matHeaderCellDef
                        mat-sort-header> {{ 'SPA.ALARMS_ESCALATIONS.ALARMS.PRODUCTION_SECTOR' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.displayLines}} </td>
                </ng-container>

                <ng-container matColumnDef="Stations">
                    <th mat-header-cell *matHeaderCellDef
                        mat-sort-header> {{ 'SPA.ALARMS_ESCALATIONS.ALARMS.STATIONS' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.displayStations}} </td>
                </ng-container>

                <ng-container matColumnDef="alarmGroup">
                    <th mat-header-cell *matHeaderCellDef
                        mat-sort-header> {{ 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_GROUP' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.displayGroups}} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0"
                            fxLayoutAlign="end">
                            <a color="primary" mat-raised-button type="button"
                            [routerLink]="['./form', element.id]">
                                <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                        inline="true">edit
                                </mat-icon>
                            </a>

                            <button color="warn" mat-raised-button type="button" (click)="delete(element)">
                                <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                        inline="true">delete
                                </mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
        </div>
    </div>

</mat-card>

<button mat-fab class="ap-fab" [routerLink]="['./add']">
    <mat-icon>add</mat-icon>
</button>

