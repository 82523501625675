import { IoDataTypeEnum } from '../enums/io-data-type.enum';
import { IoTypeEnum } from '../enums/io-type.enum';

type IO_TYPES = {
  dataTypeEnum: IoDataTypeEnum;
  typeEnum: IoTypeEnum;
}

export class IoTypesUtil {
  static isOutput(io: IO_TYPES) {
    return io.typeEnum === IoTypeEnum.OUTPUT;
  }

  static isInput(io: IO_TYPES) {
    return io.typeEnum === IoTypeEnum.INPUT;
  }

  static isDigital(io: IO_TYPES) {
    return [IoDataTypeEnum.DIGITAL_COUNTER, IoDataTypeEnum.DIGITAL_BOOLEAN].includes(io.dataTypeEnum);
  }

  static isDigitalCounter(io: IO_TYPES) {
    return [IoDataTypeEnum.DIGITAL_COUNTER].includes(io.dataTypeEnum);
  }

  static isDigitalBoolean(io: IO_TYPES) {
    return [IoDataTypeEnum.DIGITAL_BOOLEAN].includes(io.dataTypeEnum);
  }

  static isAnalog(io: IO_TYPES) {
    return [IoDataTypeEnum.ANALOG].includes(io.dataTypeEnum);
  }

  static isString(io: IO_TYPES) {
    return [IoDataTypeEnum.STRING].includes(io.dataTypeEnum);
  }

  static isMQTTTopic(io: any) {
    return io.hasOwnProperty('mqtt_topic');
  }
}