<vex-secondary-toolbar>
    <vex-breadcrumbs [crumbs]="crumbs" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>
<div class="page-content">
  <div class="row ap-card shadow">
      <page-header titleTranslate="SPA.FTP_CONFIGURATION.LABEL"></page-header>
        <form #ftpConfigForm="ngForm">
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                    <mat-label>{{ 'SPA.FTP_CONFIGURATION.HOST' | translate }}</mat-label>
                    <input matInput required [(ngModel)]="configuration.host" [ngModelOptions]="{standalone: true}">
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                    <mat-label>{{ 'SPA.FTP_CONFIGURATION.USER' | translate }}</mat-label>
                    <input matInput required [(ngModel)]="configuration.user" [ngModelOptions]="{standalone: true}">
                </mat-form-field>
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                    <mat-label>{{ 'SPA.FTP_CONFIGURATION.PASSWORD' | translate }}</mat-label>
                    <input matInput type="password" required [(ngModel)]="configuration.password" [ngModelOptions]="{standalone: true}">
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                    <mat-label>{{ 'SPA.FTP_CONFIGURATION.MAIN_FOLDER' | translate }}</mat-label>
                    <input matInput [(ngModel)]="configuration.mainFolder" [ngModelOptions]="{standalone: true}">
                </mat-form-field>
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                    <mat-label>{{ 'SPA.FTP_CONFIGURATION.UPDATE_TIME' | translate }}</mat-label>
                    <input matInput type="number" required [(ngModel)]="configuration.updateTime" [ngModelOptions]="{standalone: true}">
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <mat-slide-toggle [(ngModel)]="configuration.isSecure" [ngModelOptions]="{standalone: true}">
                    {{ 'SPA.FTP_CONFIGURATION.IS_SECURE' | translate }}
                </mat-slide-toggle>
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" *ngIf="configuration.isSecure">
                    <mat-label>{{ 'SPA.FTP_CONFIGURATION.CERTIFICATE' | translate }}</mat-label>
                    <textarea matInput required [(ngModel)]="configuration.certificate" [ngModelOptions]="{standalone: true}"></textarea>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="mt-10">
                <div fxFlex="auto" fxLayoutGap="16px">
                    <button color="primary" mat-raised-button [disabled]="configuration.slideshowsStopped" (click)="stopAllSlideshows()">
                        {{ 'SPA.FTP_CONFIGURATION.STOP_ALL_SLIDESHOWS' | translate }}
                    </button>
                    <button color="primary" mat-raised-button [disabled]="!configuration.slideshowsStopped" (click)="restartAllSlideshows()">
                        {{ 'SPA.FTP_CONFIGURATION.RESTART_ALL_SLIDESHOWS' | translate }}
                    </button>
                    <button color="primary" mat-raised-button [disabled]="!ftpConfigForm.valid" (click)="test()" type="button">
                        {{ 'SPA.FTP_CONFIGURATION.TEST' | translate }}
                    </button>
                </div>
                <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
                    <button color="warn" mat-button type="button" (click)="goBack()"> {{ 'COMMON.BACK' | translate }}</button>
                    <button color="primary" mat-raised-button (click)="save(ftpConfigForm)" [disabled]="saveButtonDisabled"> {{ 'COMMON.SAVE' | translate }}</button>
                </div>
            </div>
        </form>
  </div>
</div>
