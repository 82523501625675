import { NgModule } from '@angular/core';
import { BackupComponent } from './backup.component';
import { SharedModule } from '../../shared/shared.module';
import { BackupService } from './services/backup.service';

@NgModule({
  declarations: [BackupComponent],
  imports: [
    SharedModule
  ],
  providers: [
    BackupService
  ]
})
export class BackupModule { }
