import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CommonService } from '../../../../../../common/shared/services/common.service';
import { AlarmsAndEscalationsService } from '../../../alarms-and-escalations.service';

@Component({
    selector: 'app-forward-alarm',
    templateUrl: './forward-alarm.component.html',
    styleUrls: ['./forward-alarm.component.scss']
})
export class ForwardAlarmComponent implements OnInit {
    cAlarm = null;
    allCAlarm = [];

    constructor(private alarmsAndEscalationsService: AlarmsAndEscalationsService, private commonService: CommonService,
        public dialogRef: MatDialogRef<ForwardAlarmComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        ) {
    }

    ngOnInit(): void {
        this.alarmsAndEscalationsService.getAllConfiguredAlarm().subscribe(res => {
            this.allCAlarm = res;
        });
    }


    confirm(){
        this.alarmsAndEscalationsService.forwardAlarm(this.cAlarm.id,this.data.id,null).subscribe(
            res=>{
                this.dialogRef.close(true);
            }
        )
    }

    getTranslatedAlarm(name, translationObj): string {
        return this.commonService.getTranslatedName(name, translationObj);
    }

}
