import {ResizedEvent} from 'angular-resize-event';
import { Input, Directive } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Directive()
export class Resizing{
    fontSize = 5;
    prevFontSize;
    calculationDone = true;
    containerHeight;
    containerWidth;
    textHeight;
    textWidth;
    increasingScale = 4;
    baseFontSize = 5;
    padding = 5;
    fontChanged = new Subject<number>();
    fontChangedSubscription: Subscription;
    @Input() editMode = false;

    onResized(event: ResizedEvent, isTextContainer: boolean, withoutWidth = false, partOfContainerHeight = 1, partOfContainerWidth = 1) {
        if (!this.editMode) {
            return;
        }
        if (!isTextContainer) {
            this.containerHeight = event.newRect.height;
            this.containerWidth = event.newRect.width;
        } else {
            this.textHeight = event.newRect.height;
            this.textWidth = event.newRect.width;
        }

        if (this.calculationDone) {
            return;
        }

        if (this.containerHeight * partOfContainerHeight < (this.textHeight + 2 * this.padding) || (!withoutWidth && this.containerWidth * partOfContainerWidth < (this.textWidth + 2 * this.padding))) {
            if (this.prevFontSize === this.fontSize - this.increasingScale) {
                this.calculationDone = true;
                this.fontSize -= this.increasingScale;
                this.fontChanged.next(this.fontSize);
                return;
            }

            this.prevFontSize = this.fontSize;
            this.fontSize -= this.increasingScale;
            return;
        }

        if (this.containerHeight * partOfContainerHeight >= (this.textHeight + 2 * this.padding) && (withoutWidth || this.containerWidth * partOfContainerWidth >= (this.textWidth + 2 * this.padding))) {
            this.prevFontSize = this.fontSize;
            this.fontSize += this.increasingScale;
            return;
        }

        if (this.containerHeight * partOfContainerHeight === this.textHeight + 2 * this.padding) {
            this.calculationDone = true;
            this.fontChanged.next(this.fontSize);
        }
    }
}
