<div class="row" *ngIf="disorderOverview.length > 0">
  <div class="col s11 center-align">
      <h4><strong>{{ headers.header | translate }}</strong></h4>
  </div>
  <div class="col s1">
    <button class="btn-floating btn-large timesweep" (click)="exportDisorderData()">
      <i class="material-icons">cloud_download</i>
    </button>
  </div>
</div>
<div class="table" *ngIf="disorderOverview.length > 0">
    <div class="table-row table-header">
        <div class="column-first">{{ headers.occurred | translate }}</div>
        <div class="column">{{ headers.duration | translate }}</div>
        <div class="column">{{ headers.beatTime | translate }}</div>
        <div class="column">{{ headers.reason | translate }}</div>
        <div class="column">{{ headers.partNumber | translate }}</div>
        <div class="column">{{ headers.text | translate }}</div>
        <div class="column">{{ headers.stationName | translate }}</div>
    </div>
    <div class="table-row body-row" *ngFor="let disorderLogData of disorderOverview">
        <div class="column column-first">{{disorderLogData.occurred}}</div>
        <div class="column">{{disorderLogData.duration}}</div>
        <div class="column">{{disorderLogData.beatTime}}</div>
        <div class="column">{{ getTranslatedName('name', disorderLogData?.reasonTranslationData) }}</div>
        <div class="column">{{disorderLogData.partNumber}}</div>
        <div class="column">{{disorderLogData.text}}</div>
        <div class="column">{{disorderLogData.stationName}}</div>
    </div>
</div>