<div class="table" #table>
    <div class="table-row body-row" *ngFor="let role of roles" >
        <div class="column column-first">{{role.role | titlecase}}</div>
        <div class="column actions">
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <button color="primary" mat-raised-button type="button"
                        [routerLink]="role.id">
                    <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                              inline="true">edit</mat-icon>
                </button>
                <button color="warn" mat-raised-button type="button" [disabled]="role.isAdminRole" (click)="removeRoleById(role)">
                    <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                              inline="true">delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
</div>

<button mat-fab class="ap-fab" (click)="createRole()">
    <mat-icon>add</mat-icon>
</button>