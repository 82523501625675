import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageEnum } from '../../../../common/shared/enums/language.enum';

@Injectable()
export class TranslateNetworkService {
  constructor(
    private http: HttpClient,
  ) {
  }

  changeLanguage(lang: LanguageEnum) {
    return this.http.post('/user-language/change', {
      language: lang
    });
  }
}