import {Component, Input} from '@angular/core';
import {ColorsFormatUtil} from '../../../shared/utils/colors-format.util';
import {DividerSettingsInterface} from '../../interfaces/divider-settings.interface';

@Component({
  selector: 'configurator-bar-divider-widget',
  templateUrl: './configurator-bar-divider-widget.component.html',
  styleUrls: ['./configurator-bar-divider-widget.component.scss']
})
export class ConfiguratorBarDividerWidgetComponent {

    @Input() settings: DividerSettingsInterface;

    get background(): string {
        const color = this.settings && this.settings.backgroundColor ? this.settings.backgroundColor : '#13787D';

        return `linear-gradient(90deg, ${color} 0%, ${ColorsFormatUtil.hexToRgba(color, 0.7)} 100%)`;
    }
}
