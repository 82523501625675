import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SlidesService} from '../../services/slides.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {AndonFtpFormComponent} from '../andon-ftp-form/andon-ftp-form.component';
import * as _ from 'lodash';
import { FtpSlideInterface } from '../../../../../common/ftp/interfaces/ftp-slide.interface';
import { ModalsService } from '../../../../../common/shared/services/modals.service';
import { InfoboxService } from '../../../../../common/shared/services/infobox.service';
import { FtpSlideTypeEnum } from '../../../../../common/ftp/enums/ftp-slide-type.enum';

@Component({
  selector: 'app-andon-ftp-listing',
  templateUrl: './andon-ftp-listing.component.html',
  styleUrls: ['./andon-ftp-listing.component.scss']
})
export class AndonFtpListingComponent implements OnInit {
    slides: FtpSlideInterface[] = [];

    @Input() stationId: number;

    private mainPath: string;

    constructor(
        private slidesService: SlidesService,
        private dialog: MatDialog,
        private modalsService: ModalsService,
        private infoboxService: InfoboxService,
    ) { }

    ngOnInit() {
       this.loadList();
    }

    loadList() {
        this.slidesService.getSlideGroups(this.stationId).subscribe((result) => {
            this.slides = result.slides;
            this.mainPath = result.mainFolder;
        });
    }

    delete(slide: FtpSlideInterface) {
        this.modalsService.confirm('SPA.DEVICE.FTP_SETTING.DELETE_DESCRIPTION').subscribe((result) => {
            if (result) {
                this.slidesService.delete(this.stationId, slide.id).subscribe(() => {
                    this.loadList();
                });
            }
        });
    }

    isAndon(slide: FtpSlideInterface): boolean {
        return slide.typeEnum === FtpSlideTypeEnum.ANDON;
    }

    add() {
        const dialogRef = this.dialog.open(AndonFtpFormComponent, {
            width: '700px',
            data: {
                stationId: this.stationId,
                mainPath: this.mainPath
            }
        });

        dialogRef.afterClosed().subscribe((result: FtpSlideInterface) => {
            if (result) {
                this.slides.push(result);
                this.infoboxService.success('COMMON.SAVED');
            }
        })
    }

    edit(slide: FtpSlideInterface) {
        const dialogRef = this.dialog.open(AndonFtpFormComponent, {
            width: '700px',
            data: {
                stationId: this.stationId,
                mainPath: this.mainPath,
                slide: slide
            }
        });

        dialogRef.afterClosed().subscribe((result: FtpSlideInterface) => {
            if (result) {
                this.infoboxService.success('COMMON.SAVED');
                _.forEach(this.slides, (slide: FtpSlideInterface) => {
                    if (slide.id === result.id) {
                        slide.name = result.name;
                        slide.typeEnum = result.typeEnum;
                        slide.slideTime = result.slideTime;
                        slide.paths = result.paths;
                    }
                });
            }
        })
    }
}
