import { Routes } from '@angular/router';
import { AuthGuard } from '../_guards/auth.guard';
import {LiveDebuggingComponent} from './live-debugging.component';
import { UserPermissionsEnum } from '../../../common/auth/enums/user-permissions.enum';
import { ConfigurationEnum } from '../../../common/configuration/enums/configuration.enum';

export const LIVE_DEBUGGING_ROUTING: Routes = [
    {
        path: '',
        component: LiveDebuggingComponent,
        canActivate: [AuthGuard],
        data: {
            configuration: ConfigurationEnum.REMOTE_STATION_VIEW,
            permissions: [UserPermissionsEnum.RemoteStationView]
        },
    }
];
