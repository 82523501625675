<ng-container *ngFor="let group of groups">
    <div class="group" *ngIf="!rolePermissionService.isDisabledByConfiguration(group.value)">
        <mat-slide-toggle
          class="checkbox"
          [(ngModel)]="role.permissions[group.value]"
          (ngModelChange)="valueChanged($event, group.children)"
          [disabled]="disabled || (role.isAdminRole && rolePermissionService.requiredForSystemRoles.includes(group.value))"
        >
            {{ 'ENUMS.USER_PERMISSIONS.' + UserPermissionEnum[group.value] | translate }}
        </mat-slide-toggle>
        <app-permission-group
          *ngIf="group.children && group.children.length"
          [groups]="group.children"
          [role]="role"
          [disabled]="!role.permissions[group.value]"
          (notifyPermissionChange)="onSubPermissionChange()"
        ></app-permission-group>
    </div>
</ng-container>