import { AfterViewInit, Component, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import {AuthService} from './auth/auth.service';
import {TranslateService} from './translate/services/translate.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import {Platform} from '@angular/cdk/platform';
import {DOCUMENT} from '@angular/common';
import {NavigationService} from '../@vex/services/navigation.service';
import { Settings } from 'luxon';
import {SplashScreenService} from '../@vex/services/splash-screen.service';
import {SIDENAV_ITEMS} from './sidenav.config';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import { environment } from '../environments/environment';
import { SocketService } from './_services/socket.service';
import { BackupModeService } from '../../common/backups/services/backup-mode.service';
import { StorageService } from '../../common/shared/services/storage.service';
import { GeneralSettingsService } from './system-settings/general-settings/serivces/general-settings.service';
import { USER_LOGOUT_AFTER_INTERVAL_KEY } from '../../common/general-settings/constants/general-settings-key.constant';
import { LanguageEnum } from '../../common/shared/enums/language.enum';

const INACTIVITY_LOGOUT_TIME = 300; //seconds

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    jwtHelper = new JwtHelperService();
    lastPing?: Date = null;
    inactivityLogoutTime: number = INACTIVITY_LOGOUT_TIME;

    constructor(
        private authService: AuthService,
        private translate: TranslateService,
        private storage: StorageService,
        private renderer: Renderer2,
        private platform: Platform,
        @Inject(DOCUMENT) private document: Document,
        @Inject(LOCALE_ID) private localeId: string,
        private navigationService: NavigationService,
        private splashScreenService: SplashScreenService,//don't remove it. Construction needs to be initialized,
        private idle: Idle,
        private socketService: SocketService,
        private backupModeService: BackupModeService,
        private generalSettingsService: GeneralSettingsService
    ) {
        Settings.defaultLocale = this.localeId;
        if (this.platform.BLINK) {
            this.renderer.addClass(this.document.body, 'is-blink');
        }

        this.navigationService.items = SIDENAV_ITEMS;
        this.backupModeService.init(this.socketService);
    }

    initIdleTimer() {
        if (!environment.production) {
            return;
        }
        this.idle.setIdle(this.inactivityLogoutTime);
        this.idle.setTimeout(1);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idle.onIdleEnd.subscribe(() => {
            this.reset();
        });

        this.idle.onTimeout.subscribe(() => {
            this.logout();
        });

        this.reset();
    }

    reset() {
        this.idle.watch();
    }

    ngOnInit() {
        this.initialize();
        this.initAutomaticLogout();
    }

    ngOnDestroy() {
        this.authService.cleanSockets();
    }

    @HostListener('window:storage', ['$event'])
    private listenForTokenStorage(e: StorageEvent) {
        if (e.key !== 'token') {
            return;
        }

        if (e.newValue === null) {
            this.authService.logout(true);
        } else {
            location.reload();
        }
    }

    private initialize() {
        this.translate.init();
        const token = this.storage.getItem('token');
        if (token) {
            this.authService.decodedToken = this.jwtHelper.decodeToken(token);
        }
        this.authService.initializeSocket();
    }

    private logout() {
        if (!this.authService.loggedIn()) {
            return;
        }

        this.authService.logout(true);
    }
    
    initAutomaticLogout() {
        // Get 'stayLoggedIn' value 
        let stayLoggedIn: boolean = this.storage.getItem('stayLoggedIn') ? JSON.parse(this.storage.getItem('stayLoggedIn')) : false;
        if(stayLoggedIn) {
            return;
        } else {
            // get user logout after time value
            this.generalSettingsService.getGeneralSettingValueByKey(USER_LOGOUT_AFTER_INTERVAL_KEY).subscribe((data) => {
                if(data) {
                    this.inactivityLogoutTime = +data.settingValue * 60;
                }
                this.initIdleTimer();
            }, () => {
                this.initIdleTimer();
            });
        }
    }

    ngAfterViewInit() {
        let lang = this.translate.userLang;
        this.setBodyFontFamily(lang);
    }

    setBodyFontFamily(lang: LanguageEnum): void {
        let fontFamily: string = 'Lato, sans-serif';
        switch (lang) {
        case LanguageEnum.CHINESE:
            fontFamily = 'NatoSansSC, sans-serif';
            break; // Default font for Chinese
        default:
            fontFamily = 'Lato, sans-serif'
            break; // Fallback font
        }
        if(lang && document?.body) {
            this.renderer.setStyle(document.body, 'font-family', fontFamily);
        }
    }

}
