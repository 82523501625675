<div (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()"
     [class.collapsed]="collapsed"
     [class.open]="collapsed && collapsedOpen$ | async"
     class="sidenav flex flex-col">
  <div class="sidenav-toolbar flex-none flex items-center">
    <img [src]="imageUrl$ | async" class="w-16 select-none flex-none">
    <button (click)="toggleCollapse()"
            *ngIf="showCollapsePin$ | async"
            class="w-8 h-8 -mr-2 leading-none flex-none hidden lg:block"
            mat-icon-button
            type="button">
      <mat-icon *ngIf="!collapsed" [icIcon]="icRadioButtonChecked" size="14px"></mat-icon>
      <mat-icon *ngIf="collapsed" [icIcon]="icRadioButtonUnchecked" size="14px"></mat-icon>
    </button>
  </div>

  <vex-scrollbar class="flex-auto">
    <div class="sidenav-items">
        <ng-container *ngFor="let item of items; trackBy: trackByRoute">
            <ng-container *ngIf="isMenuItemVisible(item.configurationEnum)">
                <vex-sidenav-item *apUserAccess="item.permissions"
                                  [item]="item"
                                  [level]="0"></vex-sidenav-item>
            </ng-container>
        </ng-container>
    </div>
  </vex-scrollbar>
</div>
