<page-header titleTranslate="SPA.USERS.ADD.HEADER"></page-header>
<form [formGroup]="form">
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.FIRST_NAME' | translate }}</mat-label>
            <input matInput required formControlName="firstname">
            <mat-error>{{ 'SPA.USERS.EDIT.FIRST_NAME_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.LAST_NAME' | translate }}</mat-label>
            <input matInput type="text" required formControlName="name">
            <mat-error>{{ 'SPA.USERS.EDIT.LAST_NAME_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.EMAIL' | translate }}</mat-label>
            <input type="email" matInput formControlName="email">
            <mat-error>{{ 'SPA.USERS.EDIT.EMAIL_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.COIN' | translate }}</mat-label>
            <input type="coin" matInput formControlName="coin">
            <mat-error>{{ 'SPA.USERS.EDIT.COIN_ERROR' | translate }}</mat-error>
        </mat-form-field>

    </div>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.ADD.ROLE' | translate }}</mat-label>
            <mat-select formControlName="role">
                <ng-container *ngFor="let role of roles">
                    <mat-option [value]="role.id">{{role.role | titlecase}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.USERNAME' | translate }}</mat-label>
            <input matInput required formControlName="username">
            <mat-error>{{ 'SPA.USERS.EDIT.USERNAME_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.PASSWORD' | translate }}</mat-label>
            <input matInput [type]="showPassword ? 'text' : 'password'" required formControlName="password">
            <mat-icon class="visibility-icon" matSuffix (click)="showPassword = !showPassword">
                {{ showPassword ? 'visibility_off' : 'visibility' }}
            </mat-icon>
        </mat-form-field>
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.ADD.CONFIRM_PASSWORD' | translate }}</mat-label>
            <input matInput [type]="showConfirmPassword ? 'text' : 'password'" required formControlName="confirmPassword">
            <mat-icon class="visibility-icon" matSuffix (click)="showConfirmPassword = !showConfirmPassword">
                {{ showConfirmPassword ? 'visibility_off' : 'visibility' }}
            </mat-icon>
            <mat-error *ngIf="confirmPassword?.errors?.valueMismatch">{{ 'SPA.USERS.ADD.ERROR_PASSWORD_MATCH' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.LANGUAGE' | translate }}</mat-label>
            <mat-select formControlName="language" required>
                <mat-option *ngFor="let lang of languages | stringEnumToArray" [value]="languages[lang]">
                    {{ 'SPA.LANGUAGE_TYPE.'+lang | translate}}
                  </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.USERS.EDIT.WATCH_ID' | translate }}</mat-label>
            <mat-select formControlName="watch">
                <mat-option *ngFor="let watch of smartwatches" [value]="watch">
                    {{ watch.watchId }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</form>
<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="mt-10">
    <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
        <button color="warn" mat-button type="button" (click)="goBack()"> {{ 'SPA.USERS.EDIT.BACK' | translate }}</button>
        <button color="primary" mat-raised-button (click)="save()" [disabled]="form.pristine || !form.valid">
            {{ 'COMMON.SAVE' | translate }}
        </button>
    </div>
</div>