<div class="image-camera">
  <video #videoStream class="videoStream" autoplay playsinline muted></video>
  <canvas *ngIf="displayStream" #imageCanvas class="image-canvas" [width]="deviceCameraResolutionWidth"
    [height]="deviceCameraResolutionHeight"></canvas>
  <div
    *ngIf="displayStream && data.cameraType === 'Image'"
    class="take-screenshot"
    (click)="captureScreenshot()"
  >
    <i class="material-icons right">photo_camera</i>
  </div>
  <div
    *ngIf="displayStream && data.cameraType === 'Video' && !isRecordingStart"
    (click)="startRecording()"
    class="play-video"
  >
    <i class="material-icons right">play_circle</i>
  </div>
  <div
    *ngIf="displayStream && data.cameraType === 'Video' && isRecordingStart"
    (click)="stopRecording()"
    class="stop-video"
  >
    <i class="material-icons right">stop_circle</i>
  </div>
  <div *ngIf="displayStream && data.cameraType === 'Video' && isRecordingStart" class="recording-duration">{{this.recordingDuration}}</div>
  <div class="recording-message" *ngIf="displayStream && data.cameraType === 'Video' && isRecordingStart && displayMessage">Recording will be stopped in {{ (60 - this.duration)}} seconds.</div>
  <!-- <video class="video" style="width:360 !important;" controls #recordedVideo></video> -->
  <app-spinner [isVisible]="isLoading"></app-spinner>
</div>
