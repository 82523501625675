import {Routes} from '@angular/router';
import {AuthGuard} from '../_guards/auth.guard';
import {ShiftComponent} from './shift.component';
import { UserPermissionsEnum } from '../../../common/auth/enums/user-permissions.enum';

export const SHIFT_PAUSE_ROUTES: Routes = [
    {
        path: '',
        component: ShiftComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: [UserPermissionsEnum.ShiftManagement],
            breadcrumb: {
                label: 'SPA.NAV.SHIFTS',
            }
        },
    }
];
