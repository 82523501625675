import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../@vex/services/layout.service';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@vex/utils/check-router-childs-data';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfigService } from '../../@vex/services/config.service';
import { GeneralSettingsService } from '../system-settings/general-settings/serivces/general-settings.service';
import { USER_BENNER_SETTINGS_KEY } from '../../../common/general-settings/constants/general-settings-key.constant';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'vex-custom-layout',
  templateUrl: './spa-layout.component.html',
  styleUrls: ['./spa-layout.component.scss']
})
export class SpaLayoutComponent implements OnInit, OnDestroy {

  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isDesktop$ = this.layoutService.isDesktop$;
  showBanner: boolean = false;
  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private breakpointObserver: BreakpointObserver,
              private generalSettingsService: GeneralSettingsService,
              public authService: AuthService,
              private router: Router) { }

  ngOnInit() {
    this.getGeneralSettingsData();
    this.layoutService.banner$.subscribe((response) => {
      this.showBanner = response;
      this.updateBannerHeight();
    });
  }

  getGeneralSettingsData() {
    this.generalSettingsService.getGeneralSettingValueByKey(USER_BENNER_SETTINGS_KEY).subscribe((result) => {
      if(result) {
        let data = JSON.parse(result.settingValue);        
        this.showBanner = data.showBanner;
      } else {
        this.showBanner = false;
      }
      this.updateBannerHeight();
    }, () => {
      this.updateBannerHeight();
    });
  }

  updateBannerHeight(): void {
    if(this.showBanner && this.authService.isSuperAdmin()) {
      document.documentElement.style.setProperty('--banner-height', '28px');
    } else {
      document.documentElement.style.setProperty('--banner-height', '0');
    }
  }

  ngOnDestroy(): void {}
}
