import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CalendarDataInterface} from '../../../../common/shift/interfaces/calendar-data.interface';
import {ShiftCalendarDataInterface} from '../../../../common/shift/interfaces/shift-calendar-data.interface';
import {EventCalendarInterface} from '../../../../common/shift/interfaces/event-calendar.interface';
import {ShiftCollisionErrorsInterface} from '../../../../common/shift/interfaces/shift-collision-errors.interface';

@Injectable()
export class ShiftNetwork {
    constructor(private http: HttpClient) {
    }

    fetchEventsData(from: Date, to: Date): Observable<CalendarDataInterface> {
        const params = new HttpParams({
            fromObject: {
                from: from.toISOString(),
                to: to.toISOString(),
            }
        });
        return this.http.get<CalendarDataInterface>(`/shift-calendar`, {params: params});
    }

    fetchEvent(shiftId: number,  eventId: number): Observable<EventCalendarInterface> {
        return this.http.get<EventCalendarInterface>(`/shift-calendar/${shiftId}/${eventId}`);
    }

    createShift(form: ShiftCalendarDataInterface): Observable<ShiftCollisionErrorsInterface> {
        return this.http.post<ShiftCollisionErrorsInterface>(`/shift-calendar`, form);
    }

    editShift(shiftId: number,  eventId: number, form: ShiftCalendarDataInterface): Observable<ShiftCollisionErrorsInterface> {
        return this.http.patch<ShiftCollisionErrorsInterface>(`/shift-calendar/${shiftId}/${eventId}`, form);
    }

    stopShift(shiftId: number, eventId: number) {
        return this.http.patch<CalendarDataInterface>(`/shift-calendar/${shiftId}/${eventId}/stop`, {});
    }

    deleteShift(shiftId: number, eventId: number, allFollowing: boolean) {
        const params = new HttpParams({
            fromObject: {
                allFollowing: allFollowing.toString(),
            }
        });
        return this.http.delete<CalendarDataInterface>(`/shift-calendar/${shiftId}/${eventId}`, {
            params: params
        });
    }
}