import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IoTypeEnum } from '../../../../../common/io-module/enums/io-type.enum';

@Injectable({
  providedIn: 'root'
})
export class AesStationsIoMappingService {


  constructor(
    private http: HttpClient
  ) {
  }


  getIOList(filters): Observable<any[]> {
    const params = new HttpParams({
        fromObject: {
            name: '',
            usage: '' 
        }
    });
    return this.http.get<any[]>(`/api/io`, {params: params});
}

getAllConfiguredAlarmByStationId(stationId){
  return this.http.get<any[]>( `/configured-alarm/current/${stationId}`);
}
getStationsMappingByStationIds(stationIds){
  return this.http.get<any[]>( `/device-configurator/io-mappings/stations-mapping?ids=${stationIds}`);
}

  getIOAndMqttListByType(ioType: IoTypeEnum, mqttType: string) {
    return this.http.get<any[]>( `/api/io-mqtt?type=${ioType}&mqttType=${mqttType}`);
  }
}
