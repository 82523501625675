import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {intersection} from 'lodash-es';
import {ConfigurationService} from '../configuration/services/configuration.service';
import * as _ from "lodash";
import { UserPermissionsEnum } from '../../../common/auth/enums/user-permissions.enum';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router,
        private configurationService: ConfigurationService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.loggedIn()) {
            this.authService.redirectUrl = {
                url: state.url,
                queryParams: route.queryParams
            };
            this.router.navigate(['/login']);
            return false;
        }

        if (this.noAccessByConfiguration(route, this.configurationService)) {
            this.navigateToDashboard();
            return false;
        }

        if (this.noAccessByPermissions(route)) {
            this.navigateToDashboard();
            return false;
        }

        return true;
    }

    private navigateToDashboard() {
        this.router.navigate(['/dashboard']);
    }

    private noAccessByConfiguration(route: ActivatedRouteSnapshot, configurationService: ConfigurationService): boolean {
        return _.isNumber(route.data.configuration) && !configurationService.isOn(route.data.configuration);
    }

    private noAccessByPermissions(route: ActivatedRouteSnapshot): boolean {
        if (!route.data.permissions || route.data.permissions.length === 0) {
            return false;
        }
        return !this.hasPermission(route.data['permissions']);
    }

    hasPermission(permissions: UserPermissionsEnum[]) {
        const userData = this.authService.getDecodedTokenSnapshot();
        const userPermissions = userData.permissions || [];

        return intersection(permissions, userPermissions).length !== 0;
    }

    hasPermissionsForSpecificUser(user, permissions: UserPermissionsEnum[]): boolean {
        const userPermissions = user.permissions || [];

        return intersection(permissions, userPermissions).length !== 0;
    }
}
