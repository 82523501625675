import {Component, OnInit, ViewChild} from '@angular/core';
import {AEGroupInterface} from "../groups/interfaces/a-e-group.interface";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatSort} from "@angular/material/sort";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {AlarmReasonsFormComponent} from "./alarm-reasons-form/alarm-reasons-form.component";
import {first} from "rxjs/operators";
import {
    ImportAlarmReasonsFromCSVComponent
} from "./import-alarm-reasons-from-csv/import-alarm-reasons-from-csv.component";
import { AlarmsAndEscalationsService } from '../alarms-and-escalations.service';

@Component({
    selector: 'app-alarm-reasons',
    templateUrl: './alarm-reasons.component.html',
    styleUrls: ['./alarm-reasons.component.scss']
})
export class AlarmReasonsComponent implements OnInit {


    groups: AEGroupInterface[] = [];
    displayedColumns: string[] = [
        'name',
        'alarms',
        'actions',
    ];

    // @ViewChild(MatPaginator) paginator: MatPaginator;
    // @ViewChild(MatSort) sort: MatSort;
    showFilters = false;
    root: {
        children?:  any [];
        id: number
    } = {
        children: [],
        id: 0
    };


    constructor(private matDialog: MatDialog , private alarmsAndEscalationsService : AlarmsAndEscalationsService) {

    }

    ngOnInit(): void {
        this.loadList();
    }

    loadList() {
this.alarmsAndEscalationsService.getAllDisorderReasons().subscribe(res=>{
    this.root = {
        id: 0,
        children: res
    }
});

        // this.groupsService.fetchGroups().subscribe((result) => {
        this.root = {
            id: 0,
            children: [
                {
                    id: 1,
                    name: 'Material',
                    alarms: ['alarm 1', ' alarm 2'],
                },
                {
                    id: 2,
                    name: 'Maintenance',
                    alarms: ['alarm 1'],
                    children: [
                        {
                            id: 21,
                            name: 'sub Maintenance 1',
                            alarms: 'alarm 1',
                            children: [{
                                id: 211,
                                name: 'sub Maintenance 1.1',
                                alarms: ['alarm 1', ' alarm 2'],
                            }]
                        },
                        {
                            id: 22,
                            name: 'sub Maintenance 2',
                            alarms: ['alarm 2', ' alarm 3'],
                        }]
                },
                {
                    id: 3,
                    name: 'Mechanic',
                    alarms: ['alarm 1', ' alarm 2'],
                }
            ]
        };
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.sort = this.sort;
        // });
    }

    changeFilters() {

    }


    AddReason() {
        this.matDialog.open(AlarmReasonsFormComponent, {
            width: '400px',
            data: {
                item: null,
                parent: this.root
            }
        }).afterClosed().pipe(first()).subscribe(res => {
            if (res) {
                this.root.children.push(res);
            }
        });
    }

    importFromCSV() {
        this.matDialog.open(ImportAlarmReasonsFromCSVComponent, {
            width: '500px',
        }).afterClosed().pipe(first()).subscribe(res => {
            if (res) {
                this.root.children.push(...res);
            }
        });
    }

    ExportToCSV() {

    }

    handleLoading(mode){
        alert("ddddd");
    }

    test(e){
alert("ddddfdff");
    }

    mainParentDeleted(rId){
        let rIndex =  this.root.children.findIndex(c => c.id == rId);
        this.root.children.splice(rIndex, 1);
    }
}
