import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS, MatLegacyFormFieldDefaultOptions as MatFormFieldDefaultOptions } from '@angular/material/legacy-form-field';
import {TranslateModule} from '@ngx-translate/core';
import {TranslateService} from '../app/translate/services/translate.service';
import {TranslateNetworkService} from '../app/translate/services/translate.network.service';
import { SharedModule } from '../app/shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    TranslateModule,
    TranslateModule,
    SharedModule,
  ],
  exports: [
    LayoutModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
    TranslateService,
    TranslateNetworkService
  ]
})
export class VexModule {
}
