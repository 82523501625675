import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-system-stopped',
  templateUrl: './system-stopped.component.html',
  styleUrls: ['./system-stopped.component.scss']
})
export class SystemStoppedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
