import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { WorkInstructionsService } from './work-instructions.service';
import { InfoboxService } from '../../../common/shared/services/infobox.service';
import { LineService } from '../line/line.service';
import { WorkInstructionsEditComponent } from './work-instructions-edit/work-instructions-edit.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ModalsService} from '../../../common/shared/services/modals.service';
import {TranslateService} from '@ngx-translate/core';
import { StationFullInterface } from '../../../common/station/interfaces/station-full.interface';
import { DeviceService } from '../shared/components/devices/services/device.service';
import { WorkInstructionDataInterface } from '../../../common/work-instructions/interfaces/work-instruction-data.interface';

@Component({
  selector: 'app-work-instructions',
  templateUrl: './work-instructions.component.html',
  styleUrls: ['./work-instructions.component.scss']
})
export class WorkInstructionsComponent implements OnInit {

  workInstructions: WorkInstructionDataInterface[] = [];
  stations: StationFullInterface[] = [];
  lineId;
  searchPhrase = '';
  loading = false;

  constructor(
    private workInstructionsService: WorkInstructionsService,
    private infoboxService: InfoboxService,
    private deviceService: DeviceService,
    private lineService: LineService,
    private modalsService: ModalsService,
    private translateService: TranslateService,
    public dialog: MatDialog,
  ) { }

  @ViewChild('table') table: ElementRef;

  ngOnInit() {
    this.lineId = this.lineService.getSelectedLine();
    this.getWorkInstructions();
    this.deviceService.init(this.lineId);
    this.deviceService.getDevices()
      .subscribe((response) => {
        this.stations = response;
      });
  }

  getStationNames(workInstruction: WorkInstructionDataInterface): string {
    return workInstruction.stations.map(sId => {
      const station = this.stations.find(s => s.id === sId);

      return station?.name;
    }).join(', ');
  }

  getFolderName(folder: string): string {
    return folder.substr(0, folder.lastIndexOf("/"));
  }
  deleteWorkInstruction(workInstruction: WorkInstructionDataInterface) {
    const content = this.translateService.instant('SPA.WORK_INSTRUCTIONS.DELETE_MODAL.HEADER', {fileName: workInstruction.name});
    this.modalsService.confirm(content).subscribe((result) => {
      if (result) {
        this.workInstructionsService.deleteWorkInstruction(this.lineId, workInstruction.id).subscribe(res => {
          if (res) {
            this.infoboxService.pushInfo({success: true, text: 'SPA.WORK_INSTRUCTIONS.DELETE_MODAL.SUCCESS'});
            this.getWorkInstructions();
          }
        });
      }
    });
  }

    public search() {
        const field = this.searchPhrase.toLowerCase();
        const row = this.table.nativeElement.querySelectorAll('.body-row');
        for (const singleRow of row) {
            if (singleRow) {
                const td = singleRow.querySelectorAll('.column');
                for (const item of td) {
                    if (item) {
                        if (item.textContent.toString().toLowerCase().indexOf(field) >= 0) {
                            singleRow.hidden = false;
                            break;
                        } else {
                            singleRow.hidden = true;
                        }
                    }
                }
            }
        }
    }

  editWorkInstruction(workInstruction) {
    let dialogRef = this.dialog.open(WorkInstructionsEditComponent, {
      height: '500px',
      width: '600px',
      data: {
        workInstruction: workInstruction,
        stations: this.stations,
        lineId: this.lineId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.infoboxService.pushInfo({success: true, text: 'SPA.WORK_INSTRUCTIONS.EDIT_MODAL.SUCCESS'});
      }
      this.getWorkInstructions();
    });
  }

  private getWorkInstructions() {
        this.loading = true;
        this.workInstructionsService.getWorkInstructions(this.lineId).subscribe(res => {
            this.workInstructions = res;
            this.loading = false;
        }, () => {
            this.loading = false;
        });
  }
}
