import { Injectable } from '@angular/core';
import { TranslateService as NGXTranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { catchError, Observable, of, Subject } from 'rxjs';
import { TranslateNetworkService } from './translate.network.service';
import { LanguageEnum } from '../../../../common/shared/enums/language.enum';
import { StorageService } from '../../../../common/shared/services/storage.service';
import { CommonService } from '../../../../common/shared/services/common.service';

@Injectable()
export class TranslateService {
  private _userLang: LanguageEnum;
  private _langChanged: Subject<string> = new Subject();

  constructor(
    private ngxTranslate: NGXTranslateService,
    private storage: StorageService,
    private translateNetworkService: TranslateNetworkService,
    private commonService: CommonService,
  ) {
    this._userLang = this.storage.getItem('language') || LanguageEnum.ENGLISH;
  }

  public init() {
    this.ngxTranslate.addLangs(environment.AVAILABLE_LANGS);
    this.ngxTranslate.setDefaultLang(LanguageEnum.ENGLISH);
    this.ngxTranslate.use(this._userLang.match(/en|de|zh/) ? this._userLang : LanguageEnum.ENGLISH).subscribe(() => {
      this.loadFallbackTranslations();
    });
  }

  get userLang(): LanguageEnum {
      return this._userLang;
  }

  public get(key?: string | string[], params?: Object): Observable<any> {
    if (!key || key.length === 0) {
      return new Observable(
        observer => {
            observer.next('');
        });
    }
    return this.ngxTranslate.get(key, params);
  }

  public langChanged(): Observable<string> {
    return this._langChanged.asObservable();
  }

  async changeLang(lang: LanguageEnum) {
    this.use(lang).subscribe(() => {
      this.loadFallbackTranslations();
    });
    this.commonService.spaLangChange(lang);
    await this.translateNetworkService.changeLanguage(lang).toPromise();
  }

  use(lang: LanguageEnum): Observable<any> {
    this.storage.setItem('language', lang);
    this._userLang = lang;
    this._langChanged.next(lang);
    return this.ngxTranslate.use(lang)
  }

  // load fallback translations 
  loadFallbackTranslations(): void {
    // Get english lang translations
    this.ngxTranslate.use(LanguageEnum.ENGLISH).pipe(
      catchError(() => of({}))
    ).subscribe((enTranslations: any) => {

        // Get selected lang translations
        this.ngxTranslate.use(this._userLang).pipe(
            catchError(() => of({})),
        ).subscribe((langTranslations: any) => {
          let enObject = JSON.parse(JSON.stringify(enTranslations));
          let langObject = JSON.parse(JSON.stringify(langTranslations));
          let finalTranslations = this.commonService.mergeTranslations(enObject, langObject);
          this.ngxTranslate.setTranslation(this._userLang, finalTranslations);
        });
    });
  }

}
