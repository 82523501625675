import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceClickAction } from '../../../shared/components/devices/components/devices-list/devices-list.component';
import { DeviceActionEnum } from '../../../shared/components/devices/enums/device-action.enum';

@Component({
  selector: 'app-andon-list',
  templateUrl: './andon-list.component.html',
  styleUrls: ['./andon-list.component.scss']
})
export class AndonListComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit() {
  }

  clicked(action: DeviceClickAction) {
    const route = action.action === DeviceActionEnum.ADD
      ? ['andon', 'add']
      : ['andon', 'edit', action.deviceId];

    this.router.navigate(route);
  }

  ngOnDestroy() {
  }
}
