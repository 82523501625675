import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChartsAndTablesService } from '../charts-and-tables.service';
import { TimeService } from '../../../../common/shared/services/time.service';
import { Subscription } from 'rxjs';
import { TableDataTypeEnum } from '../../../../common/charts/enums/table-data-type.enum';
import { TableLineStopInterface } from '../../../../common/charts/interfaces/table-line-stop.interface';
import { ChartsAndTablesDataService } from '../services/charts-and-tables-data.service';
import { SortDirEnum } from '../../../../common/shared/enums/sort-dir.enum';

@Component({
  selector: 'app-line-stop-list',
  templateUrl: './line-stop-list.component.html',
  styleUrls: ['./line-stop-list.component.scss']
})
export class LineStopListComponent implements OnInit, OnDestroy {
  lineStops: TableLineStopInterface[] = [];
  headers = {
    header: 'SPA.CHARTS.TABLE.LINE_STOP.HEADER',

    occurred: 'SPA.CHARTS.TABLE.LINE_STOP.OCCURRED',
    solved: 'SPA.CHARTS.TABLE.LINE_STOP.SOLVED',
    duration: 'SPA.CHARTS.TABLE.LINE_STOP.DURATION',
    text: 'SPA.CHARTS.TABLE.LINE_STOP.TEXT',
    user: 'SPA.CHARTS.TABLE.LINE_STOP.USER',
  };
  private renewSubscription: Subscription;

  constructor(
    private chartsAndTablesService: ChartsAndTablesService,
    private timeService: TimeService,
    private chartDataService: ChartsAndTablesDataService
  ) {
      this.renewSubscription = this.chartDataService.filtersChanged().subscribe(() => {
          this.getLineStopList();
      })
  }

  ngOnInit() {
      if (this.chartDataService.isReady()) {
          this.getLineStopList();
      }
  }

  ngOnDestroy() {
    this.renewSubscription.unsubscribe();
  }

  getLineStopList() {
      this.chartDataService.getTableData(TableDataTypeEnum.LINE_STOP, SortDirEnum.DESC)
        .subscribe(res => {
          this.lineStops = res;
        });
  }

  exportLineStopData() {
    this.chartsAndTablesService.exportTable(
      this.headers.header,
       this.chartDataService.filters.timePeriod,
      [
        this.headers.occurred,
        this.headers.solved,
        this.headers.duration,
        this.headers.text,
        this.headers.user,
      ],
      this.lineStops
    )
  }
}
