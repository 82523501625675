import { Component, Input, OnInit } from '@angular/core';
import { StationService } from '../../../../../app/stations/station.service';
import { InfoboxService } from '../../../../../../common/shared/services/infobox.service';

@Component({
  selector: 'app-edit-station-ae-io-mapping',
  templateUrl: './edit-station-ae-io-mapping.component.html',
  styleUrls: ['./edit-station-ae-io-mapping.component.scss']
})
export class EditStationAeIoMappingComponent implements OnInit {

  stationsIOMapping: any ;
  @Input() stationId;
  stations;
  constructor(private stationService : StationService ,private  infoBoxService : InfoboxService) { 
  }

  ngOnInit(): void {
    this.stations = [{id:this.stationId , name:''}];
  }

  saveMapping(){
    this.stationService.saveStationsMapping(this.stationsIOMapping).subscribe(res=>{
      this.infoBoxService.pushInfo({ success: true, text: 'I/O mapping successfully changed ' });

    });

  }

}
