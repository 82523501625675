import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UserService } from '../user.service';
import { InfoboxService } from '../../../../../common/shared/services/infobox.service';
import { RoleInterface } from '../../../../../common/auth/interfaces/role.interface';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import { LanguageEnum } from '../../../../../common/shared/enums/language.enum';
import { SmartwatchInterface } from '../../../../../common/smartwatch/interfaces/smartwatch.interface';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent implements OnInit {
    form: UntypedFormGroup;

    public roles: RoleInterface[];
    languages = LanguageEnum;
    showPassword: boolean = false;
    showConfirmPassword: boolean = false;
    smartwatches: SmartwatchInterface[]  = [];

    constructor(
        private _location: Location,
        private userService: UserService,
        private infoboxService: InfoboxService
    ) { }

    ngOnInit() {
        this.initForm();
        this.userService.getUserRoles().subscribe((roles) => {
            this.roles = roles;
        });
        this.getSmartwatchList();
    }

    initForm() {
        this.form = new UntypedFormGroup({
            id: new UntypedFormControl(undefined),
            firstname: new UntypedFormControl('', Validators.required),
            name: new UntypedFormControl('', Validators.required),
            email: new UntypedFormControl(''),
            coin:new UntypedFormControl(''),
            role: new UntypedFormControl('', Validators.required),
            username: new UntypedFormControl('', Validators.required),
            password: new UntypedFormControl('', Validators.required),
            confirmPassword: new UntypedFormControl('', [Validators.required]),
            language: new UntypedFormControl('', Validators.required),
            watch: new UntypedFormControl('')
        });

        // on passwords field value changes
        this.password.valueChanges.subscribe(() => this.setPasswordMatchValidation());
        this.confirmPassword.valueChanges.subscribe(() => this.setPasswordMatchValidation());
    }

    save() {
        if (this.form.valid) {
          this.userService.addUser(this.form.value)
            .subscribe((response) => {
              this.infoboxService.pushInfo({ success: true, text: 'SPA.USERS.ADD.SUCCESS' });
              this._location.back();
            });
        }
    }

    goBack() {
        this._location.back();
    }

    get password(): AbstractControl {
        return this.form.get('password');
    }

    get confirmPassword(): AbstractControl {
        return this.form.get('confirmPassword');
    }

    setPasswordMatchValidation() {
        let passwordValue = this.password.value;
        let confirmPasswordValue = this.confirmPassword.value;

        let existErrors = this.confirmPassword.errors;
        if(passwordValue !== confirmPasswordValue) {
            let misMatchError = {valueMismatch: true};
            if(existErrors) {
                misMatchError = {
                    ...misMatchError,
                    ...existErrors
                };
            } 
            this.form.get('confirmPassword').setErrors(misMatchError);
        } else {
            if(existErrors?.valueMismatch) delete existErrors?.valueMismatch;
            if(typeof this.confirmPassword.errors === 'object' && this.confirmPassword.errors !== null) {
                if (Object.keys(this.confirmPassword.errors).length ===  0) {
                    existErrors = null;
                }
            }
            // set existing errors without misMatch Error
            this.form.get('confirmPassword').setErrors(existErrors);
        }
    }

    getSmartwatchList() {
        this.userService.getUnconfiguredSmartwatches().subscribe((res) => {
            this.smartwatches = res.filter((s) => !s.user);
        });
    }
}
