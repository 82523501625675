import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ColorEvent } from 'ngx-color';
import { COLORS } from '../../../core/colors/colors';

@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ColorPickerComponent implements OnInit{

    colors = COLORS;
    showPalette = false;

    @Input() label: string;
    @Input() displayLabel = true;
    @Input() color: string;
    @Input() width: number = 500;
    @Input() absolute = true;
    @Input() disabled = false;
    @Input() hideWhite = false;
    @Output() changed = new EventEmitter<string>();

    constructor(private eRef: ElementRef) {  
    }

    ngOnInit(){
        if(this.hideWhite){
            this.colors = [];

            COLORS.forEach(element => {
                if(element !== '#ffffff'){
                    this.colors.push(element);
                }
            });
        }
    }

    handleChange($event: ColorEvent) {
        this.changed.emit($event.color.hex);
        this.showPalette = false;
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.showPalette && !this.eRef.nativeElement.contains(event.target)) {
            this.showPalette = false;
        }
    }
}
