<div class="chart" *ngIf="chart && show && !multiple">
    <i class="material-icons" (click)="exportChartData()">cloud_download</i>
    <vex-chart [options]="chart.options" [series]="series" ></vex-chart>
</div>
<button *ngIf="showResetButton" class="filterButton" (click)="resetFilters()" color="primary" mat-raised-button>{{ 'SPA.CHARTS.RESET' | translate }}</button>
<div class="chart" *ngIf="multiple">
    <div class="multi-chart" *ngFor="let chartData of multipleChartArr; index as key">
        <i class="material-icons" (click)="exportChartData(chartData)">cloud_download</i>
        <vex-chart [options]="chartData.chart.options" [series]="chartData.series" [changingAlarmData]="changingChartValue"></vex-chart>
    </div>
</div>

