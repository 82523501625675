import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { StorageService } from '../../../../common/shared/services/storage.service';

@Injectable()
export class HttpUrlInterceptor implements HttpInterceptor {
    constructor(
      private storage: StorageService
    ) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const noContentType = req.params.has('noContentType');

        if (!req.params.has('ignoreUrlInterception') && req.url.indexOf('assets/i18n') === -1) {
            req = this.overrideRequest(req, noContentType);
        }

        return next.handle(req);
    }

    private overrideRequest(req: HttpRequest<any>, noContentType = false): HttpRequest<any> {
        let updatedUrl = req.url;

        if (req.url.indexOf(`${environment.apiUrl}/api`) === -1) {
            updatedUrl = `${environment.apiUrl}/api${req.url.replace('api/', '')}`;
        }

        req.params.delete('contentType');

        return req.clone({
            url: updatedUrl,
            headers: this.getHeaders(noContentType)
        });
    }

    private getHeaders(noContentType = false): HttpHeaders {
      const headersObj = {
          Authorization: 'Bearer ' + this.storage.getItem('token')
      };

      if (!noContentType) {
        headersObj['Content-Type'] = 'application/json';
      }

      return new HttpHeaders(headersObj);
    }
}
