import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ChartsAndTablesService } from './charts-and-tables.service';
import { LineService } from '../line/line.service';
import { BreadcrumbComponent } from '../shared/components/breadcrumb.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LineInterface } from '../../../common/line/interfaces/line.interface';
import { StationFullInterface } from '../../../common/station/interfaces/station-full.interface';
import { ChartInterface } from '../../../common/charts/interfaces/chart.interface';
import { LineBeatTypeEnum } from '../../../common/line/enums/line-beat-type.enum';
import {ChartsAndTablesDataService} from './services/charts-and-tables-data.service';
import { ChartsAndTablesFiltersInterface } from '../../../common/charts/interfaces/charts-and-tables-filters.interface';
import { chartFilterKey } from '../../../common/filters/constants/filter-key.constants';
import { Moment } from 'moment';
import {
    CHARTS_FILTERS_MAX_TIME_DIFFERENCE,
    CHARTS_FILTERS_TIME_DIFFERENCE_UNITS
} from '../../../common/charts/constants/filters.constants';
import { InfoboxService } from '../../../common/shared/services/infobox.service';
import { DeviceService } from '../shared/components/devices/services/device.service';
import { SaveFilterService } from '../../../common/shared/services/save-filter.service';

@Component({
    selector: 'app-charts-and-tables',
    templateUrl: './charts-and-tables.component.html',
    styleUrls: ['./charts-and-tables.component.scss']
})
export class ChartsAndTablesComponent extends BreadcrumbComponent implements OnInit {
    lines: LineInterface[] = [];
    stations: StationFullInterface[] = [];
    charts: ChartInterface[] = [];
    showFilters = false;
    private isStationSelectionRequired = false;
    private dateRangeCorrect = true;
    private isStationIdInFilter = false;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        private chartsAndTablesService: ChartsAndTablesService,
        private lineService: LineService,
        private deviceService: DeviceService,
        private chartDataService: ChartsAndTablesDataService,
        private infoboxService: InfoboxService,
        private saveFilterService: SaveFilterService,
    ) {
        super(route, router);
    }

    ngOnInit() {
        this.initBreadcrumbs();
        this.getCharts();
        this.lineService.getLines().subscribe(res => {
            this.lines = res;
            if (this.lines.length > 0) {
                this.filters.lineId = this.lines[0].id;
                this.getStations(this.filters.lineId);
                this.chartDataService.emitFilterChanged();
            }
        });
        setTimeout(() => {
            this.initFilters();
        }, 500);
    }

    get filters(): ChartsAndTablesFiltersInterface {
        return this.chartDataService.filters;
    }

    rangeChange(rangeName: string) {
        let begin: Moment, end: Moment;
        this.filters.range = rangeName;
        switch (rangeName) {
            case 'YESTERDAY': {
                begin = moment().subtract(1, 'days').startOf('day');
                end = moment().subtract(1, 'days').endOf('day');
                break;
            }
            case 'CURRENT_WEEK': {
                begin = moment().startOf('isoWeek');
                end = moment().endOf('isoWeek');
                break;
            }
            case 'LAST_WEEK': {
                begin = moment().subtract(1, 'weeks').startOf('isoWeek');
                end = moment().subtract(1, 'weeks').endOf('isoWeek');
                break;
            }
            case 'CURRENT_MONTH': {
                begin = moment().startOf('month');
                end = moment().endOf('month');
                break;
            }
            case 'LAST_MONTH': {
                begin = moment().subtract(1, 'months').startOf('month');
                end = moment().subtract(1, 'months').endOf('month');
                break;
            }
            default: {
                begin = moment().startOf('day');
                end = moment().endOf('day');
                break;
            }
        }

        this.checkDates(begin, end);

        this.filters.timePeriod.begin = moment(begin.toString()).format('YYYY-MM-DDTHH:mm');
        this.filters.timePeriod.end = moment(end.toString()).format('YYYY-MM-DDTHH:mm');
    }

    changeBeginDate(begin: Moment) {
        this.filters.timePeriod.begin = moment(begin.toString()).format('YYYY-MM-DD');
        const end = moment(this.filters.timePeriod.end);

        this.checkDates(begin, end);
    }

    changeEndDate(end: Moment) {
        this.filters.timePeriod.end = moment(end.toString()).format('YYYY-MM-DD');
        const begin = moment(this.filters.timePeriod.begin);

        this.checkDates(begin, end);
    }

    changeLine(line: LineInterface) {
        this.getStations(line);
        this.chartDataService.filters.stationId = null;
        this.isStationSelectionRequired = line.beatTypeEnum === LineBeatTypeEnum.STATION_BASED_BEAT;
    }

    changeFilters() {
        this.chartDataService.filters.alarmChartLabel = null;
        this.chartDataService.filters.alarmChartType = null;
        // To save the filters
        this.saveFilterService.createObject(chartFilterKey, this.chartDataService.filters);
        this.chartDataService.emitFilterChanged();
    }

    resetFilters(){
        this.chartDataService.filters.stationId = null;
        this.chartDataService.filters.lineId = this.lines[0].id;
        this.chartDataService.filters.range = 'CURRENT_MONTH';
        this.rangeChange('CURRENT_MONTH');
        this.chartDataService.emitFilterChanged();
    }

    initFilters() {
        // To get the saved filters from database
        this.saveFilterService.getFiltersUsingKey(chartFilterKey).subscribe(filters => {
            if (!!filters) {
                this.isStationIdInFilter = !!filters.stationId;
                this.chartDataService.filters.timePeriod.begin = !!filters.from ? moment(moment(filters.from).toString()).format('YYYY-MM-DD') : this.filters.timePeriod.begin;
                this.chartDataService.filters.timePeriod.end = !!filters.to ? moment(moment(filters.to).toString()).format('YYYY-MM-DD') : this.filters.timePeriod.end;
                this.chartDataService.filters.lineId = !!filters.lineId ? filters.lineId : this.filters.lineId;
                this.chartDataService.filters.range = !!filters.range ? filters.range : this.filters.range;
                if (!!filters.lineId) {
                    this.getStations(filters.lineId, filters.stationId);
                }
                if (!this.isStationIdInFilter) {
                    this.chartDataService.emitFilterChanged();
                }
            }
        });
    }

    disableFilterButton(): boolean {
        const mainCheck = !this.filters.timePeriod.begin ||
          !this.filters.timePeriod.end ||
          !this.filters.lineId ||
          !this.dateRangeCorrect;

        return mainCheck || (this.isStationSelectionRequired && !this.filters.stationId);
    }

    private getCharts() {
        this.chartsAndTablesService.getCharts().subscribe(charts => {
            this.charts = charts;
        });
    }

    private getStations(lineId, stationId = null) {
        this.deviceService.init(lineId);
        this.deviceService.getDevices()
          .subscribe(res => {
              this.stations = res;
              if (!!stationId) {
                this.chartDataService.filters.stationId = stationId;
                if (this.isStationIdInFilter) {
                    this.chartDataService.emitFilterChanged();
                }
              }
          });
    }

    private checkDates(begin: Moment, end: Moment) {
        if (!(end.diff(begin, CHARTS_FILTERS_TIME_DIFFERENCE_UNITS) > CHARTS_FILTERS_MAX_TIME_DIFFERENCE)) {
            this.dateRangeCorrect = true;
            return;
        }

        this.dateRangeCorrect = false;

        this.infoboxService.error('SPA.CHARTS.TIME_DIFFERENCE_TOO_LARGE', {
            maxTimeDifference: CHARTS_FILTERS_MAX_TIME_DIFFERENCE
        });
    }
}
