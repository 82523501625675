import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '../translate/services/translate.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserPermissionsEnum } from '../../../common/auth/enums/user-permissions.enum';
import { UserInterface } from '../../../common/auth/interfaces/user.interface';
import { RedirectUrlInterface } from '../../../common/shared/interfaces/redirect-url.interface';
import { SocketService } from '../_services/socket.service';
import { SocketMessageEnum } from '../../../common/socket/enums/socket-message.enum';
import { SocketChannel } from '../../../common/socket/utils/socket-channel';
import { RoleChangedInterface } from '../../../common/users/interfaces/role-changed.interface';
import { LoginLogoutService } from './login-logout.service';
import { AuthServiceInterface } from '../../../common/auth/interfaces/auth-service.interface';
import { StorageService } from '../../../common/shared/services/storage.service';


@Injectable()
export class AuthService implements AuthServiceInterface {
    public userToken: string;
    public decodedToken: UserInterface;
    public jwtHelper: JwtHelperService = new JwtHelperService();
    private currectUserSubject = new BehaviorSubject<UserInterface>(null);
    private _currentUser$ = this.currectUserSubject.asObservable();
    private _currentUser: UserInterface = null;
    private _redirectUrl: RedirectUrlInterface = null;
    private _intervalId;
    private _socketSubs: string[] = [];

    constructor(
        private router: Router,
        private storage: StorageService,
        private translateService: TranslateService,
        private socketService: SocketService,
        private loginLogoutService: LoginLogoutService,
    ) {
        this.initial();
    }

    getDecodedTokenSnapshot(): UserInterface {
        if (!this.decodedToken) {
            this.initial();
        }

        return this.decodedToken;
    }

    storeToken(tokenstring): void {
        if (tokenstring) {
            this.storage.setItem('token', tokenstring);
            this.decodedToken = this.jwtHelper.decodeToken(tokenstring);
            this.currectUserSubject.next(this.decodedToken);
            this._currentUser = this.decodedToken;
            this.userToken = tokenstring;
            if (this.decodedToken.language) {
                this.translateService.use(this.decodedToken.language);
            }
        }
    }

    initInterval() {
        this._intervalId = setInterval(() => {this.checkLoginState()}, 3000);
    }

    loggedIn(): boolean {
        if (this.userToken) {
            return !this.jwtHelper.isTokenExpired(this.userToken);
        }

        return false;
    }

    logout(automatic: boolean = false): void {
        this.loginLogoutService.logout(automatic).subscribe(_ => {
            this.actualLogout();
        }, _ => {
            this.actualLogout();
        });
    }

    private actualLogout() {
        this.storage.removeItem('token');
        this.storage.removeItem('stayLoggedIn');
        this.userToken = null;
        this.decodedToken = null;
        this._currentUser = null;
        this.router.navigate(['/login']);
        clearInterval(this._intervalId);
    }

    checkLoginState() {
        if (!!!this.userToken || this.jwtHelper.isTokenExpired(this.userToken)) {
            this.logout(true);
        }
    }

    initial() {
        const stringToken = this.storage.getItem('token');

        if (stringToken) {
            this.decodedToken = this.jwtHelper.decodeToken(stringToken);
            this.userToken = stringToken;
            this._currentUser = this.decodedToken;
            this.currectUserSubject.next(this.decodedToken);
        }
    }

    get currentUser$(): Observable<UserInterface> {
        return this._currentUser$;
    }

    isSuperAdmin(): boolean {
        const userData = this.getDecodedTokenSnapshot();

        if (!userData) {
            return false;
        }

        const userPermissions = userData.permissions || [];

        return userPermissions.includes(UserPermissionsEnum.SuperAdmin);
    }

    set redirectUrl(redirectUrl: RedirectUrlInterface) {
        if (redirectUrl === null) {
            this._redirectUrl = null;
            return;
        }

        if (this._redirectUrl !== null) {
            return;
        }

        this._redirectUrl = redirectUrl;
    }

    get redirectUrl(): RedirectUrlInterface|null {
        const url = this._redirectUrl;

        this.redirectUrl = null;

        return url;
    }

    initializeSocket() {
      this.socketService.join(SocketChannel.USER_EVENTS);

      this._socketSubs.push(this.socketService.on(SocketMessageEnum.USER_DELETED, (userId: number) => {
        if (this._currentUser?.id !== userId) {
          return;
        }

        this.logout(true);
      }));

      this._socketSubs.push(this.socketService.on(SocketMessageEnum.ROLE_CHANGED, (data: RoleChangedInterface) => {
          if (
            this._currentUser?.role?.id !== data.roleId ||
            JSON.stringify(this._currentUser?.permissions) === JSON.stringify(data.permissions)
          ) {
              return;
          }

          this.loginLogoutService.getNewToken().subscribe(data => {
              this.storeToken(data.token);
              location.reload();
          });
      }))
    }

    cleanSockets() {
        this._socketSubs.forEach(s => this.socketService.off(s));
        this.socketService.leave(SocketChannel.USER_EVENTS);
    }

    decodeToken(tokenstring): UserInterface {
        return this.jwtHelper.decodeToken(tokenstring);
    }
}
