import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RoleInterface } from '../../../../common/auth/interfaces/role.interface';
import { UserInterface } from '../../../../common/auth/interfaces/user.interface';
import { UserBarcodeInterface } from '../../../../common/users/interfaces/user-barcode.interface';
import { StorageService } from '../../../../common/shared/services/storage.service';
import { SmartwatchInterface } from '../../../../common/smartwatch/interfaces/smartwatch.interface';

@Injectable()
export class UserService {

    constructor(
        private http: HttpClient,
        private storage: StorageService
    ) {
        this.apiUrl = environment.apiUrl;
    }

    apiUrl: String;

    getUsers(): Observable<UserInterface[]> {
        return this.http
            .get<UserInterface[]>( this.apiUrl + '/api/user');
    }
    
    getAllUsers(): Observable<UserInterface[]> {
        return this.http
            .get<UserInterface[]>( this.apiUrl + '/api/user/all-users');
    }

    getUser(id): Observable<UserInterface> {
        return this.http
            .get<UserInterface>(this.apiUrl + '/api/user/' + id);
    }

    getUserRoles(): Observable<RoleInterface[]> {
        return this.http
            .get<RoleInterface[]>(this.apiUrl + '/api/roles');
    }
    updateUser(user): Observable<HttpResponse<any>> {
        return this.http
            .patch<any>(this.apiUrl + '/api/user/' + user.id, user);
    }

    addUser(user): Observable<HttpResponse<any>> {
        return this.http
            .post<any>(this.apiUrl + '/api/user', user);
    }

    deleteUser(id): Observable<HttpResponse<any>> {
        return this.http
            .delete<any>(this.apiUrl + '/api/user/' + id, {observe: 'response'});
    }

    getUserBarcode(id: number): Observable<UserBarcodeInterface> {
        return this.http.get<UserBarcodeInterface>(`/user/barcode/${id}`);
    }

    updateUserBarcode(id: number): Observable<UserBarcodeInterface> {
        return this.http.patch<UserBarcodeInterface>(`/user/barcode/${id}`, null);
    }

    getUnconfiguredSmartwatches(): Observable<SmartwatchInterface[]> {
        return this.http.get<SmartwatchInterface[]>(this.apiUrl + '/api/smartwatch/unconfigured');
    }
}
