import { Routes } from '@angular/router';
import { AuthGuard } from '../../_guards/auth.guard';
import { EmailConfigurationComponent } from './email-configuration.component';
import { UserPermissionsEnum } from '../../../../common/auth/enums/user-permissions.enum';

export const EMAIL_CONFIGURATION_ROUTES: Routes = [
    {
        path: '',
        component: EmailConfigurationComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: [UserPermissionsEnum.EmailSettingsManagement],
            breadcrumb: {
                label: 'SPA.NAV.EMAIL_CONFIGURATION',
            }
        }
    }
];
