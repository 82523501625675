import { NgModule } from '@angular/core';
import { TimezoneComponent } from './timezone.component';
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';

@NgModule({
  declarations: [TimezoneComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatAutocompleteModule
  ]
})
export class TimezoneModule { }
