<h1 matDialogTitle>{{'SPA.ALARMS_ESCALATIONS.ALARM_REASONS.FORM.HEADER' | translate }}</h1>

<mat-dialog-content>
    <form #form="ngForm">

        <mat-form-field>
            <mat-label>Name</mat-label>
            <input type="text" matInput [(ngModel)]="reason.translationJson[translateService.userLang].name" name="reason.reason">
            <mat-icon class="translate-language" matSuffix (click)="openAddTranslationModal('name')">edit</mat-icon>
        </mat-form-field>

        <mat-form-field *ngIf="!reason.parentId">
            <mat-label>{{'SPA.ALARMS_ESCALATIONS.ALARM_REASONS.FORM.SELECT_ALARMS' | translate }}</mat-label>
            <mat-select     [compareWith]="compareObjects"  multiple placeholder="Alarms" name="alarms"  [(ngModel)]="selectedAlarms" >
                <mat-option [value]="alarm" *ngFor="let alarm of alarms">{{getTranslatedAlarm(alarm)}}</mat-option>
            </mat-select>
        </mat-form-field>

    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="save()">{{'COMMON.SAVE' |translate}}</button>
    <span style="flex-grow: 1"></span>
    <button mat-button matDialogClose (click)="close()">{{'COMMON.CANCEL' |translate}}</button>
</mat-dialog-actions>
`
