<div class="row header" *ngIf="lineStops.length > 0">
    <div class="col s11 center-align">
        <h4><strong>{{ headers.header | translate }}</strong></h4>
    </div>
    <div class="col s1">
        <button class="btn-floating btn-large timesweep" (click)="exportLineStopData()">
            <i class="material-icons">cloud_download</i>
        </button>
    </div>
</div>
<div class="table" *ngIf="lineStops.length > 0">
    <div class="table-row table-header">
        <div class="column-first">{{ headers.occurred | translate }}</div>
        <div class="column">{{ headers.solved | translate }}</div>
        <div class="column">{{ headers.duration | translate }}</div>
        <div class="column">{{ headers.text | translate }}</div>
        <div class="column">{{ headers.user | translate }}</div>
    </div>
    <div class="table-row body-row" *ngFor="let lineStop of lineStops">
        <div class="column column-first">{{lineStop.occurred}}</div>
        <div class="column">{{lineStop.solved}}</div>
        <div class="column">{{lineStop.duration}}</div>
        <div class="column">{{lineStop.text}}</div>
        <div class="column">{{lineStop.user}}</div>
    </div>
</div>