<h3 mat-dialog-title>
    <span *ngIf="isEdit()">{{ 'SPA.DEVICE.FTP_SETTING.EDIT' | translate }}</span>
    <span *ngIf="!isEdit()">{{ 'SPA.DEVICE.FTP_SETTING.ADD' | translate }}</span>

    {{ 'SPA.DEVICE.FTP_SETTING.SLIDE' | translate }}
</h3>
<div mat-dialog-content class="content">
    <div class="form-content" [formGroup]="form">
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
            <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                <mat-label>{{ 'SPA.DEVICE.FTP_SETTING.NAME' | translate }}</mat-label>
                <input matInput required formControlName="name">
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
            <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                <mat-label>{{ 'SPA.DEVICE.FTP_SETTING.TYPE' | translate }}</mat-label>
                <mat-select formControlName="typeEnum">
                    <mat-option *ngFor="let type of types | enumToArray" [value]="types[type]">
                        {{ 'ENUMS.FTP_SLIDES.' + type | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
            <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                <mat-label>{{ 'SPA.DEVICE.FTP_SETTING.SLIDE_TIME' | translate }}</mat-label>
                <input matInput required formControlName="slideTime" type="number">
            </mat-form-field>
        </div>
        <div class="path" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" *ngFor="let path of paths.controls; let i = index" [formGroup]="path">
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                    <span matPrefix>{{data.mainPath}}/</span>
                    <input matInput formControlName="path">
                    <mat-hint>{{ 'SPA.DEVICE.FTP_SETTING.SOURCE' | translate}} {{i + 1}}</mat-hint>
                </mat-form-field>
        </div>
    </div>
    <ngx-loading [show]="loading"></ngx-loading>
</div>
<div mat-dialog-actions class="footer">
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="mt-10">
        <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
            <button color="warn" mat-button type="button" (click)="close()"> {{ 'SPA.USERS.EDIT.BACK' | translate }}</button>
            <button color="primary" mat-raised-button (click)="submit()" [disabled]="!form.valid">
                {{ 'COMMON.SAVE' | translate }}
            </button>
        </div>
    </div>
</div>
