<page-header titleTranslate="SPA.DEVICE.EDIT.HEADER"></page-header>
<div class="row">
  <div class="col s12">
    <form [formGroup]="form" (ngSubmit)="save()">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
          <mat-label>{{ 'SPA.DEVICE.EDIT.NAME' | translate }}</mat-label>
          <input matInput required formControlName="name" [maxlength]="maxNameLength">
          <mat-error>{{ 'SPA.DEVICE.EDIT.NAME_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.DEVICE.EDIT.TYPE' | translate }}</mat-label>
            <mat-select required formControlName="type">
              <ng-container *ngFor="let type of deviceTypes | stringEnumToArray">
                    <mat-option [value]="deviceTypes[type]" *ngIf="deviceService.showDeviceType(deviceTypes[type])">
                        {{ 'ENUMS.STATION_TYPE.' + type | translate | titlecase }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12" *ngIf="!(isVirtualStation)">
        <div
        fxLayout="row"
        class="content-row"
        fxLayout.lt-sm="column"
        fxLayoutGap="16px"
        fxLayoutGap.lt-sm="0"
        style="padding: 10px;"
        >
          <mat-label class="toggle-label">{{'SPA.DEVICE.ADD.RUNNING_AS_WEB_APP' | translate}}</mat-label>
          <mat-slide-toggle
            class="checkbox"
            formControlName="isRunningAsWebApp"
            ></mat-slide-toggle>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" *ngIf="!(isAndon || isVirtualStation)">
        <div class="vex-flex-form-field" style="width:50%" *ngIf="!hasCode()"></div>
        <div class="col s12">
          <div
          fxLayout="row"
          class="content-row"
          fxLayout.lt-sm="column"
          fxLayoutGap="16px"
          fxLayoutGap.lt-sm="0"
          style="padding: 10px;"
          >
            <mat-label class="toggle-label">{{'SPA.DEVICE.ADD.MULTIPLE_STATION' | translate}}</mat-label>
            <mat-slide-toggle
              class="checkbox"
              formControlName="isMultipleStation"
              ></mat-slide-toggle>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" *ngIf="hasCode() && !isVirtualStation">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.DEVICE.EDIT.CODE' | translate }}</mat-label>
            <input matInput disabled [value]="'SPA.DEVICE.EDIT.ASSIGNED' | translate">
        </mat-form-field>
          <button color="warn" mat-raised-button type="button" (click)="unassign()" class="smaller">
              <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                        inline="true">clear</mat-icon>
          </button>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" *ngIf="!hasCode()">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.DEVICE.EDIT.CODE' | translate }}</mat-label>
            <input matInput disabled [value]="device?.code">
        </mat-form-field>
        <ng-container *ngIf="isVisibleSectionForWebApp">
          <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.DEVICE.EDIT.UNASSIGNED_DEVICE' | translate }}</mat-label>
            <mat-select formControlName="unassignedDeviceId">
              <ng-container *ngFor="let unassignedDevice of unassignedDevices">
                <mat-option [value]="unassignedDevice.id">
                  {{ unassignedDevice.ip }}
                  {{ unassignedDevice.macAddress }}
                  ({{ unassignedDevice.width }}x{{ unassignedDevice.height }})
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
       </ng-container>
      </div>

      <div
        fxLayout="row"
        class="content-row"
        fxLayout.lt-sm="column"
        fxLayoutGap="16px"
        fxLayoutGap.lt-sm="0"
        style="padding: 10px;width:275px;justify-content: space-between;"
        *ngIf="isVirtualStation"
      >
        <mat-label>{{
          "SPA.DEVICE_CONFIGURATOR.SETTINGS.BLOCK_IN_PAUSE" | translate
        }}</mat-label>
        <mat-slide-toggle
          class="checkbox"
          formControlName="blockInPause"
          ></mat-slide-toggle>
      </div>

      <div
        fxLayout="row"
        class="content-row"
        fxLayout.lt-sm="column"
        fxLayoutGap="16px"
        fxLayoutGap.lt-sm="0"
        style="padding: 10px;width:275px;justify-content: space-between;"
        *ngIf="isVirtualStation"
      >
        <mat-label>{{
          "SPA.DEVICE_CONFIGURATOR.SETTINGS.BLOCK_IN_NO_SHIFT" | translate
        }}</mat-label>
        <mat-slide-toggle
          class="checkbox"
          formControlName="blockInNoShift"
        ></mat-slide-toggle>
      </div>

      <div
        fxLayout="row"
        class="content-row"
        fxLayout.lt-sm="column"
        fxLayoutGap="16px"
        fxLayoutGap.lt-sm="0"
        style="padding: 10px;width:275px;justify-content: space-between;"
        *ngIf="isAndon"
      >
        <mat-label>{{
          "SPA.DEVICE_CONFIGURATOR.SETTINGS.AUTOMATIC_REBOOT_ANDON" | translate
        }}</mat-label>
        <mat-slide-toggle
          class="checkbox"
          formControlName="isAndonAutomaticReboot"
        ></mat-slide-toggle>
      </div>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="mt-10">
        <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
         <button color="warn" mat-button type="button" (click)="deviceService.goBack()"> {{ 'SPA.DEVICE.EDIT.BACK_BTN' | translate }}</button>
          <button color="primary" mat-raised-button
                  [disabled]="form.pristine || !form.valid">
            {{ 'SPA.DEVICE.EDIT.SAVE_BTN' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<page-header *ngIf="isVisibleSectionForWebApp && !isVirtualStation" titleTranslate="SPA.DEVICE.EDIT.ROTATION"></page-header>
<!-- AS OF NOW, GUI IS NOT WORKING SO COMMENTED THE EXISTING CODE AND ADDED THE DROPDOWN TO SELECT THE ROTATION -->

<!-- NEW ADDED CODE FOR DROPDOWN -- START -->
<div *ngIf="isVisibleSectionForWebApp && !isVirtualStation">
  <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
    <mat-label>{{ 'SPA.DEVICE.EDIT.ROTATION' | translate }}</mat-label>
    <mat-select  (selectionChange)="updateRotation($event.value)" [value]="selectedRotation">
      <mat-option *ngFor="let type of DeviceRotationEnum | enumToArray" [value]="DeviceRotationEnum[type]">
      {{ type }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<!-- NEW ADDED CODE FOR DROPDOWN -- END -->

<!-- EXISTING CODE FOR GUI -- START -->
<!-- <div *ngIf="!isAndon && isVisibleSectionForWebApp" class="row row-centered">
  <div class="rotation">
    <div class="top">
      <ng-container *ngTemplateOutlet="stationRotation;context: {name: 'T', rotation: DeviceRotationEnum.NORMAL}"></ng-container>
    </div>
    <div class="bottom">
      <ng-container *ngTemplateOutlet="stationRotation;context: {name: 'L', rotation: DeviceRotationEnum.LEFT}"></ng-container>
      <div class="cross">
        <div class="ball"></div>
        <div class="cross-x"></div>
        <div class="cross-y"></div>
      </div>
      <ng-container *ngTemplateOutlet="stationRotation;context: {name: 'R', rotation: DeviceRotationEnum.RIGHT}"></ng-container>
    </div>
  </div>
</div>

<ng-template *ngIf="!isAndon" #stationRotation let-name="name" let-rotation="rotation">
  <div class="device normal" [class.selected]="isRotationSelected(rotation)" (click)="changeRotation(rotation)"> {{name}}
    <div class="connectors right" [class.left]="rotation === 'left'">
      <div class="two">
        <div class="connector"></div>
        <div class="connector"></div>
      </div>
      <div class="two">
        <div class="connector"></div>
        <div class="connector"></div>
      </div>
      <div class="two">
        <div class="connector"></div>
        <div class="connector"></div>
      </div>
    </div>
  </div>
</ng-template> -->
<!-- EXISTING CODE FOR GUI -- START -->
