import { Routes } from '@angular/router';
import { AuthGuard } from '../../_guards/auth.guard';
import { UserPermissionsEnum } from '../../../../common/auth/enums/user-permissions.enum';
import { TimezoneComponent } from './timezone.component';

export const TIMEZONE_CONFIGURATION_ROUTES: Routes = [
    {
        path: '',
        component: TimezoneComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: [UserPermissionsEnum.ClockSettingsManagement],
            configuration: null,
            breadcrumb: {
                label: 'SPA.TIMEZONE_CONFIGURATION.LABEL',
            }
        },
    }
];
