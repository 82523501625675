import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-andon-add',
  templateUrl: './andon-add.component.html',
  styleUrls: ['./andon-add.component.scss']
})

export class AndonAddComponent implements OnInit {
  constructor(
  ) {
  }

  ngOnInit() {
  }
}
