import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArray'
})
export class EnumToArrayPipe implements PipeTransform {
  transform(data: Object): any {
    const keys = Object.keys(data);
    const slice = keys.slice(keys.length / 2);

    return slice.length * 2 === keys.length ? slice : keys;
  }
}