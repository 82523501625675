<page-header titleTranslate="SPA.WORK_INSTRUCTIONS.HEADER"></page-header>
<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{ 'COMMON.SEARCH' | translate }}</mat-label>
        <input matInput type="text" name="searchPhrase" [(ngModel)]="searchPhrase" (ngModelChange)="search()">
        <mat-icon class="mr-2" matPrefix>search</mat-icon>
    </mat-form-field>
</div>
<div class="table" #table>
    <div class="table-row table-header">
        <div class="column-first">{{ 'SPA.WORK_INSTRUCTIONS.NAME' | translate }}</div>
        <div class="column">{{ 'SPA.WORK_INSTRUCTIONS.FOLDER' | translate }}</div>
        <div class="column">{{ 'SPA.WORK_INSTRUCTIONS.STATION' | translate }}</div>
        <div class="column actions"></div>
    </div>
    <div class="table-row body-row" *ngFor="let workInstruction of workInstructions">
        <div class="column column-first">{{workInstruction.name}}</div>
        <div class="column">{{ getFolderName(workInstruction.folder) }}</div>
        <div class="column">{{ getStationNames(workInstruction) }}</div>
        <div class="column actions">
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <button color="primary" mat-raised-button type="button"
                        (click)="editWorkInstruction(workInstruction)">
                    <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                              inline="true">create</mat-icon>
                </button>
                <button class="bg-blue" mat-raised-button type="button"
                        [routerLink]="['/line', lineId, 'work-instructions', 'view', workInstruction.id]">
                    <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                              inline="true">remove_red_eye</mat-icon>
                </button>
                <button color="warn" mat-raised-button type="button" (click)="deleteWorkInstruction(workInstruction)">
                    <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                              inline="true">delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
</div>
<button mat-fab class="ap-fab" [routerLink]="['/line', lineId, 'work-instructions', 'add']">
    <mat-icon>add</mat-icon>
</button>
