import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {LiveDebuggingEnum} from '../../../common/shared/enums/live-debugging.enum';
import { HttpClient } from '@angular/common/http';
import { InfoboxService } from '../../../common/shared/services/infobox.service';

@Component({
  selector: 'app-live-debugging',
  templateUrl: './live-debugging.component.html',
  styleUrls: ['./live-debugging.component.scss']
})
export class LiveDebuggingComponent implements OnInit {

    url: SafeUrl;
    constructor(
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private http: HttpClient,
        private infoBoxService: InfoboxService,
    ) { }

    ngOnInit() {
      this.route.queryParams.subscribe((data) => {
          const ip = data['ip'];
          if(ip){
            this.http.get('/remote-debugging/config/'+ip).subscribe((res)=>{
              this.url = this.sanitizer.bypassSecurityTrustResourceUrl('http://'+ip+':'+LiveDebuggingEnum.PORT+res[0].devtoolsFrontendUrl);
            }, error=>{
              this.infoBoxService.error(error.error);
            });
          }
      });
    }
}
