import {Injectable} from '@angular/core';
import {DisorderNetwork} from '../networks/disorder.network';
import {UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {DisorderInterface} from '../../../_utils/interfaces/disorder.interface';
import {DisorderRowModel} from '../models/disorder-row.model';
import * as _ from 'lodash';
import {ROW_MODE} from '../components/group-disorder/group-disorder.component';
import {ModalsService} from '../../../../../common/shared/services/modals.service';
import {Router} from '@angular/router';

@Injectable()
export class DisorderService {
    constructor(
        private network: DisorderNetwork,
        private router: Router,
        public modalsService: ModalsService,
    ) {
    }

    save(form: UntypedFormGroup): Observable<DisorderInterface> {
        return form.value.id ? this.network.edit(form, form.value.id) : this.network.create(form);
    }

    delete(id: number): Observable<any> {
        return this.network.delete(id);
    }

    fetchDisorder(id): Observable<DisorderInterface> {
        return this.network.fetchDisorderDetails(id);
    }

    fetchDisorders(): Observable<DisorderInterface[]> {
        return this.network.fetchDisordedList();
    }

    mapDisordersToModels(disorders: DisorderInterface[], parentId = null, mode = ROW_MODE.LISTING): DisorderRowModel[] {
        const data = [];
        _.forEach(disorders, (disorder: DisorderInterface) => {
            data.push(this.mapSingleToModel(disorder, parentId, mode))
        });
        return data;
    }

    mapSingleToModel(disorder: DisorderInterface, parentId = null, mode = ROW_MODE.LISTING): DisorderRowModel {
        return new DisorderRowModel(
            parentId === null ,
            disorder.id,
            disorder.code,
            disorder.reason,
            disorder.line,
            disorder.stations,
            parentId,
            this.mapDisordersToModels(disorder.children, disorder.id, mode),
            mode
        )
    }

    deleteConfirmation() {
        return this.modalsService.confirm('SPA.ALARMS_ESCALATIONS.DISORDERS.REMOVE_DESCRIPTION');
    }

    deleteRootNavigateToList(disorderToDelete: DisorderRowModel) {
        this.deleteConfirmation().subscribe((result) => {
            if (result) {
                this.delete(disorderToDelete.id).subscribe(() => {
                    this.router.navigateByUrl('/alarms-and-escalations/disorder');
                });
            }
        });
    }
}