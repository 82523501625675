import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";
import { ProductDetailsService } from "../../../../../services/product-details.service";
import { ProductDetailsNetwork } from "../../../../../networks/product-details.network";
import { InfoboxService } from "../../../../../../shared/services/infobox.service";

@Component({
  selector: "app-view-image-modal",
  templateUrl: "./view-image-modal.component.html",
  styleUrls: ["./view-image-modal.component.scss"],
  providers: [ProductDetailsService, ProductDetailsNetwork],
})
export class ViewImageModalComponent implements OnInit {
  public mediaUrl: SafeUrl;
  public filename: string;

  constructor(
    public dialogRef: MatDialogRef<ViewImageModalComponent>,
    private infoBoxService: InfoboxService,
    private domSanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.generateMediaUrl();
  }

  close(result = undefined) {
    this.dialogRef.close(result);
  }

  async generateMediaUrl() {
    try {
      const media = await fetch(this.getImageSrc());
      const mediaBlog = await media.blob();
      const mediaURL = URL.createObjectURL(mediaBlog);
      this.mediaUrl = this.domSanitizer.bypassSecurityTrustUrl(mediaURL);
      this.filename = `${new Date().getTime()}`;
    } catch (error) {
      console.error(error);
      this.infoBoxService.error('API.GENERAL.ERROR');
    }
  };

  getImageSrc() {
    if(this.data?.alarm){
      return `${this.data?.apiUrl}/api/alarm-file/get-content/${
        this.data?.alarmId
      }/${this.data?.uniqueId}?authentication_token=${localStorage.getItem("token")}`;
    }else{
      return `${this.data.apiUrl}/api/workstep-log/${
        this.data.dataId
      }?authentication_token=${localStorage.getItem("token")}`;  
    }
  }
}
