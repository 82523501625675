import {Routes} from '@angular/router';
import {AuthGuard} from '../_guards/auth.guard';
import {LineEditComponent} from './line-edit/line-edit.component';
import {LineComponent} from './line.component';
import {STATION_ROUTES} from '../stations/station.routing';
import {LineDetailComponent} from './line-detail/line-detail.component';
import {LineAddComponent} from './line-add/line-add.component';
import { ConfigurationEnum } from '../../../common/configuration/enums/configuration.enum';
import { UserPermissionsEnum } from '../../../common/auth/enums/user-permissions.enum';
import { WORK_INSTRUCTIONS_ROUTES } from '../work-instructions/work-instructions.routing';

export const LINE_ROUTES: Routes = [
    {
        path: '',
        component: LineComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: {
                label: 'SPA.NAV.LINES',
                path: '/line'
            }
        }
    },
    {
        path: 'add',
        component: LineAddComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: [UserPermissionsEnum.LineManagement],
            configuration: ConfigurationEnum.MULTILINE,
            breadcrumb: {
                label: 'SPA.LINES.LINE_ADD.ADD_LINE',
            }
        }
    },
    {
        path: ':id',
        component: LineDetailComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: [UserPermissionsEnum.LineManagement],
            breadcrumb: {
                label: 'SPA.NAV.LINES',
                path: '/line'
            }
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'edit'
            },
            {
                path: 'edit',
                component: LineEditComponent,
                data: {
                    breadcrumb: {
                        label: 'SPA.LINES.EDIT_TITLE',
                    }
                }
            },
            {
                path: 'stations',
                children: STATION_ROUTES,
                data: {
                    breadcrumb: {
                        label: 'SPA.LINES.STATIONS_TITLE',
                    }
                }
            },
            {
                path: 'work-instructions',
                children: WORK_INSTRUCTIONS_ROUTES,
                data: {
                    breadcrumb: {
                        label: 'SPA.LINES.GLOBAL_PDF_DOCUMENTS',
                    }
                }
            }
        ]
    }
];
