import { SimpleChanges, Directive } from '@angular/core';
import { Resizing } from './resizing';

@Directive()
export class BasicConfiguratorWidget extends Resizing {
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.settings) {
            if (changes.settings.firstChange && !!changes.settings.currentValue) {
                const size = !!changes.settings.currentValue.fontSize ? changes.settings.currentValue.fontSize : this.baseFontSize;
                let minSize = Math.min(changes.settings.currentValue.propHeight, changes.settings.currentValue.propWidth);
                minSize = !!minSize ? minSize : 1;
                this.fontSize = this.editMode ? size : size * minSize;
            } else {
                this.calculationDone = false;
            }
        }
    }

    ngOnDestroy(): void {
        if (this.fontChangedSubscription) {
            this.fontChangedSubscription.unsubscribe();
        }
    }
}
