import {Component, Input, OnInit} from '@angular/core';
import faCaretUp from '@iconify/icons-fa-solid/caret-up';
import faCaretDown from '@iconify/icons-fa-solid/caret-down';

@Component({
  selector: 'value-tile',
  templateUrl: './value-tile.component.html',
  styleUrls: ['./value-tile.component.scss']
})
export class ValueTileComponent implements OnInit {

    @Input() icon: string = null;
    @Input() value: string = null;
    @Input() label: string = null;
    @Input() change: number = null;
    @Input() valueFontSize: number = 30;
    @Input() iconClass: string;
    @Input() background = '#CCE2E1';

    faCaretUp = faCaretUp;
    faCaretDown = faCaretDown;

    ngOnInit(): void {
    }

}
