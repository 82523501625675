import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChartsAndTablesService } from '../charts-and-tables.service';
import { TimeService } from '../../../../common/shared/services/time.service';
import { Subscription } from 'rxjs';
import { TableDataTypeEnum } from '../../../../common/charts/enums/table-data-type.enum';
import { TableDisorderOverviewInterface } from '../../../../common/charts/interfaces/table-disorder-overview.interface';
import { ChartsAndTablesDataService } from '../services/charts-and-tables-data.service';
import { SortDirEnum } from '../../../../common/shared/enums/sort-dir.enum';
import { CommonService } from '../../../../common/shared/services/common.service';

@Component({
  selector: 'app-disorder-list',
  templateUrl: './disorder-list.component.html',
  styleUrls: ['./disorder-list.component.scss']
})
export class DisorderListComponent implements OnInit, OnDestroy {
  disorderOverview: TableDisorderOverviewInterface[] = [];
  headers = {
    header: 'SPA.CHARTS.TABLE.DISORDERS.HEADER',

    occurred: 'SPA.CHARTS.TABLE.DISORDERS.OCCURRED',
    duration: 'SPA.CHARTS.TABLE.DISORDERS.DURATION',
    beatTime: 'SPA.CHARTS.TABLE.DISORDERS.BEAT_TIME',
    reason: 'SPA.CHARTS.TABLE.DISORDERS.REASON',
    partNumber: 'SPA.CHARTS.TABLE.DISORDERS.PART_NO',
    text: 'SPA.CHARTS.TABLE.DISORDERS.TEXT',
    stationName: 'SPA.CHARTS.TABLE.DISORDERS.STATION_NAME',
  };
  private renewSubscription: Subscription;

  constructor(
    private chartsAndTablesService: ChartsAndTablesService,
    private timeService: TimeService,
    private chartDataService: ChartsAndTablesDataService,
    private commonService: CommonService
  ) {
      this.renewSubscription = this.chartDataService.filtersChanged().subscribe(() => {
          this.getDisorderOverview();
      })
  }

  ngOnInit() {
      if (this.chartDataService.isReady()) {
          this.getDisorderOverview();
      }
  }

  ngOnDestroy() {
    this.renewSubscription.unsubscribe();
  }

  getDisorderOverview() {
    this.chartDataService.getTableData(TableDataTypeEnum.DISORDER_OVERVIEW, SortDirEnum.DESC)
      .subscribe(res => {
        this.disorderOverview = res;
      });
  }

  exportDisorderData() {
    this.chartsAndTablesService.exportTable(
      this.headers.header,
      this.chartDataService.filters.timePeriod,
      [
        this.headers.occurred,
        this.headers.duration,
        this.headers.beatTime,
        this.headers.reason,
        this.headers.partNumber,
        this.headers.text,
        this.headers.stationName,
      ],
      this.disorderOverview
    )
  }

  getTranslatedName(name, translationObj): string {
    return this.commonService.getTranslatedName(name, translationObj);
  }
}
