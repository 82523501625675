import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {DisorderInterface} from '../../../_utils/interfaces/disorder.interface';

@Injectable()
export class DisorderNetwork {
    constructor(private http: HttpClient) {}

    create(form: UntypedFormGroup): Observable<DisorderInterface> {
        return this.http.post<DisorderInterface>( `/disorder-reason`, form.value);
    }

    delete(id: number): Observable<any> {
        return this.http.delete<any>( `/disorder-reason/` + id);
    }

    edit(form: UntypedFormGroup, id): Observable<DisorderInterface> {
        return this.http.patch<DisorderInterface>( `/disorder-reason/` + id, form.value);
    }

    fetchDisordedList(): Observable<DisorderInterface[]> {
        return this.http.get<DisorderInterface[]>( `/disorder-reason`);
    }

    fetchDisorderDetails(id): Observable<DisorderInterface> {
        return this.http.get<DisorderInterface>( `/disorder-reason/` + id);
    }
}