<ng-container *ngIf="lineId">
  <app-device-edit [lineId]="lineId" [deviceId]="stationId"></app-device-edit>
</ng-container>

<page-header *ngIf="!isVirtualStation" titleTranslate="SPA.DEVICE.EDIT.SETTINGS" class="mt-5"></page-header>

<div class="row" *ngIf="!isVirtualStation">
  <div class="col s12 path">
    <form #stationFtpForm="ngForm" (ngSubmit)="saveFtp()">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
          <span matPrefix *ngIf="ftpStationService.ftpStation.useFtpFile">{{ftpStationService.ftpStation.mainFolder}}/</span>
          <span matPrefix *ngIf="!ftpStationService.ftpStation.useFtpFile">/</span>
          <input matInput [(ngModel)]="ftpStationService.ftpStation.ftpFolder"
                 [ngModelOptions]="{standalone: true}">
          <mat-hint>{{ 'SPA.DEVICE.EDIT.PATH' | translate }}</mat-hint>
        </mat-form-field>
      </div>

      <div
        fxLayout="row"
        class="content-row"
        fxLayout.lt-sm="column"
        fxLayoutGap="16px"
        fxLayoutGap.lt-sm="0"
        style="padding: 10px;width:300px;justify-content: space-between;"
      >
        <mat-label>{{'SPA.DEVICE.EDIT.USE_FTP' | translate}}</mat-label>
        <mat-slide-toggle
          class="checkbox"
          [(ngModel)]="ftpStationService.ftpStation.useFtpFile"
          [ngModelOptions]="{standalone: true}"
          ></mat-slide-toggle>
      </div>

      <div
        fxLayout="row"
        class="content-row"
        fxLayout.lt-sm="column"
        fxLayoutGap="16px"
        fxLayoutGap.lt-sm="0"
        style="padding: 10px;width:300px;justify-content: space-between;"
      >
        <mat-label>{{'SPA.DEVICE.EDIT.SEARCH_DOCUMENT_BY_SERIAL_NUMBER' | translate}}</mat-label>
        <mat-slide-toggle
          class="checkbox"
          [(ngModel)]="ftpStationService.ftpStation.searchBySerialNumber"
          [ngModelOptions]="{standalone: true}"
          ></mat-slide-toggle>
      </div>


      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="mt-10">
        <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
          <button color="warn" mat-button type="button" (click)="goBack()"> {{ 'SPA.DEVICE.EDIT.BACK_BTN' | translate }}</button>
          <button color="primary" mat-raised-button type="submit">
            {{ 'SPA.DEVICE.EDIT.SAVE_BTN' | translate }}
          </button>
        </div>
      </div>

      
    </form>
  </div>
</div>



<div>
 <app-edit-station-ae-io-mapping [stationId] = "stationId"></app-edit-station-ae-io-mapping>
</div>
