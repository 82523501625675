import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

export class BreadcrumbComponent {
    crumbs = [];

    constructor(
        public route: ActivatedRoute,
        public router: Router
    ) {}

    initBreadcrumbs() {
        this.buildCrumbs();
        this.router.events.pipe
        (filter((value) => {
            return value instanceof NavigationEnd
        })).subscribe(() => {
            this.buildCrumbs();
        });
    }

    buildCrumbs() {
        this.crumbs = [];
        this.crumbs.push(this.route.snapshot.data.breadcrumb);
        this.route.children.forEach((result) => {
            this.crumbs.push(result.snapshot.data.breadcrumb)
        })
    }
}