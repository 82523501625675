import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { ConfigurationDataInterface } from '../../../../common/configuration/interfaces/configuration-data.interface';

@Injectable()
export class SettingsService {
    private configurations: ConfigurationDataInterface[] = [];

    constructor(
        private http: HttpClient,
    ) {}

    loadSettings() {
        return this.http.get<ConfigurationDataInterface[]>('/config').toPromise().then(data => {
            this.configurations = data;

            return data;
        });
    }

    getConfigurations() {
        return this.configurations;
    }
}