import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {FormManipulatorService} from './formManipulator.service';

const IM_EX_MODULES = [];
const EXPORTED_MODULES = [
    ...IM_EX_MODULES,
];
const IMPORTED_MODULES = [
    ...IM_EX_MODULES,
    SharedModule
];
const COMPONENTS = [];
const PIPES = [];
const DIRECTIVES = [];
const PROVIDERS = [
    FormManipulatorService
];

@NgModule({
    imports: [IMPORTED_MODULES],
    exports: [
        ...EXPORTED_MODULES,
        ...COMPONENTS,
        ...PIPES,
        ...DIRECTIVES
    ],
    declarations: [
        ...COMPONENTS,
        ...PIPES,
        ...DIRECTIVES
    ],
    providers: [PROVIDERS]
})
export class FormsUtilsModule {

}
