import {Injectable} from '@angular/core';
import {ProductDetailsNetwork} from '../networks/product-details.network';
import {AddHistoryModalDataInterface} from '../interfaces/add-history-modal-data.interface';
import {DataManipulationSaveInterface} from '../../data-collection/interfaces/data-manipulation-save.interface';
import {WidgetTypeEnum} from '../../widget/enums/widget-type.enum';
import {DataCollectionWidgetHistoryInterface} from '../../data-collection/interfaces/data-collection-widget-history.interface';
import {DataCollectionWidgetDataInterface} from '../../data-collection/interfaces/data-collection-widget-data.interface';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../shared/services/common.service';

@Injectable()
export class ProductDetailsService {
    constructor(
        private network: ProductDetailsNetwork,
        private translateService: TranslateService,
        private commonService: CommonService
    ) {
    }

    fetchTimeline(id: number) {
        return this.network.getTimeline(id);
    }

    getDetails(id: number) {
        return this.network.getDetails(id);
    }

    fetchDataCollection(id: number) {
        return this.network.getDataCollection(id);
    }
    
    fetchReworkComments(id: number) {
        return this.network.getReworkComments(id);
    }

    getWidgetData(productData: AddHistoryModalDataInterface) {
        return this.network.getWidgetData(productData);
    }

    updateReworkData(reworkId: number, comment: string, reworkDone: boolean, resolvedStationId: number): Observable<any> {
        return this.network.updateReworkData(
            reworkId,
            comment,
            reworkDone,
            resolvedStationId
        );
    }

    saveWidgetData(productData: AddHistoryModalDataInterface, data: DataManipulationSaveInterface) {
        return this.network.saveWidgetData(productData, data);
    }

    parseDataToDisplay(type: WidgetTypeEnum, history: DataCollectionWidgetHistoryInterface): string {
        if (!history || !history.data[0]) {
            return '';
        }

        switch (type) {
            case WidgetTypeEnum.INPUT_FIELD:
            case WidgetTypeEnum.TEXT_AREA:
            case WidgetTypeEnum.MQTT_TOPIC:
                return history.data[0].label;
            case WidgetTypeEnum.NESTED_LIST:
                return this.parseListData(history.data, ' > ');
            case WidgetTypeEnum.CHECKLIST:
                return this.parseListData(history.data, ', ');
            case WidgetTypeEnum.IO_MODULE:
                return this.parseIOModuleData(history.data);
        }
    }

    exportProductData(productItemId: number, search?: string) {
        return this.network.exportProductData(productItemId, search);
    }

    private parseListData(data: DataCollectionWidgetDataInterface[], delimiter: string): string {
        let dataLabel = this.commonService.getTranslatedName('name', data[0].translationJson) ? this.commonService.getTranslatedName('name', data[0].translationJson) : '';

        for (let i = 1; i < data.length; i++) {
            const label = data[i] ? this.commonService.getTranslatedName('name', data[i].translationJson) : '';
            dataLabel = dataLabel + delimiter + label;
        }

        return dataLabel;
    }

    isObject(value): boolean {
        return typeof value === 'object' && value !== null;
    }

    parseIOModuleData(data) {
        const ioModuleData = data[0]?.label;
        const titleData = {
            entering: 'PRODUCTS.ITEMS.DETAILS.WHEN_ENTERING_THE_WORKSTEP',
            leaving: 'PRODUCTS.ITEMS.DETAILS.WHEN_LEAVING_THE_WORKSTEP'
        };
        const subTitleData = {
            setVariables: 'PRODUCTS.ITEMS.DETAILS.SET_VARIABLES',
            checkVariables: 'PRODUCTS.ITEMS.DETAILS.CHECK_VARIABLES'
        };
        
        let htmlTemplate = '';
    
        Object.entries(ioModuleData).forEach(([ioKey, sectionData], index, arr) => {
            const sectionHasVariableData = Object.values(sectionData).some(array => array.length > 0);
            if (sectionData && Object.keys(sectionData).length && sectionHasVariableData) {

                htmlTemplate += `<b>${this.translateService.instant(titleData[ioKey])}</b><br/>`;
    
                Object.entries(sectionData).forEach(([sectionKey, variableData]) => {
                    if (variableData && variableData.length) {
                        htmlTemplate += `<ul class="ul-list type-disc"><li>${this.translateService.instant(subTitleData[sectionKey])}:</li><ul class="ul-list type-circle">`;
    
                        variableData.forEach(({ variableName, name, configuredValue, databaseValue }, i, varArr) => {
                            let formattedValue = configuredValue;
                            if (this.isObject(configuredValue)) {
                                formattedValue = `{
                                    ${this.translateService.instant('PRODUCTS.ITEMS.DETAILS.FROM')}: ${configuredValue.from}, 
                                    ${this.translateService.instant('PRODUCTS.ITEMS.DETAILS.TO')}: ${configuredValue.to}
                                }`;
                            }
    
                            htmlTemplate += `<li>
                                ${variableName}(${name}), 
                                ${this.translateService.instant('PRODUCTS.ITEMS.DETAILS.VALUE')}: ${databaseValue}, 
                                ${this.translateService.instant('PRODUCTS.ITEMS.DETAILS.DESIRED_VALUE')}: ${formattedValue}
                            </li>`;
    
                            if (i === varArr.length - 1) htmlTemplate += `</ul></ul>`;
                        });
                    }
                });
    
                if (index !== arr.length - 1) {
                    htmlTemplate += `<br/>`;
                }
            }
        });
    
        return htmlTemplate;
    }

    getProductDataCollectionDetail(id: number) {
        return this.network.getProductDataCollectionDetail(id);
    }

    globalSearchProductDataCollectionDetail(id: number, search: string) {
        return this.network.getProductDataCollectionDetail(id, search);
    }

    fetchWorkstepPdfBlob(apiUrl: string, workstepId: number, token: string) {
        return this.network.fetchWorkstepPdfBlob(apiUrl, workstepId, token);
    }
}