import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DeviceTypeEnum } from '../../../../../../../common/device/enums/device-type.enum';
import { UserPermissionsEnum } from '../../../../../../../common/auth/enums/user-permissions.enum';
import { ConfigurationEnum } from '../../../../../../../common/configuration/enums/configuration.enum';
import { DeviceFullInterface } from '../../../../../../../common/device/interfaces/device-full.interface';
import { DeviceIpInterface } from '../../interfaces/device-ip.interface';
import { DeviceUpdaterService } from '../../services/device.updater.service';
import { DeviceService } from '../../services/device.service';
import { LiveDebuggingEnum } from '../../../../../../../common/shared/enums/live-debugging.enum';
import { LineBeatTypeEnum } from '../../../../../../../common/line/enums/line-beat-type.enum';
import { SocketMessageEnum } from '../../../../../../../common/socket/enums/socket-message.enum';
import { SocketService } from '../../../../../_services/socket.service';
import { LineInterface } from '../../../../../../../common/line/interfaces/line.interface';
import { AuthService } from '../../../../../auth/auth.service';
import { ModalsService } from '../../../../../../../common/shared/services/modals.service';
import { DeviceActionEnum } from '../../enums/device-action.enum';
import { Socket } from '../../../../../../../common/socket/models/socket.model';
import { SocketChannel } from '../../../../../../../common/socket/utils/socket-channel';
import { DeviceChangedSocket } from '../../../../../../../common/device/socket/device-changed.socket';
import { DeviceActiveStateChangeSocket } from '../../../../../../../common/device/socket/device-active-state-change.socket';
import { StationOrderTypeEnum } from '../../../../../../../common/line/enums/station-order-type.enum';
import { LineService } from '../../../../../line/line.service';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { BeatSelectionPopupComponent } from '../../../../../line/beat-selection-popup/beat-selection-popup.component';
import { first } from 'rxjs/operators';

export type DeviceClickAction = {action: DeviceActionEnum, deviceId: number};

@Component({
  selector: 'app-devices-list',
  templateUrl: './devices-list.component.html',
  styleUrls: ['./devices-list.component.scss']
})
export class DevicesListComponent implements OnInit, OnDestroy {
  UserPermissionsEnum = UserPermissionsEnum;
  DeviceActionEnum = DeviceActionEnum;
  ConfigurationEnum = ConfigurationEnum;
  devices: any[] = [];
  loading = true;
  @Input() deviceType: DeviceTypeEnum;
  @Input() line: LineInterface;
  @Output() clickAction: EventEmitter<DeviceClickAction> = new EventEmitter<DeviceClickAction>();

  private ipsReachables: DeviceIpInterface[] = [];
  private deviceTypes = DeviceTypeEnum;
  private socket: Socket;
  private socketSubs: string[] = [];
  constructor(
    public deviceUpdaterService: DeviceUpdaterService,
    public deviceService: DeviceService,
    private socketService: SocketService,
    private authService: AuthService,
    private modalsService: ModalsService,
    private translateService: TranslateService,
    private lineService: LineService,
    private matLegacyDialog: MatLegacyDialog,
  ) {
  }

  ngOnInit() {
    this.socket = this.socketService.getSocket();

    this.deviceService.init(this.line?.id);

    this.getDevices();

    this.socket.join(SocketChannel.DEVICE);
    this.socketSubs.push(this.socket.on(
      SocketMessageEnum.DEVICE_CHANGED,
      (data: DeviceChangedSocket) => this.handleDeviceChange(data)
    ));
    this.socketSubs.push(this.socket.on(
      SocketMessageEnum.DEVICE_ACTIVE_STATE_CHANGE,
      (data: DeviceActiveStateChangeSocket) => this.handleDeviceChange(data)
    ));
  }

  ngOnDestroy() {
    this.socketSubs.forEach(s => this.socket.off(s));
    this.socket.leave(SocketChannel.DEVICE);
    this.deviceUpdaterService.destroy();
  }

  triggerAction(action: DeviceActionEnum, deviceId: number) {
    this.clickAction.emit({
      action: action,
      deviceId: deviceId,
    });
  }

  getStationType(type: string): string {
    return _.findKey(this.deviceTypes, item => item === type);
  }

  showList(): boolean {
    return this.devices.length > 0
  }

  isSuperAdmin(): boolean {
    return this.authService.isSuperAdmin();
  }

  drop(event: CdkDragDrop<DeviceFullInterface>) {
    moveItemInArray(this.devices, event.previousIndex, event.currentIndex);

    let order = 1;

    _.forEach(this.devices, (station: DeviceFullInterface) => {
      station.order = order;
      order++;
    });

    this.deviceService.changeOrder(
      this.devices.map(s => {
        return {
          stationId: s.id,
          order: s.order
        };
      })
    ).subscribe(null, error => {
      moveItemInArray(this.devices, event.currentIndex, event.previousIndex);
    });
  }

  disableDrag(): boolean {
    return !!!this.line || this.line.stationOrderTypeEnum !== StationOrderTypeEnum.SEQUENTIAL;
  }

  showStationBeat(): boolean {
    return !!this.line && this.line.beatTypeEnum === LineBeatTypeEnum.STATION_BASED_BEAT;
  }

  updateBeatDuration(deviceId, duration) {
    this.lineService
    .getActiveShiftsForLine(this.lineService.getSelectedLine())
    .subscribe(activeShift => {
        if (activeShift) {
          // Pop-Up open Code
          this.matLegacyDialog.open(BeatSelectionPopupComponent, {
            width: '600px',
          }).afterClosed().pipe(first()).subscribe(res => {
              if (!!res) {
                const type: boolean = res === 'currentShift' ? true : false;
                this.updateDeviceBeat(deviceId, duration, type);
              }
          });
        } else {
          this.updateDeviceBeat(deviceId, duration, false);
        }
    });
  }

  updateDeviceBeat(deviceId, duration, type = null) {
    this.deviceService.updateDeviceBeat(deviceId, duration, type);
  }

  modifyIpsData(station) {
    if (!station.ip) {
      return;
    }
    let found = false;
    _.forEach(this.ipsReachables, (ip: DeviceIpInterface) => {
      if (station.id === ip.stationId) {
        found = true;
        ip.isChecked = ip.isChecked && ip.ip == station.ip;
        ip.ip = station.ip;
      }
    });
    if (!found) {
      this.ipsReachables.push({
        ip: station.ip,
        isChecked: false,
        isReachable: false,
        stationId: station.id,
        isChecking: false
      })
    }
  }

  checkIpsForActiveStations() {
    _.forEach(this.ipsReachables, (ip: DeviceIpInterface) => {
      if (!ip.isChecked && !ip.isChecking){
        ip.isChecking = true;
        this.urlExists(`http://${ip.ip}:${LiveDebuggingEnum.PORT}`).then((result) => {
          ip.isChecked = true;
          ip.isReachable = result;
          ip.isChecking = false;
        })
      }
    });
  }

  showCastBtn(device: DeviceFullInterface): boolean {
    if (!device.ip) {
      return false;
    }

    const elem = _.find(this.ipsReachables, (item: DeviceIpInterface) => { return item.stationId === device.id});

    return elem && elem.isReachable;
  }

  urlExists(url) {
    return  fetch(url, {mode: "no-cors"}).then(res => true).catch(err => false)
  }

  async delete(device: DeviceFullInterface) {
    const content = this.translateService.instant('SPA.DEVICE.DELETE_MODAL.HEADER', {deviceName: device.name});
    const confirmation = await this.modalsService.confirm(content).toPromise();
    if (!confirmation) {
      return
    }
    this.deviceService.deleteDevice(device.id)
      .subscribe((response) => {
        this.getDevices();
      });
  }

  async reboot(device: DeviceFullInterface) {
    const header = this.translateService.instant('SPA.DEVICE.REBOOT_MODAL.HEADER');
    const confirmation = await this.modalsService.confirm(header).toPromise();

    if (!confirmation) {
      return;
    }

    this.deviceService.rebootDevice(device);
  }

  async changeActive(device: DeviceFullInterface, event: MouseEvent) {
    event.preventDefault();

    if (device.active) {
      const header = 'SPA.DEVICE.DEACTIVATE_MODAL.HEADER';
      const confirmation = await this.modalsService.confirm(header).toPromise();

      if (!confirmation) {
        return;
      }
    }

    this.deviceService.changeActive(device);
  }

  private getDevices() {
    this.deviceService.getDevices().subscribe((response) => {
      this.devices = response.map(s => {

        if (!!s?.lastUpdatedBeatValue) {
          s.beat = s.lastUpdatedBeatValue
        }
        let beat = s.beat;
        s.second = beat % 60 < 10?'0'+Math.floor(beat % 60):Math.floor(beat % 60);
        beat = beat / 60;
        s.minute = beat % 60 < 10?'0'+Math.floor(beat % 60):Math.floor(beat % 60);
        beat = Math.floor(beat / 60);
        s.hour = beat % 24 < 10?'0'+Math.floor(beat % 24):Math.floor(beat % 24);
        
        s.beat /= 60;
        
        this.modifyIpsData(s);
        return s;
      });
      this.deviceUpdaterService.init(this.devices);
      this.checkIpsForActiveStations();
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  leadingZeros(input) {
    
    if(!isNaN(input.target.value) && input.target.value.length === 1) {
      input.target.value = '0' + input.target.value;
    }else{
      input.target.value = input.target.value * 1;
      if(!isNaN(input.target.value) && input.target.value.length === 1) {
        input.target.value = '0' + input.target.value;
      }
    }
  }

  private handleDeviceChange(data: DeviceChangedSocket | DeviceActiveStateChangeSocket) {
    if (this.line && (!data.lineId || this.line.id !== data.lineId)) {
      return;
    }

    if (!this.line && !!data.lineId) {
      return;
    }

    this.getDevices();
  }
}
