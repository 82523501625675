import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InfoboxService } from '../../../../common/shared/services/infobox.service';
import { SocketService } from '../../_services/socket.service';
import { LineService } from '../line.service';
import { Router } from '@angular/router';
import {ConfigurationService} from '../../configuration/services/configuration.service';
import { LineTypeEnum } from '../../../../common/line/enums/line-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { ModalsService } from '../../../../common/shared/services/modals.service';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ConfigurationEnum } from '../../../../common/configuration/enums/configuration.enum';
import { LineBeatTypeEnum } from '../../../../common/line/enums/line-beat-type.enum';
import { StationOrderTypeEnum } from '../../../../common/line/enums/station-order-type.enum';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { BeatSelectionPopupComponent } from '../beat-selection-popup/beat-selection-popup.component';
import { catchError, first, switchMap, tap } from 'rxjs/operators';
import { LineInterface } from '../../../../common/line/interfaces/line.interface';
import { ProductItemTypeEnum } from '../../../../common/product/enums/product-item-type.enum';

@Component({
  selector: 'app-line-edit',
  templateUrl: './line-edit.component.html',
  styleUrls: ['./line-edit.component.scss']
})
export class LineEditComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  isShiftRunning = false;
  lineStop = false;
  lineTypes = LineTypeEnum;
  lineBeatTypes = LineBeatTypeEnum;
  stationOrderTypes = StationOrderTypeEnum;
  ConfigurationEnum = ConfigurationEnum;
  socketSubs: string[] = [];
  existingBeatTime: any;
  lineData: LineInterface;

  constructor(
    private configurationService: ConfigurationService,
    private lineService: LineService,
    private infoBoxService: InfoboxService,
    private socketService: SocketService,
    private router: Router,
    private translateService: TranslateService,
    private modalsService: ModalsService,
    private matDialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl(null, [Validators.required]),
      beatHours:new UntypedFormControl(0),
      beatMinutes:new UntypedFormControl(0),
      beatSeconds:new UntypedFormControl(0),
      takt: new UntypedFormControl(null),
      typeEnum: new UntypedFormControl({value: null, disabled: true}),
      beatTypeEnum: new UntypedFormControl({value: null, disabled: true}),
      stationOrderTypeEnum: new UntypedFormControl({value: null, disabled: true}),
      allowFractionValues: new UntypedFormControl({value: false, disabled:true}),
      allowAutomaticTransfer: new UntypedFormControl({value: false, disabled: false})
    });

    this.lineService.getLine(this.lineService.getSelectedLine()).subscribe((line:any) => {
      if (!!line.lastUpdatedBeatValue) {
        line.takt = line.lastUpdatedBeatValue;
      }
      this.lineData = line;
      let formData:any={
        id: line.id,
        name: line.name,
        typeEnum: line.typeEnum,
        beatTypeEnum: line.beatTypeEnum,
        stationOrderTypeEnum: line.stationOrderTypeEnum,
        allowFractionValues: line.allowFractionValues,
        allowAutomaticTransfer: line.allowAutomaticTransfer,
        takt:line.takt / 60
      };
      
      if (line.takt) {
        let takt = line.takt;
        formData.beatSeconds = takt % 60 < 10?'0'+Math.floor(takt % 60):Math.floor(takt % 60);
        takt = takt / 60;
        formData.beatMinutes = takt % 60 < 10?'0'+Math.floor(takt % 60):Math.floor(takt % 60);
        takt = Math.floor(takt / 60);
        formData.beatHours = takt % 24 < 10?'0'+Math.floor(takt % 24):Math.floor(takt % 24);
        this.existingBeatTime = line.takt;
      }

      this.form.get('allowAutomaticTransfer').valueChanges.pipe(
        switchMap((newValue) => {
          const oldValue = this.lineData.allowAutomaticTransfer;
          
          if (oldValue === newValue) {
            return [];
          } else {
            return this.checkShiftIsActive().pipe(
              switchMap((activeShift) => {
                if (activeShift) {
                  this.infoBoxService.warning('SPA.LINES.LINE_ADD.CHANGE_VALUES_ERROR_WHILE_SHIFT');
                  this.form.get('allowAutomaticTransfer').patchValue(oldValue);
                  return [];
                } else if (newValue === true) {
                  return this.lineService.getCountOfProductsBasedOnStatus({
                    status: ProductItemTypeEnum.BETWEEN_STATIONS,
                    lineId: this.lineService.getSelectedLine()
                  }).pipe(
                    catchError((error): any => {
                      this.form.get('allowAutomaticTransfer').patchValue(oldValue);
                    })
                  );
                } else {
                  return [];
                }
              })
            );
          }
        }),
        tap((count) => {
          if (count > 0) {
            this.infoBoxService.warning('SPA.LINES.LINE_ADD.CHANGE_VALUES_ERROR_WHILE_PRODUCTS_BETWEEN_STATION_STATE');
            this.form.get('allowAutomaticTransfer').patchValue(this.lineData.allowAutomaticTransfer);
          }
        })
      ).subscribe();

      this.form.patchValue(formData);
    });
  }

  leadingZeros(input) {
    
    if(!isNaN(input.target.value) && input.target.value.length === 1) {
      input.target.value = '0' + input.target.value;
    }else{
      input.target.value = input.target.value * 1;
      if(!isNaN(input.target.value) && input.target.value.length === 1) {
        input.target.value = '0' + input.target.value;
      }
    }
  }

  ngOnDestroy(): void {
  }

  isRework(): boolean {
    return this.typeEnum === LineTypeEnum.REWORK;
  }

  isNoBeat(): boolean {
    return this.typeEnum === LineTypeEnum.NO_BEAT;
  }

  isProduction(): boolean {
    return this.typeEnum === LineTypeEnum.PRODUCTION;
  }

  get isBeatSelectionPopupOpen(): boolean {
    return this.typeEnum === LineTypeEnum.PRODUCTION_REWORK || this.typeEnum === LineTypeEnum.PRODUCTION;
  }

  get isStationBasedBeat(): boolean {
    return this.beatTypeEnum === LineBeatTypeEnum.STATION_BASED_BEAT;
  }

  canDelete() {
    if (this.lineStop || this.isShiftRunning) {
      return false;
    }

    if (this.isRework()) {
      return true;
    }

    return true;
  }

  delete() {
    const content = this.translateService.instant('SPA.LINES.DELETE_MODAL.HEADER', {lineName: this.form.value.name});
    this.modalsService.confirm(content).subscribe((result) => {
      if (result) {
        this.lineService
          .deleteLine(this.lineId)
          .subscribe(response => {
            this.router.navigate(['/line']);
          });
      }
    });
  }

  save() {
    // To check for beat popup can open and compare existing and updated beat time
    if (this.isBeatSelectionPopupOpen && !this.isStationBasedBeat && Number(this.existingBeatTime) !== Number(this.taktValueInSec)) {
      // To check any active shift is running for current line or not
      this.lineService
      .getActiveShiftsForLine(this.lineService.getSelectedLine())
      .subscribe(activeShift => {
          if (activeShift) {
            // Pop-Up open Code
            this.matDialog.open(BeatSelectionPopupComponent, {
              width: '600px',
            }).afterClosed().pipe(first()).subscribe(res => {
                if (!!res) {
                  const type: boolean = res === 'currentShift' ? true : false;
                  this.saveAction(type);
                  // this.updateBeatFrequency(type);
                }
            });
          } else {
            this.saveAction(false);
          }
      })
    } else {
      this.saveAction();
    }
  }

  saveAction(type = null) {
    this.form.patchValue({
      takt: this.takt * 60,
    });
    let data :any= {
      id:this.form.value.id,
      name:this.form.value.name,
      takt:this.taktValueInSec,
      allowAutomaticTransfer: this.form.get('allowAutomaticTransfer').value
    }
    if (type === true || type === false) {
      data.lastUpdatedBeatValue = type === false ? this.taktValueInSec: null
    }
    this.lineService
      .updateLine(data)
      .subscribe(line => {
        this.form.patchValue({
          ...line,
          ...{
            takt: this.takt / 60,
          }
        })
        this.existingBeatTime = this.taktValueInSec;
        this.lineData.allowAutomaticTransfer = line.allowAutomaticTransfer;
        this.form.markAsPristine();
        this.infoBoxService.success('SPA.LINES.EDIT_SUCCESS');
      }, error => {
        this.form.patchValue({
          takt: this.takt / 60,
        });
      });
  }

  updateBeatFrequency(type) {
    this.lineService
      .updateBeatFrequency(this.lineService.getSelectedLine(), type)
      .subscribe(beatValue => {
      });
  }

  get taktValueInSec() {
    return (this.form.value.beatMinutes * 60) + (this.form.value.beatSeconds * 1) + (this.form.value.beatHours * 3600);
  }

  get lineId() {
    return this.form.get('id').value;
  }

  private get typeEnum() {
    return this.form.get('typeEnum').value;
  }

  private get beatTypeEnum() {
    return this.form.get('beatTypeEnum').value;
  }

  private get takt() {
    return this.form.get('takt').value;
  }

  get canShowAllowAutomaticTransfer(): Boolean {
    return this.lineData.beatTypeEnum === LineBeatTypeEnum.LINE_BASED_BEAT && this.lineData.stationOrderTypeEnum == StationOrderTypeEnum.SEQUENTIAL;
  }

  private checkShiftIsActive() {
    return this.lineService
    .getActiveShiftsForLine(this.lineService.getSelectedLine());
  }
}
