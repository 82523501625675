<div mat-dialog-content>
    <div class="content">
        <img *ngIf="!data?.video" [src]="getImageSrc()" class="media">
        <video *ngIf="data?.video" [src]="getImageSrc()" class="media video" style="width:360 !important;" controls
            #recordedVideo autoplay></video>

        <!-- <vg-player *ngIf="data?.video">
            <vg-overlay-play></vg-overlay-play>
            <vg-buffering></vg-buffering>

            <vg-controls [vgAutohide]="false">
                <vg-play-pause></vg-play-pause>
                <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
                <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>
                <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
                <vg-mute></vg-mute>
                <vg-volume></vg-volume>
            </vg-controls>
            <video [vgMedia]="media" #media preload="auto" loop >
                <source [src]="getImageSrc()" type="video/mp4" >
            </video>
        </vg-player> -->
    </div>
</div>
<div mat-dialog-actions class="footer">
    <div class="footer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
        <a mat-button color="secondary" [href]="mediaUrl" [download]="filename">
            {{ 'COMMON.DOWNLOAD' | translate }}
        </a>
        <button color="primary" mat-button (click)="close()"
                type="button">{{ 'COMMON.CANCEL' | translate }}
        </button>
    </div>
</div>