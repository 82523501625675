import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { BackupInterface } from '../../../../../common/backups/interfaces/backup.interface';
import { BackupEventInterface } from '../../../../../common/backups/interfaces/backup-event.interface';

@Injectable()
export class BackupService {
    constructor(private http: HttpClient) {}

    getBackupData() {
        return this.http.get<BackupInterface>(`/backup`);
    }

    createInstantBackup() {
        return this.http.post<BackupEventInterface>(`/backup/instant`, {});
    }

    saveBackupData(form: UntypedFormGroup) {
        return this.http.patch<BackupInterface>(`/backup`, form.value);
    }

    getBackupEvents() {
        return this.http.get<BackupEventInterface[]>(`/backup/events`);
    }

    checkFileExists(eventId: number): Observable<boolean> {
        return this.http.get<boolean>(`/backup/download/exists/${eventId}`);
    }
}
