<div class="tile" [ngStyle]="{'background': background, 'color': color, 'font-size': fontSize + fontUnit}" (resized)="onResized($event, false, false, 0.5)">
    <span class="text-center" (resized)="onResized($event, true, false, 0.5)">{{ label | translate}}</span>
    <span class="font-bold">
    {{
        value 
        ? unit 
          ? value + ' ' + unit
          : value
        : ''
    }}
    </span>
</div>
