import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { ClockDataInterface } from '../../clock/interfaces/clock-data.interface';
import { BaseSocketService } from '../../socket/services/base-socket.service';
import { SocketChannel } from '../../socket/utils/socket-channel';
import { SocketMessageEnum } from '../../socket/enums/socket-message.enum';
import { Subject } from 'rxjs';

@Injectable()
export class ClockService {

  private _timezone: string;
  private _differenceBetweenServerAndSystemTime: number;
  private _timezoneChanged = new Subject<void>();

  constructor(
    private http: HttpClient,
  ) {
  }

  get timezone(): string {
    return this._timezone;
  }

  get difference(): number {
    return this._differenceBetweenServerAndSystemTime;
  }

  get timezoneChanged(): any {
    return this._timezoneChanged.asObservable();
  }

  loadClock() {
    return this.http.get<ClockDataInterface>('/api/clock').toPromise().then(clockData => this.handleClockData(clockData));
  }

  listenTimezoneChanged(socket: BaseSocketService) {
    if (!socket.getSocket()) {
      socket.createSocket();
    }
    socket.getSocket().join(SocketChannel.TIMEZONE);
    socket.getSocket().on(SocketMessageEnum.TIMEZONE_CHANGED, () => {
      this.loadClock();
    });
  }

  private handleClockData(clockData: ClockDataInterface) {
    console.log("CLOCK DATA", clockData);
    this._timezone = clockData.timezone;
    const serverUtcTime = moment(clockData.isoTime);
    const localUtcTime = moment.utc();

    this._differenceBetweenServerAndSystemTime = serverUtcTime.diff(localUtcTime, 'seconds');
    this._timezoneChanged.next();
  }
}
