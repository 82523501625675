import { Component, OnInit } from '@angular/core';
import { BreadcrumbComponent } from '../shared/components/breadcrumb.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FileInput } from 'ngx-material-file-input';
import { HttpClient, HttpParams } from '@angular/common/http';
import { InfoboxService } from '../../../common/shared/services/infobox.service';

@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.scss']
})
export class MobileAppComponent extends BreadcrumbComponent implements OnInit {

  file;
  name: string;
  constructor(
      public route: ActivatedRoute,
      public router: Router,
      private http: HttpClient,
      private toastService: InfoboxService,
  ) {
    super(route, router);
  }

  ngOnInit(): void {
    this.initBreadcrumbs();
  }

  fileChangeEvent(event: FileInput) {
    this.file = event.files[0];
  }

  upload() {
    if (!this.file) {
      return;
    }
    const params = new HttpParams({
      fromObject: {
        noContentType: 'true'
      }
    });
    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('name', this.name);

    this.http.post<boolean>(`/api/mobile-app/upload`, formData, {params}).subscribe(() => {
        this.toastService.success('SPA.MOBILE_APP.UPLOADED_SUCCESSFULLY');
        this.file = null;
        this.name = null;
    });
  }
}
