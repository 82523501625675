import { Component, OnInit } from '@angular/core';
import { LineInterface } from '../../../../../common/line/interfaces/line.interface';
import { LineService } from '../../../line/line.service';

@Component({
  selector: 'app-station-add',
  templateUrl: './station-add.component.html',
  styleUrls: ['./station-add.component.scss']
})

export class StationAddComponent implements OnInit {
  constructor(
    private lineService: LineService,
  ) { }


  ngOnInit() {
  }

  get line(): LineInterface {
    return this.lineService.line;
  }
}
