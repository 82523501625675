import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from "moment";
import { ChartComponent } from "ng-apexcharts";
import { ApexOptions } from 'apexcharts';
import { MatLegacySelectChange as MatSelectChange } from "@angular/material/legacy-select";
import DurationConstructor = moment.unitOfTime.DurationConstructor;
import { AlarmsAndEscalationsService } from '../alarms-and-escalations.service';
import { ActivatedRoute } from '@angular/router';
import { DurationPipe } from '../../../../common/shared/pipes/duration.pipe';
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { UpdateReasonComponent } from "./update-reason/update-reason.component";
import { CommonService } from '../../../../common/shared/services/common.service';
import { environment } from '../../../environments/environment';
import { ViewImageModalComponent } from '../../../../common/product/components/product-item-details/product-item-data-collection/product-item-data-history/view-image-modal/view-image-modal.component';

@Component({
    selector: 'app-alarm-details',
    templateUrl: './alarm-details.component.html',
    styleUrls: ['./alarm-details.component.scss']
})
export class AlarmDetailsComponent implements OnInit {
    data: any;
    alarmId: any;
    isTimelioneLoaded = false;
    alarm = {
        alarmId: 1,
        productionSector: '',
        station: '',
        stationId: '',
        configuredAlarmId: '',
        alarmType: '',
        reason: "",
        creator: '',
        resolvedFrom: '',
        start: moment().toDate(),
        end: moment().add("h", 80).toDate(),
        duration: '00:00',
        escalated: false,
        forwarded: false,
        data: [],
        isDisorderUnderInvestigation: false,
        comment: '',
        disorderId: '',
        reworkComment: '',
        reasonTranslationJson: null,
        translationJson: {},
        configuredAlarm:{}
    };
    @ViewChild("chart") chart: ChartComponent;
    chartOptions: Partial<ApexOptions>;
    chartViewMode: 'time' | 'ticks' = "ticks";
    unit: DurationConstructor = 'minutes'
    logStart;
    logEnd;
    private series: any[];
    apiUrl = '';
    newChartOptions = {
        series: [{
            name: 'Alarm Lifespan',
            data: [],
        }], 
        chart: {
            height: 400,
            type: "rangeBar",
            zoom: {
                enabled: true,
                type: 'x'
            },
            selection: {
                enabled: true
            }
          },
        plotOptions: {
            bar: {
              horizontal: true,
              barHeight: "80%",
            },
        },
        xaxis: {
            type: "datetime",
        },
        dataLabels: {
            enabled: true,
            formatter: (val) => {
                let labelText = "";
                let format =  'MM/DD HH:mm:ss';
                if(this.logStart == val[0] && this.logEnd == val[1] ){
                    labelText =   "(" + this.calculateDurationFromDates(val[0], val[1]) + ") " +  moment(val[0]).format(format) + " - " +  moment(val[1]).format(format) ;
                } else {
                    labelText =  this.calculateDurationFromDates(val[0], val[1]);
                }
                return labelText;
            }
        },
        tooltip: {
            custom: options => {
                const item = this.newChartOptions.series[options.seriesIndex].data[options.dataPointIndex];
                if (!item) return '';
                let format = 'HH:mm:ss';
                format = 'MM.DD.YYYY HH:mm:ss';

                let text = `<div style="padding: 14px; min-width: 200px; text-align: center">
                <div style="">
                <span>${moment(item.y[0]).format(format)}</span> <small> - </small> <span>${moment(item.y[1]).format(format)}(${this.calculateDurationFromDates(item.y[0], item.y[1])})</span>
                </div>`;
                if(item.alarmType){
                    text = text + `<div style="">Alarm type: ${item.alarmType}</div>
                    `;
                }
                text = text + `
                <div style="margin: 8px 0">
                   <strong> ${item.user ?? ''}</strong>
                </div>
                <div style="">
                    ${item.sector ?? ''}
                </div>
                <div style="">
                    ${item.station ?? ''}
                </div>
                <div style="">
                    ${item.userGroup ?? ''}
                </div>
                </div>
                `
                return text;
            }
        }
    }

    constructor(private alarmsAndEscalationsService: AlarmsAndEscalationsService, private activatedRoute: ActivatedRoute, private durationPipe: DurationPipe, private matDialog: MatDialog, private commonService: CommonService) {
    }

    ngOnInit(): void {
        this.alarmId = +this.activatedRoute.snapshot.params.id;
        this.apiUrl = environment.apiUrl;

        this.alarmsAndEscalationsService.adminGetAlarmById(this.alarmId).subscribe(res => {
            let data = res;
            if (data['alarm']) {
                this.alarm.alarmId = data['alarm'].id;
                this.alarm.alarmType = data['alarm'].configuredAlarm?.name || 'NA';
                this.alarm.station = data['alarm'].station?.name || 'NA';
                this.alarm.stationId = data['alarm'].station?.id || '';
                this.alarm.configuredAlarmId = data['alarm'].configuredAlarm?.id || '';
                this.alarm.productionSector = data['alarm'].line?.name || 'NA';
                this.alarm.reworkComment = data['alarm'].comment || null;
                this.alarm.translationJson = data['alarm'].configuredAlarm?.translationJson || {};
                this.alarm.configuredAlarm = data['alarm'].configuredAlarm;
            }
            this.alarm['data'] = data['alarm'].data;
            if (data['disorderLog']) {
                this.alarm.reason = data['disorderLog'].reason;
                this.alarm.reasonTranslationJson = data['disorderLog'].reasonTranslationJson;
                this.alarm.duration = data['disorderLog'].duration;
                this.alarm.isDisorderUnderInvestigation = data['disorderLog'].isDisorderUnderInvestigation;
                this.alarm.comment = data['disorderLog'].comment;
                this.alarm.disorderId = data['disorderLog'].id;
            }
            if (data['alarmLog']) {
                data['alarmLog'].forEach(alg => {

                    if (alg.event == 3) {
                        this.alarm.forwarded = true;
                    }

                    if (alg.event == 8) {
                        this.alarm.escalated = true;
                    }
                });
            }
            if (data['creator']) {
                this.alarm.creator = data["creator"];
            }
            if (data['resolvedFrom']) {
                this.alarm.resolvedFrom = data["resolvedFrom"];
            }

        });

        var v1;
        var v2;
        this.alarmsAndEscalationsService.adminGetAlarmLogById(this.alarmId).subscribe(res => {
            var ranges = {
                lifeTime: [{ start: moment(res[0].occurred).toDate().getTime(), end: moment(res[res.length - 1].occurred).toDate().getTime(), log: res[0] }],
                active: [{ start: moment(res[0].occurred).toDate().getTime(), end: moment(res[1].occurred).toDate().getTime(), log: res[0] }],
                forwarded: [],
                subscribed: [],
                escalated: []
            }
            this.logStart = ranges.lifeTime[0].start;
            this.logEnd = ranges.lifeTime[0].end;

            this.newChartOptions.series[0].data.push({
                x: "Life Time",
                y: [
                    moment(res[0].occurred).toDate().getTime(),
                    moment(res[res.length - 1].occurred).toDate().getTime()
                ],
                user: ranges.lifeTime[0].log.user.name,
                sector: ranges.lifeTime[0].log.alarm.line.name,
                station: ranges.lifeTime[0].log.alarm.station.name,
            })

            for (let index = 0; index < res.length; index++) {
                //active
                if (res[index].event == 0) {
                    this.newChartOptions.series[0].data.push({
                        x: "Occured",
                        y: [
                            moment(res[index]?.occurred).toDate().getTime(),
                            moment(res[index + 1]?.occurred).toDate().getTime()
                        ],
                        fillColor: '#00694a',
                        user: res[index]?.user?.name,
                        sector: res[index]?.alarm?.line?.name,
                        station: res[index]?.alarm?.station?.name,
                    })
                }
                //forwarded
                if (res[index].event == 3) {
                    this.newChartOptions.series[0].data.push({
                        x: "Forwarded",
                        y: [
                            moment(res[index]?.occurred).toDate().getTime(),
                            moment(res[index + 1]?.occurred).toDate().getTime()
                        ],
                        fillColor: '#00694a',
                        user: res[index]?.user?.name,
                        sector: res[index]?.alarm?.line?.name,
                        station: res[index]?.alarm?.station?.name,
                    })
                    ranges.forwarded.push({
                        start: moment(res[index]?.occurred).toDate().getTime(),
                        end: moment(res[index + 1]?.occurred).toDate().getTime(),
                        log: res[index]
                    })
                }
                // subscribed
                if (res[index].event == 1) {
                    this.newChartOptions.series[0].data.push({
                        x: "Subscribed",
                        y: [
                            moment(res[index]?.occurred).toDate().getTime(),
                            moment(res[index + 1]?.occurred).toDate().getTime()
                        ],
                        fillColor: '#176eac',
                        user: res[index]?.user?.name,
                        sector: res[index]?.alarm?.line?.name,
                        station: res[index]?.alarm?.station?.name,
                    })
                    ranges.subscribed.push({
                        start: moment(res[index]?.occurred).toDate().getTime(),
                        end: moment(res[index + 1]?.occurred).toDate().getTime(),
                        log: res[index]
                    })
                }

                // escalated
                if (res[index].event == 8) {
                    this.newChartOptions.series[0].data.push({
                        x: "Escalated",
                        y: [
                            moment(res[index]?.occurred).toDate().getTime(),
                            moment(res[index + 1]?.occurred).toDate().getTime()
                        ],
                        fillColor: '#f00',
                        user: res[index]?.user?.name,
                        sector: res[index]?.alarm?.line?.name,
                        station: res[index]?.alarm?.station?.name,
                    })
                    this.alarm.escalated = true;
                    ranges.escalated.push({
                        start: moment(res[index]?.occurred).toDate().getTime(),
                        end: moment(res[index + 1]?.occurred).toDate().getTime(),
                        log: res[index]
                    })
                }


            }
            v1 = moment(res[0]?.occurred);
            v2 = moment(res[res.length - 1]?.occurred);

            var v3 = moment(res[1]?.occurred);

            this.series = [
                {
                    name: "Life Time",
                    data: [
                        {
                            x: "Life Time",
                            y: [
                                ranges.lifeTime[0].start,
                                ranges.lifeTime[0].end,
                                // moment().add("h", 0).toDate().getTime(),
                                // moment().add("h", 10).toDate().getTime(),
                            ],
                            user: ranges.lifeTime[0].log.user.name,
                            sector: ranges.lifeTime[0].log.alarm.line.name,
                            station: ranges.lifeTime[0].log.alarm.station.name,
                        },
                    ]
                },
                {
                    name: "Alarm flow",
                    data: [
                        {
                            x: "Active",
                            y: [
                                ranges.active[0]?.start,
                                ranges.active[0]?.end,
                            ],
                            fillColor: '#00694a',
                            id: 1,
                            user: ranges.lifeTime[0].log.user.name,
                            sector: '',
                            station: '',
                            alarmType: this.getTranslatedText('name', ranges.active[0].log.alarm.configuredAlarm?.translationJson)
                        },
                        // {
                        //     x: "Subscribed",
                        //     y: [
                        //         moment().add("h", 10).toDate().getTime(),
                        //         moment().add("h", 15).toDate().getTime(),
                        //     ],
                        //     fillColor: '#00694a',
                        //     id: 2,
                        //     user: 'alaa 1',
                        // },
                        // {
                        //     x: "Forwarded",
                        //     y: [
                        //         moment().add("h", 50).toDate().getTime(),
                        //         moment().add("h", 80).toDate().getTime(),
                        //     ],
                        //     fillColor: '#00694a',
                        //     id: 3,
                        //     user: 'alaa 1',
                        //     userGroup: 'User Group'
                        // },
                        // {
                        //     x: "Subscribed",
                        //     y: [
                        //         moment().add("h", 35).toDate().getTime(),
                        //         moment().add("h", 50).toDate().getTime(),
                        //     ],
                        //     fillColor: '#176eac',
                        //     id: 4,
                        //     user: 'alaa 1',
                        //     userGroup: 'User Group'
                        // },
                        // {
                        //     x: "Forwarded",
                        //     y: [
                        //         moment().add("h", 15).toDate().getTime(),
                        //         moment().add("h", 30).toDate().getTime(),
                        //     ],
                        //     fillColor: '#176eac',
                        //     user: 'alaa 1',
                        //     userGroup: 'User Group'
                        // },
                        // {
                        //     x: "Escalated",
                        //     y: [
                        //         moment().add("h", 30).toDate().getTime(),
                        //         moment().add("h", 35).toDate().getTime(),
                        //     ],
                        //     fillColor: '#f00',
                        //     userGroup: 'Escalation User Group'
                        // }
                    ]
                }


            ];

            for (let obj of ranges.subscribed) {
                this.series[1].data.push(
                    {
                        x: "Subscribed",
                        y: [
                            obj['start'],
                            obj['end'],
                        ],
                        fillColor: '#176eac',
                        id: 3,
                        user: obj['log'].user.name,
                        userGroup: '',
                        alarmType: this.getTranslatedText('name', obj['log'].alarm.configuredAlarm?.translationJson) 
                    }

                );

            }



            for (let obj of ranges.forwarded) {
                this.series[1].data.push(
                    {
                        x: "Forwarded",
                        y: [
                            obj['start'],
                            obj['end'],
                        ],
                        fillColor: '#00694a',
                        id: 3,
                        user: obj['log'].user.name,
                        userGroup: '',
                        alarmType: this.getTranslatedText('name', obj['log'].alarm.configuredAlarm?.translationJson)
                    }

                );
            }


            for (let obj of ranges.escalated) {
                this.series[1].data.push(
                    {
                        x: "Escalated",
                        y: [
                            obj['start'],
                            obj['end'],
                        ],
                        fillColor: '#f00',
                        id: 3,
                        user: obj['log'].user.name,
                        userGroup: '',
                        alarmType: this.getTranslatedText('name', obj['log'].alarm.configuredAlarm?.translationJson)
                    }

                );
            }



            const liftTime = moment(this.alarm.end).diff(moment(this.alarm.start), 'hour');
            // if (liftTime > 24 * 5) {
            // this.unit = 'days'
            // } else if (liftTime > 10) {
            this.unit = 'hours'
            // }

            this.chartOptions = {
                series: this.series,
                chart: {
                    height: 400,
                    type: "rangeBar",
                    zoom: {
                        enabled: true,
                        type: 'x'
                    },
                    selection: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: (val) => {
                        let labelText = "";
                        let format =  'MM/DD HH:mm:ss';
                        if(this.logStart == val[0] && this.logEnd == val[1] ){
                            labelText =   "(" + this.calculateDurationFromDates(val[0], val[1]) + ") " +  moment(val[0]).format(format) + " - " +  moment(val[1]).format(format) ;
                        }else{
                            labelText =  this.calculateDurationFromDates(val[0], val[1]);
                        }
                        return labelText ;

                    }
                },
                xaxis: {
                    type: "datetime"
                },
                legend: {
                    position: "top"
                },
                tooltip: {
                    custom: options => {
                        const item = this.series[options.seriesIndex].data[options.dataPointIndex];
                        if (!item)
                            return '';
                        let format = 'HH:mm:ss';
                        format = 'MM.DD.YYYY HH:mm:ss';

                        let text = `<div style="padding: 14px; min-width: 200px; text-align: center">
                        <div style="">
                        <span>${moment(item.y[0]).format(format)}</span> <small> - </small> <span>${moment(item.y[1]).format(format)}(${this.calculateDurationFromDates(item.y[0], item.y[1])})</span>
                        </div>`;
                        if(item.alarmType){
                            text = text + `<div style="">Alarm type: ${item.alarmType}</div>
                            `;
                        }
                        text = text + `
                        <div style="margin: 8px 0">
                           <strong> ${item.user ?? ''}</strong>
                        </div>
                        <div style="">
                            ${item.sector ?? ''}
                        </div>
                        <div style="">
                            ${item.station ?? ''}
                        </div>
                        <div style="">
                            ${item.userGroup ?? ''}
                        </div>
                        </div>
                        `
                        return text;
                    }
                }
            };


            this.isTimelioneLoaded = true;

        });


    }

    chartViewModeChanged(event: MatSelectChange) {
        if (event.value == 'ticks')
            this.chartOptions.xaxis = {
                type: "datetime",
                labels: {
                    formatter: (value, timestamp) => {
                        const diff = moment(value).diff(moment(this.alarm.start), this.unit);
                        if (diff < 0) {
                            return '';
                        } else {
                            return diff.toString();
                        }
                    }
                },
                tickAmount: 20,
                offsetX: 0
            }
        else {
            this.chartOptions.xaxis = {
                type: "datetime"
            }
        }
    }

    updateReason(alarm) {
        this.matDialog.open(UpdateReasonComponent, {
            data: {
                ...alarm,
                modalType: 'updateReason'
            },
            width: '550px'
        }).afterClosed().pipe().subscribe(res => {
            if (res) {
                this.ngOnInit();
            }
        });
    }

    calculateDurationFromDates = (begin, endTime) => {
        const begin1 = moment.utc(new Date(begin), 'HH:mm:ss');
        let end = moment.utc(new Date(endTime), 'HH:mm:ss');

        if (end.isSameOrBefore(begin1)) {
            end = end.add(1, 'day');
        }

        let dif = moment.duration(end.diff(begin)).asSeconds();
            let time = dif;
            let seconds = time % 60 < 10?'0'+Math.floor(time % 60):Math.floor(time % 60);
            time = time / 60;
            let minutes = time % 60 < 10?'0'+Math.floor(time % 60):Math.floor(time % 60);
            time = Math.floor(time / 60);
            let hours = time % 24 < 10?'0'+Math.floor(time % 24):Math.floor(time % 24);
        // return this.durationPipe.transform(Math.abs(momentBegin.diff(momentEnd)))    ;
        return hours+':'+minutes+':'+seconds;
    }

    getTranslatedText(text, translationObj = null): string {
        return this.commonService.getTranslatedName(text, translationObj);
    }

    isImageData(data){
        return data?.type === 'File';
    }

    getImageSrc(data) {
        return `${this.apiUrl}/api/alarm-file/get-content/${this.alarmId}/${data?.uniqueId}?authentication_token=${localStorage.getItem("token")}`;          
    }

    openViewModal(data){
        this.matDialog.open(ViewImageModalComponent, {
            width: '60%',
            disableClose: true,
            data: {apiUrl:this.apiUrl, alarm:true,alarmId:this.alarmId,video:data?.fileType==="video", uniqueId: data.uniqueId}
        })
    }

}
