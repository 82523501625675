import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {Router} from '@angular/router';
import {InfoboxService} from '../../../common/shared/services/infobox.service';
import {FormControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Idle} from '@ng-idle/core';
import { LoginLogoutService } from '../auth/login-logout.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '../../app/translate/services/translate.service';
import { StorageService } from '../../../common/shared/services/storage.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    form: UntypedFormGroup;
    public test: Boolean = false;
    public stayLoggedIn: FormControl = new FormControl(false);
    public showPassword: boolean = false;

    constructor(
        public authService: AuthService,
        private router: Router,
        private infoboxService: InfoboxService,
        private loginLogoutService: LoginLogoutService,
        private fb: UntypedFormBuilder,
        private idle: Idle,
        private dialog: MatDialog,
        private translateService: TranslateService,
        private storage: StorageService,
    ) {
        this.dialog.closeAll()
    }

    login() {
        if (this.form.valid) {
            // Store the 'Stay logged in' Checkbox value in local storage
            this.storage.setItem('stayLoggedIn', this.stayLoggedIn.value);

            this.loginLogoutService.login(this.form.value).subscribe(data => {
                if (!data) {
                  this.loginError();
                  return;
                }
                this.form.reset();
                this.authService.storeToken(data.token);
                this.idle.watch();
                const redirectUrl = this.authService.redirectUrl;
                let path = 'dashboard';
                this.translateService.changeLang(data.language);
                if (redirectUrl) {
                  this.authService.redirectUrl = null;
                  path = this.router.createUrlTree([redirectUrl.url], {queryParams: redirectUrl.queryParams}).toString()
                }
                window.location.pathname = path;
            }, () => this.loginError());
        }
    }

    loggedIn(): Boolean {
        return this.authService.loggedIn();
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
        if (this.loggedIn()) {
            this.router.navigate(['/dashboard']);
        }
    }

    private loginError() {
        this.infoboxService.pushInfo({danger: true, text: 'SPA.LOGIN.ERROR_INVALID'});
        this.form.reset();
    }
}
