import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class KeyMissingTranslationHandler implements MissingTranslationHandler {
    missingKeys: string[] = [];

    handle(params: MissingTranslationHandlerParams) {
        let key: string = params.key;
        if(key && !this.missingKeys.includes(key)) {
            console.error(`Missing translation for key: ${key}`);
            this.missingKeys.push(key);
        }
        return key; // Return the key itself as a placeholder
    }
}