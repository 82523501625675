import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DeviceTypeEnum } from "../../../../../../../common/device/enums/device-type.enum";
import { DEVICE_NAME_LENGTH } from "../../../../../../../common/device/constants/device.constants";
import { DeviceService } from "../../services/device.service";
import { UnassignedDeviceNetwork } from "../../network/unassigned-device.network";
import { UnassignedDeviceInterface } from "../../../../../../../common/unassigned-device/interfaces/unassigned-device.interface";
import { SocketMessageEnum } from "../../../../../../../common/socket/enums/socket-message.enum";
import { SocketService } from "../../../../../_services/socket.service";
import { Socket } from "../../../../../../../common/socket/models/socket.model";
import { SocketChannel } from "../../../../../../../common/socket/utils/socket-channel";
import { DeviceTypeUtil } from "../../../../../../../common/device/utils/device-type.util";
import { LineService } from "../../../../../../app/line/line.service";
import { Router } from '@angular/router';

@Component({
  selector: "app-device-add",
  templateUrl: "./device-add.component.html",
  styleUrls: ["./device-add.component.scss"],
})
export class DeviceAddComponent implements OnInit, OnDestroy {
  @Input() lineId: number | null;

  form: UntypedFormGroup;
  deviceTypes = DeviceTypeEnum;
  maxNameLength = DEVICE_NAME_LENGTH;
  unassignedDevices: UnassignedDeviceInterface[] = [];
  isVirtualStation: boolean = true; 
  private socket: Socket;
  private socketSubs: string[] = [];
  deviceType: string = '';
  constructor(
    public deviceService: DeviceService,
    private unassignedDeviceNetwork: UnassignedDeviceNetwork,
    private socketService: SocketService,
    private lineService: LineService,
    private router: Router
  ) {
    this.deviceType = this.router.url?.split('/')[1];
  }

  ngOnInit() {
    this.deviceService.init(this.lineId);
    this.initForm();
    this.fillUnassignedDevices();
    this.socket = this.socketService.getSocket();
    this.socket.join(SocketChannel.DEVICE);
    this.socketSubs.push(this.socket.on(SocketMessageEnum.DEVICE_UNASSIGNED_CONNECTED, () => {
      this.fillUnassignedDevices();
    }));

    this.form.get('type').valueChanges.subscribe((value)=>{
      this.fillUnassignedDevices();
    })

    this.form.get('isMultipleStation').valueChanges.subscribe(( value )=> {
      this.form.get('unassignedDeviceId').patchValue(null);
      if (value) {
        this.getAllMultipleStations();
      } else {
        this.fillUnassignedDevices();
      }
    })
  }

  initForm() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(this.maxNameLength),
      ]),
      type: new UntypedFormControl(null, Validators.required),
      isMultipleStation: new UntypedFormControl(false),
      unassignedDeviceId: new UntypedFormControl(null),
      isAndonAutomaticReboot: new UntypedFormControl(false),
    });
  }


  isAndon(): boolean {
    return this.deviceType === DeviceTypeEnum.ANDON;
  }

  save() {
    if (!this.form.valid) {
      return;
    }

    this.deviceService.addDevice(this.form.value);
  }

  ngOnDestroy(): void {
    this.socket.leave(SocketChannel.DEVICE);
    this.socketSubs.forEach((s) => this.socket.off(s));
  }

  private fillUnassignedDevices() {
    this.unassignedDeviceNetwork.getAll().subscribe((unassignedDevices) => {
      // this.unassignedDevices = unassignedDevices;
      if (this.form.get('type').value === this.deviceTypes.VIRTUAL_STATION) {
        this.isVirtualStation = false;
        this.unassignedDevices = unassignedDevices.filter(
          (i) => i.type === "virtual"
        );
        this.form.controls.unassignedDeviceId.setValue(this.unassignedDevices[0].id)
      } else {
        this.isVirtualStation = true;
        this.unassignedDevices = unassignedDevices.filter(
          (i) => i.type !== "virtual"
        );
      }
    });
  }

  private getAllMultipleStations() {
    this.unassignedDeviceNetwork.getAllDevicesForMultipleStations(this.lineId).subscribe((multipleDevices) => {
      this.unassignedDevices = multipleDevices;
    });
  }
}
