<vex-secondary-toolbar>
    <vex-breadcrumbs [crumbs]="crumbs" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>
<div class="page-content">
  <div class="row white-box shadow line-list">
      <value-tile *ngFor="let line of lines" [routerLink]="['/line/', line.id]" (click)="lineService.setSelectedLine(line.id)"
                  fxFlex="auto" iconClass="text-primary bg-primary-light" [label]="lineBeatText(line)" [icon]="getIconForType(line)"
                  [value]="line.name" [background]="'#F2F2F2'">
      </value-tile>
      <ng-container *ngIf="!loading">
          <value-tile class="add-line" *apConfiguration="[ConfigurationEnum.MULTILINE]" [routerLink]="['/line/add']"
                      fxFlex="auto" iconClass="text-primary bg-primary-light" icon="add_circle" [background]="'#F2F2F2'">
          </value-tile>
      </ng-container>

      <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
  </div>
</div>