import { AfterViewChecked, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LineService } from '../line.service';
import {BreadcrumbComponent} from '../../shared/components/breadcrumb.component';
import { LineInterface } from '../../../../common/line/interfaces/line.interface';
import { UserPermissionsEnum } from '../../../../common/auth/enums/user-permissions.enum';
import { MatLegacyTabNav as MatTabNav } from '@angular/material/legacy-tabs';

@Component({
    templateUrl: './line-detail.component.html',
    styleUrls: ['./line-detail.component.scss']
})
export class LineDetailComponent extends BreadcrumbComponent implements OnInit, AfterViewChecked {
    @ViewChild(MatTabNav) tabNav: MatTabNav;
    permissions = UserPermissionsEnum;
    selectedLine: LineInterface;

    constructor(
        private lineService: LineService,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(route, router);
    }

    public ngOnInit() {
        this.initBreadcrumbs();
        this.lineService.setSelectedLine(this.route.snapshot.params.id);
        this.route.params.subscribe(params => {
            this.lineService.getLine(params.id).subscribe((response) => {
                this.selectedLine = response;
                this.lineService.line = response;
            });
        })
    }

    public goBack() {
        this.router.navigate(['/line']);
    }

    ngAfterViewChecked(): void {
      if (!this.tabNav) {
          return;
      }

      this.tabNav._alignInkBarToSelectedTab();
    }
}
