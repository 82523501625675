import {Injectable} from '@angular/core';
import {IndividualConfig, ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class InfoboxService {

    constructor(private translate: TranslateService,
                private toastr: ToastrService) {
    }

    public error(message: string, params?: Object) {
        this.pushInfo({danger: true, text: message, params: params});
    }

    public success(message: string, params?: Object) {
        this.pushInfo({success: true, text: message, params: params});
    }

    public warning(message: string, params?: Object) {
        this.pushInfo({warning: true, text: message, params: params});
    }

    /**
     * @param info the info that should be shown
     * @example {danger: true, text: 'COMMON.GeneralError', params: someParamsObject}
     */
    pushInfo(info) {
        this.translate.get(info.text, info.params).subscribe((res: string) => {
            switch (true) {
                case info.danger:
                    this.toastr.error(res);
                    break;
                case info.success:
                    this.toastr.success(res);
                    break;
                case info.warning:
                    this.toastr.warning(res);
                    break;
            }
        });
    }

    errorOverride(msg: string, msgParams = {}, override: Partial<IndividualConfig> = {}) {
        const actualMsg = this.translate.instant(msg, msgParams);

        this.toastr.error(actualMsg, null, override);
    }
}
