import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { InfoboxService } from '../../../../common/shared/services/infobox.service';
import { TranslateService } from '../../translate/services/translate.service';
import { ReportsNetworkService } from '../services/reports.network.service';
import {DateAdapter} from '@angular/material/core';
import { DATE_FORMAT } from '../../../../common/shared/constants/time.constants';
import { LineInterface } from '../../../../common/line/interfaces/line.interface';
import {ModalsService} from '../../../../common/shared/services/modals.service';
import {ReportExportInterface} from '../interfaces/report-export.interface';
import { ReportTemplateInterface } from '../../../../common/reports/interfaces/report-template.interface';


@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExportComponent implements OnInit {
  exportObj: ReportExportInterface = {
    name: '',
    templateId: null,
    lineId: null,
    beginDate: null,
    endDate: null,
  };
  rangeName: string = "";
  reportExports: ReportExportInterface[] = [];
  templates: ReportTemplateInterface[] = [];
  lines: LineInterface[] = [];
  loading = false;
  constructor(
    private reportsNetworkService: ReportsNetworkService,
    private modalsService: ModalsService,
    private infoboxService: InfoboxService,
    private translateService: TranslateService,
    private adapter: DateAdapter<any>
  ) { }

  ngOnInit() {
    this.getTemplates();
    this.getLines();
    this.translateService.langChanged()
      .subscribe(() => {
          this.adapter.setLocale(this.translateService.userLang);
      });
  }

  getLines() {
    this.reportsNetworkService.getLines().subscribe(data => {
      this.lines = data;
    });
  }

  getTemplates() {
    this.reportsNetworkService.getTemplates().subscribe(data => {
      this.templates = data;
    });
  }

  disableDates(): boolean {
    return this.exportObj.isLastShift;
  }

  rangeChange() {
    this.exportObj.isLastShift = false;

    switch (this.rangeName) {
      case 'LAST_SHIFT': {
        this.exportObj.isLastShift = true;
        this.exportObj.beginDate = null;
        this.exportObj.endDate = null;
        break;
      }
      case 'YESTERDAY': {
        this.exportObj.beginDate = moment().subtract(1, 'days').startOf('day');
        this.exportObj.endDate = moment().subtract(1, 'days').endOf('day');
        break;
      }
      case 'CURRENT_WEEK': {
        this.exportObj.beginDate = moment().startOf('isoWeek');
        this.exportObj.endDate = moment().endOf('isoWeek');
        break;
      }
      case 'LAST_WEEK': {
        this.exportObj.beginDate = moment().subtract(1, 'weeks').startOf('isoWeek');
        this.exportObj.endDate = moment().subtract(1, 'weeks').endOf('isoWeek');
        break;
      }
      case 'CURRENT_MONTH': {
        this.exportObj.beginDate = moment().startOf('month');
        this.exportObj.endDate = moment().endOf('month');
        break;
      }
      case 'LAST_MONTH': {
        this.exportObj.beginDate = moment().subtract(1, 'months').startOf('month');
        this.exportObj.endDate = moment().subtract(1, 'months').endOf('month');
        break;
      }
      default: {
        this.exportObj.beginDate = moment().startOf('day');
        this.exportObj.endDate = moment().endOf('day');
        break;
      }
    }

    if (!this.exportObj.isLastShift) {
      this.exportObj.beginDate = moment(this.exportObj.beginDate).format(DATE_FORMAT);
      this.exportObj.endDate = moment(this.exportObj.endDate).format(DATE_FORMAT);
    }
  }

    createExport() {
        this.loading = true;

        this.reportsNetworkService.createExport(this.exportObj).subscribe((exportFile: {type: 'buffer', data: any[]}) => {
            const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileName = `${this.exportObj.name}-${moment().format('YYYY-MM-DD_HH-mm')}.xlsx`;
            const data: Blob = new Blob([Buffer.from(exportFile.data)], {type: EXCEL_TYPE});
            this.loading = false;
            FileSaver.saveAs(data, fileName);

            this.resetForm();
            this.rangeName = "";
            this.ngOnInit();
        });
    }

    resetForm() {
        this.exportObj = {
            name: '',
            templateId: null,
            lineId: null,
            beginDate: null,
            endDate: null,
        };
    }

    disableExport(): boolean {
        return !this.exportObj.name.length || !this.exportObj.lineId || !this.exportObj.templateId
            || (
                (this.exportObj.beginDate === null || this.exportObj.endDate === null)
                && !this.exportObj.isLastShift
                );
    }
}
