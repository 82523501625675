import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AEGroupInterface } from "../../groups/interfaces/a-e-group.interface";
import { AEGroupsService } from "../../groups/services/a-e-groups.service";
import { Location } from "@angular/common";
import { BreadcrumbComponent } from "../../../shared/components/breadcrumb.component";
import { InfoboxService } from "../../../../../common/shared/services/infobox.service";
import { UserService } from "../../../user-management/users/user.service";
import { UserInterface } from "../../../../../common/auth/interfaces/user.interface";

@Component({
  selector: "app-alarms-groups-form",
  templateUrl: "./alarms-groups-form.component.html",
  styleUrls: ["./alarms-groups-form.component.scss"],
})
export class AlarmsGroupsFormComponent
  extends BreadcrumbComponent
  implements OnInit {
  model: AEGroupInterface = {
    id: 0,
    name: "",
    expectedResponseTime: 0,
    userIds: [],
    lines: [],
    stationIds: [],
    sendOccurredNotifications: false,
    sendForwardedNotifications: false,
    sendEscalatedNotifications: false,
    sendEndedNotifications: false,
  };
  alarmsGroupId = 0;
  loading = false;
  public saveButtonDisabled = false;
  usersList: UserInterface[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private groupsService: AEGroupsService,
    private _location: Location,
    public router: Router,
    private infoboxService: InfoboxService,
    private userService: UserService
  ) {
    super(activatedRoute, router);
  }

  ngOnInit(): void {
    this.loading = true;
    this.getUserList().subscribe((userList) => {
      this.usersList = userList;
      this.model.userIds = userList;

      this.alarmsGroupId = +this.activatedRoute.snapshot.params.id;
      this.model.id = +this.alarmsGroupId;
      if (this.model.id) {
        this.groupsService.fetchGroup(this.model.id).subscribe(res => {
          if (res['users']) {
            this.model.userIds = res['users'].map(user => user.id);
          }
          this.loading = false;
          this.model.name = res['name'];
          this.model.sendOccurredNotifications =  res['sendOccurredNotifications'];
          this.model.sendForwardedNotifications =  res['sendForwardedNotifications'];
          this.model.sendEscalatedNotifications =  res['sendEscalatedNotifications'];
          this.model.sendEndedNotifications =  res['sendEndedNotifications'];
        })
      } else {
        this.loading = false;
      }
    });
    

  }

  getUserList() {
    return this.userService.getAllUsers();
  }

  goBack() {
    this._location.back();
  }

  onSubmit($event: Event) {
    try {
      this.saveButtonDisabled = true;
      this.groupsService.save(this.model).subscribe((res) => {
        this.infoboxService.success("SPA.ALARMS_ESCALATIONS.EDIT_SUCCESS");
        setTimeout(
          () => this.router.navigate(["/alarms-and-escalations/alarm-group"]),
          100
        );
      });
    } catch (e) {
      this.saveButtonDisabled = false;
      this.infoboxService.error(
        "SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.ALARM_GROUPS_FORM.ERROR.UPDATE"
      );
    }
  }


 

}
