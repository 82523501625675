export class ModelSubscriptionIdentifierUtil {
  static generateIdentifier(identifiersArray: {identifier: string}[]): string {
    const identifier = Math.random().toString(36).substr(7);

    if (identifiersArray.find(s => s.identifier === identifier)) {
      return this.generateIdentifier(identifiersArray);
    }

    return identifier;
  }
}