import {Routes} from "@angular/router";
import {AuthGuard} from "../_guards/auth.guard";
import {AlarmsAndEscalationsComponent} from "./alarms-and-escalations.component";
import {ConfigurationEnum} from '../../../common/configuration/enums/configuration.enum';
import {AlarmDashboardComponent} from "./alarm-dashboard/alarm-dashboard.component";
import {AlarmsComponent} from "./alarms/alarms.component";
import {AlarmDetailsComponent} from "./alarm-details/alarm-details.component";
import {AlarmFormComponent} from "./alarms/alarm-form/alarm-form.component";
import {AlarmsGroupsComponent} from "./alarms-groups/alarms-groups.component";
import {AlarmsGroupsFormComponent} from "./alarms-groups/alarms-groups-form/alarms-groups-form.component";
import {AlarmReasonsComponent} from "./alarm-reasons/alarm-reasons.component";
import {AlarmGroupListComponent} from "./alarms-groups/alarm-group-list/alarm-group-list.component";
import {AlarmsListComponent} from "./alarms/alarms-list/alarms-list.component";

export const ALARMS_AND_ESCALATIONS_ROUTES: Routes = [
    {
        path: '',
        component: AlarmsAndEscalationsComponent,
        canActivate: [AuthGuard],
        data: {
            configuration: ConfigurationEnum.ALARMS_AND_ESCALATIONS,
            breadcrumb: {
                label: 'SPA.NAV.ALARMS_AND_ESCALATIONS',
                path: '/alarms-and-escalations/dashboard'
            }
        },
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            },
            {
                path: 'dashboard', component: AlarmDashboardComponent,
                canActivate: [AuthGuard],
                data: {
                    permissions: [],
                    breadcrumb: {
                        label: 'SPA.ALARMS_ESCALATIONS.DASHBOARD.HEADER',
                        path: '/alarms-and-escalations/dashboard'
                    }
                },
            },
            {
                path: 'alarms', component: AlarmsComponent,
                canActivate: [AuthGuard],
                data: {
                    permissions: [],
                    breadcrumb: {
                        label: 'SPA.ALARMS_ESCALATIONS.ALARMS.HEADER',
                        path: '/alarms-and-escalations/alarms'
                    }
                },
                children: [
                    {
                        path: '', component: AlarmsListComponent
                    },
                    {
                        path: 'add', component: AlarmFormComponent,
                        canActivate: [AuthGuard],
                        data: {
                            permissions: [],
                            breadcrumb: {
                                label: 'SPA.ALARMS_ESCALATIONS.ALARMS.HEADER',
                                path: '/alarms-and-escalations/alarms/add'
                            }
                        },
                    },
                    {
                        path: 'form/:id', component: AlarmFormComponent,
                        canActivate: [AuthGuard],
                        data: {
                            permissions: [],
                            breadcrumb: {
                                label: 'SPA.ALARMS_ESCALATIONS.ALARMS.HEADER',
                                path: '/alarms-and-escalations/alarms/form'
                            }
                        },
                    },
                ]
            },
            {
                path: 'alarm-group', component: AlarmsGroupsComponent,
                canActivate: [AuthGuard],
                data: {
                    permissions: [],
                    breadcrumb: {
                        label: 'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.HEADER',
                        path: '/alarms-and-escalations/alarm-groups'
                    }
                },
                children: [
                    {path: '', component: AlarmGroupListComponent},
                    {
                        path: 'form', component: AlarmsGroupsFormComponent,
                        canActivate: [AuthGuard],
                        data: {
                            permissions: [],
                            breadcrumb: {
                                label: 'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.HEADER',
                                path: '/alarms-and-escalations/alarm-groups/form'
                            }
                        },
                    },
                    {
                        path: 'form/:id', component: AlarmsGroupsFormComponent,
                        canActivate: [AuthGuard],
                        data: {
                            permissions: [],
                            breadcrumb: {
                                label: 'SPA.ALARMS_ESCALATIONS.ALARM_GROUPS.HEADER',
                                path: '/alarms-and-escalations/alarm-groups/form'
                            }
                        },
                    },
                ]
            },

            {
                path: 'alarm-reasons', component: AlarmReasonsComponent,
                canActivate: [AuthGuard],
                data: {
                    permissions: [],
                    breadcrumb: {
                        label: 'SPA.ALARMS_ESCALATIONS.ALARM_REASONS.HEADER',
                        path: '/alarms-and-escalations/alarm-reasons'
                    }
                },
            },
            {
                path: ':id', component: AlarmDetailsComponent,
                canActivate: [AuthGuard],
                data: {
                    permissions: [],
                    breadcrumb: {
                        label: 'SPA.ALARMS_ESCALATIONS.ALARM_DETAILS.Header'
                    }
                },
            },
        ]
    }
];
