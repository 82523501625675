import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TableMetaDataInterface} from '../interfaces/table-meta-data.interface';
import { UserTableTypeEnum } from '../enums/user-table-type.enum';
import { UserTablePlaceEnum } from '../enums/user-table-place.enum';
import { ColumnMetaDataInterface } from '../interfaces/column-meta-data.interface';

@Injectable()
export class TableColumnVisibilityService {
    constructor(private http: HttpClient) {}

    loadTableMetaData(tableTypeEnum: UserTableTypeEnum, placeEnum: UserTablePlaceEnum) {
        const params = new HttpParams({
            fromObject: {
                tableTypeEnum: tableTypeEnum.toString(),
                placeEnum: placeEnum.toString(),
            }
        });
        return this.http.get<ColumnMetaDataInterface[]>( `/api/user-table-settings`, {params});
    }

    saveTableMetaData(data: TableMetaDataInterface) {
        return this.http.post<ColumnMetaDataInterface[]>( `/api/user-table-settings`, data);
    }
}