import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DisorderStationInterface} from '../../../../_utils/interfaces/disorder.interface';
import {Router} from '@angular/router';
import {DisorderRowModel} from '../../models/disorder-row.model';
import * as _ from 'lodash';
import {DisorderService} from '../../services/disorder.service';

export const enum ROW_MODE {
    LISTING = 'LISTING',
    EDITING = 'EDITING',
    ADD_EDIT_LISTING = 'ADD_EDIT_LISTING',
}

@Component({
  selector: 'app-group-disorder',
  templateUrl: './group-disorder.component.html',
  styleUrls: ['./group-disorder.component.scss']
})
export class GroupDisorderComponent {

    expanded = false;

    @Input() disorder: DisorderRowModel;
    @Output() onDelete: EventEmitter<DisorderRowModel> = new EventEmitter<DisorderRowModel>();

    constructor(
        private router: Router,
        private disorderService: DisorderService
    ) {}

    delete(disorder: DisorderRowModel, event) {
        this.onDelete.emit(disorder);
        event.stopPropagation();
    }

    isEditing(): boolean {
        return this.disorder.mode === ROW_MODE.EDITING;
    }

    isAddEditListing(): boolean {
        return this.disorder.mode === ROW_MODE.ADD_EDIT_LISTING;
    }

    onDisorderSaved(disorder: DisorderRowModel) {
        this.disorder.mode = ROW_MODE.ADD_EDIT_LISTING;
        if (disorder !== null) {
            this.disorder.updateModel(disorder);
        } else if(this.disorder.isNewRoot()) {
            this.router.navigateByUrl('/alarms-and-escalations/disorder');
        }
    }

    edit() {
        this.disorder.mode = ROW_MODE.EDITING;
    }

    transferListToString(data: DisorderStationInterface[]) {
        return data ? data.map(e => e.name).join(", ") : '';
    }

    addChild(event) {
        this.expanded = true;
        event.stopPropagation();
        this.disorder.addEmptyChild();
    }

    onChildDelete(disorderToRemove: DisorderRowModel) {
        this.disorderService.deleteConfirmation().subscribe((result) => {
            if (result) {
                this.disorderService.delete(disorderToRemove.id).subscribe(() => {
                    this.disorder.children = _.filter(this.disorder.children, (disorder: DisorderRowModel) => {
                        return disorder.id !== disorderToRemove.id;
                    });
                })
            }
        });
    }
}
