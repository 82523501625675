import { Component, OnInit } from '@angular/core';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment-timezone';
import { HttpClient } from '@angular/common/http';
import { InfoboxService } from '../../../../common/shared/services/infobox.service';
import { ClockService } from '../../../../common/shared/services/clock.service';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-timezone',
  templateUrl: './timezone.component.html',
  styleUrls: ['./timezone.component.scss']
})
export class TimezoneComponent extends BreadcrumbComponent implements OnInit {

  timezone: string;
  timezones: string[];
  filteredOptions: Observable<string[]>;
  myControl = new UntypedFormControl();
  constructor(
      private location: Location,
      private http: HttpClient,
      private infoboxService: InfoboxService,
      private clockService: ClockService,
      public route: ActivatedRoute,
      public router: Router
  ) {
    super(route, router);
  }

  ngOnInit(): void {
    this.initBreadcrumbs();
    this.timezones = moment.tz.names();
    this.timezone = this.clockService.timezone;
    this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map((value: string) => this._filter(value))
    );
  }

  goBack() {
    this.location.back();
  }

  save() {
    if (!this.isValid()) {
      return;
    }

    this.http.patch('/clock', {timezone: this.timezone}).subscribe(() => {
      this.infoboxService.success('COMMON.SAVED');
    });
  }

  isValid(): boolean {
    return this.timezones.includes(this.timezone);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.timezones.filter(option => option.toLowerCase().includes(filterValue));
  }
}
