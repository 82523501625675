import { Component, ElementRef, HostBinding, Input, OnInit, Renderer2 } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import icBookmarks from '@iconify/icons-ic/twotone-bookmarks';
import emojioneENG from '@iconify/icons-emojione/flag-for-flag-united-kingdom';
import emojioneDE from '@iconify/icons-emojione/flag-for-flag-germany';
import icMenu from '@iconify/icons-ic/twotone-menu';
import { ConfigService } from '../../services/config.service';
import { map } from 'rxjs/operators';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';
import icBallot from '@iconify/icons-ic/twotone-ballot';
import icDescription from '@iconify/icons-ic/twotone-description';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icDoneAll from '@iconify/icons-ic/twotone-done-all';
import { NavigationService } from '../../services/navigation.service';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icSearch from '@iconify/icons-ic/twotone-search';
import {TranslateService} from '../../../app/translate/services/translate.service';
import { LanguageEnum } from '../../../../common/shared/enums/language.enum';
import { Router } from '@angular/router';
import { StorageService } from '../../../../common/shared/services/storage.service';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

  navigationItems = this.navigationService.items;

  isHorizontalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));

  icSearch = icSearch;
  icBookmarks = icBookmarks;
  emojioneENG = emojioneENG;
  emojioneDE = emojioneDE;
  icMenu = icMenu;
  icPersonAdd = icPersonAdd;
  icAssignmentTurnedIn = icAssignmentTurnedIn;
  icBallot = icBallot;
  icDescription = icDescription;
  icAssignment = icAssignment;
  icReceipt = icReceipt;
  icDoneAll = icDoneAll;
  icArrowDropDown = icArrowDropDown;
  languages = LanguageEnum;
  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private navigationService: NavigationService,
              private translateService: TranslateService,
              private router: Router,
              private renderer: Renderer2,
              private storage: StorageService
              ) { }

  ngOnInit() {
  }

  openQuickpanel() {
    this.layoutService.openQuickpanel();
  }

  openSidenav() {
    this.layoutService.openSidenav();
  }

  setIcon(lang: LanguageEnum) {
    return `assets/images/${lang}.svg`;
  }

    langChange(lang: LanguageEnum) {
      const previousLanguage = this.storage.getItem('language');
      this.translateService.changeLang(lang);
      this.setBodyFontFamily(lang);
      this.reloadComponent(previousLanguage);
    }

    currentLangIcon() {
      return this.translateService.userLang === LanguageEnum.ENGLISH ? this.emojioneENG : this.emojioneDE;
    }

    reloadComponent(previousLanguage: string | null) {
      if (this.router.url === '/charts' && previousLanguage != this.storage.getItem('language')) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl(currentUrl);
        });
      }
    }

  // get current selected lang img url
  get langImgSrc(): string {
    let lang: LanguageEnum = this.translateService?.userLang || LanguageEnum.ENGLISH;
    return `assets/images/${lang}.svg`;
  }

  setBodyFontFamily(lang: LanguageEnum): void {
    let fontFamily: string = 'Lato, sans-serif';
    switch (lang) {
    case LanguageEnum.CHINESE:
        fontFamily = 'NatoSansSC, sans-serif'; // font for Chinese
        break; 
    default:
        fontFamily = 'Lato, sans-serif' // Default font
        break; 
    }
    if(lang && document?.body) {
        this.renderer.setStyle(document.body, 'font-family', fontFamily);
    }
  }
}
