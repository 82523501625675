import { ClockService } from '../services/clock.service';
import { AuthServiceInterface } from '../../auth/interfaces/auth-service.interface';
import { BaseSocketService } from '../../socket/services/base-socket.service';

export function clockFactory(clockService: ClockService, authService: AuthServiceInterface, socketService: BaseSocketService) {
  if (authService.loggedIn()) {
    clockService.listenTimezoneChanged(socketService)
    return () => clockService.loadClock();
  }

  return () => new Promise<void>(resolve => resolve());
}
