<vex-secondary-toolbar>
    <vex-breadcrumbs [crumbs]="crumbs" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>
<div class="page-content">
    <div class="row ap-card shadow">
        <div mat-tab-nav-bar class="tabs-nav responsive-tabs">
            <a mat-tab-link *ngFor="let menuItem of menuItems" [routerLink]="menuItem.path"
               [active]="rla.isActive" routerLinkActive #rla="routerLinkActive">
                <i class="material-icons">{{menuItem.icon}}</i>
                <span class="tab-title ml-3">{{menuItem.name | translate}}</span>
            </a>
        </div>
        <div class="mt-5">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>