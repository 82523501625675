import { Component, OnInit } from '@angular/core';
import { GeneralSettingsService } from '../../../app/system-settings/general-settings/serivces/general-settings.service';
import { LayoutService } from '../../../../src/@vex/services/layout.service';
import { USER_BENNER_SETTINGS_KEY } from '../../../../common/general-settings/constants/general-settings-key.constant';

@Component({
  selector: 'vex-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  userBannerText;

  constructor(
    private generalSettingsService: GeneralSettingsService,
    private layoutService: LayoutService
  ) {
  }

  ngOnInit( ) {
    this.getBannerText();
    this.layoutService.banner$.subscribe((response) => {
      if(response){
        this.getBannerText();
      };
    });
  }

  getBannerText() {
    this.generalSettingsService.getGeneralSettingValueByKey(USER_BENNER_SETTINGS_KEY).subscribe((result) => {
      if(result) {
        this.userBannerText = JSON.parse(result.settingValue).bannerText;
      } 
    });
  }
}
