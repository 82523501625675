<ng-container *ngIf="isLoadingData">
    <div style="padding: 20px;">
        <mat-spinner></mat-spinner>
    </div>
   
</ng-container>

<ng-container *ngIf="!isLoadingData">
<mat-accordion *ngIf="false">
    <mat-expansion-panel *ngFor="let stationIOMapping  of stationsIOMapping ; let stationMapIndex = index;  first as isFirst" [expanded]="isFirst">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{stationIOMapping.station.name}}
            </mat-panel-title>
        </mat-expansion-panel-header>
         <div>
            <div>
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">

                    <div fxFlex="auto" style="padding-top: 15px;">
                        <span>
                            No active Alarm
                        </span>

                    </div>

                    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" >
                        <mat-label>{{ 'Select Output' | translate }}</mat-label>
                        <mat-select name = 'mapping_{{stationIOMapping.station.id}}_NoAlarm_' [compareWith]="compareObjects"  [(ngModel)] = 'stationIOMapping.noAlarmOutput' (selectionChange) = 'dataChanged()'>
                            <mat-option *ngFor="let output of outputs" [value]="output">
                                {{output.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button class="deleteBtn" (click)='delete(stationMapIndex)' color="warn" mat-raised-button
                        type="button" style="height: 51px; margin-top: 2px;">
                        <mat-icon inline="true">delete</mat-icon>
                    </button>

                </div>

                <div>
                    <page-header titleTranslate="Alarms"></page-header>
                </div>
                
 
                <ng-container *ngFor="let mapping of stationIOMapping.mappingAlarms ; let mapInedx = index;">
                    <app-aes-stations-io-mapping-row  name="mapping_{{mapInedx}}_{{stationIOMapping.station.id}}" [(ngModel)]="stationIOMapping.mappingAlarms[mapInedx]" [stationId] = 'stationIOMapping.station.id' [inputs] = 'inputs' [outputs] = 'outputs' (deletMap) = "deleteMap(stationMapIndex , mapInedx)" (childChanged) = 'dataChanged()'></app-aes-stations-io-mapping-row>
                </ng-container>
                <!-- <div *ngFor="let mapping of stationIOMapping.ioMapping ; let mapInedx = index;"
                                        fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">

                                        
                                        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field"
                                            color="primary" >
                                            <mat-label>{{ 'Select Alarm' | translate }}</mat-label>
                                            <mat-select name = 'station_{{stationIOMapping.station.id}}_mapInedx'  >
                                                <mat-option *ngFor="let alarm of stationCAlarms[stationIOMapping.station.id]" [value]="alarm.id">
                                                    {{alarm.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field"
                                            color="primary">
                                            <mat-label>{{ 'Input for' | translate }}</mat-label>
                                            <mat-select>
                                                <mat-option *ngFor="let input of inputs" [value]="output">
                                                    {{input.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field"
                                            color="primary">
                                            <mat-label>{{ 'Output for' | translate }}</mat-label>
                                            <mat-select>
                                                <mat-option *ngFor="let output of outputs" [value]="output">
                                                    {{output.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <button class="deleteBtn" (click)='deleteMap(stationMapIndex , mapInedx)'
                                            color="warn" mat-raised-button type="button"
                                            style="height: 51px; margin-top: 2px;">
                                            <mat-icon inline="true">delete</mat-icon>
                                        </button>

                                    </div>  -->

                <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="8px" class="mt-3">
                    <button type="button" (click)='addMap(stationMapIndex)' color="primary" mat-raised-button>
                        <mat-icon matSuffix>add_circle_outline</mat-icon>
                    </button>
                </div>


            </div>

        </div> 
    </mat-expansion-panel>
</mat-accordion>





<div *ngIf="true">
    <div *ngFor="let stationIOMapping  of stationsIOMapping ; let stationMapIndex = index;  first as isFirst" >
         <div>
            <div>
                <div  style="font-weight: 900;font-size: 15px;margin-bottom: 10px;">
                    {{'SPA.DEVICE.IO_MAPPING.OUTPUT_FOR_NO_ACTIVE_ALARM' | translate}}
                </div>
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">

                   
                    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
                        <mat-label>{{ 'SPA.DEVICE.IO_MAPPING.SELECT_OUTPUT' | translate }}</mat-label>
                        <input type="text"
                               matInput
                               [(ngModel)]='noAlarmOutput'
                               [formControl]="outputAutoComplete"
                               [matAutocomplete]="output"
                               (focusout)="onFocusOut($event, stationMapIndex)"
                               >
                        <mat-autocomplete #output="matAutocomplete" [displayWith]="displayOutputFn"
                            (optionSelected)="dataChanged(stationMapIndex)">
                            <mat-option *ngFor="let output of filteredOutputOptions | async" [value]="output"
                                [disabled]="isDisableSelectOutput(stationMapIndex, output)">
                                {{output.name}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon style="font-size:20px" [inline]="true" matSuffix>
                            arrow_drop_down
                        </mat-icon>
                    </mat-form-field>
             
        
                    <button class="deleteBtn" (click)='delete(stationMapIndex)' color="warn" mat-raised-button
                    type="button" style="height: 51px; margin-top: 2px;">
                        <mat-icon inline="true">delete</mat-icon>
                    </button>
            
                </div>

                <div style="font-weight: 900;font-size: 15px;margin-bottom: 10px;">
                    {{ 'SPA.DEVICE.IO_MAPPING.ALARMS' | translate }}
                </div>
                
 
                <ng-container *ngFor="let mapping of stationIOMapping.mappingAlarms ; let mapInedx = index;">
                    <app-aes-stations-io-mapping-row name="mapping_{{mapInedx}}_{{stationIOMapping.station.id}}"
                        [(ngModel)]="stationIOMapping.mappingAlarms[mapInedx]" [stationId]='stationIOMapping.station.id' [inputs]='inputs'
                        [outputs]='outputs' (deletMap)="deleteMap(stationMapIndex , mapInedx)"
                        (childChanged)='dataChanged(stationMapIndex , mapInedx, $event)' [noAlarmOutput]="noAlarmOutput"></app-aes-stations-io-mapping-row>
                </ng-container>
                <!-- <div *ngFor="let mapping of stationIOMapping.ioMapping ; let mapInedx = index;"
                                        fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">

                                        
                                        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field"
                                            color="primary" >
                                            <mat-label>{{ 'Select Alarm' | translate }}</mat-label>
                                            <mat-select name = 'station_{{stationIOMapping.station.id}}_mapInedx'  >
                                                <mat-option *ngFor="let alarm of stationCAlarms[stationIOMapping.station.id]" [value]="alarm.id">
                                                    {{alarm.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field"
                                            color="primary">
                                            <mat-label>{{ 'Input for' | translate }}</mat-label>
                                            <mat-select>
                                                <mat-option *ngFor="let input of inputs" [value]="output">
                                                    {{input.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field"
                                            color="primary">
                                            <mat-label>{{ 'Output for' | translate }}</mat-label>
                                            <mat-select>
                                                <mat-option *ngFor="let output of outputs" [value]="output">
                                                    {{output.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <button class="deleteBtn" (click)='deleteMap(stationMapIndex , mapInedx)'
                                            color="warn" mat-raised-button type="button"
                                            style="height: 51px; margin-top: 2px;">
                                            <mat-icon inline="true">delete</mat-icon>
                                        </button>

                                    </div>  -->

                <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="8px" class="mt-3">
                    <button type="button" (click)='addMap(stationMapIndex)' color="primary" mat-raised-button>
                        <mat-icon matSuffix>add_circle_outline</mat-icon>
                    </button>
                </div>


            </div>

        </div> 
    </div>
</div>



</ng-container>