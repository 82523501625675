import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {ConfirmModalComponent} from '../components/modals/confirm/confirmModal.component';
import {first} from 'rxjs/operators';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Injectable()
export class ModalsService {
    constructor(private dialog: MatDialog) {

    }

    public confirm(
        content: string,
        title = 'COMMON.ARE_YOU_SURE',
        btnConfirm = 'COMMON.CONFIRM',
        btnCancel = 'COMMON.CANCEL',
    ): Observable<any> {
        return this.custom(ConfirmModalComponent, {
            data: {content, title, btnCancel, btnConfirm},
            scrollStrategy: new NoopScrollStrategy()
        });
    }

    public custom(component, data = {}): Observable<any> {
        return this.dialog.open(component, data).afterClosed().pipe(first());
    }
}