import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {RouterModule} from '@angular/router';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {
    CalendarDateFormatter,
    CalendarModule,
    CalendarNativeDateFormatter,
    DateAdapter,
    DateFormatterParams
} from 'angular-calendar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ResizableModule} from 'angular-resizable-element';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {ScrollbarModule} from '../../@vex/components/scrollbar/scrollbar.module';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {PageLayoutModule} from '../../@vex/components/page-layout/page-layout.module';
import {IconModule} from '@visurel/iconify-angular';
import {ContainerModule} from '../../@vex/directives/container/container.module';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {ShiftComponent} from './shift.component';
import { ShiftModalComponent } from './components/shift-modal/shift-modal.component';
import {ShiftNetwork} from './networks/shift-network';
import {ShiftService} from './services/shift.service';
import {LineService} from '../line/line.service';
import {UserService} from '../user-management/users/user.service';
import { RecurringModalComponent } from './components/recurring-modal/recurring-modal.component';

@Injectable()
class CustomDateFormatter extends CalendarNativeDateFormatter {
    public dayViewHour({date, locale}: DateFormatterParams): string {
        return new Intl.DateTimeFormat('de', {
            hour: 'numeric',
            minute: 'numeric'
        }).format(date);
    }
    public weekViewHour({date, locale}: DateFormatterParams): string {
        return new Intl.DateTimeFormat('de', {
            hour: 'numeric',
            minute: 'numeric'
        }).format(date);
    }
}

@NgModule({
  imports: [
    CommonModule,
    NgChartsModule,
    FormsModule,
    SharedModule,
    MatSelectModule,
    MatTabsModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }, {
        dateFormatter: {
            provide: CalendarDateFormatter,
            useClass: CustomDateFormatter
        }
    }),
    ResizableModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    ScrollbarModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    PageLayoutModule,
    IconModule,
    ContainerModule,
  ],
  declarations: [
      ShiftComponent,
      ShiftModalComponent,
      RecurringModalComponent,
  ],
    providers: [
        ShiftNetwork,
        ShiftService,
        LineService,
        UserService
    ]
})
export class ShiftModule { }
