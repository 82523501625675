<page-header titleTranslate="{{'SPA.WORK_INSTRUCTIONS.EDIT_MODAL.HEADER' | translate : {fileName: data.workInstruction?.name} }}"></page-header>
<mat-dialog-content *ngIf="isWorkInstructionAvailable">
    <div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
          <mat-label>{{ 'SPA.WORK_INSTRUCTIONS.NAME' | translate }}</mat-label>
          <input matInput required [(ngModel)]="workInstruction.name">
        </mat-form-field>
    </div>
    <div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.WORK_INSTRUCTIONS.CREATE.FILE_TYPE' | translate }}</mat-label>
            <ngx-mat-file-input
                    [(ngModel)]="workInstruction.file"
                    (ngModelChange)="fileChangeEvent($event)"
                    [accept]="'application/pdf'"
                    [valuePlaceholder]="fileName"
            >
            </ngx-mat-file-input>
            <mat-icon matSuffix>folder</mat-icon>
        </mat-form-field>
    </div>
    <div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{ 'SPA.WORK_INSTRUCTIONS.FOLDER' | translate }}</mat-label>
        <input matInput required [(ngModel)]="workInstruction.folder">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
        <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
            <mat-label>{{ 'SPA.WORK_INSTRUCTIONS.CREATE.SHOW_ON_STATIONS' | translate }}</mat-label>
            <mat-select multiple id="selectStations" name="stations" required [(ngModel)]="selectedStations">
                <ng-container *ngFor="let station of stations">
                    <mat-option [value]="station.id">{{station.name | titlecase}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="mt-10 footer">
        <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
            <button color="warn" mat-button type="button" (click)="closeDialog()"> {{ 'COMMON.CANCEL' | translate }}</button>
            <button color="primary" mat-raised-button (click)="submit()" [disabled]="disableSave() || loading">
                <ngx-loading [show]="loading"></ngx-loading>
                {{ 'SPA.WORK_INSTRUCTIONS.EDIT_MODAL.SAVE' | translate }}
            </button>
        </div>
    </div>
</mat-dialog-actions>
