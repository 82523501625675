import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SlideshowButtonEnum } from '../../../../common/andon/enums/slideshow-button.enum';
import { InfoboxService } from '../../../../common/shared/services/infobox.service';
import { SingleIoInterface } from '../../../../common/io-module/interfaces/single-io.interface';
import { SlideshowInterface } from '../../../../common/slideshow/interfaces/slideshow.interface';
import { Observable } from 'rxjs';
import { SlideshowButtonInterface } from '../../../../common/slideshow/interfaces/slideshow-button.interface';

@Injectable()
export class SlidesControlsService {
    isLoading = false;
    constructor(
        private http: HttpClient,
        private toastService: InfoboxService,
    ) {
    }

    startStop(andonId: number) {
       this.sendControlClickedInfo(andonId, SlideshowButtonEnum.START_PAUSE);
    }

    rewind(andonId: number) {
        this.sendControlClickedInfo(andonId, SlideshowButtonEnum.REWIND);
    }

    forward(andonId: number) {
        this.sendControlClickedInfo(andonId, SlideshowButtonEnum.FORWARD);
    }

    setIo(button: SlideshowButtonInterface, ioId: number) {
        this.http.put(`/slideshow-ios/button`, {
            buttonId: button.id,
            ioId
        }).subscribe((result) => {
            this.toastService.success('SPA.DEVICE.ANDON.IO_ASSIGNED');
        });
    }

    getIos() {
        return this.http.get<SingleIoInterface[]>(`/slideshow-ios`);
    }

    getSlideshow(andonId: number): Observable<SlideshowInterface> {
        return this.http.get<SlideshowInterface>(`/slideshow/${andonId}`);
    }

    private sendControlClickedInfo(andonId: number, status: SlideshowButtonEnum) {
        this.isLoading = true;
        this.http.post(`/slideshow/controls/${andonId}/action`, {status}).subscribe((result) => {
            this.toastService.success('SPA.DEVICE.ANDON.ACTION_EXECUTED_SUCCESSFULLY');
            this.isLoading = false;
        });
    }
}