import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AEGroupInterface} from '../interfaces/a-e-group.interface';
import {FormGroup} from '@angular/forms';
import { UserPermissionsEnum } from '../../../../../common/auth/enums/user-permissions.enum';
import { UserInterface } from '../../../../../common/auth/interfaces/user.interface';

@Injectable()
export class AEGroupsNetwork {
    constructor(private http: HttpClient) {}

    fetchGroupsList(): Observable<AEGroupInterface[]> {
        return this.http.get<AEGroupInterface[]>( `/alarm-group`);
    }

    fetchGroupDetails(id): Observable<AEGroupInterface> {
        return this.http.get<AEGroupInterface>( `/alarm-group/` + id);
    }

    delete(id: number): Observable<AEGroupInterface[]> {
        return this.http.delete<AEGroupInterface[]>( `/alarm-group/` + id);
    }

    fetchUsersList(): Observable<UserInterface[]> {
        const roleSupervisor = UserPermissionsEnum.AESupervisor;
        return this.http.get<UserInterface[]>( `/user/permission/` + roleSupervisor);
    }

    create(form: AEGroupInterface): Observable<AEGroupInterface> {
        return this.http.post<AEGroupInterface>( `/alarm-group`, form);
    }

    edit(form: AEGroupInterface, id): Observable<AEGroupInterface> {
        return this.http.patch<AEGroupInterface>( `/alarm-group/` + id, form);
    }
}